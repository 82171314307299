import React from 'react';
import { Grid, Stack } from '@mui/material';
import { Agent, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { ProfileContactCard, ProfileAgentInfo } from '../../components/profile/ProfileComponentsIndex';

interface ProfileAgentMainProps {
    agent?: Agent;
    listingCount: number;
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
}

const ProfileAgentMain: React.FC<{ profileAgentMain: ProfileAgentMainProps }> = ({ profileAgentMain }) => {

    return (
        <Grid container spacing={8}>

            {/* Left Column */}
            <Grid item xs={12} md={8}>

                <Stack direction="column" spacing={5} useFlexGap>

                    <ProfileAgentInfo profileAgentInfo={{
                        agent: profileAgentMain.agent,
                        lookupList: profileAgentMain.lookupList,
                        siteSettings: profileAgentMain.siteSettings
                    }} />

                </Stack>

            </Grid>

            {/* Right Column*/}
            {profileAgentMain.agent && <ProfileContactCard
                agent={profileAgentMain.agent}
                lookupList={profileAgentMain.lookupList}
                siteSettings={profileAgentMain.siteSettings}
                page="agent" />}

        </Grid>
    );
};

export default ProfileAgentMain;