import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, Avatar, Card, CardContent, useMediaQuery, Hidden, Typography, Tooltip, IconButton, Button, Grid, Stack, Link, Paper, Container, Skeleton, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon, Share as ShareIcon, Favorite as FavoriteIcon, Check as CheckIcon, PictureAsPdf as PictureAsPdfIcon, Info as InfoIcon, Attachment as AttachmentIcon, Stairs as StairsIcon, Favorite, FavoriteBorder } from '@mui/icons-material';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { getListingDefaultImagePath, getListingImagePath } from '../utils/helper/ImageHelper';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import SharePopup from '../utils/SharePopup';
import SiteSettings from '../../types/Settings/SiteSettings';

interface FavoriteItem {
    id: string;
    price: string;
    fullAddress: string;
    imagePath: string;
    propertyType: string;
}

const ListingToolBar: React.FC<{ listingDetail: ListingDetail, lookupList: LookupItem[], siteSettings: SiteSettings }> = ({ listingDetail, lookupList, siteSettings }) => {

    {/* Parameters Declaration */ }

    {/* Hook Declaration */ }
    const { t, i18n } = useTranslation();
    const [copied, setCopied] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [favoriteTooltip, setFavoriteTooltip] = useState(t('ListingFull_AddToFavouritesToolTip'));
    const [shareTooltip, setShareTooltip] = useState(t('ShareAndCompareWidget_Share'));
    const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const handleClickDocumentDropdown = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDocumentDropdown = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    const handleShareButtonClick = () => {
        setIsSharePopupOpen(true);
    };

    const handleCloseSharePopup = () => {
        setIsSharePopupOpen(false);
    };
    {/* Event Handlers */ }
    // const handleShareButtonClick = async () => {
    //     try {
    //         const currentURL = window.location.href; // Get current URL
    //         const fullURL = `${currentURL}`;

    //         await navigator.clipboard.writeText(fullURL);
    //         setCopied(true); // Update state to show "Copied to clipboard"
    //         setShareTooltip(t('ListingList_CopiedToClipboard')); // Update tooltip message
    //         setTimeout(() => {
    //             setCopied(false); // Reset copied state
    //             setShareTooltip(t('ShareAndCompareWidget_Share')); // Reset tooltip message
    //         }, 3000); // Reset state after 3 seconds
    //     } catch (err) {
    //         console.error('Failed to copy link in ListingToolBar component:', err);
    //         alert('Failed to copy link to clipboard');
    //     }
    // };

    const handleFavoriteClick = (id: string, price: number, fullAddress: string, imagePath: string, propertyType: string, rentalPriceGranularityUID?: string, transactionTypeUID?: string) => {
        // Get existing favorites from localStorage
        const existingFavorites: any[] = JSON.parse(localStorage.getItem('favorites') || '[]');
        // Check if the current item is already in the favorites list
        const isCurrentlyFavorite = existingFavorites.some((item: any) => item.id === id);
        if (isCurrentlyFavorite) {
            // If the item is already in the favorites list, remove it
            const updatedFavorites = existingFavorites.filter((item: any) => item.id !== id);
            // Save the updated favorites back to localStorage
            localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
            setIsFavorite(false); // Set isFavorite to false
            setFavoriteTooltip(t('ListingFull_AddToFavouritesToolTip')); // Reset tooltip message
        } else {
            // If the item is not in the favorites list, add it
            const favoriteItem = { id, price, fullAddress, imagePath, propertyType, rentalPriceGranularityUID, transactionTypeUID };
            const updatedFavorites = [favoriteItem, ...existingFavorites];
            // Save the updated favorites back to localStorage
            localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
            setIsFavorite(true); // Set isFavorite to true
            setFavoriteTooltip(t('ListingFull_RemoveToFavouritesToolTip')); // Update tooltip message
        }
    };

    {/* UseEffect Data Loading */ }
    useEffect(() => {
        const checkFavoriteStatus = () => {
            const favorites: FavoriteItem[] = JSON.parse(localStorage.getItem('favorites') || '[]');
            const isCardFavorite = favorites.some((item: FavoriteItem) => item.id === listingDetail.MLSID);
            setIsFavorite(isCardFavorite);
            if (isCardFavorite) {
                setFavoriteTooltip(t('ListingFull_RemoveToFavouritesToolTip')); // Set tooltip message if already a favorite
            }
        };
        checkFavoriteStatus();
    }, [listingDetail.MLSID]);

    if (isLoading) {
        return (
            <Stack direction="row" spacing={1}>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="circular" width={40} height={40} />
            </Stack>
        );
    }

    return (
        <>
            <Stack direction="row" useFlexGap alignItems="center">
                {/* Favourite Button */}
                <Tooltip title={isFavorite ? t('ListingFull_RemoveToFavouritesToolTip') : t('ListingFull_AddToFavouritesToolTip')} placement="bottom">
                    <IconButton aria-label="Favourite"
                        sx={{ color: 'var(--body-text-color) !important' }}
                        onClick={() => handleFavoriteClick(listingDetail?.MLSID
                            , listingDetail?.ListingPrice
                            , listingDetail?.FullAddress
                            , `${(listingDetail.ListingImages == null || listingDetail.ListingImages.length == 0) ?
                                getListingDefaultImagePath() :
                                getListingImagePath(listingDetail.ListingImages[0].FileName, listingDetail.RegionId, "0", "0")}`
                            , getLookupTranslationById(listingDetail.PropertyTypeUID.toString(), lookupList)
                            , listingDetail?.RentalPriceGranularityUID
                            , listingDetail?.TransactionTypeUID
                        )}>
                        {isFavorite ? <Favorite color="error" /> : <Favorite />}
                    </IconButton>
                </Tooltip>

                <Tooltip title={t("ShareAndCompareWidget_Share")} placement="bottom">
                    <IconButton
                        aria-label={t("ShareAndCompareWidget_Share")}
                        sx={{ color: 'var(--body-text-color) !important' }}
                        onClick={handleShareButtonClick}
                    >
                        <ShareIcon sx={{ color: 'var(--body-text-color) !important' }} />
                    </IconButton>
                </Tooltip>
                {isSharePopupOpen && (
                    <SharePopup open={isSharePopupOpen} onClose={handleCloseSharePopup} />
                )}

                {/* PDF Button */}
                {listingDetail?.PdfBrochureURL !== null && (
                    <Tooltip title={t('ListingFull_PdfBrochureButton')} placement="bottom" >
                        <IconButton href={'http://' + siteSettings.RegionDomain + listingDetail.PdfBrochureURL} target="_blank" aria-label={t('ListingFull_PdfBrochureButton')} sx={{ color: 'var(--body-text-color) !important' }}> 
                            <PictureAsPdfIcon sx={{ color: 'var(--body-text-color) !important' }} />
                        </IconButton>
                    </Tooltip>
                )}
                
                {/* Public Document Button */}
                {listingDetail?.HasPublicDocument && typeof(listingDetail.ListingDocuments) !== "undefined" && listingDetail.ListingDocuments !== null && (
                <Tooltip title={t('ListingFull_PublicDocumentButton')} placement="bottom">
                    <IconButton
                        onClick={handleClickDocumentDropdown}
                        aria-label={t('ListingFull_PublicDocumentButton')}
                        sx={{ color: 'var(--body-text-color) !important' }}
                    >
                        <FolderCopyIcon sx={{ color: 'var(--body-text-color) !important' }} />
                    </IconButton>
                </Tooltip>
                )}
            </Stack>

            {/* Public Document dropdown */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseDocumentDropdown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className=""
            >
                {/*To Do: Add Logic after data populated */}
                {typeof(listingDetail.ListingDocuments) !== "undefined" && listingDetail.ListingDocuments !== null && listingDetail.ListingDocuments.map((doc, index) => (
                    <a href={'http://' + siteSettings.RegionDomain + doc.FilePath} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'var(--body-text-color) !important' }}>
                    <MenuItem onClick={handleCloseDocumentDropdown} key={index}>
                        <ListItemIcon>
                            <AttachFileIcon fontSize="small" sx={{ color: 'var(--body-text-color) !important' }} />
                            </ListItemIcon>
                            <Typography className="body1" variant="body1">{doc.FileDescription}</Typography>
                    </MenuItem>
                    </a>
                ))}
            </Menu>
        </>
    );
};

export default ListingToolBar;
