import memoize from 'memoizee';
import { Development, DevelopmentSearchResponse } from '../types/TypesIndex';


const cacheDuration = 60 * 60 * 1000; // Cache for 1 hour
const memoizedFetch = memoize(async (url: string, options: RequestInit): Promise<any> => {
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
}, { promise: true, maxAge: cacheDuration });


// Get the development content by Development ID eg, Development Detail page
const fetchDevelopmentDataByDevelopmentId = async (developmentId: string): Promise<Development | null> => {
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_DEVELOPMENT}`;
    let filter = ''
    if (developmentId !== undefined)
        filter +=`content/TenantId eq ${window.tenantid} and content/RegionId eq ${window.macroregionid} and content/DevelopmentID eq ${developmentId} and content/IsActive eq true`   
     
    const requestBody = {
        count: true,
        skip: 0,
        top: 1,
        searchMode: 'any',
        queryType: 'simple',
        search: '*',
        filter: filter 
    };

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    });

    if (data.value.length === 0) { 
        return null; 
    }

    return data.value[0].content;
};


const fetchDevelopmentList = async ( body: any): Promise<DevelopmentSearchResponse> => {
    
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_DEVELOPMENT}`;

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Content-length': body.length,
        },
        body: JSON.stringify(body),
    });

    return data;
};

const fetchDevelopmentProvinceList = async (regionId: number): Promise<DevelopmentSearchResponse> => {

    let filter = ''
    if (regionId !== undefined)
        filter += `content/TenantId eq ${window.tenantid} and content/RegionId eq ${regionId} and content/IsActive eq true`

    const searchRequestBody = {
        count: true,
        skip: 0,
        top: 500,
        searchMode: 'any',
        queryType: 'simple',
        search: '*',
        facets: ["content/ProvinceID,count:500,sort:count"],
        filter: filter
    };


    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_DEVELOPMENT}`;

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchRequestBody),
    });
    return data;


};


export {fetchDevelopmentDataByDevelopmentId , fetchDevelopmentList, fetchDevelopmentProvinceList}