import React, { useCallback, useEffect } from 'react';
import { Box, Card, Stack, Paper, Button, Hidden, IconButton, Typography } from '@mui/material';
import ListingSearchFreeTextSearch from '../../components/search/ListingSearchFreeTextSearch';
import ListingSearchFilterDropdowns from '../../components/search/ListingSearchFilterDropdowns';
import ListingSearchMoreOptions from '../../components/search/ListingSearchMoreOptions';
import { useTranslation } from 'react-i18next';
import { LISTINGSEARCHFILTER, PAGE_TYPE } from '../../constants/constants';
import ListingSearchTabFilters from '../search/ListingSearchTabFilters';
import { Search as SearchIcon } from '@mui/icons-material';
import { useSearch } from '../../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleClickSearch } from '../utils/helper/handleClickSearch';
interface QuickSearchProps {
    handleClick: (option: any, search: boolean) => (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
    inputValue: string;
    isMobile: boolean;
}

interface Option {
    group: string;
    title: string;
    type: string;
    expression: string;
}
const QuickSearch: React.FC<QuickSearchProps> = ({ handleClick, inputValue, isMobile }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useSearch();
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const button = document.querySelector('.searchbar-button') as HTMLButtonElement;
        const handleTouch = (event: TouchEvent) => {
            handleClickSearch(null, true, state.searchState, dispatch, location, navigate)(event as any);
        };
        if (button) {
            button.addEventListener('touchstart', handleTouch);
        }
        return () => {
            if (button) {
                button.removeEventListener('touchstart', handleTouch);
            }
        };
    }, [state, dispatch, location, navigate]);

    return (
        <div

            style={{
                width: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Box>

                <Hidden lgUp>

                    <Card sx={{ padding: '1.5rem', borderRadius: '1rem', width: '100%' }}>

                        <Stack direction="column" spacing={2}>
                            <Box className="listing-search-tab-filter-mobile">
                                <ListingSearchTabFilters page={PAGE_TYPE.HOME} />
                            </Box>
                            <Paper className="light" elevation={0} component="form" sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', margin: 'auto', height: 'auto', minHeight: '1.4375em', padding: '.5rem', borderRadius: '4px' }}>

                                {/* Listing Search Free Text Search (component) */}
                                <ListingSearchFreeTextSearch
                                    onSearchClick={handleClick}
                                />

                            </Paper>

                            {/* Listing Search Dropdown Filters (component) */}
                            <ListingSearchFilterDropdowns page={PAGE_TYPE.HOME} />

                            <Button
                                role="button"
                                aria-label={t('Search_CmdSearch')}
                                className="primary-button searchbar-button"
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ width: '100%', padding: '0.75rem 1.5rem !important', color: 'white !important' }}
                                onClick={handleClick({ title: inputValue, type: 'FreeTextSearch', group: '', expression: inputValue }, true)} // Use the handleClick function here
                            >
                                <Typography>{t('Search_CmdSearch')}</Typography>
                            </Button>

                        </Stack>

                    </Card>

                </Hidden>


                <Hidden lgDown>

                    <Stack spacing={5} direction="column" sx={{ width: '100%' }}>

                        <Stack direction="column" mt={3} spacing={3} alignItems="center" justifyContent="space-between" useFlexGap sx={{ width: '100%' }}>

                            <Box sx={{ backgroundColor: 'white', borderRadius: '1.75rem' }}>
                                <ListingSearchTabFilters page={PAGE_TYPE.HOME} />
                            </Box>
                            {/* Search Bar */}
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"  >
                                <Paper className="light" elevation={0} component="form" sx={{ padding: '.5rem 1rem', borderRadius: '28px' }}>

                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" useFlexGap>

                                        {/* Search Button and Search Field */}
                                        <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%', minWidth: '250px' }} useFlexGap>

                                            {/* Listing Search Free Text Search (component) */}
                                            <ListingSearchFreeTextSearch
                                                onSearchClick={handleClick}
                                            />

                                        </Stack>

                                        {/* Listing Search Dropdown Filters (component) - Hidden on Mobile */}
                                        <Hidden lgDown>
                                            <ListingSearchFilterDropdowns page={PAGE_TYPE.HOME} />
                                        </Hidden>

                                    </Stack>

                                </Paper>
                                <IconButton role="button" aria-label="search" className='searchbar-button'
                                    sx={{ width: '48px', height: '48px' }}
                                    onClick={(event) => handleClickSearch(null, true, state.searchState, dispatch, location, navigate)(event)}

                                >
                                    <SearchIcon />
                                </IconButton>
                            </Stack>

                            <Hidden lgUp>
                                {/* Listing More Options (component) */}
                                <ListingSearchMoreOptions />
                            </Hidden>

                        </Stack>

                    </Stack>

                </Hidden>

            </Box>
        </div>
    );
};

export default QuickSearch;
