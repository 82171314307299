import React from 'react';
import { Helmet } from 'react-helmet';
import { isNoIndexNoFollowUrl } from '../helper/NoIndexHelper';

interface MetaTagsProps {
    title?: string;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords? : string;
    image?: string;
    url?: string;
    type?: string;
    sitename? : string;
    ogArticlePublisher? : string;    
}

const MetaTags: React.FC<{ metaTags: MetaTagsProps }> = ({ metaTags }) => {

    if (metaTags.type == null) {
        metaTags.type = 'website';
    }

    // To Do: Populate the SEO Meta Tags items 
    return (
        <>
            <Helmet>
                {isNoIndexNoFollowUrl && isNoIndexNoFollowUrl() == true && (
                    <meta name="robots" content="noindex, nofollow" />
                )}
                <title>{metaTags.title}</title>
                <meta name="title" content={metaTags.metaTitle} />
                <meta name="description" content={metaTags.metaDescription} />
                {metaTags.metaKeywords && metaTags.metaKeywords !== null && metaTags.metaKeywords !== '' && (
                    <meta name="keywords" content={metaTags.metaKeywords} />
                )}
                <meta property="fb:app_id" content='213981435691842' /> {/* It is app config value in GT site */}
                <meta property="og:locale" content={window.defaultlanguage} />

                <meta property="og:type" content={metaTags.type ? metaTags.type : 'article'} />
                <meta property="og:title" content={metaTags.metaTitle} />
                <meta property="og:description" content={metaTags.metaDescription} />
                <meta property="og:url" content={metaTags.url} />
                {metaTags.image && metaTags.image !== null && metaTags.image !== '' && (
                    <meta property="og:image" content={metaTags.image} />
                )}
                {metaTags.image && metaTags.image !== null && metaTags.image !== '' && (
                    <meta property="og:image:secure_url" content={metaTags.image} />
                )}
                {metaTags.sitename && metaTags.sitename !== null && metaTags.sitename !== '' && (
                    <meta property="og:site_name" content={metaTags.sitename} />
                )}
                <meta property="article:publisher" content={metaTags.ogArticlePublisher} />
                <meta property="twitter:title" content={metaTags.metaTitle} />
                <meta property="twitter:description" content={metaTags.metaDescription} />
                {metaTags.sitename && metaTags.sitename !== null && metaTags.sitename !== '' && (
                    <meta property="twitter:site" content={metaTags.sitename} />
                )}
                {metaTags.image && metaTags.image !== null && metaTags.image !== '' && (
                    <meta property="twitter:image" content={metaTags.image} />
                )}
                <meta name="format-detection" content="telephone=no" />
                {/*To DO
                    <meta property="twitter:card" content={metaTags.image} />       
                    <meta property="twitter:creator" content={metaTags.image} />        
                */ }
            </Helmet>
        </ >
    );
};

export default MetaTags;
