import { LISTING_STATUS_TYPES, TRANSACTION_TYPES } from '../constants/constants';
import { AdvancedContext } from '@/types/AdvancedSearch/Advanced';
import SearchResponse from '@/types/AgentSearchResponse';
import AgentSearchResult from '@/types/AgentSearchResult';
import ListingDetailContent from '@/types/Listing/ListingDetailContent';
import ListingDetailData from '@/types/Listing/ListingDetailData';
import OfficeSearchResponse from '@/types/OfficeSearchResponse';
import { debug } from 'console';
import { is } from 'date-fns/locale';

import memoize from 'memoizee';

const cacheDuration = 60 * 60 * 1000; // Cache for 1 hour

const memoizedFetch = memoize(async (url: string, options: RequestInit): Promise<any> => {
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}, { promise: true, maxAge: cacheDuration });

const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_LISTING}`;

const fetchWithPost = async (body: any): Promise<any> => {
  return memoizedFetch(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

const fetchListingCountByOfficeId = async (officeId: number): Promise<OfficeSearchResponse> => {
  const searchRequestBody = {
    count: true,
    skip: 0,
    top: 0,
    searchMode: 'any',
    queryType: 'simple',
    search: '*',
    filter: `content/TenantId eq ${window.tenantid} and content/MacroRegionId eq ${window.macroregionid} and content/OfficeId eq ${officeId} and content/OnHoldListing eq false`,
  };

  return fetchWithPost(searchRequestBody);
};

const fetchListingCountByMacroOfficeId = async (macroOfficeId: number): Promise<OfficeSearchResponse> => {
  const searchRequestBody = {
    count: true,
    skip: 0,
    top: 0,
    searchMode: 'any',
    queryType: 'simple',
    search: '*',
    filter: `content/TenantId eq ${window.tenantid} and content/MacroRegionId eq ${window.macroregionid} and content/MacroOfficeId eq ${macroOfficeId} and content/OnHoldListing eq false`,
  };

  return fetchWithPost(searchRequestBody);
};

const fetchListingCountByAgentId = async (agentId: number): Promise<AgentSearchResult> => {
  const searchRequestBody = {
    count: true,
    skip: 0,
    top: 0,
    searchMode: 'any',
    queryType: 'simple',
    search: '*',
    filter: `content/TenantId eq ${window.tenantid} and content/MacroRegionId eq ${window.regionid} and content/AgentId eq ${agentId} and content/OnHoldListing eq false`,
  };

  return fetchWithPost(searchRequestBody);
};

const fetchListingData = async (body: any): Promise<SearchResponse> => {
  return fetchWithPost(body);
};

const fetchListingDataV2 = async (body: any): Promise<SearchResponse> => {
  return fetchWithPost(body);
};


const fetchListingDataFacets = async (body: any): Promise<AdvancedContext> => {
  return fetchWithPost(body);
};

const fetchListingDataById = async (MLSID: string): Promise<ListingDetailData> => {
  
  const requestBody = {
    skip: 0,
    top: 1,
    searchMode: 'any',
    queryType: 'simple',
    search: '*',
    filter: `content/MLSID eq '${MLSID}' and content/OnHoldListing eq false`,
  };

  return fetchWithPost(requestBody);
};

const fetchListingDataByKey = async (lkey: string): Promise<ListingDetailData> => {

  const requestBody = {
    skip: 0,
    top: 1,
    searchMode: 'any',
    queryType: 'simple',
    search: '*',
    filter: `(content/ListingKey eq '${lkey}' or content/ListingKey eq '${lkey.toUpperCase()}') and content/OnHoldListing eq false`,
  };

  return fetchWithPost(requestBody);
};

const fetchListingNearby = async (
  geoName?: string | null,
  geoType?: string,
  geoId?: number,
  numberOfResults?: number,
  isSpotLight?: boolean,
  transactionType?: number | null,
  listingStatusUID?: number | null,
  numberOfBedrooms?: number | null,
  numberOfBathrooms?: number | null,
  totalNumOfRooms?: number | null,
  listingPrice?: number | null,
  soldPrice?: number | null,
): Promise<ListingDetailData> => {
  let orderBy = 'content/LastUpdatedOnWeb desc';
  let filter = `content/TenantId eq ${window.tenantid} and content/MacroRegionId eq ${window.macroregionid}`
  if (window.tenantid === undefined){
    await fetch('/ConfigRegion')
    .then(response => response.json())
    .then(data => {

    window.regionDomain = data.regiondomain;
    window.regionid = data.regionid;
    window.macroregionid = data.macroregionid;
    window.tenantid = data.tenantid;
    window.theme = data.theme;
    filter = `content/TenantId eq ${data.tenantid} and content/MacroRegionId eq ${data.macroregionid}`
  });
  }

  if (isSpotLight) {
    filter = filter + ` and (content/ListingStatusUID ne 167 and content/ListingStatusUID ne 169) and content/OnHoldListing eq false and content/IsRegionalOffice eq false`;
    const allContentData: ListingDetailContent[] = [];

    const conditions = [
      { MarketStatusUID: 3226, top: 9 }, // BestDeal
      { MarketStatusUID: 0, top: 9 },    // ComingSoon
      { MarketStatusUID: 1902, top: 9 }, // NewtotheMarket
      { MarketStatusUID: 1903, top: 9 }, // ComingSoon
    ];

    for (const condition of conditions) {

      const { MarketStatusUID, top } = condition;
      const requestBody = {
        skip: 0,
        top,
        searchMode: 'any',
        queryType: 'simple',
        search: '*',
        filter: `${filter} and content/MarketStatusUID eq ${MarketStatusUID} `,
        orderby: orderBy,
      };

      try {
        const data = await fetchWithPost(requestBody);

        if (data && data.value) {
          // Collect all content data
          allContentData.push(...data.value);
        }
        // console.log('data', data);
      } catch (error) {
        console.error(`Error fetching data for MarketStatusUID ${MarketStatusUID}:`, error);
      }
    }

    const finalData: ListingDetailData = {
      '@odata.context': '',
      '@odata.count': allContentData.length,
      value: allContentData && allContentData.length > 9 ? allContentData.slice(0, 9) : allContentData,
    };

    return finalData;
    //LastUpdatedOnWeb > 30 days
  } else {
    let geoFilter = '';

    if (geoId && geoId > 0 ) {
      geoFilter = ` and content/CityID eq ${geoId}`; //City was set on phase 1  
    } else if (geoName) {
      geoFilter = ` and search.ismatch('${geoName}', 'content/${geoType}', 'full', 'all')`; //City was set on phase 1      
    }
    filter = filter + geoFilter;

    const currentDate = new Date();
    const oneYearAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
    const oneYearAgoTimestamp = Math.floor(oneYearAgo.getTime() / 1000);

    if (transactionType === TRANSACTION_TYPES.FORSALE) {
      filter += ` and content/FirstUpdatedToWeb ge ${oneYearAgoTimestamp}`;
      filter += ` and content/ListingStatusUID ne 162 and content/ListingStatusUID ne 161 and content/ListingStatusUID ne 1616 and content/ListingStatusUID ne 167 and content/ListingStatusUID ne 169`;
      filter += ` and content/OnHoldListing eq false and content/IsRegionalOffice eq false`;
      filter += ` and content/TransactionTypeUID eq ${transactionType}`;      

      if (numberOfBedrooms) {
        let minNumberOfBedrooms = numberOfBedrooms - 2;
        let maxNumberOfBedrooms = numberOfBedrooms + 2;
        filter += ` and content/NumberOfBedrooms ge ${minNumberOfBedrooms} and content/NumberOfBedrooms le ${maxNumberOfBedrooms}`
      }
      if (numberOfBathrooms) {
        let minNumberOfBathrooms = numberOfBathrooms - 2;
        let maxNumberOfBathrooms = numberOfBathrooms + 2;
        filter += `  and content/NumberOfBathrooms ge ${minNumberOfBathrooms} and content/NumberOfBathrooms le ${maxNumberOfBathrooms}`
      }
      if (totalNumOfRooms) {
        let minTotalNumOfRooms = totalNumOfRooms - 2;
        let maxTotalNumOfRooms = totalNumOfRooms + 2;
        filter += ` and content/TotalNumOfRooms ge ${minTotalNumOfRooms} and content/TotalNumOfRooms le ${maxTotalNumOfRooms}`
      }
      if (listingPrice) {
        let minListingPrice = listingPrice * 0.85;
        let maxListingPrice = listingPrice * 1.15;
        filter += ` and content/ListingPrice ge ${minListingPrice} and content/ListingPrice le ${maxListingPrice}`
      }

      orderBy = 'content/FirstUpdatedToWeb desc';
    } else if (transactionType !== null) {
      filter += ` and content/TransactionTypeUID eq ${transactionType} `;
    }

    if (listingStatusUID === LISTING_STATUS_TYPES.SOLD) {
      orderBy = 'content/SoldDate desc';
      filter += ` and content/ListingStatusUID eq ${listingStatusUID}`
      filter += ` and content/FirstUpdatedToWeb ge ${oneYearAgoTimestamp}`;
      filter += ` and content/SoldDate ge ${oneYearAgoTimestamp}`;

      if (numberOfBedrooms) {
        let minNumberOfBedrooms = numberOfBedrooms - 2;
        let maxNumberOfBedrooms = numberOfBedrooms + 2;
        filter += ` and content/NumberOfBedrooms ge ${minNumberOfBedrooms} and content/NumberOfBedrooms le ${maxNumberOfBedrooms}`
      }
      if (numberOfBathrooms) {
        let minNumberOfBathrooms = numberOfBathrooms - 2;
        let maxNumberOfBathrooms = numberOfBathrooms + 2;
        filter += `  and content/NumberOfBathrooms ge ${minNumberOfBathrooms} and content/NumberOfBathrooms le ${maxNumberOfBathrooms}`
      }
      if (totalNumOfRooms) {
        let minTotalNumOfRooms = totalNumOfRooms - 2;
        let maxTotalNumOfRooms = totalNumOfRooms + 2;
        filter += ` and content/TotalNumOfRooms ge ${minTotalNumOfRooms} and content/TotalNumOfRooms le ${maxTotalNumOfRooms}`
      }
      if (listingPrice) {
        if (soldPrice) {
          let minSoldPrice = soldPrice * 0.85;
          let maxSoldPrice = soldPrice * 1.15;
          filter += ` and content/SoldPrice ge ${minSoldPrice} and content/SoldPrice le ${maxSoldPrice}`
        } else {
          let minListingPrice = listingPrice * 0.85;
          let maxListingPrice = listingPrice * 1.15;
          filter += ` and content/ListingPrice ge ${minListingPrice} and content/ListingPrice le ${maxListingPrice}`
        }
      }
    }
    const requestBody = {
      skip: 0,
      top: numberOfResults,
      searchMode: 'any',
      queryType: 'simple',
      search: '*',
      filter,
      orderby: orderBy,
    };

    return fetchWithPost(requestBody);
  }

};

const fetchListingCountByTeamId = async (teamid: number): Promise<OfficeSearchResponse> => {
  const searchRequestBody = {
    count: true,
    skip: 0,
    top: 0,
    searchMode: 'any',
    queryType: 'simple',
    search: '*',
    filter: `content/TenantId eq ${window.tenantid} and content/MacroRegionId eq ${window.macroregionid} and content/TeamID eq ${teamid} and content/OnHoldListing eq false `,
  };

  return fetchWithPost(searchRequestBody);
};


const fetchListingDataByDevelopmentId = async (developmentID: string): Promise<ListingDetailData> => {

  const searchRequestBody = {
    count: true,
    skip: 0,
    top: 24,
    searchMode: 'any',
    queryType: 'simple',
    search: '*',
    filter: `content/TenantId eq ${window.tenantid} and content/MacroRegionId eq ${window.macroregionid} and content/DevelopmentID eq ${developmentID} and content/OnHoldListing eq false `,
    orderby: `content/LastUpdatedOnWeb desc`
};


  return fetchWithPost(searchRequestBody);
};

export {
  fetchListingCountByOfficeId,
  fetchListingCountByMacroOfficeId,
  fetchListingCountByAgentId,
  fetchListingCountByTeamId, 
  fetchListingDataByDevelopmentId,
  fetchListingData,
  fetchListingDataById,
  fetchListingDataFacets,
  fetchListingDataV2,
  fetchListingNearby,
  fetchListingDataByKey
};
