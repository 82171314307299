import React, { useEffect, useState } from 'react';
import LookupItem from '../../types/LookupItem';
import { useTranslation } from 'react-i18next';
import ListingCard from './ListingCard';
import { Box, Link, Grid, Card, CardContent, CardMedia, Stack, Tooltip, Typography } from '@mui/material';
import { Panorama as PanoramaIcon, ThreeSixty as ThreeSixtyIcon, Image as ImageIcon, YouTube as YouTubeIcon, Bed as BedIcon, Shower as ShowerIcon, Favorite as FavoriteIcon, Share as ShareIcon, Map as MapIcon, Favorite, FavoriteBorder } from '@mui/icons-material';
import ListingDetailData from '../../types/Listing/ListingDetailData';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { fetchLookupTranslations } from '../../services/TranslationService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { SiteSettings } from '../../types/TypesIndex';
import { fetchListingNearby } from '../../services/ListingService';
import { set } from 'lodash';
import ListingDetailContent from '../../types/Listing/ListingDetailContent';

interface Props {
    title: string
    geoName?: string;
    geoType?: string;
    geoId?: number;
    numberOfResults?: number;
    tenantId: number;
    macroRegionId: number;
    transactionType?: number | null
    listingStatusUID?: number | null;
    isSpotLight?: boolean;
    numberOfBedrooms?: number;
    numberOfBathrooms?: number;
    totalNumOfRooms?: number;
    listingPrice?: number;
    soldPrice?: number;
    children?: React.ReactNode;
    itemsToShow?: number;
    setListingCount?: (data: number) => void;
}

const ListingSlider: React.FC<Props> = ({ title = null, geoName = null, geoType = 'City', geoId = 0, numberOfResults = 5,
    tenantId, macroRegionId, isSpotLight = false, transactionType = null, listingStatusUID = null,
    numberOfBedrooms = null, numberOfBathrooms = null, totalNumOfRooms = null, listingPrice = null, soldPrice = null, itemsToShow = 3 , setListingCount}) => {

    const [listingDetailData, setListingDetailData] = useState<ListingDetailData | any>();
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings | undefined>();
    const { t, i18n } = useTranslation();

    const responsive = {
        xl: {
            breakpoint: { max: 4000, min: 1536 },
            items: itemsToShow, 
        },
        lg: {
            breakpoint: { max: 1535, min: 1200 },
            items: 3, 
        },
        md: {
            // breakpoint from 768px
            breakpoint: { max: 1199, min: 600 },
            items: 2,
        },
        sm: {
            // breakpoint from 0px
            breakpoint: { max: 599, min: 0 },
            items: 1,
        },
    };


    useEffect(() => {
        const fetchData = async () => {
            
            //debugger;

            const result = await fetchListingNearby(geoName, geoType, geoId, numberOfResults,
                isSpotLight, transactionType, listingStatusUID, numberOfBedrooms, numberOfBathrooms,
                totalNumOfRooms, listingPrice, soldPrice
            ) as ListingDetailData;
            setListingDetailData(result);

            if (setListingCount !== undefined) {
                let resNum = result === undefined || result.value.length <= 3 ? 0 : result.value.length;
                setListingCount(resNum);
            }
        }

        fetchData();

    }, [geoName, setListingCount]);

    useEffect(() => {

        const loadSiteDataSource = async () => {
            try {
                await fetchLookupTranslations().then((result) => {
                    if (result == null) {
                        console.log("lookupList loads nothing");
                    } else {
                        setLookupList(result);
                    }
                });

                await fetchSiteSettings().then((siteSettings) => {
                    if (siteSettings == null) {
                        console.log("siteSettings loads nothing");
                    } else {
                        setSiteSettings(siteSettings);
                    }
                });
            } catch (error) {
                console.error('Profile Search : failed to load site data source', error);
            }

        };

        loadSiteDataSource();

    }, [i18n.language]);

    if (listingDetailData === undefined || listingDetailData.value.length <= 3) {
        return null;
    }

    return (
        <>
            {title && title !== '' && (

                <Typography className="h4" variant="h4" mb={4}>
                    {title}
                </Typography>

            )}

            {listingDetailData && (

                <Carousel
                    responsive={responsive}
                    showDots={false}
                    itemClass="carousel-item-padding"
                >

                    {listingDetailData.value.map((item: ListingDetailContent, index: number) => {

                        return (

                            <ListingCard
                                key={item.content.MLSID}
                                listingDetail={item.content}
                                lookupList={lookupList ?? []}
                                currency={siteSettings?.CurrencyCode ?? ""}
                                singleImage={true}
                                siteSettings={siteSettings}
                            />

                        );

                    })}

                </Carousel>

            )}

        </>

    );
};

export default ListingSlider;


