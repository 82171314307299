import React, { useEffect } from 'react';
import ListingCard from './ListingCard';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListingDetailData from '../../types/Listing/ListingDetailData';
import LookupItem from '../../types/LookupItem';
import { SiteSettings } from '../../types/TypesIndex';

const ListingCardList: React.FC<{ data: ListingDetailData, LookupItem: LookupItem[], currency: string, showMapAndImage?: boolean, gridSize?: number, siteSettings?: SiteSettings }> = ({ data, LookupItem, currency, showMapAndImage = true, gridSize = 3, siteSettings }) => {
    return (
        <div>
            <Grid container spacing={3}>
                {data.value.map((item) => (
                    <Grid key={item.metadata_storage_path} item xs={12} sm={12} md={6} lg={gridSize === 3 ? 4 : 6} xl={gridSize === 3 ? 3 : 6}>
                        <ListingCard listingDetail={item.content} lookupList={LookupItem} currency={currency} showMapAndImage={showMapAndImage} siteSettings={siteSettings} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default ListingCardList;