'use client';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Alert, Snackbar, MenuItem, Box, Stack, Card, CardHeader, CardContent, CardMedia, Typography, CardActions, Button, Link, useTheme, useMediaQuery, Modal, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HITNAMES, PAGE_TYPE } from '../../constants/constants';
import { leadService } from '../../services/LeadService';
import 'react-phone-input-2/lib/style.css';
import { z } from 'zod';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import SiteSettings from '../../types/Settings/SiteSettings';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { hitService } from '../../services/HitService';
import HitType from '@/types/Hit';


interface ContactFormProps {
    page?: string;
    tenantId?: number;
    regionId?: number;
    agentId?: string;
    officeId?: number;
    teamId?: number;
    agentName?: string;
    handleCloseDialog?: () => void;
    listingId?: string | number;
    listingKey?: string;
}


const ContactForm: React.FC<{ data: ContactFormProps }> = ({ data }) => {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const placeholderComments = data.page === PAGE_TYPE.LISTING_DETAIL ? `${t("ContactMe_CommentsForListing")}` : `${t("ContactMe_CommentsForProfile")}`
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    type FormData = z.infer<typeof schema>;

    const baseSchema = z.object({
        firstName: z.string().min(1, `${t('ContactMe_Required')}`),
        lastName: z.string().min(1, `${t('ContactMe_Required')}`),
        phone: z.string().optional(),
        email: z.string().optional().refine(value => !value || z.string().email().safeParse(value).success, {
            message: `${t('ContactMe_InvalidEmailAddress')}`,
        }),
        comments: z.string().optional(),

    });


    const schema = baseSchema.superRefine((data, ctx) => {
        if (!data.phone && !data.email) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `${t('ContactMe_Required')}`,
                path: ['phone'],
            });
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `${t('ContactMe_Required')}`,
                path: ['email'],
            });
        }
    });

    const initialFormData: FormData = { firstName: '', lastName: '', phone: '', email: '', comments: '' };
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<Record<keyof FormData, string>>>({});
    const [commentsLabel, setCommentsLabel] = useState(`${t(placeholderComments)}`);
    const [openModal, setOpenModal] = useState(false);


    useEffect(() => {

        const loadTranslations = async () => {
            const _siteSettings = await fetchSiteSettings();
            setSiteSettings(_siteSettings);

        }
        loadTranslations();

    }, []);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: undefined }));
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    }


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            schema.parse(formData);
            const body = {
                TenantId: data.tenantId ?? 0,
                RegionId: data.regionId ?? 0,
                ListingId: data.listingId ?? "",
                ListingKey: data.listingKey ?? "",
                FirstName: formData.firstName,
                LastName: formData.lastName,
                Phone: formData.phone,
                Email: formData.email,
                Message: formData.comments,
                CreationTime: new Date(),
                SAgentId: data.agentId ?? "0",
                OfficeId: data.officeId ?? 0,
                TeamId: data.teamId ?? 0,
                LanguageCode: i18n.language,
                SiteId: siteSettings?.SiteID ?? '',
                RequestHost: siteSettings?.RegionDomain ?? '',
                Captcha: siteSettings?.GoogleReCaptchaKey ?? '',
                AgentName: data.agentName,
                page: data.page ?? '',
                //IsTeamContact: false,
                //LeadSourceId: 0,
                //RequestViewing: false,
                //RequestViewingType: false,
                //RequestViewingDate: '',
                //PreferredTimes: '',
                //AgendaUid: '',
                //LeadLink: '',
            };

            const lead = leadService(body);
            setFormData(initialFormData);
            setCommentsLabel(`${t(placeholderComments)}`);
            setErrors({});

            if (data.page === PAGE_TYPE.TEAM) {
                const hitData: HitType = {
                    TenantId: data.tenantId,
                    RegionId: data.regionId,
                    LanguageCode: i18n.language,
                    OfficeId: data.officeId,
                    TeamId: data.teamId,
                    SiteType: siteSettings?.SiteType ?? "",
                    HitType: HITNAMES.TEAMSWEBSITELEADS,
                }
                hitService(hitData);
            } 
            //Remax do not need will be add for REP in the future
            // else {
            //     const hitData: HitType = {
            //         TenantId: data.tenantId,
            //         RegionId: data.regionId,
            //         LanguageCode: i18n.language,
            //         AgentId: Number(data.agentId) || 0,
            //         OfficeId: data.officeId,
            //         ListingId: data.listingId,
            //         SiteType: siteSettings?.SiteType ?? "",
            //         HitType: HITNAMES.CONTACT_ME,
            //     }
            //     hitService(hitData);
            //}


            setOpenModal(true);

            // Clear the confirmation message after 3 seconds
            setTimeout(() => {
                if (data.handleCloseDialog) {
                    data.handleCloseDialog();
                }
                setOpenModal(false);

            }, 3000); // 3000 milliseconds = 3 seconds



        } catch (e) {
            if (e instanceof z.ZodError) {
                const fieldErrors: Partial<Record<keyof FormData, string>> = {};
                e.errors.forEach(err => {
                    if (err.path.length > 0) {
                        const fieldName = err.path[0] as keyof FormData;
                        fieldErrors[fieldName] = err.message;
                    }
                });
                setErrors(fieldErrors);
            }
        }

    };

    // const [isAgentOfficePage, setisAgentOfficePage] = useState(false);

    // useEffect(() => {
    //     const location = window.location.pathname;

    //     // Check if 'agent' exists in the URL path
    //     if (location.includes('/agent/') || location.includes('/office/')) {
    //         setisAgentOfficePage(true);
    //     } else {
    //         setisAgentOfficePage(false);
    //     }
    // }, []);
    const [isPopupOpenSentMessage, setIsPopupOpenSentMessage] = useState(false);
    const handleClosePopup = () => {
        setIsPopupOpenSentMessage(false);
        if (data.handleCloseDialog) {
            data.handleCloseDialog();
        }

    };

    return (
        <>



            {/* Form Everywhere Else */}
            {(data.page !== PAGE_TYPE.AGENT && data.page !== PAGE_TYPE.OFFICE && data.page !== PAGE_TYPE.TEAM && !isMobile) && (

                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <Stack direction="row" spacing={2} useFlexGap>
                        {/* First name field */}
                        <TextField
                            label={t("ContactMe_FirstName")}
                            InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                            InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                            fullWidth
                            name="firstName"
                            value={formData.firstName}
                            margin="normal"
                            variant="outlined"
                            onChange={handleChange}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                            aria-invalid={!!errors.firstName}
                            aria-describedby="first-name-helper-text"
                        />

                        {/* To Do - Last name field Hidden for Brazil */}
                        <TextField
                            label={t("ContactMe_LastName")}
                            InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                            InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                            name="lastName"
                            value={formData.lastName}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                            aria-invalid={!!errors.lastName}
                            aria-describedby="last-name-helper-text"
                        />

                    </Stack>

                    <Stack direction="row" spacing={2} useFlexGap>

                        {/* Phone Number */}
                        <TextField
                            label={t("ContactMe_Phone")}
                            name="phone"
                            value={formData.phone}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onChange={handleChange}
                            error={!!errors.phone}
                            helperText={errors.phone}
                            aria-invalid={!!errors.phone}
                            aria-describedby="phone-helper-text"
                            InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                            InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        />

                        {/* Phase II */}
                        {/* <PhoneInput
                        country={siteSettings?.CountryCode.toLocaleLowerCase() ?? 'us'}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        inputStyle={{ width: '100%' }}
                        containerStyle={{ marginBottom: '16px' }}
                        isValid={value => {
                            try {
                                phoneSchema.parse(value);
                                return true;
                            } catch {
                                return false;
                            }
                        }}
                        inputProps={{
                            'aria-invalid': !!errors.phone,
                            'aria-describedby': 'phone-helper-text'
                        }}
                        inputClass={`form-control ${errors.phone ? 'is-invalid' : ''}`} // Ensure the correct CSS class for validation
                    />
                    {errors.phone && (
                        <FormHelperText id="phone-helper-text" error>
                            {errors.phone}
                        </FormHelperText>
                    )} */}

                        {/* Email */}
                        <TextField
                            label={t("ContactMe_EmailAddress")}
                            InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                            InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            aria-invalid={!!errors.email}
                            aria-describedby="email-helper-text"
                            fullWidth
                            margin="normal"
                            FormHelperTextProps={{ style: { color: '#d32f2f !important' } }}
                        />

                        {/* To Do - Schedule a Viewing Hidden For Brazil */}
                        {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t("ScheduleAviewing")}
                            className="calendar-input"
                            sx={{ width: '100%', marginTop: '1rem', marginBottom: '.5rem' }}
                        />
                    </LocalizationProvider>*/}

                        {/* To Do - These Checkboxes Are Hidden for Brazil */}
                        {/*<Stack direction="row" spacing={2}>
                        <FormControlLabel
                            control={<Checkbox size="small" checked={virtual} onChange={(e) => setVirtual(e.target.checked)} id="video-meeting" />}
                            label={t("Virtual")}
                        />
                        <FormControlLabel
                            control={<Checkbox size="small" checked={inPerson} onChange={(e) => setInPerson(e.target.checked)} id="in-person" />}
                            label={t("InPerson")}
                        />
                    </Stack>*/}

                    </Stack>

                    {/* Comments */}
                    <TextField
                        sx={{ marginBottom: '0' }}
                        label="Comments"
                        name="comments"
                        placeholder={placeholderComments}
                        InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        rows={6}
                        fullWidth
                        multiline
                        value={formData.comments}
                        onChange={handleChange}
                        aria-invalid={!!errors.comments}
                        margin="normal"
                        variant="outlined"

                    />
                    {/* To Do - Terms and Conditions and Contact Agreement Checkboxes Hidden for Brazil */}
                    {/*<Stack className="text-align-left" direction="column" spacing={1} mt={1} mb={1} justifyContent="space-between" alignItems="start">
                        {/* Terms and conditions checkbox */}
                    {/* To Do - Marcelo add Terms and Conditions page, must be dynamic/specific to each tenant */}
                    {/*<FormControlLabel
                            control={<Checkbox size="small" checked={termsConditions} onChange={(e) => setTermsConditions(e.target.checked)} id="terms-conditions" />}
                            label={
                                <Link href="/terms-and-conditions" title="terms & conditions" aria-label="See terms & conditions" target="_blank" rel="noopener noreferrer">
                                    {t("ContactMe_TermsOfUseAndPrivacyPolicyGlobal")}
                                </Link>
                            }
                    />*/}
                    {/* Contact agreement checkbox */}
                    {/* To Do - Tenant object not pulling proper tenant */}
                    {/*<FormControlLabel
                            control={<Checkbox size="small" checked={contactAgreement} onChange={(e) => setContactAgreement(e.target.checked)} id="contact-agreement" />}
                            label={t("GDPR_Checkbox_ContactMe")}
                        />
                    </Stack>*/}


                    {/* Send Message Button */}
                    {/* To Do - Marcelo this translation is not working */}
                    <Button className="primary-button" role="button" aria-label="Send Message" type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                        {t("ContactMe_SendMessage")}
                    </Button>
                </Box>

            )}

            {/* Agent/Office Single Column Contact Form */}
            {((data.page === PAGE_TYPE.AGENT || data.page === PAGE_TYPE.OFFICE || data.page === PAGE_TYPE.TEAM) || isMobile) && (

                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>

                    {/* First name field */}
                    <TextField
                        label={t("ContactMe_FirstName")}
                        InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        fullWidth
                        name="firstName"
                        value={formData.firstName}
                        margin="normal"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        aria-invalid={!!errors.firstName}
                        aria-describedby="first-name-helper-text"
                    />

                    {/* To Do - Last name field Hidden for Brazil */}
                    <TextField
                        label={t("ContactMe_LastName")}
                        InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        name="lastName"
                        value={formData.lastName}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        aria-invalid={!!errors.lastName}
                        aria-describedby="last-name-helper-text"
                    />

                    {/* Phone Number */}
                    <TextField
                        label={t("ContactMe_Phone")}
                        name="phone"
                        value={formData.phone}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        aria-invalid={!!errors.phone}
                        aria-describedby="phone-helper-text"
                        InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                    />
                    {/* Phase II */}
                    {/* <PhoneInput
                        country={siteSettings?.CountryCode.toLocaleLowerCase() ?? 'us'}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        inputStyle={{ width: '100%' }}
                        containerStyle={{ marginBottom: '16px' }}
                        isValid={value => {
                            try {
                                phoneSchema.parse(value);
                                return true;
                            } catch {
                                return false;
                            }
                        }}
                        inputProps={{
                            'aria-invalid': !!errors.phone,
                            'aria-describedby': 'phone-helper-text'
                        }}
                        inputClass={`form-control ${errors.phone ? 'is-invalid' : ''}`} // Ensure the correct CSS class for validation
                    />
                    {errors.phone && (
                        <FormHelperText id="phone-helper-text" error>
                            {errors.phone}
                        </FormHelperText>
                    )} */}
                    {/* Email */}
                    <TextField
                        label={t("ContactMe_EmailAddress")}
                        InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        aria-invalid={!!errors.email}
                        aria-describedby="email-helper-text"
                        fullWidth
                        margin="normal"
                        FormHelperTextProps={{ style: { color: '#d32f2f !important' } }}
                    />

                    {/* To Do - Schedule a Viewing Hidden For Brazil */}
                    {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t("ScheduleAviewing")}
                            className="calendar-input"
                            sx={{ width: '100%', marginTop: '1rem', marginBottom: '.5rem' }}
                        />
                    </LocalizationProvider>*/}

                    {/* To Do - These Checkboxes Are Hidden for Brazil */}
                    {/*<Stack direction="row" spacing={2}>
                        <FormControlLabel
                            control={<Checkbox size="small" checked={virtual} onChange={(e) => setVirtual(e.target.checked)} id="video-meeting" />}
                            label={t("Virtual")}
                        />
                        <FormControlLabel
                            control={<Checkbox size="small" checked={inPerson} onChange={(e) => setInPerson(e.target.checked)} id="in-person" />}
                            label={t("InPerson")}
                        />
                    </Stack>*/}

                    {/* Comments */}
                    <TextField
                        sx={{ marginBottom: '0' }}
                        label="Comments"
                        name="comments"
                        placeholder={placeholderComments}
                        InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                        rows={7}
                        fullWidth
                        multiline
                        value={formData.comments}
                        onChange={handleChange}
                        aria-invalid={!!errors.comments}
                        margin="normal"
                        variant="outlined"

                    />
                    {/* To Do - Terms and Conditions and Contact Agreement Checkboxes Hidden for Brazil */}
                    {/*<Stack className="text-align-left" direction="column" spacing={1} mt={1} mb={1} justifyContent="space-between" alignItems="start">
                        {/* Terms and conditions checkbox */}
                    {/* To Do - Marcelo add Terms and Conditions page, must be dynamic/specific to each tenant */}
                    {/*<FormControlLabel
                            control={<Checkbox size="small" checked={termsConditions} onChange={(e) => setTermsConditions(e.target.checked)} id="terms-conditions" />}
                            label={
                                <Link href="/terms-and-conditions" title="terms & conditions" aria-label="See terms & conditions" target="_blank" rel="noopener noreferrer">
                                    {t("ContactMe_TermsOfUseAndPrivacyPolicyGlobal")}
                                </Link>
                            }
                    />*/}
                    {/* Contact agreement checkbox */}
                    {/* To Do - Tenant object not pulling proper tenant */}
                    {/*<FormControlLabel
                            control={<Checkbox size="small" checked={contactAgreement} onChange={(e) => setContactAgreement(e.target.checked)} id="contact-agreement" />}
                            label={t("GDPR_Checkbox_ContactMe")}
                        />
                    </Stack>*/}


                    {/* Send Message Button */}
                    {/* To Do - Marcelo this translation is not working */}
                    <Button className="primary-button" role="button" aria-label="Send Message" type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: '1.5rem', marginBottom: '1.5rem', backgroundColor: 'var(--primary-color) !important', padding: '0.75rem 1.5rem !important', color: 'white !important' }}>
                        {t("ContactMe_SendMessage")}
                    </Button>
                </Box>

            )}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box className="contact-me-message" sx={{ width: 'calc(100% - 32px)', maxWidth: '552px', maxHieight: 'calc(100% - 64px) !important', height: { xs: '715px', sm: 'unset' } }}>
                    <DoneAllIcon style={{ fontSize: 48 }} color='success' className="icon-reveal" />
                    <Typography className="h4" variant="h4">{t('ContactMe_YourMessageHasBeenSent')}</Typography>
                </Box>
            </Modal>
        </>
    );
};

export default ContactForm;