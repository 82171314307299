import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Dialog, DialogContent, IconButton, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'react-image-gallery/styles/css/image-gallery.css';
import ListingDetailData from '@/types/Listing/ListingDetailData';
import ListingStatusChips from '../../components/utils/ListingStatusChips';
import * as PANOLENS from 'panolens';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { getListingImagePath } from '../utils/helper/ImageHelper';
import { getYouTubeEmbedUrl, isValidYouTubeUrl } from '../utils/helper/FunctionHelper';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CloseIcon from '@mui/icons-material/Close';
import VideocamIcon from '@mui/icons-material/Videocam';
import ImageIcon from '@mui/icons-material/Image';
import PanoramaPhotosphereIcon from '@mui/icons-material/PanoramaPhotosphere';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface Props {
    property: ListingDetailData;
    lookupitem: any[];
}

const ListingMobileImageSliderAndToolbar: React.FC<Props> = ({ property, lookupitem }) => {
    const { t } = useTranslation();
    const ListingDetail = property?.value[0]?.content;

    const image360UID = "5103";
    const panoramaUID = "5104";

    const panoramaRefs = useRef<(HTMLDivElement | null)[]>([]);
    const image360Refs = useRef<(HTMLDivElement | null)[]>([]);
    const panoramaViewers = useRef<(PANOLENS.Viewer | null)[]>([]);
    const image360Viewers = useRef<(PANOLENS.Viewer | null)[]>([]);

    // Prepare gallery items for Swiper
    const galleryItems = ListingDetail.ListingImages.sort((a,b) => a.Order - b.Order).map(item => ({
        original: getListingImagePath(item.FileName, ListingDetail.RegionId, item.HasLargeImage, item.IsWatermarked),
    }));

    const top360ImageURL = ListingDetail.ListingImages.sort((a,b) => a.Order - b.Order).filter(item => item.ImageQualityTypeUID === image360UID);
    const panoImageURL = ListingDetail.ListingImages.sort((a,b) => a.Order - b.Order).filter(item => item.ImageQualityTypeUID === panoramaUID);

    // Media type availability checks
    const hasVideo = ListingDetail.VideoLinkURL && isValidYouTubeUrl(ListingDetail.VideoLinkURL);
    const hasPanorama = panoImageURL.length > 0;
    const has360Image = top360ImageURL.length > 0;
    const hasMultipleMediaTypes = hasVideo || hasPanorama || has360Image;

    // State for media type, dialog visibility, current Swiper index
    const [activeMediaType, setActiveMediaType] = useState(hasVideo ? 'video' : 'images');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    // States for zooming and panning
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [lastTouchDistance, setLastTouchDistance] = useState(0);
    const [lastTouchPosition, setLastTouchPosition] = useState({ x: 0, y: 0 });

    // Initialize Panolens panorama viewers based on media type
    const initializePanorama = (src, ref, index, type) => {
        if (ref.current) {
            ref.current.innerHTML = '';  // Clear the container to avoid duplicates
    
            const panorama = new PANOLENS.ImagePanorama(src);
            const viewer = new PANOLENS.Viewer({
                container: ref.current,
                autoHideInfospot: true,
                enableControlBar: false,
            });
    
            viewer.add(panorama);
    
            // Auto-rotation speed (adjust as needed for a slow rotation)
            const rotationSpeed = 0.001; // Adjust this value to make rotation slower or faster
    
            // Set up auto-rotation loop
            const animate = () => {
                if (viewer) {
                    viewer.OrbitControls.rotateLeft(rotationSpeed); // Rotate horizontally
                    viewer.update(); // Update the viewer with new position
                }
                requestAnimationFrame(animate); // Continue the animation loop
            };
    
            // Start the animation loop when panorama is loaded
            panorama.addEventListener('load', animate);
    
            // Save the viewer instance to the appropriate ref array
            if (type === 'panorama') {
                panoramaViewers.current[index] = viewer;
            } else {
                image360Viewers.current[index] = viewer;
            }
        }
    };
    

    // Effect to initialize viewers when the media type changes
    useEffect(() => {
        if (activeMediaType === 'panorama') {
            panoImageURL.forEach((item, index) => {
                const src = getListingImagePath(item.FileName, ListingDetail.RegionId, item.HasLargeImage, item.IsWatermarked);
                initializePanorama(src, { current: panoramaRefs.current[index] }, index, 'panorama');
            });
        } else if (activeMediaType === '360Image') {
            top360ImageURL.forEach((item, index) => {
                const src = getListingImagePath(item.FileName, ListingDetail.RegionId, item.HasLargeImage, item.IsWatermarked);
                initializePanorama(src, { current: image360Refs.current[index] }, index, 'image360');
            });
        }
        
        // Cleanup function to dispose viewers when switching away
        return () => {
            if (activeMediaType === 'panorama') {
                panoramaViewers.current.forEach(viewer => viewer && viewer.dispose());
                panoramaViewers.current = [];
            } else if (activeMediaType === '360Image') {
                image360Viewers.current.forEach(viewer => viewer && viewer.dispose());
                image360Viewers.current = [];
            }
        };
    }, [activeMediaType]);

    // Zoom in and out using the mouse wheel
    const handleWheelZoom = (e) => {
        e.preventDefault();
        setScale(prevScale => Math.min(Math.max(prevScale + e.deltaY * -0.01, 1), 5));
    };

    // Handle mouse drag for panning
    const handleMouseMove = (e) => {
        if (scale > 1) {
            const imageContainer = e.currentTarget.getBoundingClientRect();
            const imgWidth = imageContainer.width * scale;
            const imgHeight = imageContainer.height * scale;
    
            // Calculate max boundaries for panning
            const maxX = (imgWidth - imageContainer.width) / 2;
            const maxY = (imgHeight - imageContainer.height) / 2;
    
            // Constrain the panning position within bounds
            const newX = Math.min(maxX, Math.max(-maxX, position.x + e.movementX));
            const newY = Math.min(maxY, Math.max(-maxY, position.y + e.movementY));
    
            setPosition({ x: newX, y: newY });
        }
    };

    // Handle touch start for panning and pinch zoom
    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            setLastTouchPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        } else if (e.touches.length === 2) {
            const touchDistance = Math.hypot(
                e.touches[0].clientX - e.touches[1].clientX,
                e.touches[0].clientY - e.touches[1].clientY
            );
            setLastTouchDistance(touchDistance);
        }
    };

    // Handle touch move for panning and pinch zoom
    const handleTouchMove = (e) => {
        if (e.touches.length === 2) {
            const newTouchDistance = Math.hypot(
                e.touches[0].clientX - e.touches[1].clientX,
                e.touches[0].clientY - e.touches[1].clientY
            );
            const scaleChange = newTouchDistance / lastTouchDistance;
            setScale(prevScale => Math.min(Math.max(prevScale * scaleChange, 1), 5));
            setLastTouchDistance(newTouchDistance);
        } else if (scale > 1 && e.touches.length === 1) {
            const imageContainer = e.currentTarget.getBoundingClientRect();
            const imgWidth = imageContainer.width * scale;
            const imgHeight = imageContainer.height * scale;
    
            // Calculate max boundaries for panning
            const maxX = (imgWidth - imageContainer.width) / 2;
            const maxY = (imgHeight - imageContainer.height) / 2;
    
            // Calculate new position and constrain within boundaries
            const newX = Math.min(maxX, Math.max(-maxX, position.x + e.touches[0].clientX - lastTouchPosition.x));
            const newY = Math.min(maxY, Math.max(-maxY, position.y + e.touches[0].clientY - lastTouchPosition.y));
    
            setPosition({ x: newX, y: newY });
            setLastTouchPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        }
    };

    // Reset zoom and panning position
    const resetZoom = () => {
        setScale(1);
        setPosition({ x: 0, y: 0 });
    };

    // Open dialog for fullscreen view
    const handleOpenDialog = (index: number) => {
        setCurrentIndex(index);
        setIsDialogOpen(true);
    };

    // Close dialog
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    // Render media content based on active media type
    const renderMediaContent = () => {
        if (activeMediaType === 'video' && hasVideo) {
            return (
                <iframe
                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    allow="encrypted-media"
                    src={getYouTubeEmbedUrl(ListingDetail.VideoLinkURL)}
                    title="Video"
                    allowFullScreen
                />
            );
        } else if (activeMediaType === '360Image' && has360Image) {
            return top360ImageURL.map((item, index) => (
                <div key={index} ref={(el) => (image360Refs.current[index] = el)} style={{ height: '100%' }} />
            ));
        } else if (activeMediaType === 'panorama' && hasPanorama) {
            return panoImageURL.map((item, index) => (
                <div key={index} ref={(el) => (panoramaRefs.current[index] = el)} style={{ height: '100%' }} />
            ));
        } else if (activeMediaType === 'images') {
            return (
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    loop={true}
                    navigation={{ nextEl: '.custom-next', prevEl: '.custom-prev' }}
                    pagination={{ type: 'fraction' }}
                    style={{ height: '100%' }}
                    onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
                >
                    {galleryItems.map((item, index) => (
                        <SwiperSlide key={index}>
                            <img
                                src={item.original}
                                alt="Image"
                                style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                                onClick={() => handleOpenDialog(index)}
                            />
                        </SwiperSlide>
                    ))}
                     {/* View All Photos Button */}
                     <div style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 2 }}>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: "white",
                                borderRadius: '25px',
                                padding: '4px 12px',
                                fontSize: '0.813rem',
                                border: 'none',
                                display: 'flex',
                                gap: '0.25rem',
                                fontWeight: '400'
                            }}
                            onClick={() => handleOpenDialog(currentIndex)}  // Open dialog on button click
                        >
                            <InsertPhotoIcon />
                            <Typography sx={{ color: "white !important", fontSize: '0.813rem' }}>+{galleryItems.length}</Typography>
                        </Button>
                    </div>

                    {/* Global Listing Status Chips */}
                    <div style={{ position: 'absolute', top: 16, left: 16, zIndex: 2 }}>
                        <ListingStatusChips listingDetail={property?.value[0]?.content} lookupList={lookupitem} />
                    </div>
                </Swiper>
            );
        }
        return null;
    };

    return (
        <>
            <Box sx={{ width: '100%', margin: 'auto', position: 'relative' }}>
                <Box sx={{ height: '300px', overflow: 'hidden', position: 'relative' }}>
                    {renderMediaContent()}
                </Box>

                {/* Media Toggle Buttons */}
                {hasMultipleMediaTypes && (
                    <Box sx={{ display: 'flex', gap: '1rem', pb: 2, pt: 2, overflowX: 'auto', paddingLeft: '1rem', paddingRight: '1rem', scrollSnapType: 'x mandatory', borderBottom: '1px solid', borderColor: 'divider' }} className="mobile-media-toggle">
                        {hasVideo && (
                            <Box onClick={() => setActiveMediaType('video')} className={activeMediaType === 'video' ? 'active-media-button' : ''}>
                                <VideocamIcon />{t('Video')}
                            </Box>
                        )}
                        <Box onClick={() => setActiveMediaType('images')} className={activeMediaType === 'images' ? 'active-media-button' : ''}>
                            <ImageIcon />{t('Images')}
                        </Box>
                        {hasPanorama && (
                            <Box onClick={() => setActiveMediaType('panorama')} className={activeMediaType === 'panorama' ? 'active-media-button' : ''}>
                                <PanoramaPhotosphereIcon />{t('Panorama')}
                            </Box>
                        )}
                        {has360Image && (
                            <Box onClick={() => setActiveMediaType('360Image')} className={activeMediaType === '360Image' ? 'active-media-button' : ''}>
                                <ThreeSixtyIcon />{t('360')}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>

            {/* Fullscreen Gallery Dialog */}
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullScreen className="fullscreen-gallery slider-fullscreen" style={{zIndex: '9999999'}}>
                <DialogContent sx={{ backgroundColor: 'black', color: 'white', alignContent: 'center', maxWidth: '100vw', maxHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', overflow: 'hidden !important' }}>
                    {/* Toolbar with Pagination and Close Button */}
                    <Toolbar sx={{ paddingLeft: '0 !important', paddingRight: '0 !important', paddingBottom: '1.5rem', justifyContent: 'space-between', width: '100%' }}>
                        <Box className="custom-pagination" sx={{ padding: '8px', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', color: 'white', display: 'inline-block', fontSize: '1rem' }} />
                        <IconButton onClick={handleCloseDialog}>
                            <CloseIcon sx={{ color: 'white !important' }} />
                        </IconButton>
                    </Toolbar>

                    {/* Swiper in Dialog */}
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        navigation
                        pagination={{ type: 'fraction', el: '.custom-pagination' }}
                        initialSlide={currentIndex}
                        style={{ width: '100%', height: '100%' }}
                        onSlideChange={() => {
                            resetZoom();
                        }}
                        onSlideChangeTransitionEnd={(swiper) => setCurrentIndex(swiper.activeIndex)}
                    >
                        {galleryItems.map((item, index) => (
                            <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div
                                    onWheel={handleWheelZoom}
                                    onMouseMove={scale > 1 && index === currentIndex ? handleMouseMove : null}
                                    onTouchStart={index === currentIndex ? handleTouchStart : null}
                                    onTouchMove={index === currentIndex ? handleTouchMove : null}
                                    className='fullscreen-mobile-gallery-wrapper'
                                    style={{
                                        transform: `scale(${index === currentIndex ? scale : 1}) translate(${position.x}px, ${position.y}px)`,
                                        transition: 'transform 0.1s ease-out',
                                        maxHeight: '100%',
                                        maxWidth: '100%',
                                        touchAction: 'none',
                                    }}
                                >
                                    <img src={item.original} alt="Fullscreen Image" style={{ objectFit: 'contain', width: '100%', aspectRatio: '3 / 2', maxHeight: '100%' }} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ListingMobileImageSliderAndToolbar;
