import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack, Typography, Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { formatNumberWithRegionSetting } from '../utils/helper/FormatNumberHelper';
import SiteSettings from '../../types/Settings/SiteSettings';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import ShowerOutlinedIcon from '@mui/icons-material/ShowerOutlined';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import HeightIcon from '@mui/icons-material/Height';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ShowerIcon from '@mui/icons-material/Shower';
import KingBedIcon from '@mui/icons-material/KingBed';

const NumberFormatting = (num: any): string => {
    if (num === null) {
        return ''
    }

    const parsedNum = parseFloat(num);
    return Number.isInteger(parsedNum) ? parsedNum.toString() : parsedNum.toFixed(1);
};

const isNumeric = (str: string): boolean => {
    const regex = /^[0-9]+$/;
    return regex.test(str);
}

interface Props {
    listingDetail: ListingDetail
    lookupList: LookupItem[];
    siteSettings: SiteSettings | undefined;
}

interface Item {
    icon: JSX.Element;
    label: string;
    value: string | number | null;
}

const ListingAttributesIcons: React.FC<Props> = ({ listingDetail, lookupList, siteSettings }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const typographyClassName = isMobile ? "body1-medium" : "body1-medium";
    const typographyVariant = isMobile ? "body1" : "body1";

    if (Object.values(listingDetail).every(value => value === null)) {
        return null;
    }

    const items: Item[] = [

        listingDetail.TotalNumOfRooms !== null && listingDetail.TotalNumOfRoomsDec !== null && {
            icon: <ViewQuiltIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_TotalRooms"),
            value: listingDetail.TotalNumOfRooms + '.' + listingDetail.TotalNumOfRoomsDec
        },
        listingDetail.TotalNumOfRooms !== null && listingDetail.TotalNumOfRoomsDec === null && {
            icon: <ViewQuiltIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_TotalRooms"),
            value: listingDetail.TotalNumOfRooms
        },      
        listingDetail.NumberOfBedrooms !== null && {
            icon: <KingBedIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_TotalBedrooms"),
            value: listingDetail.NumberOfBedrooms
        }, 
        listingDetail.NumberOfBathrooms !== null && {
            icon: <ShowerIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_TotalBathrooms"),
            value: listingDetail.NumberOfBathrooms
        },
        listingDetail.TotalArea !== null && {
            icon: <SquareFootIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_CommTotalSqM"),
            value: formatNumberWithRegionSetting(listingDetail.TotalArea, siteSettings)
        },
        listingDetail.LotSize !== null && {
            icon: <SquareFootIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_LotSize"),
            value: isNumeric(listingDetail.LotSize) ? formatNumberWithRegionSetting(parseInt(listingDetail.LotSize), siteSettings) : listingDetail.LotSize
        },
        listingDetail.LotSize2 !== null && {
            icon: <SquareFootIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_LotSizeM2"),
            value: formatNumberWithRegionSetting(listingDetail.LotSize2, siteSettings)
        },
        listingDetail.BuiltArea !== null && {
            icon: <SquareFootIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_BuiltArea"),
            value: formatNumberWithRegionSetting(listingDetail.BuiltArea, siteSettings)
        },
        listingDetail.LivingArea !== null && {
            icon: <SquareFootIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_LivingSqM"),
            value: formatNumberWithRegionSetting(listingDetail.LivingArea, siteSettings)
        },
        (listingDetail.YearBuilt !== null || listingDetail.MonthBuiltUID !== null) && {
            icon: <DateRangeIcon fontSize="medium" className="secondary-text" />,
            label: listingDetail.MonthBuiltUID !== null ? t("ListingFull_YearMonthBuilt") : t("ListingFull_CommYearBuilt"),
            value: listingDetail.MonthBuiltUID !== null ? `${listingDetail.YearBuilt}/${getLookupTranslationById(listingDetail.MonthBuiltUID.toString(), lookupList)}` : listingDetail.YearBuilt
        },
        listingDetail.ParkingSpaces !== null && {
            icon: <DirectionsCarIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_ParkingSpaces"),
            value: listingDetail.ParkingSpaces
        },
        listingDetail.FloorLevelNumber !== null && {
            icon: <HeightIcon fontSize="medium" className="secondary-text" />,
            label: t("ListingFull_FloorLevel"),
            value: `${listingDetail.FloorLevelNumber}, ${listingDetail.FloorNumberUID !== null ? getLookupTranslationById(listingDetail.FloorNumberUID.toString(), lookupList) : ''}`
        }
    ].filter(Boolean) as Item[];

    const leftColumnItems: Item[] = [];
    const rightColumnItems: Item[] = [];

    items.forEach((item, index) => {
        if (index % 2 === 0) {
            leftColumnItems.push(item);
        } else {
            rightColumnItems.push(item);
        }
    });

    return (
        <div>
            {Object.keys(listingDetail).length !== 0 && (
                <Stack id="listing-attributes-icons" direction="column" spacing={2} sx={{ borderTop: 1, paddingTop: '2.5rem', borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>
                    <Grid container sx={{ maxWidth: '100%' }} spacing={2} gap={{ xs: "1rem", lg: "1.5rem" }} direction={{ xs: "column", lg: "row" }}>

                        {/* Left Column */}
                        <Grid xs={12} lg={6} sx={{ flex: '1 !important' }}>
                            <Stack direction="column" spacing={2}>
                                {leftColumnItems.map((item, index) => (
                                    <Stack key={index} direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap>
                                        <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                                            {item.icon}
                                            <Typography className="secondary-text">
                                                {item.label}
                                            </Typography>
                                        </Stack>
                                        <Typography className={typographyClassName} variant={typographyVariant}>
                                            {item.value}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Grid>

                        <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', lg: 'block' } }} />

                        {/* Right Column */}
                        <Grid xs={12} lg={6} sx={{ flex: '1 !important' }}>
                            <Stack direction="column" spacing={2}>
                                {rightColumnItems.map((item, index) => (
                                    <Stack key={index} direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap>
                                        <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                                            {item.icon}
                                            <Typography className="secondary-text">
                                                {item.label}
                                            </Typography>
                                        </Stack>
                                        <Typography className={typographyClassName} variant={typographyVariant}>
                                            {item.value}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Grid>

                    </Grid>
                </Stack>
            )}
        </div>
    );
};

export default ListingAttributesIcons;
