import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { IconButton, Button, Hidden, Container, Paper, CircularProgress, Stack, useMediaQuery, useTheme, Typography, Box, Card, CardContent } from '@mui/material';
import { useLoading } from '../../components/utils/LoadingContext';
import { Development, DevelopmentSearchResponse, LookupItem, SiteSettings } from '../../types/TypesIndex';
import ListingDetailContent from '../../types/Listing/ListingDetailContent';
import MetaTags from '../../components/utils/seo/MetaTags';
import { DevelopmentDetailBreadcrumb, DevelopmentDetailDescription, DevelopmentDetailToolBar, DevelopmentDetailAvailableUnit, DevelopmentDetailMultiMedia } from '../../components/development/DevelopmentComponentsIndex';
import { fetchLookupTranslations } from '../../services/TranslationService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { fetchDevelopmentDataByDevelopmentId } from '../../services/DevelopmentService';
import { fetchListingDataByDevelopmentId } from '../../services/ListingService';
import ListingDetail from '../../types/Listing/ListingDetail';
import Location from '../../components/utils/Location';
import { PAGE_TYPE } from '../../constants/constants';


// SEO Shortlink Pattern
interface DevelopmentParams extends Record<string, string | undefined> {
    developmentUrl: string;
    developmentId: string;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const SellersPortal: React.FC = () => {

    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const { showLoading, hideLoading } = useLoading();
    const params = useParams<DevelopmentParams>();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Hook Declaration
    const [development, setDevelopment] = useState<Development | any>();
    const [listings, setListings] = useState<ListingDetail[]>([]);
    const [developmentListingCount, setDevelopmentListingCount] = useState(0);
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loading, setLoading] = React.useState(true);

    return (
        <div>

            <Container maxWidth="md" className="text-align-left light" sx={{ paddingTop: { xs: '2.5rem', md: '3rem' }, paddingBottom: { xs: '2.5rem', md: '3rem' } }}>

                <Stack direction="column" spacing={5}>

                    {/* Intro */}
                    <Stack direction="column" spacing={4} alignItems="center">

                        <Box component="img"
                            src="/images/remax/maxmatch_logo.svg"
                            alt="logo"
                            title="logo"
                            loading="lazy"
                            style={{ maxWidth: '100%', width: '200px' }} />

                        <Stack direction="column" spacing={3}>
                            <Typography className="h2 text-center" variant="h2">Find The Perfect Match For Your Home</Typography>
                            <Typography className="body1 text-center" variant="body1">RE/MAX has thousands of qualified buyers. Find out how many are looking for a home like yours. Enter your location, property type and number of rooms in the form below to find potential buyers!</Typography>

                        </Stack>

                    </Stack>

                    {/* Form and Info */}
                    <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" justifyContent="center" spacing={4}>

                        <Card>test</Card>

                        <Card>test</Card>

                    </Stack>



                </Stack>

            </Container>

        </div>

    );

};
export default SellersPortal;