import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { Hidden, Typography, Button, Grid, Link, Container, Stack, Paper } from '@mui/material';
import { PAGE_TYPE } from '../../constants/constants';
import { SiteSettings, Team } from '../../types/TypesIndex';


interface ProfileBreadcrumbsProps {
    page: string;
    name?: string;
    team?: Team;
    siteSettings?: SiteSettings;
}

const ProfileBreadcrumbs: React.FC<{ profileBreadcrumbs: ProfileBreadcrumbsProps }> = ({ profileBreadcrumbs }) => {

    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;

    let breadCrumbNameTrans = (profileBreadcrumbs.page !== null && profileBreadcrumbs.page === PAGE_TYPE.AGENT) ? t('AgentFull_AgentsBreadCrumb') : t('OfficeFull_OfficesBreadCrumb');
    let breadCrumbBackTrans = (profileBreadcrumbs.page !== null && profileBreadcrumbs.page === PAGE_TYPE.AGENT) ? t('AgentFull_Back') : t('OfficeFull_Back');
    
    let urlProfile = "/ProfileSearch?page=1";
    if (window.macroregionid !== null && window.macroregionid > 0) {
        urlProfile = "/ProfileSearch?page=1&countryId=" + window.macroregionid; 
    }

    if (profileBreadcrumbs.page !== null && profileBreadcrumbs.page === PAGE_TYPE.AGENT) {
        urlProfile = urlProfile + "&searchType=" + PAGE_TYPE.AGENT;
    } else {
        urlProfile = urlProfile + "&searchType=" + PAGE_TYPE.OFFICE;
    }

    let officeShortLink = "";
    if (profileBreadcrumbs.page === PAGE_TYPE.TEAM && profileBreadcrumbs.team !== undefined && profileBreadcrumbs.team !== null)
    {
        officeShortLink = `/${PAGE_TYPE.OFFICE}/` + profileBreadcrumbs.team?.OfficeId;

        if (profileBreadcrumbs.team?.OfficeShortLinks !== null
            && profileBreadcrumbs.team?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null
            && profileBreadcrumbs.team?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0) {
                officeShortLink = `/${PAGE_TYPE.OFFICE}/` + profileBreadcrumbs.team.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;
        }        
    }    


    return (
        <Grid container spacing={8} alignItems="center">

            <Grid item xs={12} md={8}>

                <Stack className="text-align-left" sx={{ xs: { marginBottom: '3rem' } }}>

                    <Stack itemScope itemType="http://schema.org/BreadcrumbList" direction="row" alignItems="center" justifyContent="flex-start" flexWrap="wrap" >

                        <a itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" title={breadCrumbBackTrans} href={urlProfile} aria-label={breadCrumbBackTrans} style={{ fontSize: '0.875rem' }}>
                            <span itemProp="name">{breadCrumbNameTrans}</span>
                            <meta itemProp="position" content="1"></meta>
                        </a>

                        {officeShortLink !== "" && (
                            <>
                                <KeyboardArrowRightIcon className="flip-text" style={{ fontSize: 'Medium', color: 'rgba(0, 0, 0, 0.6)!important' }} />

                                <a className="breadcrumbs-last-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" href={officeShortLink} target='_blank' style={{ fontSize: '0.875rem' }}>
                                    <span itemProp="name">{profileBreadcrumbs.team?.OfficeName}</span>
                                    <meta itemProp="position" content="2"></meta>
                                </a>
                            </>
                        )}

                        <KeyboardArrowRightIcon className="flip-text" style={{ fontSize: 'Medium', color: 'rgba(0, 0, 0, 0.6)!important' }} />

                        <a className="breadcrumbs-last-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" style={{ fontSize: '0.875rem' }}>
                            <span itemProp="name">{profileBreadcrumbs.name}</span>
                            <meta itemProp="position" content="2"></meta>
                        </a>

                    </Stack>

                </Stack>

            </Grid>

        </Grid>
    );
};

export default ProfileBreadcrumbs;
