import SiteSettings from "@/types/Settings/SiteSettings";
import { format } from "date-fns";


function formatNumberWithSeparator(number: number, separator?: string): string {
  if (number === undefined || number === null) {
    return '';
  }

  if (!Number.isInteger(number)) {
    return number.toString();
  }

  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator ?? '.');

  return parts.join('.');
}

function formatShortPrice(numStr: string): string {
  const cleanedNumStr = numStr.replace(/\.\d+$/, '').replace(/\./g, '').replace(',', '.');
  const num = parseFloat(cleanedNumStr);

  if (isNaN(num)) {
    throw new Error("Invalid number format");
  }

  // Helper function to format number with custom logic
  const toFixedDown = (value: number, digits: number): string => {
    const re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)");
    const m = value.toString().match(re);
    return m ? parseFloat(m[1]).toString() : value.toString();
  };

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Qa" }
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  // Find the appropriate symbol based on the value of num
  const item = lookup.slice().reverse().find(item => num >= item.value);

  // Check if item exists, then format the number accordingly
  const formattedNum = item ? toFixedDown(num / item.value, 1).replace(rx, "$1") + " " + item.symbol : "0";

  // Add LEFT-TO-RIGHT MARK to prevent flipping in RTL
  const LRM = '\u200E';
  return LRM + formattedNum;
}



function getAlternativeCurrencyCode(currencyCode: string, siteSettings?: SiteSettings): string {

  const sylbol = siteSettings?.RegionSupportedCurrencies.find(
    currency => currencyCode === currency.CurrencyCode
);

  return sylbol?.CurrencySymbol ?? '';

}




function formatNumberWithRegionSetting(amount: number | string, siteSettings?: SiteSettings, hideDecimals?: boolean): string {
  if (amount === undefined || amount === null) {
    return '';
  }

  if (amount === 0) {
    return '0';
  }

  // Parse the amount as a number
  const parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

  // Check if the parsed amount is a valid number
  if (isNaN(parsedAmount)) {
    return '';
  }

  const options = {
    thousandSeparator: siteSettings?.NumberGroupSeparator ?? ',', // ',' or '.'
    decimalSeparator: siteSettings?.NumberDecimalSeparator ?? '.', // '.' or ','
    decimalPlaces: hideDecimals ? 0 : siteSettings?.NumberDecimalDigits ?? 2
  };

  // Ensure decimalPlaces is a non-negative integer
  const decimalPlaces = Math.max(0, Math.floor(options.decimalPlaces));

  // Ensure amount has the correct number of decimal places
  const amountString = parsedAmount.toFixed(decimalPlaces);

  // Split the amount into integer and decimal parts
  let [integerPart, decimalPart] = amountString.split('.');

  // Format the integer part with thousand separators
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandSeparator);

  // Only include the decimal part if it's non-zero and not hidden
  if (decimalPart && parseFloat(decimalPart) !== 0 && !hideDecimals) {
    decimalPart = options.decimalSeparator + decimalPart;
  } else {
    decimalPart = '';
  }

  return `${integerPart}${decimalPart}`;
}

function formatCurrency(amount: number, listingCurrencyCode?: string, siteSettings?: SiteSettings ): string {
  let symbol = siteSettings?.CurrencySymbol ?? '$';
  if (amount === undefined || amount === null) {
    return '';
  }

  if(listingCurrencyCode && listingCurrencyCode !== '' && listingCurrencyCode !== siteSettings?.CurrencyCode){
    symbol = getAlternativeCurrencyCode(listingCurrencyCode, siteSettings)
    }


  // Define the options inside the function
  const options = {
    currencySymbol: symbol,          // or '€', '£', etc.
    currencyPosition: siteSettings?.CurrencySignPosition ?? 1,    // 1 for 'left', 0 for 'right'
    thousandSeparator: siteSettings?.NumberGroupSeparator ?? ',', // ',' or '.'
    decimalSeparator: siteSettings?.NumberDecimalSeparator ?? '.', // '.' or ','
    decimalPlaces: siteSettings?.NumberDecimalDigits ?? 2
  };

  // Check if the number has a decimal part
  const hasDecimal = amount % 1 !== 0;

  // Format the number with thousands separator and decimal point
  let [integerPart, decimalPart] = hasDecimal
    ? amount.toFixed(options.decimalPlaces).split('.')
    : [Math.floor(amount).toString(), ''];

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandSeparator);

  // Include decimal part only if the decimalPlaces is greater than 0 and hasDecimal is true
  decimalPart = hasDecimal && options.decimalPlaces > 0
    ? options.decimalSeparator + decimalPart
    : '';

  // Construct the final formatted price
  const formattedPrice = options.currencyPosition === 1
    ? `${options.currencySymbol} ${integerPart}${decimalPart}`
    : `${integerPart}${decimalPart} ${options.currencySymbol}`;

  return formattedPrice;
}

function formatCustomCurrency(amount: number, siteSettings?: SiteSettings, customCurrency?: string): string {
  if (amount === undefined || amount === null) {
    return '';
  }

  // Define the options inside the function
  const options = {
    currencySymbol: customCurrency ?? siteSettings?.CurrencySymbol ?? '$', // or '€', '£', etc.
    currencyPosition: siteSettings?.CurrencySignPosition ?? 1,             // 1 for 'left', 0 for 'right'
    thousandSeparator: siteSettings?.NumberGroupSeparator ?? ',',          // ',' or '.'
    decimalSeparator: siteSettings?.NumberDecimalSeparator ?? '.',         // '.' or ','
    decimalPlaces: siteSettings?.NumberDecimalDigits ?? 2
  };

  // Check if the number has a decimal part
  const hasDecimal = amount % 1 !== 0;

  // Format the number with thousands separator and decimal point
  let [integerPart, decimalPart] = hasDecimal
    ? amount.toFixed(options.decimalPlaces).split('.')
    : [Math.floor(amount).toString(), ''];

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandSeparator);

  // Include decimal part only if the decimalPlaces is greater than 0 and hasDecimal is true
  decimalPart = hasDecimal && options.decimalPlaces > 0
    ? options.decimalSeparator + decimalPart
    : '';

  // Construct the final formatted price
  const formattedPrice = options.currencyPosition === 1
    ? `${options.currencySymbol} ${integerPart}${decimalPart}`
    : `${integerPart}${decimalPart} ${options.currencySymbol}`;

  return formattedPrice;
}


function formatDate(date: Date, labelName?: string): string {

  try {
    return format(new Date(date), 'dd/MM/yy')

  } catch {
    console.log("invalid date:", labelName, date);
  }

  return "";
}


export { formatNumberWithSeparator, formatShortPrice, formatCurrency, formatCustomCurrency, formatNumberWithRegionSetting, getAlternativeCurrencyCode , formatDate};
