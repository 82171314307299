import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Grid, Link, Container, Stack, Paper, Hidden, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check as CheckIcon } from '@mui/icons-material';
import { getProfileDefaultImagePath, getProfileImagePath } from '../utils/helper/ImageHelper';
import { Agent, Team, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { PAGE_TYPE } from '../../constants/constants';

interface ProfileTeamInfoProps {
    team?: Team;
    agentList: Agent[],
    spokenLanguages: string[];
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const ProfileTeamInfo: React.FC<{ profileTeamInfo: ProfileTeamInfoProps }> = ({ profileTeamInfo }) => {
    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;
    // Category is used to match both REP and GT: 0 for non agent, 1 for SalesAssociate, 2, 3, 4 etc will be used for REP Agent Type
    const agentList = profileTeamInfo.agentList?.filter(a => a.Category === 1);
    const nonAgentList = profileTeamInfo.agentList?.filter(a => a.Category === 0);



    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    const [lastBorder, setLastBorder] = useState({  
        AgentList: true,
        NonAgentList: true,   
    });

    useEffect(() => {
        if (nonAgentList && nonAgentList.length > 0) {
            setLastBorder(prevState => ({ ...prevState, NonAgentList: false }));
        } else if (agentList && agentList.length > 0) {
            setLastBorder(prevState => ({ ...prevState, AgentList: false }));
        } 

    }, []);


    if (isLoading) {
        return (
            <Stack spacing={3}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={200} />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="rectangular" height={150} />
                <Skeleton variant="text" width="40%" />
            </Stack>
        );
    }

    return (
        <>

            {/* Slogan, Closer, and Description Section */}
            {profileTeamInfo.team && (profileTeamInfo.team.Slogan || profileTeamInfo.team.Closer) && (
                <Stack id="slogan-description-closer" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                    {profileTeamInfo.team && profileTeamInfo.team.Slogan !== null && (
                        <Typography id="slogan" dangerouslySetInnerHTML={{ __html: profileTeamInfo.team.Slogan }} className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}></Typography>
                    )}

                    {profileTeamInfo.team && profileTeamInfo.team.Closer !== null && (
                        <Typography id="closer" dangerouslySetInnerHTML={{ __html: profileTeamInfo.team.Closer }} className="body1" variant="body1"></Typography>
                    )}
                </Stack>
            )}

            {/* Bullet Points */}
            {profileTeamInfo.team && (profileTeamInfo.team.BulletPoint1 !== null || profileTeamInfo.team.BulletPoint2 !== null || profileTeamInfo.team.BulletPoint3 !== null) && (
                <Stack id="three-bullets" spacing={2} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important', paddingTop: 0 }}>

                    {profileTeamInfo.team.BulletPoint1 && (
                        <Typography className="body1" variant="body1">
                            <CheckIcon className="bullet-point-checkmark" />
                            <span dangerouslySetInnerHTML={{ __html: profileTeamInfo.team.BulletPoint1 }}></span>
                        </Typography>
                    )}

                    {profileTeamInfo.team.BulletPoint2 && (
                        <Typography className="body1" variant="body1">
                            <CheckIcon className="bullet-point-checkmark" />
                            <span dangerouslySetInnerHTML={{ __html: profileTeamInfo.team.BulletPoint2 }}></span>
                        </Typography>
                    )}

                    {profileTeamInfo.team.BulletPoint3 && (
                        <Typography className="body1" variant="body1">
                            <CheckIcon className="bullet-point-checkmark" />
                            <span dangerouslySetInnerHTML={{ __html: profileTeamInfo.team.BulletPoint3 }}></span>
                        </Typography>
                    )}
                </Stack>
            )}

            {/* Spoken Languages */}
            {profileTeamInfo.spokenLanguages && profileTeamInfo.spokenLanguages !== null && profileTeamInfo.spokenLanguages.length > 0 && (
                <Stack id="languages-spoken" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                    <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                        {t('OfficeFull_LanguageSpoken')}
                    </Typography>

                    <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">

                        {profileTeamInfo.spokenLanguages.map((uid) => (
                            <Typography variant="body1" /* key={uid} */>
                                {getLookupTranslationById(uid, profileTeamInfo.lookupList)}
                            </Typography>
                        ))}

                    </Stack>

                </Stack>
            )}


            {/* Agent List */}
            {agentList !== null && agentList.length > 0 && (
                <>
                    <Stack direction="column" spacing={3} sx={{ borderBottom: lastBorder.AgentList ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.AgentList ? '1rem !important' : '0 !important' }} >

                        {/* Heading */}
                        <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                            {t('OfficeFull_Agents')}
                        </Typography>

                        <Grid container sx={{ maxWidth: '100%' }}>

                            {agentList.sort((a, b) => a.AgentName.localeCompare(b.AgentName)).map((agent, index) => (

                                <Grid key={agent.AgentId} xs={6} sm={3}
                                    sx={{ paddingLeft: '0.75rem', paddingRight: '0.75rem', marginBottom: '1.5rem' }}>

                                    <Link
                                        underline="hover"
                                        href={
                                            (agent.AgentShortLinks !== null
                                                && agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode) !== null
                                                && agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode).length > 0) ?
                                                window.location.origin + `/${PAGE_TYPE.AGENT}/` + agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink
                                                : `/${PAGE_TYPE.AGENT}/` + agent.AgentId
                                        }
                                        target="_blank"
                                        sx={{ textDecoration: 'none' }}>

                                        <Stack direction="column" alignItems="center" spacing={2}>


                                            {/* Agent Image */}
                                            <img
                                                style={{ width: '100%', height: 216, maxHeight: 216, objectFit: 'cover', borderRadius: '1rem' }}
                                                src={getProfileImagePath(agent.RegionId, agent.AgentPhotoId) || getProfileDefaultImagePath()}
                                                loading="lazy"
                                                alt={agent.AgentName}
                                                title={agent.AgentName}
                                            />


                                            <Stack direction="column" spacing={1} useFlexGap flexWrap="wrap" sx={{ maxWidth: '100%' }}>

                                                {/* Agent Name */}
                                                <Typography className="body1-medium" variant="body1" textAlign="center" sx={{ margin: 'auto', xs: { maxWidth: '147px' }, maxWidth: '162px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                    {agent.AgentName}
                                                </Typography>

                                                {/* License Number */}
                                                {agent.AgentLicenseNumber !== null && (
                                                    <Typography className="body2 secondary-text" variant="body2" align="center">
                                                        {t('OfficeFull_LicenseNumber')} {agent.AgentLicenseNumber}
                                                    </Typography>
                                                )}

                                            </Stack>

                                        </Stack>

                                    </Link>

                                </Grid>

                            ))}

                        </Grid>

                    </Stack>

                </>
            )}

            {/* Non Agent List */}
            {nonAgentList !== null && nonAgentList.length > 0 && (

                <>
                    <Stack direction="column" spacing={3} sx={{ borderBottom: lastBorder.NonAgentList ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.NonAgentList ? '1rem !important' : '0 !important' }}>

                        {/* Heading */}
                        <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                            {t('OfficeFull_OfficeAssistant')}
                        </Typography>

                        <Grid container sx={{ maxWidth: '100%' }}>

                            {nonAgentList.sort((a, b) => a.AgentName.localeCompare(b.AgentName)).map((agent, index) => (

                                <Grid key={agent.AgentId} xs={6} sm={4} className="padding-right-24" sx={{ marginBottom: '1.5rem' }}>

                                    <Stack direction="column">

                                        {/* Agent Name */}
                                        <Typography className="body1-medium text-align-left" variant="body1" sx={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {agent.AgentName.length > 18
                                            ? `${agent.AgentName.slice(0, 18)}...`
                                            : agent.AgentName}
                                        </Typography>

                                    </Stack>

                                </Grid>

                            ))}

                        </Grid>

                    </Stack>

                </>

            )}

            {/* Social Media Feeds Section Phase II */}


        </>
    );
};

export default ProfileTeamInfo;