import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


const ListingDisclaimer: React.FC = () => {
    const { t, i18n } = useTranslation();

    return (
        <div >
            <Stack id="disclaimer" sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>
                <Typography className="body1" variant="body1">
                    {t('ListingFull_LegalDisclaimer')}
                </Typography>
            </Stack>
        </div>
    );
};
export default ListingDisclaimer;
