import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden, Typography, Button, Grid, Link, Container, Stack, Paper, useTheme, useMediaQuery } from '@mui/material';
import { getProfileImagePath } from '../utils/helper/ImageHelper';
import { ProfilePropertiesCountWithLink, ProfileContactInfo, ProfilePhoto, ProfileSocialChatIntegration, ProfileSocialMediaLinks, ProfileDisplayTitle, ProfileTeamNameAndLogo, ProfileBreadcrumbs } from '../../components/profile/ProfileComponentsIndex';
import { ContactMobileDrawer } from '../utils';
import { Agent, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { PAGE_TYPE } from '../../constants/constants';


interface ProfileAgentCardProps {
    agent?: Agent;
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
    listingCount: number;
}

const ProfileAgentCardMobile: React.FC<{ profileAgentCard: ProfileAgentCardProps }> = ({ profileAgentCard }) => {
    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const languageCode = i18n.language;

    const displayTitleTranslation = profileAgentCard.agent?.DisplayTitleUID !== null && profileAgentCard.agent?.DisplayTitleUID !== undefined
        ? `${getLookupTranslationById(profileAgentCard.agent?.DisplayTitleUID.toString(), profileAgentCard.lookupList)}`
        : '';

    let officeShortLink = `/${PAGE_TYPE.OFFICE}/` + profileAgentCard.agent?.OfficeId;
    if (profileAgentCard.agent && profileAgentCard.agent?.OfficeShortLinks !== null
        && profileAgentCard.agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && profileAgentCard.agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        officeShortLink = `/${PAGE_TYPE.OFFICE}/` + profileAgentCard.agent.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

    let teamShortLink = "";
    if (profileAgentCard.agent && profileAgentCard.agent?.TeamId !== undefined && profileAgentCard.agent?.TeamId > 0) {
        teamShortLink = `/${PAGE_TYPE.TEAM}/` + profileAgentCard.agent.TeamId;
        if (profileAgentCard.agent.TeamShortLinks !== null
            && profileAgentCard.agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode) !== null
            && profileAgentCard.agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
            teamShortLink = `/${PAGE_TYPE.TEAM}/` + profileAgentCard.agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;
    }

    // Hook Declaration
    const [openDrawer, setOpenDrawer] = useState(false);

    // Event Handlers
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);    };


    return (
        <>
            <Stack direction="column" spacing={5} useFlexGap>

                {/* Breadcrumbs */}
                <ProfileBreadcrumbs profileBreadcrumbs={{ page: PAGE_TYPE.AGENT, name: profileAgentCard.agent?.AgentName.toString(), siteSettings: profileAgentCard.siteSettings }} />

                {/* To Do: SEO Micro Data */}
                <div itemScope itemType="http://schema.org/RealEstateAgent">
                    <span itemProp="priceRange" style={{ display: 'none' }}>0 - 99999999</span>
                    <span style={{ display: 'none' }} itemProp="address">{profileAgentCard.agent?.OfficeAddress}</span>
                    <span style={{ display: 'none' }} itemProp="areaServed">{profileAgentCard.agent?.OfficeCountry}</span>
                    <span style={{ display: 'none' }} itemProp="brand"></span>
                    <span style={{ display: 'none' }} itemProp="legalName">{profileAgentCard.agent?.AgentName}</span>
                    <span style={{ display: 'none' }} itemProp="url"></span>
                    <span style={{ display: 'none' }} itemProp="description"></span>
                    <span style={{ display: 'none' }} itemProp="currenciesAccepted"></span>
                    <span style={{ display: 'none' }} itemProp="email">{profileAgentCard.agent?.AgentEmail}</span>

                    {/* Global Profile Photos (component) */}
                    {profileAgentCard.agent &&
                        <ProfilePhoto profilePhoto={{
                            imagePath: getProfileImagePath(profileAgentCard.agent.RegionId, profileAgentCard.agent.AgentPhotoId),
                            alt: displayTitleTranslation + ' - ' + profileAgentCard.agent?.AgentName + ' - ' + profileAgentCard.agent?.OfficeName,
                            title: profileAgentCard.agent?.AgentName
                        }}
                        />}
                </div>

            </Stack>

            <Stack direction="column" spacing={2} textAlign="center" mt={3}>

                <Stack direction="column" spacing={1}>
                    {profileAgentCard.agent && (
                        <ProfileDisplayTitle
                            name={profileAgentCard.agent?.AgentName}
                            displayTitleUID={profileAgentCard.agent?.DisplayTitleUID?.toString()}
                            titleUID={profileAgentCard.agent?.TitleUID?.toString()}
                            lookupList={profileAgentCard.lookupList}
                            siteSettings={profileAgentCard.siteSettings}
                            className='h3'
                            variant='h3'
                        />
                    )}

                    {profileAgentCard.agent?.AgentLicenseNumber !== null && (
                        <Typography className="body2 secondary-text" variant="body2">
                            {t('AgentFull_LicenseNumber')} {profileAgentCard.agent?.AgentLicenseNumber}
                        </Typography>
                    )}
                </Stack>

                <Stack direction="column" spacing={3}>

                    {/* Office Name */}
                    <Typography className="body1-medium secondary-text" variant="body1" itemProp='member' itemScope itemType='http://schema.org/LocalBusiness'>
                        <Link className="secondary-text" href={officeShortLink}
                            title={profileAgentCard.agent?.OfficeName} aria-label={profileAgentCard.agent?.OfficeName} underline="hover" color="inherit">
                            {profileAgentCard.agent?.OfficeName}
                        </Link>
                    </Typography>

                    <img style={{ display: 'none' }} itemProp="image" src="" alt="" title="" />
                    <span style={{ display: 'none' }} itemProp="telephone">{profileAgentCard.agent?.OfficePhone}</span>
                    <span style={{ display: 'none' }} itemProp="priceRange">0 - 99999999</span>
                    <span style={{ display: 'none' }} itemProp="currenciesAccepted"></span>
                    <span style={{ display: 'none' }} itemProp="address"></span>
                    <span style={{ display: 'none' }} itemProp="areaServed">{profileAgentCard.agent?.OfficeCountry}</span>
                    <span style={{ display: 'none' }} itemProp="brand"></span>
                    <span style={{ display: 'none' }} itemProp="legalName">{profileAgentCard.agent?.OfficeName}</span>
                    <span style={{ display: 'none' }} itemProp="location">{profileAgentCard.agent?.OfficeCity}</span>
                    <span style={{ display: 'none' }} itemProp="name">{profileAgentCard.agent?.OfficeName}</span>

                    {/* Global Team Name and Logo (component) */}
                    {(profileAgentCard.agent && profileAgentCard.agent.TeamId > 0) &&
                        <ProfileTeamNameAndLogo
                        teamId={profileAgentCard.agent.TeamId}
                        teamName={profileAgentCard.agent.TeamName}
                        teamRegionId={profileAgentCard.agent.RegionId}
                        teamPhotoOrLogo={profileAgentCard.agent.TeamLogo}
                        teamUrl={teamShortLink}
                        />}

                    <Stack spacing={2} alignItems="center">

                        {/* Contact Me Button */}
                        <Button type="button" role="button" aria-label={t('ListingFull_ContactMe')} className="primary-button" variant="contained" style={{ width: '100%', marginBottom: '.5rem' }} onClick={toggleDrawer}>
                            {t('ListingFull_ContactMe')}
                        </Button>

                        {/* Global Contact Me Mobile Drawer (component) */}
                        <ContactMobileDrawer data={{ agentdata: profileAgentCard.agent, open: openDrawer, toggleDrawer: toggleDrawer }} />

                        {/* Global Agent/Office Properties Link (component) */}
                        {profileAgentCard.agent && !profileAgentCard.agent.ExcludeAgentTraining &&
                            <ProfilePropertiesCountWithLink agent={profileAgentCard.agent} page="agent" listingCount={profileAgentCard.listingCount} />}

                        {/* Global Chat Integration Links (component) */}
                        {/* To Do: Split the Chat Integration and Social Media Integration */}
                        {profileAgentCard.agent && <ProfileSocialChatIntegration
                            phone={profileAgentCard.agent?.AgentPhone}
                            email={profileAgentCard.agent?.AgentEmail}
                            whatsApp={profileAgentCard.agent?.WhatsApp}
                            facebookMessengerID={profileAgentCard.agent?.FacebookMessengerID}
                            viberID={profileAgentCard.agent?.ViberID}
                            telegramID={profileAgentCard.agent?.TelegramID}
                            lineChat={profileAgentCard.agent?.LINEChat}
                            lineChatID={profileAgentCard.agent?.LineChatID}
                            weChat={profileAgentCard.agent.WeChat}
                            weChatID={profileAgentCard.agent.WeChatID}
                            isMobile={isMobile}
                            agent={profileAgentCard.agent}
                            siteSettings={profileAgentCard.siteSettings}
                            page={PAGE_TYPE.AGENT}
                        />}

                        {/* Global Social Media Link (component) */}
                        {profileAgentCard.agent && <ProfileSocialMediaLinks
                            socialMedias={profileAgentCard.agent?.SocialMedias}
                            isSocialMediaEnabled={profileAgentCard.agent?.IsSocialMediaEnabled}
                            lookupList={profileAgentCard.lookupList}
                            siteSettings={profileAgentCard.siteSettings}
                        />}

                        {/* Global Contact Info Section, eg Phone, Email (component) */}
                        {profileAgentCard.agent && <ProfileContactInfo
                        page={PAGE_TYPE.AGENT}
                        contactInfo={{
                            TenantId: profileAgentCard.agent?.TenantId,
                            RegionId: profileAgentCard.agent?.RegionId,
                            AgentId: profileAgentCard.agent?.AgentId,
                            AgentPhone: profileAgentCard.agent?.AgentPhone,
                            DirectDialPhoneNumber: profileAgentCard.agent?.AgentDirectDialPhone,
                            OfficePhone: profileAgentCard.agent?.OfficePhone,
                            Email: profileAgentCard.agent?.AgentEmail,
                            siteSettings: profileAgentCard.siteSettings
                        }} />}

                    </Stack>

                </Stack>

            </Stack>

        </>

    );
};

export default ProfileAgentCardMobile;
