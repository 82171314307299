import { BlogPost } from '@/types/BlogPost';
import MetaTags from '../components/utils/seo/MetaTags';
import { List, ListItemButton, Pagination, CircularProgress, Hidden, useMediaQuery, Tooltip, IconButton, useTheme, Avatar, Grid, ButtonBase, Box, Tabs, Tab, Chip, Card, CardContent, CardMedia, Container, Typography, Link, Stack, Divider, Paper, Button } from '@mui/material';
import { Favorite as FavoriteIcon, Share as ShareIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { max } from 'date-fns';
import { useEffect, useState, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

const Blog: React.FC = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [value, setValue] = useState('one');
  const { t, i18n } = useTranslation();

  const isRtl = i18n.dir() === 'rtl';

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const perPage = 10;

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff', ...theme.typography.body2, padding: theme.spacing(1),
  }));

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchTotalRecords = async () => {
      try {
        const response = await fetch(`/cms/api/v1/blog?perPage=1&page=1`);
        if (!response.ok) {
          return null;
        }
        const data = await response.json();
        setTotalRecords(data.pages);
        console.log(data.totalRecords);
        console.log(data.pages);
      } catch (error) {
        console.error('Error fetching total records:', error);
      }
    };
    fetchTotalRecords();
  },
    []);

  // Fetch posts for the current page
  useEffect(() => {
    const route = `/api/v1/blog?perPage=${perPage}&page=${currentPage}`;
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`/cms/${route}`);
        if (!response.ok) {
          return null;
        }
        const data = await response.json();
        setPosts(data.results);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };
    fetchBlogPosts();
  }, [currentPage]);

  const totalPages = Math.ceil(totalRecords / perPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 200);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
      </div>
    );
  }

  return (

    <div>
      <MetaTags metaTags={{
        //title: post.seoTitle ?? post.title,
        //ogTitle: post.seoTitle ?? post.title,
        //description: post.seoDescription ?? post.description,
        url: window.location.href,
        // image: post.image?._rendered, TODO: Add image to the post object
      }}
      />

      <Container maxWidth="xl" className="text-align-left" sx={{ paddingTop: { xs: '2.5rem', md: '3rem' }, paddingBottom: { xs: '2.5rem', md: '3rem' }, }}>

        <Stack direction="row" justifyContent={'space-between'} useFlexGap>

          {/* Page Header*/}
          <Typography className={isMobile ? "h3" : "h2"} variant={isMobile ? "h3" : "h2"} mb={3} sx={{ width: '100%' }}>Blog</Typography>

          {/* Favourites Button */}
          <Box>

            <Button type="button" role="button" aria-label={t('Favourites_Header')} variant="text" startIcon={<FavoriteIcon sx={{ color: 'var(--body-text-color) !important;', ...(isRtl && { transform: 'scaleX(-1)' }) }} />} sx={{ color: 'var(--body-text-color) !important;' }}>{t('Favourites_Header')}</Button>

          </Box>

        </Stack>

        {/* Two column grid */}
        <Grid container spacing={{ xs: 5, sm: 8 }}>

          {/* Left column - Blogs */}
          <Grid item xs={12} sm={8} sx={{ order: { xs: 1, sm: 0 } }}>

            <Stack direction="column" spacing={3}>

              {/*{posts.map((post) => (*/}

              {/* Mobile Card */}

              <Hidden mdUp>

                <Item style={{ padding: '0', borderRadius: '1rem', boxShadow: 'none !important' }}>

                  <Link underline="none" //href={`/blog/${post.aposDocId}`}
                  >

                    <Card className="gridItem customBox" sx={{ boxShadow: 'none !important' }} //key={post.aposDocId} 
                    >

                      <CardContent sx={{ padding: '1.5rem !important', boxShadow: 'none !important' }}>

                        <Stack direction="column" spacing={3}>


                          {/* {post.image && post.image.items.length > 0 && ( */}
                          <Box sx={{ position: 'relative' }}>

                            <div className="favourite-map-buttons-container">

                              <Stack direction="column" spacing={1}>

                                <Tooltip title={t('ListingFull_AddToFavouritesToolTip')} placement="left">
                                  <IconButton className="card-button-icons" aria-label={t('ListingFull_AddToFavouritesToolTip')}>
                                    <FavoriteIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title={t("ShareAndCompareWidget_Share")} placement="left">
                                  <IconButton className="card-button-icons" aria-label={t("ShareAndCompareWidget_Share")}>
                                    <ShareIcon />
                                  </IconButton>
                                </Tooltip>

                              </Stack>

                            </div>

                            <CardMedia
                              component="img"
                              image="https://blog.remax.com.br/wp-content/uploads/2024/06/fiador-de-aluguel-todas-as-duvidas-respondidas-aqui-730x401.png"

                            />

                          </Box>

                          {/* )} */}

                          {/* Blog Image
{post.image && post.image.items.length > 0 && (
<CardMedia
  component="img"
  //image="https://mui.com/static/images/cards/paella.jpg"
  image={post.image.items[0]._image[0].attachment._urls["one-third"]}
  style={{ width: 350, height: 200 }}
  alt={post.title}
  title={post.title}
/>
)} */}

                          <Stack direction="column" spacing={3}>

                            {/* {post.category} */}
                            {/* {post.category && ( */}
                            <Box>
                              <Chip label="Category" variant="outlined" />
                            </Box>
                            {/* )} */}

                            <Stack direction="column" spacing={2}>

                              {/* {post.title} */}
                              {/* {post.title && ( */}
                              <Typography className="h4" variant="h4">
                                Fiador de aluguel: todas as dúvidas respondidas aqui
                              </Typography>
                              {/*  )} */}

                              {/* {post.description} */}
                              {/* {post.description && ( */}
                              <Typography className="body1" variant="body1">Saiba tudo sobre uma das modalidades mais tradicionais utilizadas na locação de imóveis: o fiador de aluguel Na hora de fazer a locação de um imóvel, seja este comercial ou residencial, sempre são exigidas garantias locatícias, e a utilização de um fiador de aluguel está entre as mais antigas e tradicionais. Essa figura desempenha um papel crucial...</Typography>
                              {/* )} */}

                            </Stack>

                            {/* {post.author && post.publishedAt && ( */}
                            <Typography className="body2 secondary-text" variant="body2">Author / Date </Typography>{/* {post.author} / {post.publishedAt} */}
                            {/* )} */}

                          </Stack>

                        </Stack>

                      </CardContent>

                    </Card>

                  </Link>

                </Item>

              </Hidden>

              {/* Desktop Card */}

              <Hidden mdDown>

                <Item style={{ padding: '0', borderRadius: '1rem', boxShadow: 'none !important' }}>

                  <Link underline="none" //href={`/blog/${post.aposDocId}`}
                  >

                    <Card className="gridItem customBox" sx={{ boxShadow: 'none !important' }} //key={post.aposDocId} 
                    >

                      <CardContent sx={{ padding: '1.5rem !important', boxShadow: 'none !important' }}>

                        <Grid container spacing={5}>

                          <Grid item xs={8}>

                            <Stack direction="column" spacing={3}>

                              {/* {post.category} */}
                              {/* {post.category && ( */}
                              <Box>
                                <Chip label="Category" variant="outlined" />
                              </Box>
                              {/* )} */}

                              <Stack direction="column" spacing={2}>

                                {/* {post.title} */}
                                {/* {post.title && ( */}
                                <Typography className="h4" variant="h4">
                                  Fiador de aluguel: todas as dúvidas respondidas aqui
                                </Typography>
                                {/*  )} */}

                                {/* {post.description} */}
                                {/* {post.description && ( */}
                                <Typography className="body1" variant="body1">Saiba tudo sobre uma das modalidades mais tradicionais utilizadas na locação de imóveis: o fiador de aluguel Na hora de fazer a locação de um imóvel, seja este comercial ou residencial, sempre são exigidas garantias locatícias, e a utilização de um fiador de aluguel está entre as mais antigas e tradicionais. Essa figura desempenha um papel crucial...</Typography>
                                {/* )} */}

                              </Stack>

                              {/* {post.author && post.publishedAt && ( */}
                              <Typography className="body2 secondary-text" variant="body2">Author / Date </Typography>{/* {post.author} / {post.publishedAt} */}
                              {/* )} */}

                            </Stack>

                          </Grid>

                          <Grid item xs={4}>

                            {/* {post.image && post.image.items.length > 0 && ( */}
                            <Box sx={{ position: 'relative', backgroundImage: 'url(https://blog.remax.com.br/wp-content/uploads/2024/06/fiador-de-aluguel-todas-as-duvidas-respondidas-aqui-730x401.png)', backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '100%', borderRadius: '1rem' }}>

                              <div className="favourite-map-buttons-container">

                                <Stack direction="column" spacing={1}>

                                  <Tooltip title={t('ListingFull_AddToFavouritesToolTip')} placement="left">
                                    <IconButton className="card-button-icons" aria-label={t('ListingFull_AddToFavouritesToolTip')}>
                                      <FavoriteIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title={t("ShareAndCompareWidget_Share")} placement="left">
                                    <IconButton className="card-button-icons" aria-label={t("ShareAndCompareWidget_Share")}>
                                      <ShareIcon />
                                    </IconButton>
                                  </Tooltip>

                                </Stack>

                              </div>

                            </Box>
                            {/* )} */}

                            {/* Blog Image
                          {post.image && post.image.items.length > 0 && (
                            <CardMedia
                              component="img"
                              //image="https://mui.com/static/images/cards/paella.jpg"
                              image={post.image.items[0]._image[0].attachment._urls["one-third"]}
                              style={{ width: 350, height: 200 }}
                              alt={post.title}
                              title={post.title}
                            />
                          )} */}

                          </Grid>

                        </Grid>

                      </CardContent>

                    </Card>

                  </Link>

                </Item>

              </Hidden>

              {/* ))} */}

            </Stack>

          </Grid>

          {/* Right column - Filters */}
          <Grid item xs={12} sm={4}>

            <Item style={{ padding: '0', borderRadius: '1rem', boxShadow: 'none !important' }}>

              <Card className="gridItem customBox" sx={{ boxShadow: 'none !important' }}>

                <CardContent sx={{ padding: '1.5rem', boxShadow: 'none !important' }}>

                  <Stack direction="column" spacing={2} textAlign="center">

                    <Typography className="text-center" variant="h5" textAlign="center" sx={{ textAlign: 'center !important', fontSize: '1.25rem !important', fontWeight: '500 !important;', fontFamily: 'var(--font-family) !important;' }}>Explore by category</Typography>

                    <List component="nav" aria-label="" sx={{ paddingTop: '0 !important', paddingBottom: '0 !important' }}>

                      <ListItemButton
                        sx={{ justifyContent: 'center' }}
                        selected={selectedCategory === 'category1'} // Conditionally apply 'selected'
                        onClick={() => handleCategoryClick('category1')}>
                        Buyer
                      </ListItemButton>

                      <ListItemButton
                        sx={{ justifyContent: 'center' }}
                        selected={selectedCategory === 'category2'} // Conditionally apply 'selected'
                        onClick={() => handleCategoryClick('category2')}>
                        Seller
                      </ListItemButton>

                      <ListItemButton
                        sx={{ justifyContent: 'center' }}
                        selected={selectedCategory === 'category3'} // Conditionally apply 'selected'
                        onClick={() => handleCategoryClick('category3')}>
                        Expertise
                      </ListItemButton>

                      <ListItemButton
                        sx={{ justifyContent: 'center' }}
                        selected={selectedCategory === 'category4'} // Conditionally apply 'selected'
                        onClick={() => handleCategoryClick('category4')}>
                        Franchise
                      </ListItemButton>

                    </List>

                  </Stack>

                </CardContent>

              </Card>

            </Item>

          </Grid>

        </Grid>

        <Stack direction={{ xs: 'column', md: 'row' }} mt={3} alignItems="start" useFlexGap flexWrap="wrap">

          <Pagination shape="rounded" />

        </Stack>

      </Container>

    </div>



  );
};

export default Blog;