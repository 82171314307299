import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';

// Define the type for the context
interface ContentContextType {
    footer: boolean;
    header: boolean;
    setFooter: React.Dispatch<React.SetStateAction<boolean>>;
    setHeader: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with a default value
const ContentProvider = createContext<ContentContextType | undefined>(undefined);

// Custom hook to use the ContentContext
export const useContent = () => {
    const context = useContext(ContentProvider);
    if (!context) {
        throw new Error('useContent must be used within a ContentContext');
    }
    return context;
};

// Create a provider component
export const ContentContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [footer, setFooter] = useState<boolean>(false);
    const [header, setHeader] = useState<boolean>(false);

    useEffect(() => {
      //
    }, []);

    // Memoize the context value to avoid unnecessary recalculations
    const contextValue = useMemo(() => ({ footer, header, setFooter, setHeader }), [footer, header]);

    return (
        <ContentProvider.Provider value={contextValue}>
            {children}
        </ContentProvider.Provider>
    );
};
