import { TENANTS } from "../../../constants/constants";

function getProfileDefaultImagePath() : string {
    return `/images/${window.theme}/default_image_office.gif`;
}

function getProfileImagePath(regionId?: number, photoId?: string) : string {

    if (photoId === null || photoId === '' || regionId == null || regionId <= 0)
        return getProfileDefaultImagePath();

    if (window.tenantid !== TENANTS.REMAX ){
        return `https://repstaticneu.azureedge.net/images/${photoId}`;
    } else {
        // return `https://phoenix-kangaroo.azure-api.net/userimages/${regionId}/${photoId}`;
        return `https://remax.azureedge.net/userimages/${regionId}/${photoId}`; 
    } 
}

function getDesignationLogoImagePath(logo: string) : string{
    const theme = process.env.REACT_APP_THEME;
    return `/images/${window.theme}/${logo}`;
}

function getListingDefaultImagePath() : string {
    return `/images/${window.theme}/default_image.gif`;
}

function getListingImagePath(photoId: string, regionId: number, hasLargeImage: string, isWatermarked: string, tenantID = TENANTS.REMAX): string {

    if (photoId === null || photoId === '' || regionId <= 0)
        return getListingDefaultImagePath();

    if (window.tenantid !== TENANTS.REMAX ){
        return `https://repstaticneu.azureedge.net/images/${photoId}`; // To Check with REP
    }else{
        if (hasLargeImage === "0") {
            return `${process.env.REACT_APP_USERIMAGES}${regionId}/${photoId}`;
        }

        return isWatermarked === "1" ? `${process.env.REACT_APP_USERIMAGES}${regionId}/LargeWM/${photoId}`:
                                        `${process.env.REACT_APP_USERIMAGES}${regionId}/Large/${photoId}`;  
    } 
}

export { getProfileImagePath, getListingImagePath, getProfileDefaultImagePath, getListingDefaultImagePath, getDesignationLogoImagePath };