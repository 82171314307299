import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Hidden, Link, Typography, Paper, Stack, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import { ContactMePopup } from '../utils';
import { getProfileImagePath } from '../utils/helper/ImageHelper';
import { Office, SiteSettings } from '../../types/TypesIndex';
import { ProfileOfficeCardForNameAddressLicense, ProfileSocialChatIntegration, ProfileContactInfo } from '../profile/ProfileComponentsIndex';
import { PAGE_TYPE } from '../../constants/constants';

interface ProfileSearchOfficeCardProps {
    office: Office;
    hideOfficeUrl?: boolean;
    siteSettings?: SiteSettings
}

const ProfileSearchOfficeCard: React.FC<ProfileSearchOfficeCardProps> = ({ office, siteSettings }) => {
    // Parameters Declaration
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a data fetch or initialization
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200); // Replace with actual loading logic

        return () => clearTimeout(timer);
    }, []);

    let officeShortLink = `/${PAGE_TYPE.OFFICE}/` + office.OfficeId;
    if (office.OfficeShortLinks !== null
        && office.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && office.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        officeShortLink = `/${PAGE_TYPE.OFFICE}/` + office.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

    // Hook Declaration
    const [openDialog, setOpenDialog] = useState(false);

    // Events Handlers
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const DesktopSkeleton = (
        <Paper
            sx={{
                p: 3,
                margin: 'auto',
                borderRadius: '1rem',
                marginBottom: '1.5rem',
                maxWidth: '100%',
                flexGrow: 1,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
        >
            <Stack direction="column">
                <Grid container spacing={3}>
                    <Grid item xs="auto" alignItems="center">
                        <Skeleton variant="rectangular" width={240} height={180} sx={{ borderRadius: '1rem' }} />
                    </Grid>
                    <Grid item xs={6} alignItems="center" alignContent="center">
                        <Stack spacing={3} direction="column">
                            <Stack spacing={1} direction="column">
                                <Skeleton variant="text" width={200} />
                                <Skeleton variant="text" width={150} />
                            </Stack>
                            <Stack spacing={2} direction="column">
                                <Skeleton variant="rectangular" width={120} height={40} />
                                <Skeleton variant="text" width={180} />
                                <Skeleton variant="text" width={180} />
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Paper>
    );

    // Mobile Skeleton
    const MobileSkeleton = (
        <Paper
            sx={{
                p: 3,
                margin: 'auto',
                borderRadius: '1rem',
                marginBottom: '1.5rem',
                maxWidth: '100%',
                flexGrow: 1,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
        >
            <Grid container spacing={2} alignItems="center" direction="column" textAlign="center" justifyContent="center">
                <Grid item>
                    <Skeleton variant="rectangular" width={240} height={180} sx={{ borderRadius: '1rem' }} />
                </Grid>
                <Grid item xs={12} sm container alignItems="center" alignContent="center" justifyContent="center">
                    <Grid item xs container direction="column" spacing={2} alignItems="center" alignContent="center" justifyContent="center">
                        <Grid item>
                            <Stack spacing={3} direction="column" alignItems="center" alignContent="center" justifyContent="center">
                                <Stack spacing={1} direction="column" alignItems="center" alignContent="center">
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={150} />
                                </Stack>
                                <Stack direction="column" alignItems="center" alignContent="center" justifyContent="center" spacing={2}>
                                    <Skeleton variant="rectangular" width={120} height={40} />
                                    <Skeleton variant="text" width={180} />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );

    if (loading) {
        return (
            <>
                <Hidden lgDown>
                    {DesktopSkeleton}
                </Hidden>
                <Hidden lgUp>
                    {MobileSkeleton}
                </Hidden>
            </>
        );
    }

    return (
        <>

            {/* Desktop Card */}
            <Hidden lgDown>

                <Paper
                    sx={{
                        p: 3,
                        margin: 'auto',
                        borderRadius: '1rem',
                        marginBottom: '1.5rem',
                        maxWidth: '100%',
                        flexGrow: 1,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    }}
                >

                    <Stack direction="column">

                        {/* First Row - Office img and information */}
                        <Grid container spacing={3}>

                            {/* First Column */}
                            <Grid item xs="auto" alignItems="center">

                                <Link underline="none" title={office.OfficeName} aria-label={office.OfficeName} href={officeShortLink} sx={{ textDecoration: 'none !important' }}>
                                    <img style={{ borderRadius: '1rem', width: '240px', maxWidth: '100%', height: 'auto' }}
                                        alt={office.OfficeName}
                                        title={office.OfficeName}
                                        src={getProfileImagePath(office.RegionId, office.PhotoId)} />
                                </Link>

                            </Grid>

                            {/* Second Column */}
                            <Grid item xs={6} alignItems="center" alignContent="center">

                                <Stack spacing={3} direction="column">

                                    <Stack spacing={1} direction="column">

                                        {/* Office Name */}
                                        <Link underline="hover" href={officeShortLink} title={office.OfficeName} aria-label={office.OfficeName}>
                                            <Typography className="h4" component="span">
                                                {office.OfficeName}
                                            </Typography>
                                        </Link>

                                        {/* Office License Number */}
                                        {office.OfficeLicenseNumber && (
                                            <Typography className="body2 secondary-text" variant="body2">
                                                {t('OfficeFull_LicenseNumber')} {office.OfficeLicenseNumber}
                                            </Typography>
                                        )}

                                    </Stack>

                                    <Stack spacing={2} direction="column">

                                        <Stack spacing={{ xs: 2, md: 0 }} sx={{ gap: '1rem !important' }} direction="row" alignItems="center" useFlexGap flexWrap="wrap" justifyContent="flex-start">

                                            {/* Contact Us Button */}
                                            <Button fullWidth={true}
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    width: 'auto',
                                                    maxWidth: 'fit-content',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--button-active-color) !important',
                                                    }
                                                }}
                                                role="button"
                                                aria-label={t('OfficeFull_ContactUs')}
                                                className="primary-outlined-button"
                                                variant="outlined"
                                                onClick={handleOpenDialog}>
                                                {t('OfficeFull_ContactUs')}
                                            </Button>

                                            {/* Global Chat Integration Links (component) */}
                                            {office && <ProfileSocialChatIntegration
                                                phone={office?.Phone}
                                                email={office?.Email}
                                                whatsApp=''
                                                facebookMessengerID=''
                                                viberID=''
                                                telegramID=''
                                                lineChat=''
                                                lineChatID=''
                                                weChat=''
                                                weChatID=''
                                                isMobile={isMobile}
                                                page={PAGE_TYPE.OFFICE}
                                            />}

                                            {/* Global Contact Me Popup (component) */}
                                            {/* To Do:  Implment the Lead Agent Info for Office Contact Us Form */}
                                            <ContactMePopup data={{ office: office, open: openDialog, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog }} />

                                        </Stack>

                                        {/* Global Show More Buttons (component) */}
                                        <Typography className="secondary-text body1" variant="body1">
                                            {office && <ProfileContactInfo
                                                page={PAGE_TYPE.OFFICESEARCH}
                                                contactInfo={{
                                                    AgentPhone: '',
                                                    DirectDialPhoneNumber: '',
                                                    OfficePhone: office.Phone,
                                                    Email: office.Email,
                                                    OfficeId: office.OfficeId,
                                                    RegionId: office.RegionId,
                                                    TenantId: office.TenantId,
                                                    siteSettings: siteSettings
                                                }} />}
                                        </Typography>

                                    </Stack>

                                    {/* Global Office Name, Address and License Number (component) */}
                                    {office && <ProfileOfficeCardForNameAddressLicense
                                        data={{
                                            office: office,
                                            page: PAGE_TYPE.OFFICE,
                                            isSeo: true,
                                            hideOfficeName: true,
                                            hideOfficeLicenseNumber: true,
                                            hideAgentLicenseNumber: true,
                                            hideUrl: true,
                                        }}
                                    />}

                                </Stack>

                            </Grid>

                        </Grid>

                    </Stack>

                </Paper>

            </Hidden>

            {/* Mobile Card */}
            <Hidden lgUp>

                <Paper
                    sx={{
                        p: 3,
                        margin: 'auto',
                        borderRadius: '1rem',
                        marginBottom: '1.5rem',
                        maxWidth: '100%',
                        flexGrow: 1,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    }}
                >
                    <Grid container spacing={2} alignItems="center" direction="column" textAlign="center" justifyContent="center">

                        {/* First Column*/}
                        <Grid item justifyContent="center">

                            <Link underline="none" title={office.OfficeName} aria-label={office.OfficeName} href={officeShortLink} sx={{ textDecoration: 'none !important' }}>
                                <img style={{ borderRadius: '1rem', width: '240px', height: 'auto', maxWidth: '100%' }}
                                    alt={office.OfficeName}
                                    title={office.OfficeName}
                                    src={getProfileImagePath(office.RegionId, office.PhotoId)} />
                            </Link>

                        </Grid>

                        <Grid item xs={12} sm container alignItems="center" justifyContent="center">

                            <Grid item xs container direction="column" spacing={2} justifyContent="center" >

                                <Grid item justifyContent="center">

                                    <Stack spacing={3} direction="column">

                                        <Stack spacing={1} direction="column">

                                            {/* Office Name  */}
                                            <Link underline="hover" href={officeShortLink} title={t('ProfileSearch_ShortLink')} aria-label={t('ProfileSearch_ShortLink')}>
                                                <Typography className="h4" component="span">
                                                    {office.OfficeName}
                                                </Typography>
                                            </Link>

                                            {/* Office License Number */}
                                            {office.OfficeLicenseNumber && (
                                                <Typography className="body2 secondary-text" variant="body2">
                                                    {t('OfficeFull_LicenseNumber')} {office.OfficeLicenseNumber}
                                                </Typography>
                                            )}

                                        </Stack>

                                        <Stack direction="column" alignItems="center" spacing={2}>

                                            <Button fullWidth={true}
                                                sx={{
                                                    marginBottom: '.5rem !important',
                                                    whiteSpace: 'nowrap',
                                                    width: 'auto',
                                                    maxWidth: 'fit-content',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--button-active-color) !important',
                                                    }
                                                }}
                                                role="button"
                                                aria-label={t('OfficeFull_ContactUs')}
                                                className="primary-outlined-button"
                                                variant="outlined"
                                                onClick={handleOpenDialog}>
                                                {t('OfficeFull_ContactUs')}
                                            </Button>

                                            {/* * Global Chat Integration Links component */}
                                            {office && <ProfileSocialChatIntegration
                                                phone={office?.Phone}
                                                email={office?.Email}
                                                whatsApp=''
                                                facebookMessengerID=''
                                                viberID=''
                                                telegramID=''
                                                lineChat=''
                                                lineChatID=''
                                                weChat=''
                                                weChatID=''
                                                isMobile={isMobile}
                                                page={PAGE_TYPE.OFFICE}
                                            />}

                                            {/* Global Contact Me Popup (component) */}
                                            {/* To Do:  Implment the Lead Agent Info for Office Contact Us Form */}
                                            <ContactMePopup data={{ office: office, open: openDialog, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog }} />

                                            {/* Global Show More Buttons (component) */}
                                            <Typography className="secondary-text body1" variant="body1">
                                                {office && <ProfileContactInfo
                                                    page={PAGE_TYPE.OFFICESEARCH}
                                                    contactInfo={{
                                                        AgentPhone: '',
                                                        DirectDialPhoneNumber: '',
                                                        OfficePhone: office.Phone,
                                                        Email: office.Email,
                                                        OfficeId: office.OfficeId,
                                                        RegionId: office.RegionId,
                                                        TenantId: office.TenantId,
                                                        siteSettings: siteSettings
                                                    }} />}
                                            </Typography>

                                        </Stack>

                                        {/* Global Office Name, Address and License Number (component) */}
                                        {office && <ProfileOfficeCardForNameAddressLicense
                                            data={{
                                                office: office,
                                                page: PAGE_TYPE.OFFICE,
                                                isSeo: true,
                                                hideOfficeName: true,
                                                hideOfficeLicenseNumber: true,
                                                hideAgentLicenseNumber: true,
                                                hideUrl: true,
                                            }} />}

                                    </Stack>

                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>

                </Paper>

            </Hidden>

        </>
    );
};

export default ProfileSearchOfficeCard;
