import ListingSearchProps from "../../../types/Listing/ListingSearch";
import { TENANTS } from "../../../constants/constants";
import { de } from "date-fns/locale";


function listingSearch(searchParams: URLSearchParams, searchState: ListingSearchProps): string {
  let filter = '';
  let filteror = '';

  //Test
  const addFilter = (condition: string) => {
    if (filter.length > 0) {
      filter += ' and ';
    }
    filter += condition;
  };

  const addFilteror = (condition: string) => {
    if (filteror.length > 0) {
      filteror += ' or ';
    }
    filteror += condition;
  };


  if (window.tenantid !== undefined) {
    addFilter(`content/TenantId eq ${window.tenantid}`);
  }
  if (window.tenantid !== TENANTS.EXP && window.tenantid !== undefined) {
    addFilter(`content/MacroRegionId eq ${window.macroregionid}`);
  }
  addFilter(`content/OnHoldListing eq false`);
  // Check if there are query parameters
if(!searchState.IsReset) {
  if (Array.from(searchParams.keys()).length > 0) {
    for (const [key, value] of searchParams.entries()) {
      if (value && value.trim() !== '' && value.trim() !== 'NaN' && value !== '-1') { // Ensure value is not empty or 'NaN'
        switch (key) {
          // Common Section
          case 'TransactionTypeUID':
            if(value !== '-1') 
            addFilter(`content/TransactionTypeUID eq ${value}`);
            break;
          case "ListingClass":
            if(value !== '-1') 
            addFilter(`content/ListingClass eq ${value}`);
            break;
          // Geo Data Section : To Do: More GEO and Free Text        
          case 'City':
            const cities = searchParams.getAll('City');
            cities.map(city => addFilteror(`content/CityID eq ${city}`));
            break;
          case 'Province':
            const provinces = searchParams.getAll('Province');
            provinces.map(province => addFilteror(`content/ProvinceID eq ${province}`));
            break;
          case 'FreeTextSearch':
            addFilter(`(search.ismatch('${value}', 'content/District') 
                          or search.ismatch('${value}', 'content/City') 
                          or search.ismatch('${value}', 'content/StreetName')
                          or search.ismatch('${value}', 'content/MLSID'))`);
            break;
          case 'LocalZone':
            if (value.indexOf('-') > -1) { 
            addFilter(`search.ismatch('"${value.split('-')[0]}"', 'content/LocalZone') `);
            }
            break;
          case 'FreeText':
            addFilter(`search.ismatch('${value.split('-')[0]}', 'content/FullTextSearch') `);
            break;
          case 'StreetName':
            addFilter(`content/StreetName eq '${value}'`);
            break;          
          // Quick Search Filter
          case 'MacroPropertyTypeUIDs':        
            if (value.includes(',')) {             
              const values = value.split(',');
              const conditions = values.filter(id => id !== 'NaN' && id !== "").map(value => `content/MacroPropertyTypeUID eq ${parseInt(value)}`);
              addFilter(`(${conditions.join(' or ')})`);  
            } else {
              addFilter(`content/MacroPropertyTypeUID eq ${value}`);
            } 
            break;
          case 'PriceMin':
            addFilter(`content/ListingPrice ge ${value}`);
            break;
          case 'PriceMax':
            addFilter(`content/ListingPrice le ${value}`);
            break;
          case 'Bedrooms':
            addFilter(`content/NumberOfBedrooms ge ${value}`);
            break;
          case 'Bathrooms':
            addFilter(`content/NumberOfBathrooms ge ${value}`);
            break;
          case 'PropertyFeatures':
            const featureIds = value.split(',').filter(id => id.trim() !== '');
            const conditions = featureIds.map(id => `content/ListingFeatures/any(f: f/FeatureID eq '${id}')`);
            addFilter(`(${conditions.join(' and ')})`);
            break;
          case 'MinTotalArea':
            addFilter(`content/TotalArea ge ${value}`);
            break;
          case 'MaxTotalArea':
            addFilter(`content/TotalArea le ${value}`);
            break;
          case 'ParkingSpaces':
            addFilter(`content/ParkingSpaces ge ${value}`);
            break;
          case 'PropertiesAdded':
            const newDate = getFilteredDate(value);
            const comparisonDate = new Date(newDate ?? '');
            const comparisonTimestamp = Math.floor(comparisonDate.getTime() / 1000);
            if (newDate) {
              addFilter(`content/LastUpdatedOnWeb ge ${comparisonTimestamp}`);
            }
            break;
          case 'MLSID':
            addFilter(`content/MLSID eq '${value}'`);
            break;
          case 'DevelopmentID':
            if (value !== '-1'){
             addFilter(`content/DevelopmentID eq ${value}`);
            }
            break;
          case 'HasMultiMedia':
            const media = value === '1';
            addFilter(`content/HasEnhancedMultimedia eq ${media}`);
            break;        
          case "AgentID":
            addFilter(`((content/AgentId eq ${value} and content/RepresentingAgentID eq null) or content/RepresentingAgentID  eq ${value})`);
            break;
          case "TeamID":
            addFilter(`content/TeamID eq ${value}`);
            break;
          case "OfficeID":
            addFilter(`content/OfficeId eq ${value}`);
            break;
          case "MacroOfficeID":
              addFilter(`content/MacroOfficeId eq ${value}`);
              break;         
          default:
            // addFilter(`content/${key} eq '${value}'`);
            break;
        }
      }
    }
  }
  } 
    // Sync query string with searchState
    const syncQueryString = (key: string, value: any) => {
      if (value && value.toString().trim() !== '' && value.toString().trim() !== 'NaN' && value.toString().trim() !== '-1') {
        searchParams.set(key, value.toString().trim());
      }
    };

    syncQueryString('FreeText', searchState.FreeText);
    syncQueryString('TransactionTypeUID', searchState.TransactionTypeUID);
    syncQueryString('ListingClass', searchState.ListingClass);
    syncQueryString('MacroPropertyTypeUIDs', searchState.MacroPropertyTypeUIDs);
    syncQueryString('PropertyTypeUID', searchState.PropertyTypeUID);
    syncQueryString('PriceMin', searchState.PriceMin);
    syncQueryString('PriceMax', searchState.PriceMax);
    syncQueryString('Bedrooms', searchState.Bedrooms);
    syncQueryString('Bathrooms', searchState.Bathrooms);
    syncQueryString('PropertyFeatures', searchState.PropertyFeatures);
    syncQueryString('PropertiesAdded', searchState.PropertiesAdded);
    syncQueryString('MLSID', searchState.MLSID);
    syncQueryString('DevelopmentID', searchState.DevelopmentID);
    syncQueryString('HasMultiMedia', searchState.HasMultiMedia);
    syncQueryString('AgentID', searchState.AgentID);
    syncQueryString('TeamID', searchState.TeamID);
    syncQueryString('OfficeID', searchState.OfficeID);
    syncQueryString('MacroOfficeID', searchState.MacroOfficeID);
    syncQueryString('CenterLng', searchState.CenterLng);
    syncQueryString('CenterLat', searchState.CenterLat);
    syncQueryString('DynamicRadius', searchState.DynamicRadius);

    // Handle searchState when no query parameters
    if (searchState.FreeText && searchState.FreeText.trim() !== '' && searchState.FreeText.trim() !== 'NaN') {
      addFilter(`(search.ismatch('${searchState.FreeText}', 'content/District') 
                    or search.ismatch('${searchState.FreeText}', 'content/City') 
                    or search.ismatch('${searchState.FreeText}', 'content/StreetName')
                    or search.ismatch('${searchState.FreeText}', 'content/MLSID'))`);
    }

    if (Array.isArray(searchState.Province)) {
      searchState.Province.forEach(province => {
        if (province && province.toString().trim() !== 'NaN') {
          addFilteror(`content/ProvinceID eq ${province}`);
        }
      });
    }

    if (Array.isArray(searchState.City)) {
      searchState.City.forEach(city => {
        if (city && city.toString().trim() !== 'NaN') {
          addFilteror(`content/CityID eq ${city}`);
        }
      });
    }

    if (Array.isArray(searchState.LocalZone)) {
      searchState.LocalZone.forEach(lz => {
        if (lz && lz.toString().trim() !== 'NaN') {
          addFilteror(`content/LocalZoneID eq ${lz}`);
        }
      });
    }    

    if (searchState.StreetName && searchState.StreetName.trim() !== 'NaN') {
      addFilter(`content/StreetName eq '${searchState.StreetName}'`);
    }
    if (searchState.PriceMin && searchState.PriceMin.trim() !== '-1' && searchState.PriceMin.trim() !== 'NaN') {
      addFilter(`content/ListingPrice ge ${searchState.PriceMin.trim()}`);
    }
    if (searchState.PriceMax && searchState.PriceMax.trim() !== '-1' && searchState.PriceMax.trim() !== 'NaN') {
      addFilter(`content/ListingPrice le ${searchState.PriceMax.trim()}`);
    }
    if (searchState.Bedrooms && searchState.Bedrooms.trim() !== 'NaN') {
      addFilter(`content/NumberOfBedrooms ge ${parseInt(searchState.Bedrooms)}`);
    }
    if (searchState.Bathrooms && searchState.Bathrooms.trim() !== 'NaN') {
      addFilter(`content/NumberOfBathrooms ge ${parseInt(searchState.Bathrooms)}`);
    }
    if (searchState.TransactionTypeUID && searchState.TransactionTypeUID.trim() !== '-1') {
      addFilter(`content/TransactionTypeUID eq ${searchState.TransactionTypeUID}`);
    }
    if (searchState.ListingClass && searchState.ListingClass.trim() !== '-1') {
      addFilter(`content/ListingClass eq ${searchState.ListingClass}`);
    }
    if (searchState.MacroPropertyTypeUIDs && searchState.MacroPropertyTypeUIDs.includes(',')) {
      const values = searchState.MacroPropertyTypeUIDs.split(',');
      const conditions = values.filter(id => id !== 'NaN' && id !== "").map(value => `content/MacroPropertyTypeUID eq ${parseInt(value)}`);
      addFilter(`(${conditions.join(' or ')})`);
    } else if (searchState.MacroPropertyTypeUIDs) {
      addFilter(`content/MacroPropertyTypeUID eq ${searchState.MacroPropertyTypeUIDs}`);
    }
    if (searchState.PropertyTypeUID && searchState.PropertyTypeUID.trim() !== '-1') {
      addFilter(`content/PropertyTypeUID eq ${searchState.PropertyTypeUID}`);
    }
    if (searchState.PropertyFeatures && searchState.PropertyFeatures.trim() !== 'NaN') {
      const featureIds = searchState.PropertyFeatures.split(',').filter(id => id.trim() !== '');;
      const conditions = featureIds.map(id => `content/ListingFeatures/any(f: f/FeatureID eq '${id}')`);
      addFilter(`(${conditions.join(' and ')})`);
    }
    if (searchState.PropertiesAdded && searchState.PropertiesAdded.trim() !== 'NaN') {
      const newDate = getFilteredDate(searchState.PropertiesAdded);
      const comparisonDate = new Date(newDate ?? '');
      const comparisonTimestamp = Math.floor(comparisonDate.getTime() / 1000);
      if (newDate) {
        addFilter(`content/LastUpdatedOnWeb ge ${comparisonTimestamp}`);
      }
    }
    if (searchState.MLSID && searchState.MLSID.trim() !== 'NaN') {
      addFilter(`content/MLSID eq '${searchState.MLSID}'`);
    }
    if (searchState.DevelopmentID && searchState.DevelopmentID.trim() !== 'NaN' && searchState.DevelopmentID.trim() !== '-1') {
      addFilter(`content/DevelopmentID eq ${searchState.DevelopmentID}`);
    }
    if (searchState.HasMultiMedia && searchState.HasMultiMedia.trim() !== 'NaN') {
      addFilter(`content/HasEnhancedMultimedia eq ${searchState.HasMultiMedia}`);
    }
    if (searchState.AgentID && searchState.AgentID.trim() !== 'NaN') {
      addFilter(`((content/AgentId eq ${searchState.AgentID} and content/RepresentingAgentID eq null) or content/RepresentingAgentID eq ${searchState.AgentID}) `);
    }
    if (searchState.TeamID && searchState.TeamID.trim() !== 'NaN') {
      addFilter(`content/TeamID eq ${searchState.TeamID}`);
    }
    if (searchState.OfficeID && searchState.OfficeID.trim() !== 'NaN') {
      addFilter(`content/OfficeId eq ${searchState.OfficeID}`);
    }
    if (searchState.MacroOfficeID && searchState.MacroOfficeID.trim() !== 'NaN') {
      addFilter(`content/MacroOfficeId eq ${searchState.MacroOfficeID}`);
    }
    if (searchState.CenterLng && searchState.CenterLat && searchState.DynamicRadius) {
      addFilter(`geo.distance(content/Location, geography'POINT(${searchState.CenterLng} ${searchState.CenterLat})') le ${searchState.DynamicRadius}`);
    }
  
  addFilter(`content/OnHoldListing eq false`);
  addFilter(`content/IsRegionalOffice eq false`);

  if (filteror) {
    filter += ` and (${filteror})`;
  }

  return filter;
}

function getRadius(caseNumber: number): number {
  const radii = [
    78271, 39156, 19568, 9784, 4892, 2446, 1223, 611,
    306, 153, 30, 15, 8, 4, 3, 2.5, 2, 1.5, 1, 0.8, 0.5
  ];
  return radii[caseNumber - 1] || 1;
}

function getOrderByValue(sortOption: string): string {
  switch (sortOption) {
    case 'Search_PriceIncreasing':
      return "content/ListingPrice asc";
    case 'Search_PriceDecreasing':
      return "content/ListingPrice desc";
    case 'Search_MostRecent':
      return "content/LastUpdatedOnWeb desc";
    case 'Search_LivingAreaIncreasing':
      return "content/TotalArea asc";
    case 'Search_LivingAreaDecreasing':
      return "content/TotalArea desc";
    default:
      return "";
  }
}

const getFilteredDate = (id: string): string | null => {
  const today = new Date();
  let newDate = new Date(today);

  switch (id) {
    case "156":
      newDate.setDate(today.getDate() - 1);
      break;
    case "157":
      newDate.setDate(today.getDate() - 30);
      break;
    case "158":
      newDate.setDate(today.getDate() - 7);
      break;
    default:
      return null; // No filter
  }

  return formatDate(newDate);
};

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export { listingSearch, getRadius, getOrderByValue };
