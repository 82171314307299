import React, { useEffect, useState } from 'react';
import { SiteSettings, DevelopmentSearchResponse, LookupItem, Province } from '../../types/TypesIndex';
import { DevelopmentSearchDevelopmentCard } from './DevelopmentComponentsIndex';
import { Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material';
import { fetchDevelopmentList } from '../../services/DevelopmentService';
import { useTranslation } from 'react-i18next';
import { escapeSpecialCharacters } from '../utils/helper/DataFormatHelper';

interface DevelopmentSearchDevelopmentListProps {
    debouncedProvinceId: number;
    debouncedDevelopmentName: string;
    pageId: number;
    totalRecordsPerPage?: number;
    onTotalMatchCount: (matchCount: number) => void;
    onTotalPageCount: (total: number) => void;
    onPageIdChange: (pageId: number) => void;
    siteSettings?: SiteSettings;
    lookupList?:LookupItem[];
    regionProvinceList: Province[];
    isFeatured: boolean;
}

const DevelopmentSearchDevelopmentList: React.FC<DevelopmentSearchDevelopmentListProps> = ({
    debouncedProvinceId,
    debouncedDevelopmentName,
    pageId = 1,
    totalRecordsPerPage = 10,
    onTotalMatchCount,
    onTotalPageCount,
    onPageIdChange,
    siteSettings, 
    lookupList,
    regionProvinceList, 
    isFeatured
}) => {

    // Parameters Declaration
    const { t, i18n } = useTranslation();

    // Hook Declaration 
    const [developments, setDevelopments] = useState<DevelopmentSearchResponse | undefined>();


    // Use Effect Declaration 
    useEffect(() => {

        const getFilter = () => {

            const addFilter = (condition: string) => {
                if (filter.length > 0) {
                    filter += ' and ';
                }

                filter += condition;
            };

            let filter = '';
            if (window.tenantid > 0) {
                addFilter(`content/TenantId eq ${window.tenantid}`);
            }
            
            if (window.macroregionid > 0) {
                addFilter(`content/RegionId eq ${window.macroregionid}`);
            }

            addFilter(`content/IsActive eq true`);

            if (isFeatured ) {  
                addFilter(`content/IsFeatured eq true`);
                return filter;
            } 

            if (debouncedProvinceId > 0) {
                addFilter(`content/ProvinceID eq ${debouncedProvinceId}`);
            }

            if (debouncedDevelopmentName.length > 0 ) {
                let str = escapeSpecialCharacters(debouncedDevelopmentName);
                let strWords = str.trim().split(/\s+/);
                let updatedDevelopmentName = strWords.map(word => word + '*').join(' ');
                       
                addFilter(`search.ismatch('${updatedDevelopmentName}', 'content/DevelopmentName', 'full', 'all')`);                
            }
            
            return filter;
        }

        const getSkip = () => {
            if (isFeatured) {
                return 0;
            }
            
            return ((pageId ?? 1) - 1) * totalRecordsPerPage;
        }

        const getSelectContent =() => {
            return 'content/RegionId, content/DevelopmentID, content/DevelopmentName, content/DevelopmentShortlink, content/DevelopmentImages, content/ProvinceID, content/CityID';
        }

        const loadDevelopmentList = async () => {
            try {

                const searchRequestBody = {
                    count: true,
                    skip: getSkip(),
                    top: totalRecordsPerPage,
                    searchMode: 'any',
                    queryType: 'simple',
                    search: '*',
                    select: getSelectContent(),
                    filter: getFilter(),
                    orderby: 'content/DevelopmentName asc'
                };

                const result = await fetchDevelopmentList(searchRequestBody);

                setDevelopments(result);

                const totalMatchCount = result['@odata.count'];
                const totalPageCount = Math.ceil(totalMatchCount / (totalRecordsPerPage || 10));

                onTotalMatchCount(totalMatchCount);
                onTotalPageCount(totalPageCount);

                if (pageId > totalPageCount && totalPageCount > 0) {
                    onPageIdChange?.(1);
                }

            } catch (error) {
                console.log("Render Development List Error", error);
            }

        };

        loadDevelopmentList();
    }, [pageId, debouncedDevelopmentName, debouncedProvinceId]); 



    return (
        <>
            <div>
                {(developments === null || typeof (developments?.value) === "undefined" || developments?.value.length === 0 ) ? (
                    <div style={{ textAlign: 'center', background: 'white', width: '100%', padding: '3rem' }}>
                        <Typography className="h5 rtl-text-center" variant="h5" aria-live="polite" role="status" sx={{ width: '100%' }}>
                            {(t('Search_SorryNoDevelopments'))}
                        </Typography>
                    </div>
                ) : (
                    <>
                        <Typography className="body1-medium" variant="body1" aria-live="polite" role="status" sx={{ width: '100%', marginBottom: '1.5rem' }}>
                            {isFeatured ? (t('Search_FeaturedDevelopments')) : (t('Search_SearchResults'))}
                        </Typography>

                        {/* Developments Grid */}
                        <Grid container spacing={3}>
                            {developments && typeof (developments?.value) !== undefined && developments?.value.map((item) => (
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={item.content.DevelopmentID}>
                                    <DevelopmentSearchDevelopmentCard  development={item.content} regionProvinceList={regionProvinceList} />
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </div >
        </>
    );
};

export default DevelopmentSearchDevelopmentList;
