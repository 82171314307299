import React from 'react';
import { Typography, Link } from '@mui/material';
import { t } from 'i18next';
import { PHONE } from '../../constants/constants';

interface ContactLinkProps {
    info: string;
    showFull: boolean;
    toggleShowFull: () => void;
    type?: 'tel' | 'mailto';
}

const ContactLink: React.FC<ContactLinkProps> = ({ info, showFull, toggleShowFull, type = 'mailto' }) => (
    <Typography className="secondary-text" variant="body1">
        {showFull ? (
            // To do - Add title and aria-label translations
            <Link
                href={`${type}:${info}`}
                className="call-link"
                underline="none"
                color="inherit"
            >
                <span className="secondary-text">{info}</span>
            </Link>
        ) : (
            // To do - Add title and aria-label translations
            <Link
                className="call-link"
                underline="none"
                color="inherit"
                onClick={toggleShowFull}
            >
                <span className="secondary-text">{`${info.slice(0, Math.floor(info.length / 2))}...`}</span>
            </Link>
        )}
        {info.length > PHONE.SHOWFULLNUMBER && (
            // To do - Add title and aria-label translations
            <Link
                className="capitalize secondary-text"
                onClick={toggleShowFull}
                sx={{ paddingLeft: '8px', paddingRight: '8px' }}
            >
                {showFull ? '' : t('ContactMe_ContactFormShow')}
            </Link>
        )}
    </Typography>
);

export default ContactLink;
