import React, { useEffect, useState, useRef } from 'react';
import SearchResponse from '../types/SearchResponse';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Hidden, Paper, Card, CardContent, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "@mui/material";
import { SelectChangeEvent } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoading } from '../components/utils/LoadingContext';
import ListingSearchFreeTextSearch from '../components/search/ListingSearchFreeTextSearch';
import ListingSearchMoreOptions from '../components/search/ListingSearchMoreOptions';
import ListingSearchFilterDropdowns from '../components/search/ListingSearchFilterDropdowns';
import { PAGE_TYPE, TENANTS } from '../constants/constants';
import { useSearch } from '../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../reducer/actionTypes';
import ListingSearchTabFilters from '../components/search/ListingSearchTabFilters';
import SpotLight from '../components/utils/spot-light';
import QuickSearch from '../components/utils/quicksearch';
import { handleClickSearch } from '@/components/utils/helper/handleClickSearch';

interface HomeProps {
    children?: React.ReactNode;
    index?: number;
    value: number;
}

function CustomTabPanel(props: HomeProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            sx={{ marginTop: '0 !important' }}
            style={{ marginLeft: '0 !important' }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="span">{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface Option {
    group: string;
    title: string;
    type: string;
    expression: string;
}

const Home: React.FC<HomeProps> = () => {
    // Use useMediaQuery to determine screen size
    const navigate = useNavigate();
    const location = useLocation();
    const { showLoading, hideLoading } = useLoading();
    const isMobile = useMediaQuery('(max-width:600px)'); // Define your mobile breakpoint
    const ProfileSearchUrl = "/ProfileSearch?page=1&countryId=" + window.macroregionid + "&searchType=agent";

    const typographyClassName = isMobile ? "h3" : "h2";
    const typographyVariant = isMobile ? "h3" : "h2";

    const [value, setValue] = React.useState(0);
    const [listings, setListings] = useState<SearchResponse | undefined>();
    const [country, setCountry] = React.useState('');
    const [propertyType, setPropertyType] = React.useState('');
    const [selectedOption, setSelectedOption] = useState('Brazil');
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false); // Initially set to false
    const { t, i18n } = useTranslation();
    const [locations, setLocations] = useState([]);
    const [options, setOptions] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_URL;

    const agentsRef = useRef<HTMLSpanElement>(null);
    const propertiesToSellRef = useRef<HTMLSpanElement>(null);
    const propertiesForRentRef = useRef<HTMLSpanElement>(null);
    const officesRef = useRef<HTMLSpanElement>(null);

    const { state, dispatch } = useSearch();

    useEffect(() => {
        if (inputValue.trim() === '') {
            setOptions([]);
            return;
        }
    }, [inputValue]);

    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        setSelectedOption(event.target.value);
    };

    const handlePropertyChange = (event: SelectChangeEvent<string>) => {
        setPropertyType(event.target.value);
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    }));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleSearch = (query: string | null) => {
        // Update the search criteria and perform a new search
        // You may adjust the requestBody based on your search requirements
        const updatedRequestBody: any = {
            // ... your existing criteria
        };

        // Conditionally set the search property
        if (query !== null) {
            updatedRequestBody.search = query;
        } else {
            updatedRequestBody.search = '*';
        }

        // Perform the search with the updated criteria
        // fetchData(updatedRequestBody.search);
    };

    const animateValue = (ref: React.RefObject<HTMLSpanElement>, start: number, end: number, duration: number) => {
        if (!ref.current) return;

        let startTimestamp: number | null = null;
        const step = (timestamp: number) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            if (ref.current) {
                ref.current.innerHTML = Math.floor(progress * (end - start) + start).toString();
            }
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    animateValue(agentsRef, 0, 6253, 800);
                    animateValue(propertiesToSellRef, 0, 46686, 1000);
                    animateValue(propertiesForRentRef, 0, 11471, 1200);
                    animateValue(officesRef, 0, 606, 1400);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (agentsRef.current) {
            observer.observe(agentsRef.current);
        }
        return () => observer.disconnect();
    }, []);

    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const language = event.target.value;
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        // ... your existing useEffect logic
        // fetchData(null); // Using the correct variable name
    }, []); // The empty dependency array ensures the effect runs once when the component mounts

    const handleClick = (option: Option, search: boolean = false) => (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        event.preventDefault();
        //  dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, FreeText: option.expression } });

        if (search) {
            let targetUrl = location.pathname + `?${option.type}=${option.expression}&searchType=FreeTextSearch`;
            if (location.pathname !== '/listings') {
                targetUrl = `/listings?${option.type}=${option.expression}&searchType=FreeTextSearch`;
            }
            if (location.pathname === '/listings' && !option.expression) {
                targetUrl = '/listings';
            }
            navigate(targetUrl);
        }
    };

    return (
        <>
            <div
                aria-label={t('WebSite_House')}
                role="img"
                title={t('WebSite_House')}
                style={{
                    width: '100%',
                    backgroundImage: `url('/images/remax/brazil-homepage-hero.webp')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative', // Ensure the container is positioned relative for absolute positioning of overlay
                }}
            >
                {/* Overlay Box */}
                <QuickSearch handleClick={handleClick} inputValue={inputValue} isMobile={isMobile} />

            </div>

            {/* Best Properties and In The Spotlight */}
            <div style={{ background: 'white' }}>

                <Container maxWidth="xl" className="text-align-left" sx={{ paddingTop: isMobile ? '3rem' : '6rem', paddingBottom: isMobile ? '3rem' : '6rem' }}>

                    <Stack spacing={5} direction="column">

                        <Stack spacing={{ xs: '1.5rem', md: '0' }} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems={{ xs: 'flex-start', md: 'center' }} sx={{ maxWidth: '100%' }}>

                            {/* Best Properties Header */}
                            <CustomTabPanel value={value} index={0}>

                                <Stack spacing={2} direction="column">

                                    <Typography sx={{ marginBottom: '1rem' }} className="label">
                                        {t('WebSite_BuyOrSell')}
                                    </Typography>

                                    <Typography className={`${typographyClassName}`} variant={typographyVariant} mb={{ xs: '1.5rem', lg: '0' }}>
                                        {t('WebSite_BestPropertiesForSale')}
                                    </Typography>

                                </Stack>

                            </CustomTabPanel>

                            {/* In The Spotlight Header */}
                            <CustomTabPanel value={value} index={1}>

                                <Stack spacing={2} direction="column">

                                    <Typography sx={{ marginBottom: '1rem' }} className="label">
                                        {t('WebSite_BuyOrSell')}
                                    </Typography>

                                    <Typography className={`${typographyClassName}`} variant={typographyVariant} mb={{ xs: '1.5rem', lg: '0' }}>
                                        {t('HomeSettings_SpotlightTitle')}
                                    </Typography>

                                </Stack>

                            </CustomTabPanel>

                            {/* Tabs */}
                            <Box>
                                <Tabs sx={{ flexWrap: 'wrap !important' }} className="pills-tab-container" value={value} onChange={handleChange}>
                                    <Tab className="pills-tab" label={t('WebSite_BestPropertiesForSale')} {...a11yProps(0)} />
                                    <Tab className="pills-tab" label={t('HomeSettings_SpotlightTitle')} {...a11yProps(1)} />
                                </Tabs>
                            </Box>

                        </Stack>

                        {/* Best Properties Content */}
                        <CustomTabPanel value={value} index={0}>

                            <Stack spacing={4} direction={{ xs: 'column', md: 'row' }} sx={{ width: '100%', marginTop: '2.5rem !important' }} useFlexGap>

                                {/* Land */}
                                <Box sx={{ position: 'relative', width: '100%' }}>

                                    <Link rel="noopener noreferrer" title={t('ListingFull_CommLand')} aria-label={t('ListingFull_CommLand')} href="/listings?MacroPropertyTypeUIDs=2663">

                                        <img width="100%" height="auto" style={{ display: 'block' }} src="images/remax/homepage_land_400x300.webp" alt={t('ListingFull_CommLand')} title={t('ListingFull_CommLand')} />

                                        <Box
                                            sx={{
                                                borderRadius: '1rem',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                                                color: 'white',
                                                textAlign: 'center',
                                                padding: '16px', // Adjust padding as needed
                                            }}
                                        >

                                            <Hidden mdUp>
                                                <Typography sx={{ color: 'white !important', fontWeight: '500', fontSize: '1.5rem' }} component="span">{t('ListingFull_CommLand')}</Typography>
                                            </Hidden>

                                            <Hidden mdDown>
                                                <Typography sx={{ color: 'white !important', fontWeight: '500', fontSize: '2rem' }} component="span">{t('ListingFull_CommLand')}</Typography>
                                            </Hidden>

                                        </Box>

                                    </Link>

                                </Box>

                                {/* House */}
                                <Box sx={{ position: 'relative', width: '100%' }}>

                                    <Link rel="noopener noreferrer" title={t('WebSite_House')} aria-label={t('WebSite_House')} href="/listings?MacroPropertyTypeUIDs=3237">

                                        <img width="100%" height="auto" style={{ display: 'block' }} src="images/remax/homepage_house_400x300.webp" alt={t('WebSite_House')} title={t('WebSite_House')} />

                                        <Box
                                            sx={{
                                                borderRadius: '1rem',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                                                color: 'white',
                                                textAlign: 'center',
                                                padding: '1rem', // Adjust padding as needed
                                            }}
                                        >
                                            <Hidden mdUp>
                                                <Typography sx={{ color: 'white !important', fontWeight: '500', fontSize: '1.5rem' }} component="span">{t('WebSite_House')}</Typography>
                                            </Hidden>

                                            <Hidden mdDown>
                                                <Typography sx={{ color: 'white !important', fontWeight: '500', fontSize: '2rem' }} component="span">{t('WebSite_House')}</Typography>
                                            </Hidden>

                                        </Box>

                                    </Link>
                                </Box>


                                {/* Apartments */}
                                <Box sx={{ position: 'relative', width: '100%' }}>

                                    <Link rel="noopener noreferrer" title={t('HomeSettings_Apartment')} aria-label={('HomeSettings_Apartment')} href="/listings?MacroPropertyTypeUIDs=2667">

                                        <img width="100%" height="auto" style={{ display: 'block' }} src="images/remax/homepage_apartment_400x300.webp" alt={t('HomeSettings_Apartment')} title={t('HomeSettings_Apartment')} />

                                        <Box
                                            sx={{
                                                borderRadius: '1rem',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                                                color: 'white',
                                                textAlign: 'center',
                                                padding: '1rem', // Adjust padding as needed
                                            }}
                                        >
                                            <Hidden mdUp>
                                                <Typography sx={{ color: 'white !important', fontWeight: '500', fontSize: '1.5rem' }} component="span">{t('HomeSettings_Apartment')}</Typography>
                                            </Hidden>

                                            <Hidden mdDown>
                                                <Typography sx={{ color: 'white !important', fontWeight: '500', xs: { fontSize: '1.5rem !important' }, fontSize: '2rem' }} component="span">{t('HomeSettings_Apartment')}</Typography>
                                            </Hidden>

                                        </Box>

                                    </Link>

                                </Box>

                            </Stack>

                        </CustomTabPanel>

                        {/* Listing Carousel */}
                        <SpotLight value={value} ></SpotLight>

                        <Typography className="text-align-left secondary-text h5" variant="h5">
                            {t('WebSite_SellAllproperties')}. <Link title={t('WebSite_ClickToSeeAll')} aria-label={t('WebSite_ClickToSeeAll')} className="text-align-left secondary-text" href="/listings" underline="always">{t('WebSite_ClickToSeeAll')}</Link>
                        </Typography>

                    </Stack>

                </Container>

            </div>


            {/* Tenant Name Quote Section*/}
            <div style={{ background: 'radial-gradient(ellipse at bottom, rgba(0, 125, 195, 1) 0%, rgba(26, 54, 104, 1) 100%)' }}>

                <Container maxWidth="xl" sx={{ paddingTop: isMobile ? '3rem' : '6rem', paddingBottom: isMobile ? '3rem' : '6rem', color: '#fff !important' }}>

                    <Grid container spacing={{ xs: 6, md: 4 }} alignItems="center" justifyContent="space-between" >

                        <Grid item xs={12} md={5}>

                            <Box sx={{
                                borderLeft: '4px solid var(--secondary-color)',
                                pl: 3,
                                position: 'relative',
                                '::before': {
                                    content: '"\\201C"',
                                    fontSize: '30rem',
                                    color: 'rgba(0, 0, 0, 0.08)',
                                    position: 'absolute',
                                    top: '0rem',
                                    left: '-2.5rem',
                                    zIndex: 0,
                                    lineHeight: '200px',
                                    fontWeight: '800',
                                    display: {
                                        xs: 'none',  // Hide on mobile devices
                                        sm: 'block', // Show on larger screens
                                    },
                                },
                            }}>

                                <Typography className="text-align-left" variant={isMobile ? 'h5' : 'h4'} component="div" sx={{ fontSize: isMobile ? '1.5rem' : '2rem', fontFamily: 'var(--font-family)', position: 'relative', zIndex: 1 }}>
                                    &ldquo;{t('WebSite_SupportGlobalNetwork')}&rdquo;
                                </Typography>

                                <Typography className="text-align-left" mt={2} sx={{ color: "#fff !important" }} >
                                    &ldquo;{t('WebSite_NoOneSellMore')}&rdquo;
                                </Typography>
                            </Box>

                        </Grid>

                        <Grid item xs={12} md={7} alignItems="center" justifyContent="center" >

                            <Grid container spacing={2} alignItems="center" >

                                <Grid item xs={6} md={3}>

                                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ background: 'rgba(255, 255,255,0.05)', height: '220px', borderRadius: '0.5rem', padding: '0 0.5rem' }}>

                                        <Stack textAlign="center" alignItems="center" spacing={1}>

                                            <PeopleAltOutlinedIcon sx={{ fontSize: 48 }} />

                                            <Typography variant="h5" sx={{ color: "#fff !important" }}>
                                                <span ref={agentsRef}>0</span>
                                            </Typography>

                                            <Typography sx={{ color: "#fff !important" }}>{t('AgentFull_AgentsBreadCrumb')}</Typography>

                                        </Stack>

                                    </Box>

                                </Grid>

                                <Grid item xs={6} md={3}  >

                                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ background: 'rgba(255, 255,255,0.05)', height: '220px', borderRadius: '0.5rem', padding: '0 0.5rem' }}>

                                        <Stack textAlign="center" alignItems="center" spacing={1}>

                                            <img loading="lazy" width="48" height="48" alt="logo" title="logo" src={`/images/${window.theme}/pointer.svg`} />

                                            <Typography variant="h5" sx={{ color: "#fff !important" }}>
                                                <span ref={officesRef}>0</span>
                                            </Typography>

                                            <Typography sx={{ color: "#fff !important" }}>{t('OfficeFull_OfficesBreadCrumb')}</Typography>

                                        </Stack>

                                    </Box>

                                </Grid>

                                <Grid item xs={6} md={3}  >

                                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ background: 'rgba(255, 255,255,0.05)', height: '220px', borderRadius: '0.5rem', padding: isMobile ? '0 1rem' : '0 0.5rem' }}>

                                        <Stack textAlign="center" alignItems="center" spacing={1}>

                                            <SellOutlinedIcon sx={{ fontSize: 48 }} />

                                            <Typography variant="h5" sx={{ color: "#fff !important" }}>
                                                <span ref={propertiesToSellRef}>0</span>
                                            </Typography>

                                            <Typography sx={{ color: "#fff !important" }}>{t('WebSite_PropertiesToSell')}</Typography>

                                        </Stack>

                                    </Box>

                                </Grid>

                                <Grid item xs={6} md={3}>

                                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ background: 'rgba(255, 255,255,0.05)', height: '220px', borderRadius: '0.5rem', padding: isMobile ? '0 1rem' : '0 0.5rem' }}>

                                        <Stack textAlign="center" alignItems="center" spacing={1}>

                                            <MeetingRoomOutlinedIcon sx={{ fontSize: 48 }} />

                                            <Typography variant="h5" sx={{ color: "#fff !important" }}>
                                                <span ref={propertiesForRentRef}>0</span>
                                            </Typography>

                                            <Typography sx={{ color: "#fff !important" }}>{t('WebSite_PropertiesForRent')}</Typography>

                                        </Stack>

                                    </Box>

                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>

                </Container>

            </div>

            {/* Join  TenantName Section */}
            <div style={{ background: 'white' }}>

                <Container maxWidth="xl" className="text-align-left" sx={{ paddingTop: isMobile ? '3rem' : '6rem', paddingBottom: isMobile ? '3rem' : '6rem' }}>

                    <Grid container rowSpacing={1} columnSpacing={6} direction={{ xs: 'column', md: 'column', lg: 'row' }}>

                        <Grid item xs={6}>

                            <Stack direction="column" spacing={6} mb={{ xs: '3rem' }}>

                                <Stack direction="column">

                                    <Typography mb={2} className="label">
                                        {t('WebSite_JoinREMAX')}
                                    </Typography>

                                    <Typography mb={3} className={`${typographyClassName}`} variant={typographyVariant}>
                                        {t('WebSite_GetIntoRealEstate')}
                                    </Typography>

                                    <Typography className="secondary-text h5" variant="h5">
                                        {t('WebSite_DiscoverCareer')}
                                    </Typography>

                                    <Hidden lgUp>

                                        <img style={{ marginTop: '1.5rem', maxHeight: '100%', width: '100%', height: 'auto', maxWidth: '100%', borderRadius: '1rem' }} loading="lazy" src='images/remax/homepage_agents.webp' alt={t('AgentFull_AgentsBreadCrumb')} title={t('AgentFull_AgentsBreadCrumb')} />

                                    </Hidden>

                                </Stack>

                            </Stack>

                            <Accordion defaultExpanded sx={{ boxShadow: 'none', background: 'transparent !important' }}>

                                <AccordionSummary sx={{ padding: '0 !important' }}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >

                                    <Typography className="h6" variant="h6">{t('WebSite_BeABroker')}</Typography>

                                </AccordionSummary>

                                <AccordionDetails sx={{ padding: '0 !important' }}>

                                    <Typography className="body1" variant="body1">
                                        {t('WebSite_BecomeABrokerThought')}
                                    </Typography>

                                    <Button target="_blank" href="https://brasil.remaxbrasil.com.br/seja-um-corretor" role="button" aria-label={t('cmdSearch')} className="primary-outlined-button" type="submit" variant="outlined" color="primary" fullWidth={false} style={{ marginTop: '1.5rem', width: 'auto', maxWidth: 'fit-content' }}>
                                        {t('WebSite_WantToBeABroker')}
                                    </Button>

                                </AccordionDetails>

                            </Accordion>

                            <Accordion defaultExpanded sx={{ boxShadow: 'none', background: 'transparent !important' }}>

                                <AccordionSummary sx={{ padding: '0 !important' }}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography className="h6" variant="h6">{t('WebSite_BeAFranchisee')}</Typography>

                                </AccordionSummary>

                                <AccordionDetails sx={{ padding: '0 !important' }}>

                                    <Typography>
                                        {t('WebSite_FranchiseModel')}
                                    </Typography>

                                    <Button target="_blank" href="https://brasil.remaxbrasil.com.br/seja-um-franqueado" role="button" aria-label={t('cmdSearch')} className="primary-outlined-button" type="submit" variant="outlined" color="primary" fullWidth={false} style={{ marginTop: '1.5rem', width: 'auto', maxWidth: 'fit-content' }}>
                                        {t('WebSite_WantToBeAFranchise')}
                                    </Button>

                                </AccordionDetails>

                            </Accordion>

                        </Grid>

                        {/* Image Column - Hidden on Mobile */}
                        <Hidden lgDown>

                            <Grid className="gridItem" item xs={6}>

                                <Item className="gridItem">

                                    <img style={{ maxHeight: '800px', width: '100%', height: 'auto', maxWidth: '100%', borderRadius: '1rem' }} loading="lazy" src='images/remax/homepage_agents.webp' alt={t('AgentFull_AgentsBreadCrumb')} title={t('AgentFull_AgentsBreadCrumb')} />

                                </Item>

                            </Grid>

                        </Hidden>

                    </Grid>

                </Container>

            </div>

            {/* Find An Agent or Office Section */}
            <div className="light">

                <div style={{ backgroundImage: `url('/images/remax/homepage_agents_cta.webp')`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '650px', height: '100%', width: '100%', padding: '1.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} title={t('WebSite_House')}>

                    <Card sx={{ textAlign: 'center', borderRadius: '1rem', padding: '2rem', maxWidth: '700px', width: '100%' }}>

                        <CardContent sx={{ padding: '0 !important' }}>

                            <Typography className="label" mb={2}>
                                {t('WebSite_AgentsAndOffices')}
                            </Typography>

                            <Typography className="h4" variant="h4" mb={3}>
                                {t('ProfileSearch_SearchForAgentsAndOffices')}
                            </Typography>

                            <Button role="button" aria-label={t('cmdSearch')} href={ProfileSearchUrl} className="primary-button" type="submit" variant="contained" color="primary" fullWidth={false} style={{ width: 'auto', maxWidth: 'fit-content' }}>
                                {t('WebSite_AgentsAndOffices')}
                            </Button>

                        </CardContent>

                    </Card>

                </div>

            </div>

        </>
    );
};

export default Home;
