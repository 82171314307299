import React from 'react';
import { Stack, Typography, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Check as CheckIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ListingDetailData from '../../types/Listing/ListingDetailData';
import LookupItem from '../../types/LookupItem';
import { TENANTS } from '../../constants/constants';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';

interface Props {
    property: ListingDetailData;
    lookupList: LookupItem[];
}

const ListingFeatures: React.FC<Props> = ({ property, lookupList }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const typographyClassName = isMobile ? "body1-medium" : "body1-medium";
    const typographyVariant = isMobile ? "body1" : "body1";

    const ListingFeatures = property?.value[0]?.content.ListingFeatures;

    if (ListingFeatures.length === 0) {
        return null;
    }

    const getFeatureName = (feature) => {
        if (property?.value[0]?.content.TenantId === TENANTS.REMAX) {
            return t(feature.FeatureName);
        } else {
            return getLookupTranslationById(feature.FeatureID.toString(), lookupList)
        }
    }

    return (
        <div>
            <Stack id="features" direction="column" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                <Typography className="h4" variant="h4">
                    {t('ListingFull_Features')}
                </Typography>

                <Grid display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={{ xs: '1rem', md: '1.5rem' }} container sx={{ maxWidth: '100%' }}>

                    <Grid xs={12} lg={6} sx={{ paddingLeft: '0px !important', flex: '1 !important' }}>

                        <Stack direction="column" spacing={2}>

                            {ListingFeatures.slice(0, Math.ceil(ListingFeatures.length / 2)).map((value, index) => (

                                <div key={index}>

                                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap>

                                        <Typography className="body1" variant="body1">
                                            {getFeatureName(value)}
                                        </Typography>

                                        <CheckIcon className="brand-secondary" />

                                    </Stack>

                                </div>

                            ))}

                        </Stack>

                    </Grid>

                    <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', lg: 'block' } }} />

                    <Grid xs={12} lg={6} sx={{ paddingLeft: '0px !important', flex: '1 !important' }}>

                        <Stack direction="column" spacing={2}>

                            {ListingFeatures.slice(Math.ceil(ListingFeatures.length / 2)).map((value, index) => (

                                <div key={index}>

                                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap>
                                        <Typography className="body1" variant="body1">
                                            {getFeatureName(value)}
                                        </Typography>

                                        <CheckIcon className="brand-secondary" />

                                    </Stack>

                                </div>

                            ))}

                        </Stack>

                    </Grid>

                </Grid>

            </Stack>

        </div>

    );
};

export default ListingFeatures;
