export const TENANTS = {

  KW: 1,
  SKYMARK: 2,
  CB: 4,
  ERA: 5,
  REMAX: 6,
  EXP: 7,
  C21: 9,
  VIKING: 10,
  REALTYONE: 11

} as const;



export const LANGUAGE = {
  DEFAULT: "en-US",
} as const;

export type Language = typeof LANGUAGE[keyof typeof LANGUAGE];


export const PROPERTY_TYPES = {
  NOTSELECTED: -1,
  COMMERCIAL: 1,
  RESIDENTIAL: 2,
  LAND: 3,

} as const;

export type PropertyType = typeof PROPERTY_TYPES[keyof typeof PROPERTY_TYPES];

export const TRANSACTION_TYPES = {
  NOTSELECTED: -1,
  FORSALE: 261,
  FORRENT: 260,


} as const;

export type TransactionType = typeof TRANSACTION_TYPES[keyof typeof TRANSACTION_TYPES];

export const LISTING_STATUS_TYPES = {
  SOLD: 169

} as const;

export type ListingStatusType = typeof LISTING_STATUS_TYPES[keyof typeof LISTING_STATUS_TYPES];

export const FAVOURITES = {
  LIMIT: 20,
  TOP: 5,
} as const;

export type Favourite = typeof FAVOURITES[keyof typeof FAVOURITES];

export const LISTING_SEARCH_VIEW = {
  LIST: 'list',
  MAP: 'map',
} as const;

export type ListingSearchView = typeof LISTING_SEARCH_VIEW[keyof typeof LISTING_SEARCH_VIEW];

export const MAP_PROVIDER = {
  LEAFLET: 'leaflet',
  MAPBOX: 'mapbox',
} as const;

export type MapProvider = typeof MAP_PROVIDER[keyof typeof MAP_PROVIDER];


export const DESCRIPTION = {
  SHOWMORE: 750,
  ISDEFAULT: 1,
  ID: 629,
  TITLE: 1113,
  COMMUNITY: 3139,

} as const;

export type Description = typeof DESCRIPTION[keyof typeof DESCRIPTION];

export const PAGE_TYPE = {
  LISTING_DETAIL: 'listingDetail',
  AGENT: 'agent',
  OFFICE: 'office',
  TEAM: 'team',
  LISTINGS: 'listings',
  HOME: 'home',
  DEVELOPMENT: 'developments',
  AGENTSEARCH: 'agentsearch',
  OFFICESEARCH: 'officesearch'

} as const;

export type PageType = typeof PAGE_TYPE[keyof typeof PAGE_TYPE];

export const UNIT_OF_MEASURE = {
  SQM: 'sqm',
  SQF: 'sqf'

} as const;

export type UnitOfMeasure = typeof UNIT_OF_MEASURE[keyof typeof UNIT_OF_MEASURE];

export const REGION_PARAMETER = {
  UNITOFMEASURE: 'UnitOfMeasure'


} as const;

export type RegionParameter = typeof REGION_PARAMETER[keyof typeof REGION_PARAMETER];

export const PHONE = {
  SHOWFULLNUMBER: 6,
} as const;

export type Phone = typeof PHONE[keyof typeof PHONE];

export const RECENTLY_VIEWED_ITEMS = {
  MAX: 5
} as const;

export type Recently_Viewed_Items = typeof RECENTLY_VIEWED_ITEMS[keyof typeof RECENTLY_VIEWED_ITEMS];

export const DEVELOPMENT = {
  SHOW_MORE: 10
} as const;

export type Development = typeof DEVELOPMENT[keyof typeof DEVELOPMENT];

export const LINKS = {
  YOUTUBE: 'https://www.youtube.com/embed/'
} as const;

export type Links = typeof LINKS[keyof typeof LINKS];

export const MAPZOOM = {
  TWELVE: 12,
  FIFTEEN: 15,
  EIGHTEEN: 18,
} as const;
export type MapZoom = typeof MAPZOOM[keyof typeof MAPZOOM];

export const HITNAMES = {
  //CONTACT_ME: "Contact Me",
  TEAMSWEBSITELEADS: "TeamsWebsiteLeads",
  R_LISTINGFULL_CLICKCCONTACTICON: "R_ListingFull_ClickContactIcon",
  WHATSAPPWEBLEADS: "WhatsAppWebLeads",
  PUBOFFICEFULL: "PubOfficeFull",
  PUBAGENTFULL: "PubAgentFull",
  PUBLISTINGFULL: "PubListingFull",
  TEAMSPUBLICPROFILEVIEW: "TeamsPublicProfileView",

} as const;
export type HitNames = typeof HITNAMES[keyof typeof HITNAMES];


export const LISTINGSEARCHFILTER = {
  TRANSACTIONTYPEUID: "TransactionTypeUID",
  LISTINGCLASS: "ListingClass",
  MACROPROPERTYTYPEUIDS: "MacroPropertyTypeUIDs",
  PRICEMIN: "PriceMin",
  PRICEMAX: "PriceMax",
  PROPERTYFEATURES: "PropertyFeatures",
  BEDROOMS: "Bedrooms",
  BATHROOMS: "Bathrooms",
  PARKINGSPACES: "ParkingSpaces",
  MINTOTALAREA: "MinTotalArea",
  MAXTOTALAREA: "MaxTotalArea",
  PROPERTIESADDED: "PropertiesAdded",
  DEVELOPMENTID: "DevelopmentID",
  MLSID: "MLSID",
  HASMULTIMEDIA: "HasMultiMedia",
  AGENTID: "AgentID",
  TEAMID: "TeamID",
  OFFICEID: "OfficeID",
  MACROOFFICEID: "MacroOfficeID",

  STREETNAME: "StreetName"

} as const





