import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import { getProfileDefaultImagePath } from '../../components/utils/helper/ImageHelper';

interface PhotoProps {
    imagePath: string; 
    alt: string;
    title: string;
}

const ProfilePhoto: React.FC<{ profilePhoto: PhotoProps }> = ({ profilePhoto }) => {

    return (
        <CardMedia
            sx={{
                width: '100%',
                maxWidth: '240px',
                height: 'auto',
                margin: '0 auto !important',
                borderRadius: '1rem',
                objectFit: 'contain'
            }}
            component="img"
            image={profilePhoto.imagePath}
            loading="lazy"
            alt={profilePhoto.alt}
            title={profilePhoto.title}
            onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null; // prevents looping
                target.src = getProfileDefaultImagePath(); // fallback image URL
            }}
        />
    );
};
export default ProfilePhoto;