import React, { createContext, useContext, ReactNode, useState } from 'react';

// Define the type for the language context
type LanguageContextProps = {
  selectedLanguage: string;
  setLanguage: (language: string) => void;
};

// Create the context
const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

// Define a custom hook to access the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    //throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

// Define the LanguageProvider component
type LanguageProviderProps = {
  children: ReactNode;
};

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [selectedLanguage, setLanguage] = useState(''); // You can initialize it with a default language

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
