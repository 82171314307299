import React from 'react';
import { Link, Box, Stack } from '@mui/material';
import { SocialMedia } from '../../types/TypesIndex';
import { LookupItem, SiteSettings } from '../../types/TypesIndex';

interface ProfileSocialMediaLinksProps {
    socialMedias: SocialMedia[];
    isSocialMediaEnabled: boolean;
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
}

const ProfileSocialMediaLinks: React.FC<ProfileSocialMediaLinksProps> = ({ socialMedias, isSocialMediaEnabled, lookupList, siteSettings }) => {

    {/* Phase II review if will need this
    if (!isSocialMediaEnabled || socialMedias === null || socialMedias.length === 0 ) {
    return null;*/}

    if (socialMedias.find(s => s.UID === '' && s.Name === '')) {
        return null;
    }

    // To Do: Phase II, Check the Social Media Profile vs Social Media Feeds
    const hasValidSocialMedias = socialMedias.some(s => s.UID !== "3339");

    // If there are no valid social media items, hide the entire stack
    if (!hasValidSocialMedias) {
        return null;
    }


    return (
        <Stack direction="row" spacing={.5} useFlexGap flexWrap="wrap" justifyContent="center">
            {/* To Do: Handle Twitter Script as Hyperlink */}
            {socialMedias.filter(s => s.UID !== "3339" && s.Name !== null).map((s, index) => (
                <Box mx={.5} key={s.UID}>
                    <Link href={s.Value} title={s.Name} aria-label={s.Name} target="_blank">
                        <img src={`/images/common/icon-${s.Name.toLowerCase()}.svg`} title={s.Name} alt={s.Name} loading='lazy' />
                    </Link>
                </Box>
            ))}
        </Stack>
    );
};

export default ProfileSocialMediaLinks;
