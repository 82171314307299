import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { Hidden, Stack, Skeleton } from '@mui/material';
import { Development } from '../../types/TypesIndex';


const DevelopmentDetailBreadcrumb: React.FC<{ development?: Development }> = ({ development }) => {

    // Parameters Declaration
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true); //State to manage loading for skeleton

    // To Check Design: Need it or not?  Timer for skeleton
    React.useEffect(() => {
        // Simulate a data fetch or initialization
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200); // Replace with actual loading logic

        return () => clearTimeout(timer);
    }, []);

    const DesktopSkeleton = (
        <Skeleton variant="rectangular" width={300} height={24}></Skeleton>
    );

    const MobileSkeleton = (
        <Skeleton variant="rectangular" width={200} height={24}></Skeleton>
    );

    if (loading) {
        return (
            <>
                <Hidden lgDown>
                    {DesktopSkeleton}
                </Hidden>
                <Hidden lgUp>
                    {MobileSkeleton}
                </Hidden>
            </>
        );
    }

    return (

        <Stack itemScope itemType="http://schema.org/BreadcrumbList" direction="row" alignItems="center" justifyContent="flex-start" flexWrap="wrap" >

            <a itemProp="itemListElement" href="/Developments" itemScope itemType="http://schema.org/ListItem" title="" aria-label="" style={{ fontSize: '0.875rem' }}>
                <span itemProp="name">{t('Development_Developments')}</span>
                <meta itemProp="position" content="1"></meta>
            </a>
            {development?.DevelopmentName !== null && (
                <>
                    <KeyboardArrowRightIcon className="flip-text" style={{ fontSize: 'Medium', color: 'rgba(0, 0, 0, 0.6)!important' }} />

                    <a className="breadcrumbs-last-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" style={{ fontSize: '0.875rem' }}>
                        <span itemProp="name">{development?.DevelopmentName}</span>
                        <meta itemProp="position" content="2"></meta>
                    </a>
                </>
            )}

        </Stack>
    );
};

export default DevelopmentDetailBreadcrumb;