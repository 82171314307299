import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { styled } from '@mui/material/styles';
import { Card, CardHeader, CardContent, CardMedia, Typography, CardActions, Button, Link, Stack, Box, Container, Divider, Paper, Grid, Skeleton } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { getDesignationLogoImagePath, getProfileDefaultImagePath, getProfileImagePath } from '../utils/helper/ImageHelper';
import { Agent, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { ProfileAgentMoreOfficeInfo } from '../../components/profile/ProfileComponentsIndex';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

interface ProfileAgentInfoProps {
    agent?: Agent
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
}

const ProfileAgentInfo: React.FC<{ profileAgentInfo: ProfileAgentInfoProps }> = ({ profileAgentInfo }) => {
    const showMoreOfficeInfo = profileAgentInfo.agent && profileAgentInfo.agent?.Slogan === null && profileAgentInfo.agent?.Closer === null;
    const validDesignations = profileAgentInfo.agent?.Designations.filter(d => d.Name && d.Name.trim() !== '' && d.Name.trim() !== "''");

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    const [lastBorder, setLastBorder] = useState({
        AgentDisclaimer: true,
        Specializations: true,
        ValidDesignations: true,
        SpokenLanguages: true,
    });

    useEffect(() => {
        if (profileAgentInfo.agent?.AgentDisclaimer) {
            setLastBorder(prevState => ({ ...prevState, AgentDisclaimer: false }));
        } else if (profileAgentInfo.agent && profileAgentInfo.agent?.Specializations.length > 0) {
            setLastBorder(prevState => ({ ...prevState, Specializations: false }));
        } else if (validDesignations && validDesignations.length > 0) {
            setLastBorder(prevState => ({ ...prevState, ValidDesignations: false }));
        } else if (profileAgentInfo.agent && profileAgentInfo.agent?.SpokenLanguages.length > 0) {
            setLastBorder(prevState => ({ ...prevState, SpokenLanguages: false }));
        }
    }, []);

    if (isLoading) {
        return (
            <Stack spacing={3}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={200} />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="rectangular" height={150} />
                <Skeleton variant="text" width="40%" />
            </Stack>
        );
    }

    return (
        <>

            {profileAgentInfo.agent && (profileAgentInfo.agent.Slogan || profileAgentInfo.agent.Closer) && (
                <Stack id="slogan-closer" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                    {profileAgentInfo.agent && profileAgentInfo.agent?.Slogan && (
                        <Typography id="slogan" dangerouslySetInnerHTML={{ __html: profileAgentInfo.agent?.Slogan }} className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}></Typography>
                    )}

                    {profileAgentInfo.agent && profileAgentInfo.agent?.Closer && (
                        <Typography id="closer" dangerouslySetInnerHTML={{ __html: profileAgentInfo.agent?.Closer }} className="body1" variant="body1" sx={{ marginBottom: '0 !important' }}></Typography>
                    )}

                </Stack>
            )}

            {/* Show Office Info when Slogan and Closer are empty */}
            {showMoreOfficeInfo && (<ProfileAgentMoreOfficeInfo agent={profileAgentInfo.agent} paddingTop={'0 !important'} />)}

            {/* Bullet Points */}
            {profileAgentInfo.agent
                && (profileAgentInfo.agent.BulletPoint1 !== null || profileAgentInfo.agent.BulletPoint2 !== null || profileAgentInfo.agent.BulletPoint3 !== null)
                && (
                    <Stack id="three-bullets" spacing={2} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important', paddingTop: 0 }}>

                        {profileAgentInfo.agent?.BulletPoint1 && (
                        <Typography className="body1" variant="body1">
                                <CheckIcon className="bullet-point-checkmark" />
                                <span dangerouslySetInnerHTML={{ __html: profileAgentInfo.agent.BulletPoint1 }}></span>
                            </Typography>
                        )}

                        {profileAgentInfo.agent?.BulletPoint2 && (
                        <Typography className="body1" variant="body1">
                                <CheckIcon className="bullet-point-checkmark" />
                                <span dangerouslySetInnerHTML={{ __html: profileAgentInfo.agent.BulletPoint2 }}></span>
                            </Typography>
                        )}

                        {profileAgentInfo.agent?.BulletPoint3 && (
                        <Typography className="body1" variant="body1">
                                <CheckIcon className="bullet-point-checkmark" />
                                <span dangerouslySetInnerHTML={{ __html: profileAgentInfo.agent.BulletPoint3 }}></span>
                            </Typography>
                        )}

                    </Stack>
                )
            }

                {/* Spoken Languages */}
                {profileAgentInfo.agent?.SpokenLanguages && profileAgentInfo.agent?.SpokenLanguages !== null && profileAgentInfo.agent?.SpokenLanguages.length > 0 && (
                    <Stack id="languages-spoken" spacing={3} sx={{ borderBottom: lastBorder.SpokenLanguages ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.SpokenLanguages ?  '2.5rem !important' : '0 !important' }}>

                        <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                            {t('AgentFull_LanguagesSpoken')}
                        </Typography>

                        <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">

                            {profileAgentInfo.agent?.SpokenLanguages.map((uid) => (
                                <Typography variant="body1" key={uid}>
                                    {getLookupTranslationById(uid, profileAgentInfo.lookupList)}
                                </Typography>
                            ))}

                        </Stack>

                    </Stack>
                )}

                {/* Designations */}
                {validDesignations && validDesignations !== null && validDesignations.length > 0 && (
                    <Stack id="Certifications" spacing={3} sx={{ borderBottom: lastBorder.ValidDesignations ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.ValidDesignations ?  '2.5rem !important' : '0 !important' }}>

                        <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                            {t("ListingList_Certificates")}
                        </Typography>

                        <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">

                            {validDesignations && validDesignations.map((d, index) => (
                                <a key={index} href={(d.URL === null || d.URL === '') ? "#" : d.URL}>
                                    <img src={getDesignationLogoImagePath(d.Logo)} alt={d.Name} loading="lazy" />
                                </a>
                            ))}

                        </Stack>

                    </Stack>
                )}

                {/* Specializations Section */}
                {profileAgentInfo.agent?.Specializations && profileAgentInfo.agent?.Specializations.length > 0 && (
                    <Stack spacing={3} sx={{ borderBottom: lastBorder.Specializations ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.Specializations ?  '2.5rem !important' : '0 !important'  }}>

                        <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                            {t('AgentFull_Specializations')}
                        </Typography>

                        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} useFlexGap flexWrap="wrap">

                            {profileAgentInfo.agent?.Specializations.map((uid) => (
                                <Typography key={uid} variant="body1">
                                    {getLookupTranslationById(uid, profileAgentInfo.lookupList)}
                                </Typography>
                            ))}

                        </Stack>

                    </Stack>
                )}

            {/* To Do: Social Media Feeds in Phase II */}

            {profileAgentInfo.agent && profileAgentInfo.agent?.AgentDisclaimer && profileAgentInfo.agent?.AgentDisclaimer !== null && (
                <Stack id="agent-disclaimer" direction="column" spacing={3} useFlexGap sx={{ borderBottom: lastBorder.AgentDisclaimer ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.AgentDisclaimer ?  '2.5rem !important' : '0 !important'  }}>

                    <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                        {t("AgentFull_AgentDisclaimer")}
                    </Typography>

                    <Typography variant="body1">
                        {profileAgentInfo.agent?.AgentDisclaimer}
                    </Typography>

                </Stack>
            )}

        </>
    );
};

export default ProfileAgentInfo;
