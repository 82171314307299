import { LINKS } from '../../../constants/constants';

function getYouTubeEmbedUrl(url: string): string {
    try {
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search);
        const videoId = searchParams.get('v') || urlObj.pathname.split('/').pop() || '';
        return `${LINKS.YOUTUBE}${videoId}?autoplay=1&mute=1&showinfo=0&rel=0`;
    } catch (e) {
        return "";
    }
};

function isValidYouTubeUrl(url: string): boolean {
    const embedUrl = getYouTubeEmbedUrl(url);
    return embedUrl !== null;
};

function updateLocationUrl(searchParams: URLSearchParams, key: string, value?: string | null): void {
  searchParams.delete(key);
  if (value && value !== null) {
    searchParams.set(key, value);
  }
  
  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.replaceState(null, '', newUrl);
  window.dispatchEvent(new Event('popstate'));
}

export { getYouTubeEmbedUrl, isValidYouTubeUrl, updateLocationUrl };