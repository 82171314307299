import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Agent } from '../../types/TypesIndex';
import { PAGE_TYPE } from '../../constants/constants';
import CardImageMottoAddress from '../CardImageMottoAddress';


interface ProfileAgentMoreOfficeInfoProps {
    agent?: Agent,
    paddingTop: string;
}

/* To Do: 
a. Office SEO Short Link
b. SEO Micro Data
*/

const ProfileAgentMoreOfficeInfo: React.FC<ProfileAgentMoreOfficeInfoProps> = ({ agent, paddingTop }) => {

    const { t, i18n } = useTranslation();
    const officePhotoAltText = `${t('OfficeFull_OfficeOf')}` + `${agent?.OfficeName?.toString()}` + `${agent?.OfficeCity?.toString()}`;
    const languageCode = i18n.language;
    let officeShortLink = `/${PAGE_TYPE.OFFICE}/` + agent?.OfficeId;
    if (agent && agent?.OfficeShortLinks !== null
        && agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        officeShortLink = `/${PAGE_TYPE.OFFICE}/` + agent.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

    return (

        <Stack direction="row" spacing={3} alignItems="center" sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

            <CardImageMottoAddress data={{
                RegionId: agent?.RegionId,
                PhotoId: agent?.OfficePhotoId,
                PhotoAltText: officePhotoAltText,
                Name: agent?.OfficeName,
                ShortLink: officeShortLink,
                Address: agent?.OfficeAddress,
                Url: agent?.InternetUrl,

            }} />

        </Stack>
    );
};

export default ProfileAgentMoreOfficeInfo;
