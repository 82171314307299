import { PAGE_TYPE } from '../constants/constants';
import LeadType from '../types/Lead';
import { v4 as uuidv4 } from 'uuid';

const leadService = async (input: LeadType): Promise<boolean> => {
  const locationOrigin = window?.location?.origin ?? ''; // Fallback for environments where window might be undefined
  let body = {};
  let controller = ''
  if (input.page === PAGE_TYPE.LISTING_DETAIL) {
    controller = 'CreateLead'
    body = {
      TenantId: input.TenantId,
      RegionId: input.RegionId,
      FirstName: input.FirstName,
      LastName: input.LastName,
      Phone: input.Phone,
      Email: input.Email,
      Comments: input.Message,
      Message: input.Message,
      ListingKey: input.ListingKey,
      ListingId: input.ListingId,
      CreationTime: input.CreationTime,
      SAgentId: input.SAgentId?.toString(),
      AgentName: input.AgentName,
      LanguageCode: input.LanguageCode,
      SiteId: input.SiteId,
      RequestHost: input.RequestHost,
      FilePath: 'publiclistingfull',
      RequestReferrer: '',
      AlternateSettings: '',
      LeadType: 'ListingLead',

    };
  } else {
    controller = 'CreateProfileLead'
    body = {
      TenantId: input.TenantId,
      RegionId: input.RegionId,
      FirstName: input.FirstName,
      LastName: input.LastName,
      Phone: input.Phone,
      Email: input.Email,
      Message: input.Message,
      CreationTime: input.CreationTime,
      AgentId: input.SAgentId,
      OfficeId: input.OfficeId,
      TeamId: input.TeamId,
      LanguageCode: input.LanguageCode,
      SiteId: input.SiteId,
      LeadType: 'ProfileLead'
    };
  }
  const apiHitEndpoint = `${locationOrigin}/lead/${controller}`;
  console.log(input);

  try {
    const serviceBusResponse = await fetch(`/sendServiceBusMessage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Message: body }),
    });

    if (!serviceBusResponse.ok) {
      console.error('Failed to send message to Service Bus:', serviceBusResponse.statusText);
      return false;
    }

    const serviceBusData = await serviceBusResponse.json();
    console.log('Service Bus response data:', serviceBusData);
  } catch (serviceBusError) {
    console.error('Error sending message to Service Bus:', serviceBusError);
    return false;
  }

  console.log('Request body:', body);
  return true;
  // try {
  //   const response = await fetch(apiHitEndpoint, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(body),
  //   });

  //   if (!response.ok) {
  //     console.error('Failed to create lead:', response.statusText);
  //     return false;
  //   }

  //   const data = await response.json();
  //   console.log('Response data:', data);
  //   return true;
  // } catch (error) {
  //   console.error('Error creating lead:', error);
  //   return false;
  // }
};

export { leadService };
