import React from 'react';
import { Grid, Stack } from '@mui/material';
import { ProfileContactCard, ProfileTeamInfo } from './ProfileComponentsIndex';
import { Agent, Team, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { PAGE_TYPE } from '../../constants/constants';

interface ProfileTeamMainProps {
    team?: Team;
    agentList: Agent[],
    spokenLanguages: string[];
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
    teamListingCount: number;
}

const ProfileTeamMain: React.FC<{ profileTeamMain: ProfileTeamMainProps }> = ({ profileTeamMain }) => {

    return (
        <Grid container spacing={8}>

            {/* Left Content Column */}
            <Grid item xs={12} md={8}>

                <Stack direction="column" spacing={5} useFlexGap>

                    <ProfileTeamInfo profileTeamInfo={{
                        team: profileTeamMain.team,
                        agentList: profileTeamMain.agentList,
                        spokenLanguages: profileTeamMain.spokenLanguages,
                        lookupList: profileTeamMain.lookupList,
                        siteSettings: profileTeamMain.siteSettings,
                    }} />


                </Stack>

            </Grid>

            {/* Right Contact Column */}
            {profileTeamMain.team &&
                <ProfileContactCard
                    team={profileTeamMain.team}
                    lookupList={profileTeamMain.lookupList}
                    siteSettings={profileTeamMain.siteSettings}
                    page={PAGE_TYPE.TEAM} />}

        </Grid>
    );
};

export default ProfileTeamMain;