import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Agent, Office, SiteSettings, Team } from '../../types/TypesIndex';
import { getFirstNCharacters } from '../utils/helper/DataFormatHelper';
import { useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PAGE_TYPE } from '../../constants/constants';


interface ProfileOfficeCardForNameAddressLicenseProps {
    office?: Office;
    team? : Team;
    agent?: Agent;
    page?: string;
    isSeo?: boolean
    hideOfficeName?: boolean;
    hideOfficeLicenseNumber?: boolean;
    hideAgentLicenseNumber?: boolean;
    hideUrl?: boolean;
    hideOfficeAddress?: boolean;
}

const ProfileOfficeCardForNameAddressLicense: React.FC<{ data: ProfileOfficeCardForNameAddressLicenseProps }> = ({ data }) => {

    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile


    const office = data.office;
    const team = data.team;
    const agent = data.agent;
    
    const isOfficePage = (data.page && data.page === PAGE_TYPE.OFFICE) ? true : false;
    const isTeamPage =  (data.page && data.page === PAGE_TYPE.TEAM) ? true : false;
    const isAgentPage =  (data.page && data.page === PAGE_TYPE.AGENT) ? true : false;

    const officeId = isOfficePage ? office?.OfficeId : (isTeamPage ? team?.TeamId : agent?.OfficeId);
    const officeName = isOfficePage ? office?.OfficeName : (isTeamPage ? team?.OfficeName : agent?.OfficeName);

    let officeShortLink =  `/Office/${officeId}`; 
    if (isAgentPage) {
        if (data.agent?.OfficeShortLinks !== null 
            && data.agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null 
            && data.agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== undefined
            && data.agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0 ) {
                officeShortLink = `/${PAGE_TYPE.OFFICE}/` + data.agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink; 
            }  
    }
    else if (isTeamPage) {
        if (data.team?.OfficeShortLinks !== null 
            && data.team?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null 
            && data.team?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== undefined
            && data.team?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0 ) {
                officeShortLink = `/${PAGE_TYPE.OFFICE}/` + data.team?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink; 
            }       
    } else {
        if (data.office?.OfficeShortLinks !== null 
            && data.office?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null 
            && data.office?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== undefined
            && data.office?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0 ) {
                officeShortLink = `/${PAGE_TYPE.OFFICE}/` + data.office?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink; 
            }  
    }         

    let officeWebAddress =  null;
    if (isOfficePage){
        officeWebAddress = data.isSeo ? office?.OfficeWebSeoAddress : office?.OfficeWebAddress;
        if (office?.GeoDatas !== null 
            && office.GeoDatas.filter(geo => geo.LanguageCode === languageCode) !== null 
            && office.GeoDatas.filter(geo => geo.LanguageCode === languageCode).length > 0) {
                officeWebAddress = office.GeoDatas.filter(geo => geo.LanguageCode === languageCode)[0].OfficeWebAddress;
            }
    } else if (isTeamPage) {
        officeWebAddress = data.isSeo ? team?.OfficeWebSeoAddress : team?.OfficeWebAddress;
        if (team?.GeoDatas !== null 
            && team.GeoDatas.filter(geo => geo.LanguageCode === languageCode) !== null 
            && team.GeoDatas.filter(geo => geo.LanguageCode === languageCode).length > 0) {
                officeWebAddress = team.GeoDatas.filter(geo => geo.LanguageCode === languageCode)[0].OfficeWebAddress;
            }      
    } else if (isAgentPage) {
        officeWebAddress = data.isSeo ? agent?.OfficeWebSeoAddress : agent?.OfficeWebAddress;
        if (agent?.GeoDatas !== null 
            && agent.GeoDatas.filter(geo => geo.LanguageCode === languageCode) !== null 
            && agent.GeoDatas.filter(geo => geo.LanguageCode === languageCode).length > 0) {
                officeWebAddress = agent.GeoDatas.filter(geo => geo.LanguageCode === languageCode)[0].OfficeWebAddress;
            }  
    }
                      

    const officeLicenseNumber = isOfficePage ? office?.OfficeLicenseNumber : (isTeamPage ? team?.OfficeLicenseNumber : agent?.OfficeLicenseNumber);
    const agentLicenseNumber = (isOfficePage || isTeamPage) ? '' : agent?.AgentLicenseNumber;
    let profileUrl = "";
    if (isOfficePage) {
        if (office?.URLToPrivatePage && office?.URLToPrivatePage !== null) {
            profileUrl = office?.URLToPrivatePage;
        } else {
            profileUrl = office?.RegularWebSiteUrl
        }
    } else if (isTeamPage) {
        if (team?.AlternateURL && team?.AlternateURL !== null) {
            profileUrl = team?.AlternateURL.startsWith("http") ? team?.AlternateURL : "http://" + team?.AlternateURL;
        } else if (team?.OfficeURLToPrivatePage && team?.OfficeURLToPrivatePage !== null) {
            profileUrl = team?.OfficeURLToPrivatePage;
        } else {
            profileUrl = team?.OfficeSlingshotUrl;
        }
    } else if (isAgentPage) {
        profileUrl = agent?.InternetUrl;
    }   
   

    return (
        <Stack spacing={1} useFlexGap flexWrap="wrap">

            {/* To Do: SEO Micro Data*/}
            {/* Office Name without Hyperlink */}
            {(isOfficePage && officeName && officeName !== null && !data.hideOfficeName) && (
                <Typography className="secondary-text body1-medium" variant="body1">
                    {officeName}
                </Typography>
            )}

            {/* Office Name with Hyperlink */}
            {/* To Do: Office Shortlink on Agent Profile but no Hyperlink on Office Profile*/}
            {(!isOfficePage && officeName && officeName !== null && !data.hideOfficeName) && (
                <Typography className="secondary-text body1-medium" variant="body1">
                    <Link className="secondary-text" href={officeShortLink} title={officeName} aria-label={officeName} underline="hover" color="inherit">
                        {officeName}
                    </Link>
                </Typography>
            )}

            {/* Agent License Number */}
            {(agentLicenseNumber && agentLicenseNumber !== null && data.hideAgentLicenseNumber == false) && (
                <Typography className="secondary-text body1" variant="body1">
                    {t('AgentFull_LicenseNumber')} {agentLicenseNumber}
                </Typography>
            )}

            {/* Office License Number */}
            {(officeLicenseNumber && officeLicenseNumber !== null && data.hideOfficeLicenseNumber == false) && (
                <Typography className="secondary-text body1" variant="body1">
                    {t('OfficeFull_LicenseNumber')} {officeLicenseNumber}
                </Typography>
            )}

            {/* Office Web Address */}
            {officeWebAddress && officeWebAddress !== null && (
                <Typography sx={{ maxWidth: '100%' }} className="secondary-text body1" variant="body1" dangerouslySetInnerHTML={{ __html: officeWebAddress }}></Typography>
            )}

            {/* Internet Url (not just Office Url) */}
            {(profileUrl && profileUrl !== null && data.hideUrl == false) && (
                <Typography className="secondary-text body1" variant="body1">
                    <Link className="secondary-text" href={profileUrl} title={t("OfficeFull_MoreInfo")} aria-label={t("OfficeFull_MoreInfo")} target="_blank" underline="hover">
                        {isMobile ? getFirstNCharacters(profileUrl, 15) : getFirstNCharacters(profileUrl, 25)}
                    </Link>
                </Typography>
            )}

        </Stack>
    );
};

export default ProfileOfficeCardForNameAddressLicense;