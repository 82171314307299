import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Check as CheckIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ListingDetail from '../../types/Listing/ListingDetail';


const ListingLegalRequirements: React.FC<{ ListingDetail: ListingDetail }> = ({ ListingDetail }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (ListingDetail.LegalRequirementText === null) {
        return null;
    }

    {/* To Do: Hide it for Global Site*/ }
    return (
        <>
            {ListingDetail.LegalRequirementText !== null  && ( 
                <Stack id="legal" direction="column" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>
                    <Typography className="h4" variant="h4">
                        {t('ListingFull_LegalRequirement')}
                    </Typography>
                    <Typography className="body1" variant="body1">
                        {ListingDetail.LegalRequirementText}
                    </Typography>
                </Stack>
            )}
        </>
    );
};

export default ListingLegalRequirements;
