function getFirstNCharacters(str?: string, len: number = 10): string {
    if (typeof str !== 'string' || str === null || str === undefined) {
        return '';
    }

    if (str.length > len) {
        return str.slice(0, len) + '...';
    } else {
        return str;
    }
}

// To Do: Optimize the escape function
function escapeSpecialCharacters(inputString: string) {
   inputString = inputString.replace(/[-!$%^&*()_+|~=`{}\[\]:";<>?,.\/]/g, '\\$&');
   return inputString.replace('&', '&amp;').replace('/', '&#47;').replace('<', '&lt;').replace('>', '&gt;').replace('"', '&quot;').replace("'", '&apos;');
}

const normalizeString = (str: string): string => {
    return str ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim() : '';
  };

export { getFirstNCharacters, escapeSpecialCharacters, normalizeString };