import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { formatCurrency, formatCustomCurrency } from '../../components/utils/helper/FormatNumberHelper';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import SiteSettings from '../../types/Settings/SiteSettings';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { TRANSACTION_TYPES } from '../../constants/constants';


const ListingAdditionalCosts: React.FC<{ ListingDetail: ListingDetail, LookupList: LookupItem[] }> = ({ ListingDetail, LookupList }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const typographyClassName = isMobile ? "body1-medium" : "body1-medium";
    const typographyVariant = isMobile ? "body1" : "body1";

    const [siteSettings, setSiteSettings] = useState<SiteSettings>();


    useEffect(() => {
        const loadSiteSettings = async () => {
            await fetchSiteSettings().then((siteSettings) => {
                if (siteSettings == null) {
                    console.log("siteSettings loads nothing");
                } else {
                    setSiteSettings(siteSettings);
                }
            });
        };
    
        loadSiteSettings();
    }, []);

    if (ListingDetail.BuyAgentPct === null && ListingDetail.BuyAgentFix === null && ListingDetail.SecurityDeposit === null && ListingDetail.KeyMoney === null
        && ListingDetail.NonRefundableDeposit === null && ListingDetail.RenewalFee === null && ListingDetail.UtilityCost === null
        && ListingDetail.IsHeatingCostIncluded === null && ListingDetail.HeatingCost === null && ListingDetail.ParkingCost === null
        && ListingDetail.MaintenanceFee === null && ListingDetail.FundsForMajorRepairs === null
        && ListingDetail.InsuranceFee === null && ListingDetail.PropertyTax === null && ListingDetail.OtherExpenses === null
    ) {
        return null;
    }

    return (
        <>
            <Stack id="additional-costs" direction="column" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                <Stack direction="column" spacing={2}>
                    {/* Selling and Listing Commission , To Do: Optimize in Phase II "REP will set as NULL"*/}
                    {(ListingDetail.BuyAgentPct !== null || ListingDetail.BuyAgentFix !== null) && (
                        <Stack id="selling-listing-commission" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {ListingDetail.TransactionTypeUID == TRANSACTION_TYPES.FORSALE.toString() ? t("ListingDetail.BuyAgentPct") : t("ListingFull_RentalCommission")}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {ListingDetail.TransactionTypeUID == TRANSACTION_TYPES.FORSALE.toString() ? t("ListingFull_SellingCommissionPercent") : t("ListingFull_RentalCommissionPercent")}
                            </Typography>
                        </Stack>
                    )}

                    {/* To Do: Site Setting DisplayAdditionalCostsOnListingFull*/}
                    {/* Security Deposit */}
                    {(ListingDetail.SecurityDeposit !== null) && (
                        <Stack id="security-deposit" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_SecurityDeposit')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.SecurityDeposit), '', siteSettings)}
                            </Typography>
                        </Stack>
                    )}

                    {/* Key Money */}
                    {(ListingDetail.KeyMoney !== null) && (
                        <Stack id="key-money" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_KeyMoney')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.KeyMoney), '', siteSettings)}
                            </Typography>
                        </Stack>
                    )}

                    {/* Non Refundable Deposit */}
                    {(ListingDetail.NonRefundableDeposit !== null) && (
                        <Stack id="non-refundable-deposit" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_NonRefundableDeposit')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.NonRefundableDeposit), '', siteSettings)}
                            </Typography>
                        </Stack>
                    )}

                    {/* Renewal Fee */}
                    {(ListingDetail.RenewalFee !== null) && (
                        <Stack id="renewal-fee" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_RenewalFee')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.RenewalFee), '', siteSettings)}
                            </Typography>
                        </Stack>
                    )}
                    {/* End Commission Fields*/}

                    {/* Utilities Cost */}
                    {(ListingDetail.UtilityCost !== null) && (
                        <Stack id="utilities-cost" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_Utilities')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.UtilityCost), '', siteSettings)}
                                {ListingDetail.UtilityCostUID !== null ? getLookupTranslationById(ListingDetail.UtilityCostUID.toString(), LookupList) : ''}
                            </Typography>
                        </Stack>
                    )}

                    {/* Heating Costs Included */}
                    {(ListingDetail.IsHeatingCostIncluded !== null) && (
                        <Stack id="heating-costs-included" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_IsHeatingCostIncluded')}
                            </Typography>
                        </Stack>
                    )}

                    {/* Heating Costs */}
                    {(ListingDetail.HeatingCost !== null) && (
                        <Stack id="heating-costs" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_HeatingCost')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.HeatingCost), '', siteSettings)}
                            </Typography>
                        </Stack>
                    )}

                    {/* Parking Costs */}
                    {(ListingDetail.ParkingCost !== null) && (
                        <Stack id="parking-costs" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_ParkingCosts')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.ParkingCost), '', siteSettings)}
                            </Typography>
                        </Stack>
                    )}

                    {/* Maintenance Fee */}
                    {(ListingDetail.MaintenanceFee !== null) && (
                        <Stack id="maintenance-fee" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_MaintenanceFee')}
                            </Typography>
                            <Stack direction="row" spacing={.5}>
                                <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                    {ListingDetail.MaintenanceFeeOverRideCurrency !== null &&
                                        (formatCustomCurrency(parseFloat(ListingDetail.MaintenanceFee), siteSettings, ListingDetail.MaintenanceFeeOverRideCurrency)
                                        )}
                                    {ListingDetail.MaintenanceFeeOverRideCurrency === null && (
                                        formatCurrency(parseFloat(ListingDetail.MaintenanceFee), '', siteSettings)
                                    )}
                                </Typography>
                                <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                    {ListingDetail.MaintenanceFeeUID !== null ? getLookupTranslationById(ListingDetail.MaintenanceFeeUID.toString(), LookupList) : ''}
                                </Typography>
                            </Stack>
                        </Stack>
                    )}

                    {/* Funds for Major Repairs */}
                    {(ListingDetail.FundsForMajorRepairs !== null) && (
                        <Stack id="funds-for-major-repairs" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_FundsForMajorRepairs')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.FundsForMajorRepairs), '', siteSettings)}
                                {ListingDetail.FundsForMajorRepairsUID !== null ? getLookupTranslationById(ListingDetail.FundsForMajorRepairsUID.toString(), LookupList) : ''}
                            </Typography>
                        </Stack>
                    )}

                    {/* Insurance Fee */}
                    {(ListingDetail.InsuranceFee !== null) && (
                        <Stack id="insurance-fee" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_InsuranceFee')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.InsuranceFee), '', siteSettings)}
                            </Typography>
                            <Typography className={`${typographyClassName} secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_TermOfInsurance')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {ListingDetail.TermOfInsurance}
                            </Typography>
                        </Stack>
                    )}

                    {/* Property Tax */}
                    {(ListingDetail.PropertyTax !== null) && (
                        <Stack id="property-tax" direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t("Listing_PropertyTax")}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {formatCurrency(parseFloat(ListingDetail.PropertyTax), '', siteSettings)}
                            </Typography>
                        </Stack>
                    )}

                    {/* Other Expenses */}
                    {(ListingDetail.OtherExpenses !== null) && (
                        <Stack id="other-expenses" direction="row" spacing={2} justifyContent="space-between" alignItems="start" useFlexGap flexWrap="nowrap">
                            <Typography className={` secondary-text`} variant={typographyVariant} sx={{ whiteSpace: 'nowrap' }}>
                                {t('Listing_OtherExpenses')}
                            </Typography>
                            <Typography className={typographyClassName} variant={typographyVariant} sx={{ textAlign: 'right' }}>
                                {ListingDetail.OtherExpenses}
                            </Typography>
                        </Stack>
                    )}
                </Stack>


            </Stack>
        </>
    );
};

export default ListingAdditionalCosts;
