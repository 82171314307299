import React, { useEffect, useState } from 'react';
import { Hidden, Stack, Skeleton } from '@mui/material';
import {
    Favorite as FavoriteIcon,
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
    Share as ShareIcon,
} from '@mui/icons-material';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { PAGE_TYPE, TRANSACTION_TYPES } from '../../constants/constants';

interface Props {
    listingDetail: ListingDetail;
    macroPropertyTypes: LookupItem[];
    lookupList: LookupItem[];
}

const ListingBreadcrumbs: React.FC<Props> = ({ listingDetail, lookupList, macroPropertyTypes }) => {
    const ListingLocation = {
        Name: '',
        ListingSearchUrl: '',
    };

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    const transactionTypeUID = listingDetail.TransactionTypeUID ? listingDetail.TransactionTypeUID.toString() : '';
    const macroPropertyTypeUID = listingDetail.MacroPropertyTypeUID ? listingDetail.MacroPropertyTypeUID.toString() : '';

    const transactionTypeUrl =
        transactionTypeUID === TRANSACTION_TYPES.FORSALE.toString()
            ? `/${PAGE_TYPE.LISTINGS}?TransactionTypeUID=${TRANSACTION_TYPES.FORSALE.toString()}&MacroPropertyTypeUIDs=${macroPropertyTypeUID}&ListingClass=${listingDetail.ListingClass}`
            : `/${PAGE_TYPE.LISTINGS}?TransactionTypeUID=${TRANSACTION_TYPES.FORRENT.toString()}&MacroPropertyTypeUIDs=${macroPropertyTypeUID}&ListingClass=${listingDetail.ListingClass}`;

    const macroPropertyTypeUrl = `/${PAGE_TYPE.LISTINGS}?MacroPropertyTypeUIDs=${macroPropertyTypeUID}&ListingClass=${listingDetail.ListingClass}`;

    if (listingDetail && listingDetail.MLSID) {
        if (listingDetail.LocalZone) {
            ListingLocation.Name = listingDetail.LocalZone;
            ListingLocation.ListingSearchUrl = `/${PAGE_TYPE.LISTINGS}?LocalZone=${listingDetail.LocalZoneID}&LocalZoneNM=${listingDetail.LocalZone}&TransactionTypeUID=${listingDetail.TransactionTypeUID ? listingDetail.TransactionTypeUID.toString() : TRANSACTION_TYPES.NOTSELECTED.toString()}&MacroPropertyTypeUIDs=${macroPropertyTypeUID}&ListingClass=${listingDetail.ListingClass}`;
        } else if (listingDetail.City) {
            ListingLocation.Name = listingDetail.City;
            ListingLocation.ListingSearchUrl = `/${PAGE_TYPE.LISTINGS}?City=${listingDetail.CityID}&CityNM=${listingDetail.City}&TransactionTypeUID=${listingDetail.TransactionTypeUID ? listingDetail.TransactionTypeUID.toString() : TRANSACTION_TYPES.NOTSELECTED.toString()}&MacroPropertyTypeUIDs=${macroPropertyTypeUID}&ListingClass=${listingDetail.ListingClass}`;
        } else if (listingDetail.Province) {
            ListingLocation.Name = listingDetail.Province;
            ListingLocation.ListingSearchUrl = `/${PAGE_TYPE.LISTINGS}?Province=${listingDetail.ProvinceID}&ProvinceNM=${listingDetail.Province}&TransactionTypeUID=${listingDetail.TransactionTypeUID ? listingDetail.TransactionTypeUID.toString() : TRANSACTION_TYPES.NOTSELECTED.toString()}&MacroPropertyTypeUIDs=${macroPropertyTypeUID}&ListingClass=${listingDetail.ListingClass}`;
        } else if (listingDetail.RegionalZone) {
            ListingLocation.Name = listingDetail.RegionalZone;
            ListingLocation.ListingSearchUrl = `/${PAGE_TYPE.LISTINGS}?RegionalZone=${listingDetail.RegionRowID}&RegionalZoneNM=${listingDetail.RegionalZone}&TransactionTypeUID=${listingDetail.TransactionTypeUID ? listingDetail.TransactionTypeUID.toString() : TRANSACTION_TYPES.NOTSELECTED.toString()}&MacroPropertyTypeUIDs=${macroPropertyTypeUID}&ListingClass=${listingDetail.ListingClass}`;
        }
    }

    if (isLoading) {
        return (
            <Stack sx={{maxWidth: '100%'}}>
                <Skeleton variant="text" height={21} />
                </Stack>
        );
    }

    return (
        <div>
            {/* Desktop Breadcrumb */}
            <Hidden mdDown>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                    itemScope
                    itemType="http://schema.org/BreadcrumbList"
                >
                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        style={{ fontSize: '0.875rem' }}
                        href={macroPropertyTypeUrl}
                    >
                        <span itemProp="name">{getLookupTranslationById(macroPropertyTypeUID, macroPropertyTypes)}</span>
                        <meta itemProp="position" content="1" />
                    </a>

                {macroPropertyTypeUID !== "" && (
                    <KeyboardArrowRightIcon className="flip-text" style={{ fontSize: 'Medium' }} />
                )}

                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        style={{ fontSize: '0.875rem' }}
                        href={transactionTypeUrl}
                    >
                        <span itemProp="name">{getLookupTranslationById(transactionTypeUID, lookupList)}</span>
                        <meta itemProp="position" content="2" />
                    </a>

                    <KeyboardArrowRightIcon className="flip-text" style={{ fontSize: 'Medium', color: 'rgba(0, 0, 0, 0.6)!important' }} />

                    <a
                        className="breadcrumbs-last-item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        style={{ fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.6) !important' }}
                        title={ListingLocation.Name}
                        href={ListingLocation.ListingSearchUrl}
                    >
                        <span itemProp="name">{ListingLocation.Name}</span>
                        <meta itemProp="position" content="3" />
                    </a>
                </Stack>
            </Hidden>

            {/* Mobile Breadcrumb */}
            <Hidden mdUp>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexWrap="wrap"
                >
                    <a
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        style={{ fontSize: '0.875rem' }}
                        href="/listings"
                    >
                        <span itemProp="name">{getLookupTranslationById(macroPropertyTypeUID, macroPropertyTypes)}</span>
                        <meta itemProp="position" content="1" />
                    </a>

                    <KeyboardArrowRightIcon className="flip-text" style={{ fontSize: 'Medium' }} />

                    <a
                        className="breadcrumbs-last-item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                        style={{ fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.6) !important' }}
                        title={ListingLocation.Name}
                        href={ListingLocation.ListingSearchUrl}
                    >
                        <span itemProp="name">{ListingLocation.Name}</span>
                        <meta itemProp="position" content="3" />
                    </a>
                </Stack>
            </Hidden>
        </div>
    );
};

export default ListingBreadcrumbs;
