import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardContent, Hidden, Link, Typography, Grid, Stack, Paper, useTheme, useMediaQuery, Skeleton, CardMedia } from '@mui/material';
import { ContactForm } from '../utils';
import LeafletMap from '../utils/LeafletMap';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { MAP_PROVIDER, MAPZOOM, PAGE_TYPE } from '../../constants/constants';
import { t, use } from 'i18next';
import { Agent, Office, LookupItem, SiteSettings, Team } from '../../types/TypesIndex';
import { ProfileContactInfo, ProfileSocialChatIntegration, ProfileTeamNameAndLogo, ProfileDisplayTitle, ProfileOfficeCardForNameAddressLicense } from './ProfileComponentsIndex';
import { getProfileDefaultImagePath, getProfileImagePath } from '../utils/helper/ImageHelper';
// import MapBoxMap from '../utils/MapBoxMap';
import StaticMapBox from '../utils/StaticMapBox';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

interface ProfileContactCardProps {
    agent?: Agent;
    team?: Team;
    office?: Office;
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
    page?: string;
}


const ProfileContactCard: React.FC<ProfileContactCardProps> = ({ agent, team, office, lookupList, siteSettings, page }) => {

    // Parameters Declaration
    const isOfficeProfile = page === PAGE_TYPE.OFFICE;
    const isAgentProfile = page === PAGE_TYPE.AGENT;
    const isTeamProfile = page === PAGE_TYPE.TEAM;

    const defaultMap = siteSettings?.DefaultMapProvider ?? MAP_PROVIDER.LEAFLET
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    let officeShortLink = `/${PAGE_TYPE.OFFICE}/`;
    if (isAgentProfile) {
        officeShortLink = officeShortLink + agent?.OfficeId;
    } else if (isTeamProfile) {
        officeShortLink = officeShortLink + team?.OfficeId;
    }

    if (agent && agent?.OfficeShortLinks !== null
        && agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0) {
        officeShortLink = `/${PAGE_TYPE.OFFICE}/` + agent.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;
    } else if (team && team?.OfficeShortLinks !== null
        && team?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && team?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0) {
        officeShortLink = `/${PAGE_TYPE.OFFICE}/` + team.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;
    }

    let teamShortLink = "";
    if (isAgentProfile && agent?.TeamId !== undefined && agent?.TeamId > 0) {
        teamShortLink = `/${PAGE_TYPE.TEAM}/` + agent.TeamId;
        if (agent.TeamShortLinks !== null
            && agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode) !== null
            && agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
            teamShortLink = `/${PAGE_TYPE.TEAM}/` + agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;
    }


    if (isLoading) {
        return (
            <Grid id="contact-card" item xs={12} md={4}>
                <Item style={{ padding: '1rem', borderRadius: '1rem', textAlign: 'center', position: 'sticky', top: '3rem' }}>
                    <Card sx={{ maxWidth: 345, boxShadow: 'none' }}>
                        <Skeleton variant="rectangular" height={118} />
                        <CardContent>
                            <Stack direction="column" spacing={3} alignItems="center">
                                <Stack direction="column" spacing={2} alignItems="center">
                                    <Skeleton variant="text" width="60%" />
                                    <Skeleton variant="text" width="40%" />
                                </Stack>
                                <Skeleton variant="text" width="80%" />
                                <Skeleton variant="text" width="60%" />
                                <Skeleton variant="text" width="40%" />
                            </Stack>
                        </CardContent>
                        <CardContent sx={{ pt: '0' }}>
                            <Stack className="text-align-left" direction="column" spacing={2}>
                                <Skeleton variant="rectangular" height={200} />
                                <Skeleton variant="rectangular" height={200} />
                            </Stack>
                        </CardContent>
                    </Card>
                </Item>
            </Grid>
        );
    }

    return (
        <>
            {/* Agent Profile Contact Card */}

            {isAgentProfile && (
                <Hidden mdDown>

                    <Grid id="contact-card" item xs={12} md={4}>

                        <Item style={{ padding: '1rem', borderRadius: '1rem', textAlign: 'center', position: 'sticky', top: '3rem' }}>

                            <Card sx={{ maxWidth: 345, boxShadow: 'none' }}>

                                <CardContent style={{ paddingTop: '1.5rem', paddingBottom: '.5rem' }} >

                                    <Stack direction="column" spacing={3}>

                                        <Stack direction="column" spacing={2}>

                                            <Stack direction="column" spacing={1}>

                                                {/* Agent Name + Display Title */}
                                                {agent?.AgentName && agent?.AgentName !== null && (
                                                    <ProfileDisplayTitle
                                                        name={agent?.AgentName}
                                                        displayTitleUID={agent?.DisplayTitleUID?.toString()}
                                                        titleUID={agent?.TitleUID?.toString()}
                                                        lookupList={lookupList}
                                                        siteSettings={siteSettings}
                                                        className='h4'
                                                        variant='h4'
                                                        sortName={false} />
                                                )
                                                }

                                                {/* Agent License Number */}
                                                {agent?.AgentLicenseNumber !== null && agent?.AgentLicenseNumber !== null && (
                                                    <Typography className="body2 secondary-text" variant="body2">
                                                        {t('AgentFull_LicenseNumber')} {agent?.AgentLicenseNumber}
                                                    </Typography>
                                                )}

                                            </Stack>

                                            {/* Office Name */}
                                            <Stack direction="column" spacing={3}>

                                                <Link className="body1-medium secondary-text" variant="body1" href={officeShortLink} title={agent?.OfficeName} aria-label={agent?.OfficeName} underline="hover" color="inherit">
                                                    {agent?.OfficeName}
                                                </Link>

                                                {/* Global Team Name and Logo (component) */}
                                                {(agent && agent.TeamId > 0) &&
                                                    <ProfileTeamNameAndLogo
                                                        teamId={agent.TeamId}
                                                        teamName={agent.TeamName}
                                                        teamRegionId={agent.RegionId}
                                                        teamPhotoOrLogo={agent.TeamLogo}
                                                        teamUrl={teamShortLink}
                                                    />
                                                }

                                            </Stack>


                                        </Stack>



                                        <Stack direction="column" alignItems="center" spacing={2}>

                                            {/* Global Chat Integration Links component */}
                                            {agent &&
                                                <ProfileSocialChatIntegration
                                                    phone={agent?.AgentPhone}
                                                    email={agent?.AgentEmail}
                                                    whatsApp={agent?.WhatsApp}
                                                    facebookMessengerID={agent?.FacebookMessengerID}
                                                    viberID={agent?.ViberID}
                                                    telegramID={agent?.TelegramID}
                                                    lineChat={agent?.LINEChat}
                                                    lineChatID={agent?.LineChatID}
                                                    weChat={agent.WeChat}
                                                    weChatID={agent.WeChatID}
                                                    isMobile={isMobile}
                                                    agent={agent}
                                                    siteSettings={siteSettings}
                                                    page={PAGE_TYPE.AGENT}
                                                />}

                                            {/* To Do Global Show More Buttons (component) */}
                                            {agent &&
                                                <ProfileContactInfo
                                                    page={PAGE_TYPE.AGENT}
                                                    contactInfo={{
                                                        AgentPhone: agent?.AgentPhone,
                                                        DirectDialPhoneNumber: agent?.AgentDirectDialPhone,
                                                        OfficePhone: agent?.OfficePhone,
                                                        Email: agent?.AgentEmail,
                                                        siteSettings: siteSettings,
                                                        AgentId: agent?.AgentId,
                                                        OfficeId: agent?.OfficeId,
                                                        RegionId: agent?.RegionId,
                                                        TenantId: agent?.TenantId
                                                    }}
                                                />}

                                        </Stack>

                                    </Stack>

                                </CardContent>

                                <CardContent sx={{ pt: '0' }}>

                                    {/* Global Contact Form (component) */}
                                    {agent &&
                                        <ContactForm data={{
                                            tenantId: agent?.TenantId,
                                            regionId: agent?.RegionId,
                                            officeId: agent?.OfficeId,
                                            agentId: agent?.AgentId.toString(),
                                            agentName: agent?.AgentName,
                                            page: PAGE_TYPE.AGENT
                                        }} />}

                                    <Stack className="text-align-left" direction="column" spacing={2}>

                                        {agent && <ProfileOfficeCardForNameAddressLicense
                                            data={{
                                                agent: agent,
                                                page: PAGE_TYPE.AGENT,
                                                hideOfficeLicenseNumber: false,
                                                hideAgentLicenseNumber: true,
                                                hideUrl: false,
                                            }}
                                        />}

                                        {agent && defaultMap === MAP_PROVIDER.MAPBOX && (
                                            
                                            <StaticMapBox  longitude={agent.OfficeCoordinates.coordinates[0]}
                                            latitude={agent.OfficeCoordinates.coordinates[1]}
                                            zoom={15} height={'396'} width={'277'} hideLiveMapButton={true}/>
                                            //  <MapBoxMap longitude={agent.OfficeCoordinates.coordinates[0]} latitude={agent.OfficeCoordinates.coordinates[1]} zoom={MAPZOOM.TWELVE} height={'400px'} width={'100%'} />
                                        )}

                                        {agent && defaultMap === MAP_PROVIDER.LEAFLET && (
                                            <LeafletMap longitude={agent.OfficeCoordinates.coordinates[0]} latitude={agent.OfficeCoordinates.coordinates[1]}
                                                zoom={MAPZOOM.TWELVE} scrollWheelZoom={false} height={'400px'} width={'100%'} />
                                        )}

                                        {/* <GoogleMap agentdata={data} />  */}

                                    </Stack>

                                </CardContent>

                            </Card>

                        </Item>

                    </Grid>

                </Hidden>

            )}

            {/* Team Profile Contact Card */}

            {isTeamProfile && (
                <Hidden mdDown>

                    <Grid id="contact-card" item xs={12} md={4}>

                        <Item style={{ padding: '1rem', borderRadius: '1rem', textAlign: 'center', position: 'sticky', top: '3rem' }}>

                            <Card sx={{ maxWidth: 345, boxShadow: 'none' }}>

                                <CardContent style={{ paddingTop: '1.5rem', paddingBottom: '.5rem' }} >

                                    <Stack direction="column" spacing={3}>

                                        <Stack direction="column" spacing={2}>

                                            <Stack direction="column" spacing={1}>

                                                {/* Team Name + Display Title */}
                                                {team?.TeamName && team?.TeamName !== null && (
                                                    <ProfileDisplayTitle
                                                        name={team?.TeamName}
                                                        displayTitleUID=''
                                                        titleUID=''
                                                        lookupList={lookupList}
                                                        siteSettings={siteSettings}
                                                        className='h4'
                                                        variant='h4'
                                                        sortName={false} />
                                                )
                                                }

                                            </Stack>

                                        </Stack>


                                        {/* Global Team Name and Logo (component) */}
                                        {team && team.TeamId > 0 && <ProfileTeamNameAndLogo
                                            teamId={team.TeamId}
                                            teamName=""
                                            teamRegionId={team.RegionId}
                                            teamPhotoOrLogo={team.TeamLogo}
                                        />}

                                        <Stack direction="column" alignItems="center" spacing={2}>

                                            {/* Global Chat Integration Links component */}
                                            {team &&
                                                <ProfileSocialChatIntegration
                                                    phone={team?.Phone}
                                                    email={team?.Email}
                                                    whatsApp={team?.WhatsAppNumber}
                                                    facebookMessengerID=''
                                                    viberID=''
                                                    telegramID=''
                                                    lineChat={team?.LINEChat}
                                                    lineChatID=''
                                                    weChat={team.WeChat}
                                                    weChatID=''
                                                    isMobile={isMobile}
                                                    page={PAGE_TYPE.TEAM}
                                                />}

                                            {/* Global Show More Buttons (component) */}
                                            {team &&
                                                <ProfileContactInfo
                                                page={PAGE_TYPE.AGENT}
                                                    contactInfo={{
                                                        AgentPhone: team?.Phone,
                                                        DirectDialPhoneNumber: '',
                                                        OfficePhone: team?.OfficePhone,
                                                        Email: team?.Email,
                                                        siteSettings: siteSettings,
                                                        TeamId: team?.TeamId,
                                                        OfficeId: team?.OfficeId,
                                                        RegionId: team?.RegionId,
                                                        TenantId: team?.TenantId
                                                    }}
                                                />}

                                        </Stack>

                                    </Stack>

                                </CardContent>

                                <CardContent sx={{ pt: '0' }}>

                                    {/* Global Contact Form (component) */}
                                    {team &&
                                        <ContactForm data={{
                                            tenantId: team?.TenantId,
                                            regionId: team?.RegionId,
                                            officeId: team?.OfficeId,
                                            teamId: team?.TeamId,
                                            page: PAGE_TYPE.TEAM
                                        }} />}


                                    <Stack className="text-align-left" direction="column" spacing={2}>
                                        {team && <ProfileOfficeCardForNameAddressLicense
                                            data={{
                                                team: team,
                                                page: PAGE_TYPE.TEAM,
                                                hideOfficeLicenseNumber: false,
                                                hideAgentLicenseNumber: true,
                                                hideUrl: false,
                                            }}
                                        />}


                                        {team && defaultMap === MAP_PROVIDER.MAPBOX && (
                                            <StaticMapBox  longitude={team.Coordinates.coordinates[0]} latitude={team.Coordinates.coordinates[1]} zoom={15} height={'400'} width={'280'} hideLiveMapButton={true}/>
                                            // <MapBoxMap longitude={team.Coordinates.coordinates[0]} latitude={team.Coordinates.coordinates[1]} zoom={MAPZOOM.TWELVE} height={'400px'} width={'100%'} />
                                        )}

                                        {team && defaultMap === MAP_PROVIDER.LEAFLET && (
                                            <LeafletMap longitude={team.Coordinates.coordinates[0]} latitude={team.Coordinates.coordinates[1]}
                                                zoom={MAPZOOM.TWELVE} scrollWheelZoom={false} height={'400px'} width={'100%'} />
                                        )}

                                        {/* <GoogleMap agentdata={data} />  */}

                                    </Stack>

                                </CardContent>

                            </Card>

                        </Item>

                    </Grid>

                </Hidden>

            )}

            {/* Office Profile Contact Card */}
            {isOfficeProfile && (
                <Hidden mdDown>

                    <Grid id="contact-card" item xs={12} md={4}>

                        <Item style={{ padding: '1rem', borderRadius: '1rem', textAlign: 'center', position: 'sticky', top: '3rem' }}>

                            <Card sx={{ maxWidth: 345, boxShadow: 'none' }}>

                                <CardContent style={{ paddingTop: '1.5rem', paddingBottom: '.5rem' }} >

                                    <Stack direction="column" spacing={3}>

                                        <Stack direction="column" spacing={1}>

                                            {/* Office Name */}
                                            <Typography className="h4" variant="h4">
                                                {office?.OfficeName}
                                            </Typography>

                                            {/* Office License Number */}
                                            {office?.OfficeLicenseNumber !== null && office?.OfficeLicenseNumber !== undefined && (
                                                <Typography className="body2 secondary-text" variant="body2">
                                                    {t('OfficeFull_LicenseNumber')} {office?.OfficeLicenseNumber}
                                                </Typography>
                                            )}
                                        </Stack>

                                        <Stack spacing={2} alignItems="center">

                                            {/* Comments: Office has not chat integration */}
                                            {office && <ProfileSocialChatIntegration
                                                phone={office?.Phone}
                                                email={office?.Email}
                                                whatsApp=''
                                                facebookMessengerID=''
                                                viberID=''
                                                telegramID=''
                                                lineChat=''
                                                lineChatID=''
                                                weChat=''
                                                weChatID=''
                                                isMobile={isMobile}
                                                page={PAGE_TYPE.OFFICE}
                                            />}

                                            {/* Global Show More Buttons (component) */}
                                            {office && <ProfileContactInfo
                                                page={PAGE_TYPE.OFFICE}
                                                contactInfo={{
                                                    AgentPhone: '',
                                                    DirectDialPhoneNumber: '',
                                                    OfficePhone: office?.Phone,
                                                    Email: office?.Email,
                                                    OfficeId: office?.OfficeId,
                                                    RegionId: office?.RegionId,
                                                    siteSettings: siteSettings,
                                                }} />}

                                        </Stack>

                                    </Stack>

                                </CardContent>

                                <CardContent sx={{ pt: '0' }}>

                                    {/* Global Contact Form (component) To Do */}
                                    {office && <ContactForm data={{
                                        tenantId: office?.TenantId,
                                        regionId: office?.RegionId,
                                        officeId: office?.OfficeId,
                                        page: PAGE_TYPE.OFFICE
                                    }} />}

                                    <Stack className="text-align-left" direction="column" spacing={2}>

                                        {office && <ProfileOfficeCardForNameAddressLicense
                                            data={{
                                                office: office,
                                                page: "office",
                                                hideOfficeLicenseNumber: false,
                                                hideAgentLicenseNumber: true,
                                                hideUrl: false,
                                            }}
                                        />}

                                        {/* Global Google Map (component) */}
                                        {office && defaultMap === MAP_PROVIDER.MAPBOX && (
                                            <StaticMapBox  longitude={office.Coordinates.coordinates[0]} latitude={office.Coordinates.coordinates[1]} zoom={15} height={'400'} width={'280'} hideLiveMapButton={true}/>
                                            // <MapBoxMap longitude={office.Coordinates.coordinates[0]} latitude={office.Coordinates.coordinates[1]} zoom={MAPZOOM.TWELVE} height={'400px'} width={'100%'} />
                                        )}

                                        {office && defaultMap === MAP_PROVIDER.LEAFLET && (
                                            <LeafletMap longitude={office.Coordinates.coordinates[0]} latitude={office.Coordinates.coordinates[1]}
                                                zoom={MAPZOOM.TWELVE} scrollWheelZoom={false} height={'400px'} width={'100%'} />
                                        )}
                                    </Stack>

                                </CardContent>

                            </Card>

                        </Item>

                    </Grid>

                </Hidden>

            )}
        </>
    );
};

export default ProfileContactCard;
