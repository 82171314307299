import axios from 'axios';
import HitType from '../types/Hit'
import { TENANTS } from '../constants/constants';
const locationOrigin = window?.location?.origin ?? ''; // Fallback for environments where window might be undefined
const hitService = async (input: HitType): Promise<boolean> => {
  const apiHitEndpoint = `${locationOrigin}/hits/InsertHit`;

  if(input.HitName === undefined)
    input.HitName = "";

  if(input.ListingId === undefined)
    input.ListingId = 0;

  if(input.SiteId === undefined)
    input.SiteId = "";

  if(input.UserId === undefined)
    input.UserId = 0;

  if(input.TeamId === undefined)
    input.TeamId = 0;

  const body = {
    TenantId: input.TenantId,
    RegionId: input.RegionId,
    HitName: input.HitName,
    HitType: input.HitType,
    LanguageCode: input.LanguageCode,
    AgentId: input.AgentId,
    OfficeId: input.OfficeId,
    TeamId: input.TeamId,
    UserId: input.UserId,
    ListingId: input.ListingId,
    SiteId: input.SiteId,
    //OccurenceInUTC: input.OccurenceInUTC,
    IpAddress: await getIpAddress(),
    SiteType: input.SiteType,
    //CreationTime: input.CreationTime


  };

  try {
    const response = await fetch(apiHitEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return data;
  } catch (error) { 
    console.error('Error:', error);
      console.log(body);
    return true;
    
  }
};


const getIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
};



export { hitService };
