import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useMediaQuery, Card, Container, Typography, Grid, Stack, Paper } from '@mui/material';
import { ContactForm } from './../components/utils';
import { useTranslation } from 'react-i18next';

const Contact: React.FC = () => {
    const [contactAgreement, setContactAgreement] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)'); // Define your mobile breakpoint
    const { t, i18n } = useTranslation();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    }));

    return (
        <Container maxWidth="xl" className="text-align-left light" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
            <Grid container spacing={isMobile ? 4 : 8} alignItems={isMobile ? 'flex-start' : 'center'}>
                <Grid item xs={12} md={6}>
                    <Item className="gridItem">
                        <Stack direction="column" spacing={3}>
                            <Typography className="h1" variant="h1">
                                {t('SendUsAMessage')}
                            </Typography>
                            <Typography className="body1" variant="body1">
                                {t('CommentsDesc')}
                            </Typography>
                        </Stack>
                    </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Item className="gridItem">
                        <Card sx={{ padding: '1.5rem', borderRadius: '1rem' }}>
                            {/* Global Contact Form (component) */}
                            <ContactForm data={{}} />
                        </Card>
                    </Item>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Contact;