import React, { useReducer, createContext, useContext, ReactNode } from 'react';
import ListingSearchPayload from '../types/Listing/ListingSearch';
import { SET_SEARCH_STATE, RESET_SEARCH_STATE } from './actionTypes';
import { PROPERTY_TYPES, TRANSACTION_TYPES } from '../constants/constants';

// Define the initial state matching the ListingSearchProps structure
const initialState: { searchState: ListingSearchPayload & { IsReset: boolean } } = {
  searchState: {
    FreeText: null,
    Search: null,
    Country: null,
    State: null,
    Province: [],
    City: [],
    CityId: null,
    Distrinct: [],
    LocalZone: [],
    Zip: null,
    StreetName: null,
    CountryId: null,
    ProvinceId: null,
    CityNM: [],
    ProvinceNM: [],
    ContryNM: [],
    LocalZoneNM: [],
    ListingClass: PROPERTY_TYPES.NOTSELECTED.toString(),
    TransactionTypeUID: TRANSACTION_TYPES.FORSALE.toString(),
    MacroPropertyTypeUIDs: null,
    PropertyTypeUID: null,
    Bedrooms: null,
    Bathrooms: null,
    ParkingSpaces: null,
    Price: null,
    PriceMin: '-1',
    PriceMax: '-1',
    PriceRange: null,
    PropertyFeatures: null,
    MinTotalArea: null,
    MaxTotalArea: null,
    PropertiesAdded: null,
    HasMultiMedia: null,
    DevelopmentID: '-1',
    DevelopmentName: null,
    MLSID: null,
    CenterLng: null,
    CenterLat: null,
    DynamicRadius: null,
    AgentID: null,
    TeamID: null,
    OfficeID: null,
    MacroOfficeID: null,
    IsReset: false,
  }
};

// Reducer function
function searchReducer(state: typeof initialState, action: { type: string; payload?: ListingSearchPayload }) {
  switch (action.type) {
    case SET_SEARCH_STATE:
      return {
        ...state,
        searchState: {
          ...state.searchState,
          ...action.payload,
          IsReset: false
        }
      };
    case RESET_SEARCH_STATE:
      return {
        ...initialState,
        searchState: {
          ...initialState.searchState,
          IsReset: true
        }
      };
    default:
      return state;
  }
}

// Create context
const SearchContext = createContext<{
  state: typeof initialState;
  dispatch: React.Dispatch<{ type: string; payload?: ListingSearchPayload }>;
} | null>(null);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(searchReducer, initialState);

  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
