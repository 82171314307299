import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Button, Link, Hidden, Accordion, AccordionSummary, AccordionDetails, Collapse, Typography, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ListingDetail from '../../types/Listing/ListingDetail';
import { LookupItem, SiteSettings } from '../../types/TypesIndex';
import { id } from 'date-fns/locale';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { formatCurrency, formatNumberWithRegionSetting } from '../utils/helper/FormatNumberHelper';
import { DEVELOPMENT, TRANSACTION_TYPES } from '../../constants/constants';

interface DevelopmentDetailAvailableUnitProps {
    development: string;
    listings: ListingDetail[];
    siteSettings?: SiteSettings;
    lookupList: LookupItem[];
}


interface AvailableUnit {
    mlsId: string;
    listingUrl: string;
    propertyType: string;
    totalNumOfRooms: string;
    numberOfBedrooms: string;
    unitNumber: string;
    totalArea: string;
    parkingSpaces: string;
    listingPrice: string;
}


const DevelopmentDetailAvailableUnit: React.FC<{ detailAvailableUnit: DevelopmentDetailAvailableUnitProps }> = ({ detailAvailableUnit }) => {
    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;
    const [showAll, setShowAll] = React.useState(false);
    const [loading, setLoading] = React.useState(true); //State to manage loading for skeleton
    //Timer for skeleton
    React.useEffect(() => {
        // Simulate a data fetch or initialization
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200); // Replace with actual loading logic

        return () => clearTimeout(timer);
    }, []);

    const DesktopSkeleton = (
        <Skeleton variant="rectangular" width="100%" height={615}></Skeleton>
    );

    const MobileSkeleton = (
        <Skeleton variant="rectangular" width="100%" height={577}></Skeleton>
    );

    const listings: AvailableUnit[] = detailAvailableUnit.listings.map(
        l => ({
            mlsId: l.MLSID,
            listingUrl: "/" + (l.ShortLinks == null || l.ShortLinks.filter(s => s.LanguageCode === languageCode) === null || l.ShortLinks.filter(s => s.LanguageCode === languageCode).length <= 0 ?
                l.MLSID : l.ShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink),
            propertyType: getLookupTranslationById(l.PropertyTypeUID.toString(), detailAvailableUnit.lookupList),
            totalNumOfRooms: l.TotalNumOfRooms > 0 ? l.TotalNumOfRooms.toString() : "-",
            numberOfBedrooms: l.NumberOfBedrooms > 0 ? l.NumberOfBedrooms.toString() : "-",
            unitNumber: l.ApartmentNumber !== null ? l.ApartmentNumber : "-",
            totalArea: l.TotalArea !== null ? formatNumberWithRegionSetting(l.TotalArea, detailAvailableUnit.siteSettings) : "-",
            parkingSpaces: l.ParkingSpaces !== null ? l.ParkingSpaces.toString() : "-",
            listingPrice: l.ListingPrice === 0 ? t("ListingList_UpOnRequest")
                : formatCurrency(l.ListingPrice, l.ListingCurrency, detailAvailableUnit.siteSettings)
                + ((l.ListingPrice !== 0 && l.RentalPriceGranularityUID !== null && l.TransactionTypeUID == TRANSACTION_TYPES.FORRENT.toString()) ? (" " + getLookupTranslationById(l.RentalPriceGranularityUID.toString(), detailAvailableUnit.lookupList)) : ''),
        })
    );




    const listingsToDisplay = showAll ? listings : listings.slice(0, 10);

    // Functional component to render each row of the table
    function Row(props: { row: AvailableUnit }) {

        const { row } = props; // Destructure row data from props
        const [open, setOpen] = React.useState(false); // State to manage the collapse/expand state of the row        

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' }, borderBottom: open ? 1 : 'unset', borderColor: 'divider' }}>
                    <TableCell sx={{ borderBottom: open ? 1 : 'unset', borderColor: 'divider' }} >
                        <Link href={row.listingUrl} target="_blank" rel="noopener">
                            {row.mlsId}
                        </Link>
                    </TableCell>
                    <TableCell sx={{ borderBottom: open ? 1 : 'unset', borderColor: 'divider' }} align="center">{row.propertyType}</TableCell>
                    <TableCell sx={{ borderBottom: open ? 1 : 'unset', borderColor: 'divider' }} align="center">{row.listingPrice}</TableCell>
                    <TableCell sx={{ textOverflow: 'unset !important', borderBottom: open ? 1 : 'unset', borderColor: 'divider' }}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)} // Toggle the open state on click
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} {/* Display the appropriate icon */}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow className='light'>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Box sx={{ paddingBottom: '0.5rem', paddingTop: '0.5rem' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.75rem', alignItems: 'center', gap: '8px' }}>
                                        <Typography variant="body2">{t("Development_AvailableUnits_Col_UnitNum")}</Typography>
                                        <Typography variant="body2" sx={{ height: '1px', width: '100%', borderBottom: 1, borderColor: 'divider' }}> </Typography>
                                        <Typography variant="body2">{row.unitNumber}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.75rem', alignItems: 'center', gap: '8px' }}>
                                        <Typography variant="body2">{t("Development_AvailableUnits_Col_UnitArea")}</Typography>
                                        <Typography variant="body2" sx={{ height: '1px', width: '100%', borderBottom: 1, borderColor: 'divider' }}> </Typography>
                                        <Typography variant="body2">{row.totalArea}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.75rem', alignItems: 'center', gap: '8px' }}>
                                        <Typography variant="body2">{t("Development_AvailableUnits_Col_Parking")}</Typography>
                                        <Typography variant="body2" sx={{ height: '1px', width: '100%', borderBottom: 1, borderColor: 'divider' }}> </Typography>
                                        <Typography variant="body2">{row.parkingSpaces}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
                                        <Typography variant="body2">{t("Development_AvailableUnits_Col_Price")}</Typography>
                                        <Typography variant="body2" sx={{ height: '1px', width: '100%', borderBottom: 1, borderColor: 'divider' }}> </Typography>
                                        <Typography variant="body2">{row.listingPrice}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }


    if (loading) {
        return (
            <>
                <Hidden lgDown>
                    {DesktopSkeleton}
                </Hidden>
                <Hidden lgUp>
                    {MobileSkeleton}
                </Hidden>
            </>
        );
    }

    if (detailAvailableUnit.listings === null || detailAvailableUnit.listings.length === 0) {
        return (
            <>
                <div
                    style={{
                        textAlign: 'center',
                        background: 'white',
                        width: '100%',
                        padding: '3rem',
                    }}
                >
                    <Typography className="h5" variant="h5">
                        {t("Development_NoUnitsAvailable")}
                    </Typography>
                </div>
            </>
        )
    }

    return (
        <>
            {/* Mobile Version */}
            <Hidden smUp>
                <TableContainer component={Paper} className="MainTable" >
                    <Table aria-label="collapsible table" sx={{ tableLayout: 'fixed', width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Development_AvailableUnits_Col_Id")}</TableCell>
                                <TableCell align="center">{t("Development_AvailableUnits_Col_PropertyType")}</TableCell>
                                <TableCell align="center">{t("Development_AvailableUnits_Col_Price")}</TableCell>
                                <TableCell sx={{ width: '15% !important', textOverflow: 'unset !important' }} /> {/* Empty cell for the expand/collapse icon */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listingsToDisplay.map((listing) => (
                                <Row key={listing.mlsId} row={listing} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box display="flex" justifyContent="flex-start" m={2} sx={{ marginTop: '1.5rem !important' }}>
                    {listings.length > DEVELOPMENT.SHOW_MORE && !showAll && (
                        <Box display="flex" justifyContent="flex-start" m={2} sx={{ marginTop: '1.5rem !important' }}>

                            <Button
                                fullWidth={false}
                                sx={{
                                    width: "auto",
                                    maxWidth: "fit-content",
                                    justifyContent: "left",
                                    border: "none",
                                    color: "var(--body-text-color) !important",
                                    '&:hover': {
                                        textDecoration: "underline",
                                        backgroundColor: 'white !important'
                                    }
                                }}
                                variant="text"
                                onClick={() => setShowAll(!showAll)}
                            >

                                {t("Showmore")}
                            </Button>

                        </Box>
                    )}
                </Box>
            </Hidden>

            {/* Desktop Version */}
            <Hidden smDown>
                <TableContainer component={Paper} className="MainTable">
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Development_AvailableUnits_Col_Id")}</TableCell>
                                <TableCell>{t("Development_AvailableUnits_Col_PropertyType")}</TableCell>
                                <TableCell>{t("Development_AvailableUnits_Col_TotalRooms")}</TableCell>
                                <TableCell>{t("Development_AvailableUnits_Col_Bedrooms")}</TableCell>
                                <TableCell>{t("Development_AvailableUnits_Col_UnitNum")}</TableCell>
                                <TableCell>{t("Development_AvailableUnits_Col_UnitArea")}</TableCell>
                                <TableCell>{t("Development_AvailableUnits_Col_Parking")}</TableCell>
                                <TableCell>{t("Development_AvailableUnits_Col_Price")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listingsToDisplay.map((listing) => (
                                <TableRow key={listing.mlsId}>
                                    <TableCell>
                                        <Link href={listing.listingUrl} target="_blank" rel="noopener">
                                            {listing.mlsId}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{listing.propertyType}</TableCell>
                                    <TableCell>{listing.totalNumOfRooms}</TableCell>
                                    <TableCell>{listing.numberOfBedrooms}</TableCell>
                                    <TableCell>{listing.unitNumber}</TableCell>
                                    <TableCell>{listing.totalArea}</TableCell>
                                    <TableCell>{listing.parkingSpaces}</TableCell>
                                    <TableCell>{listing.listingPrice}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {listings.length > DEVELOPMENT.SHOW_MORE && !showAll && (
                    <Box display="flex" justifyContent="flex-start" m={2} sx={{ marginTop: '1.5rem !important' }}>

                        <Button
                            fullWidth={false}
                            sx={{
                                width: "auto",
                                maxWidth: "fit-content",
                                justifyContent: "left",
                                border: "none",
                                color: "var(--body-text-color) !important",
                                '&:hover': {
                                    textDecoration: "underline",
                                    backgroundColor: 'white !important'
                                }
                            }}
                            variant="text"
                            onClick={() => setShowAll(!showAll)}
                        >

                            {t("Showmore")}
                        </Button>

                    </Box>
                )}
            </Hidden>
        </>
    );
};

export default DevelopmentDetailAvailableUnit;
