import React, { useState, useEffect } from 'react';
import SearchToolBar from '../components/search/index';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import { Container, Grid, Button, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { result } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useContent } from './ContentContext';

const Footer: React.FC = () => {
    const [logo, setLogo] = useState('');
    const { t, i18n } = useTranslation();
    const [htmlContent, setHtmlContent] = useState<string>('');
    const navigate = useNavigate();
    const manageContent = useContent();
    useEffect(() => {
        const language = i18n.language ?? 'en-US';
        const path = `/cms/api/v1/@apostrophecms/global/footer?aposLocale=${language}`;
        fetch(`${path}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error fetching data');
                }
                return response.text();
            })
            .then(html => {               
                const div = document.createElement('div');
                div.innerHTML = html;
                const copyrightYearElement = div.querySelector('#copyrightYear');
                if (copyrightYearElement) {
                    copyrightYearElement.textContent = new Date().getFullYear().toString();
                }

                setHtmlContent(div.innerHTML);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                //navigate("/");
            });

    }, [manageContent.footer, manageContent.header, i18n.language]);




    return (

        <footer className="footer">
            {!manageContent.footer && (<div dangerouslySetInnerHTML={{ __html: htmlContent }} />)}
        </footer>
    );
};

export default Footer;