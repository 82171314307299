import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, CardActionArea, CardActions, CardMedia } from '@mui/material';
import { getProfileDefaultImagePath, getProfileImagePath } from '../utils/helper/ImageHelper';

interface ProfileTeamNameAndLogoProps {
    teamId: number;
    teamName: string;
    teamRegionId: number | undefined;
    teamPhotoOrLogo: string;
    teamUrl?: string;
    direction?: 'row' | 'column';
    alignment?: 'center';
    borderTop?: '1px solid rgba(0, 0, 0, 0.12)' | 'none';
    paddingTop?: string;
    marginTop?: string;
    margin?: '0' | 'auto';
}


const ProfileTeamNameAndLogo: React.FC<ProfileTeamNameAndLogoProps> = ({ 
    teamId, 
    teamName, 
    teamRegionId,
    teamPhotoOrLogo,
    teamUrl,
    direction = 'column', 
    alignment, 
    borderTop = 'none', 
    paddingTop, 
    marginTop, 
    margin = 'auto', }) => {



    return (
        <>
            {teamId > 0 && (
                <Link underline="none" href={teamUrl}>
                <Stack
                    direction={direction}
                    alignItems="center"
                    borderTop={borderTop}
                    spacing={2} sx={{
                        justifyContent: { xs: 'center', lg: 'start' },
                        width: '100%',
                        borderTop: borderTop,
                        paddingTop: paddingTop,
                        marginTop: marginTop,

                    }}>

                    
                        <CardMedia
                            sx={{
                                width: '100px',
                                maxWidth: '300px',
                                borderRadius: '1rem',
                                margin: { margin },
                            }}
                            component="img"
                            image={teamPhotoOrLogo !== null && teamPhotoOrLogo !== '' ? getProfileImagePath(teamRegionId, teamPhotoOrLogo) : getProfileDefaultImagePath()}
                            alt={teamName}
                            title={teamName}
                            loading="lazy"
                            onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null; // prevents looping
                                target.src = getProfileDefaultImagePath(); // fallback image URL
                            }}
                        />

                        {teamName && teamName !== "" && (
                            
                                <Typography className="secondary-text" variant="body1">
                                    {teamName}
                                </Typography>
                            
                        )}

                </Stack>
                </Link>

            )}

        </>
    );
};

export default ProfileTeamNameAndLogo;
