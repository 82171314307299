import React from 'react';
import { Typography, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { LookupItem, SiteSettings } from '../../types/TypesIndex';
import { getFirstNCharacters } from '../utils/helper/DataFormatHelper';

interface ProfileDisplayTitleProps {
    name: string;
    displayTitleUID?: string;
    titleUID?: string;
    lookupList: LookupItem[];
    siteSettings?: SiteSettings
    className?: string;
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
    sx?: SxProps<Theme>;
    sortName?: boolean;
}

const ProfileDisplayTitle: React.FC<ProfileDisplayTitleProps> = ({ name, displayTitleUID, titleUID, lookupList, siteSettings, className, variant, sx, sortName = false }) => {

    const uid = displayTitleUID !== null ? displayTitleUID : titleUID;
    const showAgentTitle = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'PublicWebShowAgentTitle')?.HasAccess === "1";
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

    return (
        <Typography className={className} variant={variant} sx={sx}>
            {isMobile && sortName ? (
                <span>{getFirstNCharacters(name, 10)}</span>
            ) : (
                <span>{name}</span>
            )
            }
            {showAgentTitle && uid && uid !== null && (
                <span>{getLookupTranslationById(uid, lookupList)}</span>
            )}
        </Typography>
    );
};

export default ProfileDisplayTitle;
