import React, { useState, useEffect } from 'react';
import { Link, Box, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { Agent, Office, Team } from '../../types/TypesIndex';
import { LISTINGSEARCHFILTER, PROPERTY_TYPES, TRANSACTION_TYPES } from '../../constants/constants';
import { useSearch } from '../../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';


const ProfilePropertiesCountWithLink: React.FC<{ agent?: Agent, office?: Office, team?: Team, page?: string, listingCount?: number, macroOfficeListingCount?: number }> =
    ({ agent, office, team, page, listingCount, macroOfficeListingCount }) => {
        // Parameters Declaration
        const agentListingLink = `/listings?${LISTINGSEARCHFILTER.AGENTID}=${agent?.AgentId}&${LISTINGSEARCHFILTER.TRANSACTIONTYPEUID}=${TRANSACTION_TYPES.NOTSELECTED}&${LISTINGSEARCHFILTER.LISTINGCLASS}=${PROPERTY_TYPES.NOTSELECTED}`
        const officeListingLink = `/listings?${LISTINGSEARCHFILTER.OFFICEID}=${office?.OfficeId}&${LISTINGSEARCHFILTER.TRANSACTIONTYPEUID}=${TRANSACTION_TYPES.NOTSELECTED}&${LISTINGSEARCHFILTER.LISTINGCLASS}=${PROPERTY_TYPES.NOTSELECTED}`
        const macroOfficeListingLink = `/listings?${LISTINGSEARCHFILTER.MACROOFFICEID}=${office?.MacroOfficeId}&${LISTINGSEARCHFILTER.TRANSACTIONTYPEUID}=${TRANSACTION_TYPES.NOTSELECTED}&${LISTINGSEARCHFILTER.LISTINGCLASS}=${PROPERTY_TYPES.NOTSELECTED}`
        const teamListingLink = `/listings?${LISTINGSEARCHFILTER.TEAMID}=${team?.TeamId}&${LISTINGSEARCHFILTER.TRANSACTIONTYPEUID}=${TRANSACTION_TYPES.NOTSELECTED}&${LISTINGSEARCHFILTER.LISTINGCLASS}=${PROPERTY_TYPES.NOTSELECTED}`

        /*
        // Hook Declaration
        const { state, dispatch } = useSearch();

        // Event Handlers
        const handleLinkClick = () => {
            dispatch({ type: SET_SEARCH_STATE, 
                payload: 
                { 
                    ...state.searchState, 
                    TransactionTypeUID: TRANSACTION_TYPES.NOTSELECTED.toString(), 
                    ListingClass: PROPERTY_TYPES.NOTSELECTED.toString(),                 
                } });
        };
        */

        if (page  === "agent") {
            return (
                // Agents My Properties Link
                <Typography className="body1-medium" variant="body1"  sx={{ textAlign: 'center', textDecoration: 'underline' }}>
                    <Link  href={agentListingLink} title={t('PrivateNet_ListingsAgent')} aria-label={t('PrivateNet_ListingsAgent')} underline="always">{t('PrivateNet_ListingsAgent')} ({listingCount})</Link>
                </Typography>
            );
        } else if (page === "office"){
            return (
                <>
                   {/* Office Property Link */}
                    <Stack direction="column" spacing={1} >
                        <Typography className="body1-medium" variant="body1" sx={{ textAlign: 'center', textDecoration: 'underline' }}>
                            <Link href={officeListingLink} title={t('OfficeFull_ViewAllProperties')} aria-label={t('OfficeFull_ViewAllProperties')} underline="always">{t('OfficeFull_ViewAllProperties')}  ({listingCount})</Link>
                        </Typography>
                        <Typography display="none" className="body1-medium" variant="body1" sx={{ textAlign: 'center', textDecoration: 'underline' }}>
                            <Link href={officeListingLink} title={t('OfficeFull_ViewAllProperties')} aria-label={t('OfficeFull_ViewAllProperties')} underline="always">{t('OfficeFull_ViewAllProperties')}  ({listingCount})</Link>
                        </Typography>
                    </Stack>

                    {/* Macro Office: Group Properties Link */}
                    { office && office?.MacroOfficeId !== null && office?.MacroOfficeId !== '' && parseFloat(office?.MacroOfficeId) > 0 && (
                        <Stack direction="column" spacing={1} >
                            <Typography className="body1-medium" variant="body1" sx={{ textAlign: 'center', textDecoration: 'underline' }}>
                                <Link href={macroOfficeListingLink} title={t('OfficeFull_GroupProperties')} aria-label={t('OfficeFull_GroupProperties')} underline="always">{t('OfficeFull_GroupProperties')}  ({macroOfficeListingCount})</Link>
                            </Typography>
                            <Typography display="none" className="body1-medium" variant="body1" sx={{ textAlign: 'center', textDecoration: 'underline' }}>
                                <Link href={macroOfficeListingLink} title={t('OfficeFull_GroupProperties')} aria-label={t('OfficeFull_GroupProperties')} underline="always">{t('OfficeFull_GroupProperties')}  ({macroOfficeListingCount})</Link>
                            </Typography>
                        </Stack>
                    )
                    }
                </>
            );
        } else if (page === "team") {
            return (
                <>
                    {/* Team Property Link */}
                    <Typography className="body1-medium" variant="body1" sx={{ textAlign: 'center', textDecoration: 'underline' }}>
                        <Link href={teamListingLink} title={t('OfficeFull_ViewAllProperties')} aria-label={t('OfficeFull_ViewAllProperties')} underline="always">{t('OfficeFull_ViewAllProperties')} ({listingCount})</Link>
                    </Typography>
                </>
            );
        } else {
            return (<></>);
        }
    }

export default ProfilePropertiesCountWithLink;