import React, { useEffect, useState } from 'react';
import { Link as MuiLink, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListingDetail from '../../types/Listing/ListingDetail';
import { formatCurrency } from '../utils/helper/FormatNumberHelper';
import SiteSettings from '../../types/Settings/SiteSettings';
import LookupItem from '../../types/LookupItem';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { TRANSACTION_TYPES } from '../../constants/constants';

interface Props {
    listingDetail: ListingDetail;
    macroPropertyTypes: LookupItem[];
    lookupList: LookupItem[];
    siteSettings: SiteSettings | undefined;
}

interface Link {
    link: string;
    text: string;
}

const ListingRelatedLinks: React.FC<Props> = ({ listingDetail, macroPropertyTypes, lookupList, siteSettings }) => {
    const { t } = useTranslation();
    const [links, setLinks] = useState<Link[]>([]);
   
    const maxPrice = listingDetail.ListingPrice + (listingDetail.ListingPrice * 0.25);
    const minPrice = listingDetail.ListingPrice - (listingDetail.ListingPrice * 0.25);

    useEffect(() => {
        const generateLinks = () => {
            //if (!listingDetail.MacroPropertyTypeUID) return;
           
            const newLinks: Link[] = [];

            let baseLink;
            if(listingDetail.MacroPropertyTypeUID !== null){
                 baseLink = `/listings?MacroPropertyTypeUIDs=${listingDetail.MacroPropertyTypeUID.toString()}&`;
            }else{
                baseLink = `/listings?`;
            }

            if (listingDetail.ListingPrice > 0) {
                if (listingDetail.City) {
                    newLinks.push({
                        link: `${baseLink}City=${listingDetail.CityID}&PriceMin=${minPrice}&PriceMax=${maxPrice}`,
                        text: `${t('ListingFull_RelatedLinksBetween')} ${formatCurrency(minPrice, '', siteSettings)} ${t('ListingFull_RelatedLinksAnd')} ${formatCurrency(maxPrice, '', siteSettings)} ${t('ListingFull_RelatedLinkIn')} ${listingDetail.City}`
                    });
                }

                if (listingDetail.Province) {
                    newLinks.push({
                        link: `${baseLink}Province=${listingDetail.ProvinceID}&PriceMin=${minPrice}&PriceMax=${maxPrice}`,
                        text: `${t('ListingFull_RelatedLinksBetween')} ${formatCurrency(minPrice, '', siteSettings)} ${t('ListingFull_RelatedLinksAnd')} ${formatCurrency(maxPrice, '', siteSettings)} ${t('ListingFull_RelatedLinkIn')} ${listingDetail.Province}`
                    });
                }
            }

            newLinks.push({
                link: `${baseLink}TransactionTypeUID=${TRANSACTION_TYPES.FORSALE}&Province=${listingDetail.ProvinceID ?? ''}`,
                text: `${getLookupTranslationById(TRANSACTION_TYPES.FORSALE.toString(), lookupList)} ${t('ListingFull_RelatedLinkIn')} ${listingDetail.Province ?? ''}`
            });

            newLinks.push({
                link: `${baseLink}TransactionTypeUID=${TRANSACTION_TYPES.FORRENT}&Province=${listingDetail.ProvinceID ?? ''}`,
                text: `${getLookupTranslationById(TRANSACTION_TYPES.FORRENT.toString(), lookupList)} ${t('ListingFull_RelatedLinkIn')} ${listingDetail.Province ?? ''}`
            });

            if (listingDetail.City) {
                newLinks.push({
                    link: `${baseLink}TransactionTypeUID=${TRANSACTION_TYPES.FORSALE}&City=${listingDetail.CityID}`,
                    text: `${getLookupTranslationById(TRANSACTION_TYPES.FORSALE.toString(), lookupList)} ${t('ListingFull_RelatedLinkIn')} ${listingDetail.City}`
                });

                newLinks.push({
                    link: `${baseLink}TransactionTypeUID=${TRANSACTION_TYPES.FORRENT}&City=${listingDetail.CityID}`,
                    text: `${getLookupTranslationById(TRANSACTION_TYPES.FORRENT.toString(), lookupList)} ${t('ListingFull_RelatedLinkIn')} ${listingDetail.City}`
                });
            }

            setLinks(newLinks);
        };

        generateLinks();
    }, [listingDetail, lookupList, macroPropertyTypes, minPrice, maxPrice, siteSettings, t]);

    return (
        <Stack id="related-links" direction="column" spacing={3}>

            <Typography className="h4" variant="h4">
                {t('ListingFull_RelatedLinks')}
            </Typography>

            <Stack direction="column" spacing={2}>

                <Typography className="h6" variant="h6">
                    {t('ListingFull_SeeMoreListingsOfType')} {getLookupTranslationById(listingDetail.MacroPropertyTypeUID?.toString() || '', macroPropertyTypes)}
                </Typography>

                {links.map((l, index) => (

                    <div key={index}>
                        <MuiLink underline="always" href={l.link} dangerouslySetInnerHTML={{ __html: l.text }} />
                    </div>

                ))}

            </Stack>

        </Stack>
    );
};

export default ListingRelatedLinks;
