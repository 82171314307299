import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { IconButton, Hidden, Typography, Button, Grid, Link, Container, Stack, Paper, Tooltip, useMediaQuery, useTheme, Skeleton } from '@mui/material';
import { PictureAsPdf as PictureAsPdfIcon, SquareFoot as SquareFootIcon, Attachment as AttachmentIcon, Info as InfoIcon, KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon, Place as PlaceIcon } from '@mui/icons-material';
import { DESCRIPTION, LANGUAGE, PAGE_TYPE } from '../../constants/constants';
import { Development, SiteSettings } from '../../types/TypesIndex';
import { DevelopmentDetailMultiMedia } from './DevelopmentComponentsIndex';
import DevelopmentDescription from '@/types/Development/DevelopmentDescription';

interface DevelopmentDetailDescriptionProps {
    development: Development;
    developmentListingCount: number;
    siteSettings?: SiteSettings;
    scrollToElement: (id: string) => void;
}

const DevelopmentDetailDescription: React.FC<{ description: DevelopmentDetailDescriptionProps }> = ({ description }) => {

    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Hook Declaration
    const [loading, setLoading] = React.useState(true); //State to manage loading for skeleton
    const [showMore, setShowMore] = useState(false);

    // event handlers declaration
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // Development Description  Handle
    const siteDefaultLanguage = description.siteSettings?.RegionSupportedLanguages.find(
        lang => lang.IsDefault.trim() === DESCRIPTION.ISDEFAULT.toString()
    );
    const siteDefaultLanguageCode = siteDefaultLanguage?.FullLanguageCode.trim() ?? LANGUAGE.DEFAULT;
    //console.log(description.development);
    const filterDescriptionByUIDAndLanguage = (
        data: DevelopmentDescription[],
    ): string => {

        if (data == null || data.length <= 0)
            return "";

        let currentLanguageDescrption = data.filter(
            (item) =>
                item.LanguageCode === i18n.language
        );

        if (currentLanguageDescrption !== null && currentLanguageDescrption.length > 0)
            return currentLanguageDescrption[0].DevelopmentDescription;

        let siteDefaultLanguageDescription = data.filter(
            (item) =>
                item.LanguageCode === siteDefaultLanguageCode
        );

        if (siteDefaultLanguageDescription !== null && siteDefaultLanguageDescription.length > 0)
            return siteDefaultLanguageDescription[0].DevelopmentDescription;

        return "";
    };

    const developmentDescription = filterDescriptionByUIDAndLanguage(description.development?.DevelopmentDescriptions);

    //Timer for skeleton
    React.useEffect(() => {
        // Simulate a data fetch or initialization
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200); // Replace with actual loading logic

        return () => clearTimeout(timer);
    }, []);

    const DesktopSkeleton = (

        <Stack direction="column" spacing={3}>

            <Stack direction="column" spacing={2}>
                <Skeleton variant="rectangular" width={600} height={37}></Skeleton>
                <Skeleton variant="rectangular" width={100} height={24}></Skeleton>
            </Stack>

            <Stack direction="column" spacing={{ xs: 2, md: 3 }}>

                <Skeleton variant="rectangular" width="100%" height={72}></Skeleton>

                <Skeleton variant="rectangular" width={292} height={40}></Skeleton>

                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                    useFlexGap
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                >
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} useFlexGap flexWrap="wrap" alignItems={{ xs: 'flex-start', md: 'center' }}>

                        <Skeleton variant="rectangular" width={300} height={24}></Skeleton>
                        <Skeleton variant="rectangular" width={300} height={24}></Skeleton>

                    </Stack>

                </Stack>

            </Stack>
        </Stack>
    );

    const MobileSkeleton = (
        <Stack direction="column" spacing={3}>

            <Stack direction="column" spacing={2}>
                <Skeleton variant="rectangular" width={300} height={37}></Skeleton>
                <Skeleton variant="rectangular" width={100} height={24}></Skeleton>
            </Stack>

            <Stack direction="column" spacing={{ xs: 2, md: 3 }}>

                <Skeleton variant="rectangular" width="100%" height={300}></Skeleton>

                <Skeleton variant="rectangular" width={292} height={40}></Skeleton>

                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                    useFlexGap
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                >
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} useFlexGap flexWrap="wrap" alignItems={{ xs: 'flex-start', md: 'center' }}>

                        <Skeleton variant="rectangular" width={300} height={24}></Skeleton>
                        <Skeleton variant="rectangular" width={300} height={24}></Skeleton>

                    </Stack>

                </Stack>

            </Stack>

        </Stack>
    );

    if (loading) {
        return (
            <>
                <Hidden lgDown>
                    {DesktopSkeleton}
                </Hidden>
                <Hidden lgUp>
                    {MobileSkeleton}
                </Hidden>
            </>
        );
    }

    return (
        <>

            <Stack direction="column" spacing={3}>

                <Stack direction="column" spacing={2}>

                    <Stack direction={{xs: 'column', md: 'row'}} spacing={2} sx={{alignItems: {xs: 'start', md: 'end'}}}>
                        
                        {/* Development Name */}
                        <Typography id="h1-title" className={`${isMobile ? "h4" : "h3"}`} variant={isMobile ? "h4" : "h1"}>
                            {description.development.DevelopmentName !== null ? description.development.DevelopmentName : t("Development_DescriptionTitle")}
                        </Typography>

                        {/* Development Listing Count */}
                        <Typography className="body1-medium secondary-text" style={{color: 'var(--secondary-grey-text-color) !important'}} variant="body1">
                            ({description.developmentListingCount} {t("Development_ActiveListings")})
                        </Typography>

                    </Stack>

                    {/* Address , To Do: Update address base on SupSet of both GT and REP*/}
                    <Stack id="developments-address" onClick={() => description.scrollToElement('mapDiv')} direction={{ xs: 'row-reverse', sm: 'row-reverse', md: 'row' }} spacing={1} useFlexGap alignItems="normal" justifyContent="start">
                        
                        <PlaceIcon className="brand-primary" style={{ cursor: 'pointer' }} />
                        
                        <Typography className="body1 developments-detail-address" variant="body1" style={{ color: 'var(--primary-color) !important', cursor: 'pointer' }}>
                            {description.development.ProvinceName + " , " + description.development.CountryName}
                        </Typography>

                    </Stack>

                </Stack>

                {developmentDescription !== "" && (
                    <Stack direction="column" sx={{color: '#fff'}} spacing={{ xs: 2, md: 3 }}>

                        {/* Description */}

                        <Typography id="developments-description"
                            className="body1"
                            variant="body1"
                            dangerouslySetInnerHTML={{
                                __html: showMore || developmentDescription.length <= DESCRIPTION.SHOWMORE
                                    ? developmentDescription
                                    : developmentDescription.slice(0, DESCRIPTION.SHOWMORE)
                            }}>
                        </Typography>


                        {/* Show More Description Button */}
                        {!showMore && developmentDescription.length > DESCRIPTION.SHOWMORE && (
                            <Button
                                onClick={toggleShowMore}
                                fullWidth={false}
                                sx={{
                                    backgroundColor: 'white !important',
                                    width: "auto",
                                    maxWidth: "fit-content",
                                    justifyContent: "left",
                                    color: "var(--primary-color) !important",
                                    border: '1px solid var(--primary-color) !important',
                                    '&:hover': {
                                        backgroundColor: 'var(--button-active-color) !important',
                                    }
                                }}
                                variant="outlined"
                                endIcon={<KeyboardArrowRightIcon className="flip-text" sx={{ color: "var(--primary-color) !important " }} />}
                            >
                                {t('ListingFull_SeeMoreDescription')}
                            </Button>
                        )}

                        {/* Collapse Description Button */}
                        {showMore && developmentDescription.length > DESCRIPTION.SHOWMORE && (
                            <Button
                                onClick={toggleShowMore}
                                sx={{
                                    backgroundColor: 'white !important',
                                    width: "auto",
                                    maxWidth: "fit-content",
                                    justifyContent: "left",
                                    color: "var(--primary-color) !important",
                                    border: '1px solid var(--primary-color) !important',
                                    '&:hover': {
                                        backgroundColor: 'var(--button-active-color) !important',
                                    }
                                }}
                                variant="outlined"
                                endIcon={<KeyboardArrowRightIcon sx={{ color: "var(--primary-color) !important " }} />}
                            >
                                {t('ListingFull_CollapseDescription')}
                            </Button>
                        )}
                    </Stack>
                )}

                        {/* To Do - Add logic for more info, documents, floor plan */}
                        { (description.development.IntroductionPageURL !== null || 
                        (description.development.DevelopmentFiles && 
                        description.development.DevelopmentFiles.some(file => file.PDFBrochureURL !== '' || file.FloorPlanURL !== ''))) && (
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} useFlexGap flexWrap="wrap" alignItems={{ xs: 'flex-start', md: 'center' }}>

                                {/* More Info Button */}
                                {description.development.IntroductionPageURL !== null && (
                                    <>
                                    <Link aria-label={t("Development_MoreInfo")} href={description.development.IntroductionPageURL} target="_blank" rel="noopener noreferrer">
                                    <Tooltip title={t("Development_MoreInfo")} placement="bottom" aria-label={t("Development_MoreInfo")}>
                                    <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                                        <InfoIcon sx={{ color: "var(--body-text-color) !important" }} />
                                        <Typography className="body1" variant="body1">{description.development.IntroductionPageURL}</Typography>
                                        </Stack>
                                    </Tooltip>
                                    </Link>
                                    </>
                                    )
                                }
                                

                                {/* PDF Documents Button */} 
                                {description.development.DevelopmentFiles.length > 0 && (
                                    description.development.DevelopmentFiles.map((file, index) => (
                                        file.PDFBrochureURL !== '' && (
                                            <>
                                            <Link aria-label={t("Development_PDFBrochure")} href={file.PDFBrochureURL} target="_blank" rel="noopener noreferrer">
                                            <Tooltip
                                                key={index}
                                                title={t("Development_PDFBrochure")}
                                                placement="bottom"
                                                aria-label={t("Development_PDFBrochure")}
                                            >
                                                <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                                                <PictureAsPdfIcon aria-label={t("Development_PDFBrochure")} sx={{ color: "var(--body-text-color) !important" }} />
                                                <Typography className="body1" variant="body1">{t("Development_PDFBrochure")}</Typography>
                                                </Stack>
                                            </Tooltip>
                                            </Link>
                                            </>
                                        )
                                    ))
                                )}

                                {/* Floorplan */} 
                                {description.development.DevelopmentFiles.length > 0 && (
                                    description.development.DevelopmentFiles.map((file, index) => (
                                        file.FloorPlanURL !== '' && (
                                            <>
                                            <Link aria-label={t("Development_FloorPlan")} href={file.FloorPlanURL} target="_blank" rel="noopener noreferrer">
                                            <Tooltip
                                                key={index}
                                                title={t("Development_FloorPlan")}
                                                placement="bottom"
                                                aria-label={t("Development_FloorPlan")}
                                            >
                                            <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                                                <SquareFootIcon aria-label={t("Development_FloorPlan")} sx={{ color: "var(--body-text-color) !important" }} />
                                            <Typography className="body1" variant="body1">{t("Development_FloorPlan")}</Typography>
                                            </Stack>
                                            </Tooltip>
                                            </Link>
                                            </>
                                        )
                                    ))
                                )}
                            </Stack>
                        )}
                        

                </Stack>

        </>
    );
};

export default DevelopmentDetailDescription;