import React from 'react';
import { Box, Typography } from '@mui/material'; // Assuming you're using Material UI
import ListingSlider from '../listing/ListingSlider';
import { TENANTS } from '../../constants/constants';


interface SpotLightProps {
  value: number;  // Add appropriate type annotations for your props

}

interface HomeProps {
    children?: React.ReactNode;
    index?: number;
    value: number;
}


function CustomTabPanel(props: HomeProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            sx={{ marginTop: '0 !important' }}
            style={{ marginLeft: '0 !important', }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            

            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="span" >{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

const SpotLight: React.FC<SpotLightProps> = ({ value }) => {
    return (
        <>
   
        <CustomTabPanel value={1} index={1}>
            <Box sx={{ marginTop: '2.5rem !important' }}>
                <ListingSlider 
                    title="" 
                    isSpotLight={true} 
                    numberOfResults={10} 
                    tenantId={window.tenantid} 
                    macroRegionId={window.regionid} // Providing default value if regionID is undefined
                />
            </Box>
        </CustomTabPanel>
        </>
    );
};

export default SpotLight;
