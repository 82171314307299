import React, { useState, useEffect, useCallback } from 'react';
import {
    Autocomplete,
    TextField,
    Box,
    Stack,
    IconButton,
    Typography,
    debounce,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSearch } from '../../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';
import { PAGE_TYPE } from '../../constants/constants';

interface Props {
    apiBaseUrl?: string;
    onOptionSelect?: (value: string) => void;
    onDetailsSelect?: (value: any) => void;
    page?: string;
    clearSearch?: boolean;
    countryId?: number;
}

interface Option {
    group: string;
    title: string;
    type: string;
    expression: string;
    id: string
}

const normalizeString = (str: string): string => {
    return str ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim() : '';
};
const normalizeSpaces = (str: string) => str.replace(/\s+/g, ' ').trim();

const normalizeCommas = (str: string) => str.replace(/,\s*/g, ', ');

const normalizeAndTrim = (str: string) => normalizeSpaces(normalizeString(str).trim());

const toCamelCase = (str: string) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

const normalizeAndTrimCamelCase = (str: string) => toCamelCase(normalizeSpaces(normalizeAndTrim(normalizeCommas(normalizeString(str).trim()))));


const fetchOptions = async (inputValue: string, t: any, page: string, countryId: number): Promise<Option[]> => {
    let url = "";
    let filter = `content/TenantId eq ${window.tenantid}`;
    const search = inputValue.trim() + '*';
    const select = [];
    const searchTerms = [];
    if (page === PAGE_TYPE.OFFICE) {
        url = `${process.env.REACT_APP_API_SEARCH_OFFICE}`;
        filter = filter + ` and content/CountryID eq ${countryId}`;
        select.push('content/RegionZone');
        select.push('content/Province');
        select.push('content/City');
        select.push('content/LocalZone');
        select.push('content/OfficeAddress');
        select.push('content/ProvinceID');
        select.push('content/CityID');
        select.push('content/LocalZoneId');
        select.push('content/GeoDatas/Province');
        select.push('content/GeoDatas/City');
        select.push('content/GeoDatas/RegionalZone');
        select.push('content/GeoDatas/OfficeWebAddress');
        select.push('content/GeoDatas/LocalZone');

        searchTerms.push('content/OfficeAddress');
        searchTerms.push('content/City');
        searchTerms.push('content/Province');
        searchTerms.push('content/RegionZone');
        searchTerms.push('content/LocalZone');
        searchTerms.push('content/OfficeDescription');
    } else {
        url = `${process.env.REACT_APP_API_SEARCH_AGENT}`;
        filter = filter + ` and content/OfficeCountryID eq ${countryId}`;
        select.push('content/OfficeProvince');
        select.push('content/OfficeCity');
        select.push('content/OfficeLocalZone');
        select.push('content/OfficeAddress');
        select.push('content/OfficeProvinceID');
        select.push('content/OfficeCityID');
        select.push('content/OfficeLocalZoneId');
        select.push('content/GeoDatas/City');
        select.push('content/GeoDatas/Province');
        select.push('content/GeoDatas/RegionalZone');
        select.push('content/GeoDatas/OfficeWebAddress');
        select.push('content/GeoDatas/LocalZone');

        searchTerms.push('content/OfficeAddress');
        searchTerms.push('content/OfficeCity');
        searchTerms.push('content/OfficeProvince');
        searchTerms.push('content/OfficeRegionZone');
        searchTerms.push('content/OfficeLocalZone');
        searchTerms.push('content/Designations/Description');
    }

    const data = {
        count: true,
        skip: 0,
        top: 200,
        filter: filter,
        select: select.join(', '),
        searchFields: searchTerms.join(', '),
        search: search
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "referer": window.location.href,
                "origin": window.location.origin,
                "User-Agent": window.navigator.userAgent
            },
            body: JSON.stringify(data)
        });
        const result = await response.json();

        if (!result.value) {
            console.error('Response does not contain value property', result);
            return [];
        }

        const addOption = (optionsArray: Option[], condition: boolean, option: Option) => {
            if (condition) {
                optionsArray.push(option);
            }
        };


        const transformedOptions: Option[] = result.value.flatMap((item: any) => {

            const optionsArray: Option[] = [];

            if (page === PAGE_TYPE.OFFICE) {
                addOption(optionsArray, item.content.Province, {
                    title: item.content.Province,
                    group: t('FreeTextSearch_Provinces'),
                    type: 'province',
                    expression: item.content.Province,
                    id: item.content.ProvinceID
                });

                addOption(optionsArray, item.content.City && normalizeString(item.content.City).includes(normalizeString(inputValue)), {
                    title: `${item.content.City ? item.content.City.trim() : ""}, ${item.content.Province}`,
                    group: t('FreeTextSearch_Cities'),
                    type: 'city',
                    expression: item.content.City,
                    id: item.content.CityID
                });

                item.content.GeoDatas.map((geoData: any, index: number) => {
                    addOption(optionsArray, geoData.City && normalizeString(geoData.City).includes(normalizeString(inputValue)), {
                        title: `${geoData.City ? geoData.City.trim() : ""}, ${geoData.Province}`,
                        group: t('FreeTextSearch_Cities'),
                        type: 'city',
                        expression: geoData.City,
                        id: item.content.CityID
                    });

                    addOption(optionsArray, geoData.LocalZone && normalizeString(geoData.LocalZone.trim()).includes(normalizeString(inputValue.trim())), {
                        title: `${(geoData.LocalZone ? geoData.LocalZone.trim() : "")}, ${(geoData.City ? geoData.City.trim() : "")}, ${(geoData.Province)}`,
                        group: t('FreeTextSearch_LocalZones'),
                        type: 'localzone',
                        expression: geoData.LocalZone,
                        id: item.content.LocalZoneId
                    });
                });

                addOption(optionsArray, item.content.LocalZone && normalizeString(item.content.LocalZone).includes(normalizeString(inputValue)), {
                    title: `${item.content.LocalZone ? item.content.LocalZone.trim() : ""}, ${item.content.City ? item.content.City.trim() : ""}, ${item.content.Province ? item.content.Province.trim() : ""}`,
                    group: t('FreeTextSearch_LocalZones'),
                    type: 'localzone',
                    expression: item.content.LocalZone,
                    id: item.content.LocalZoneId
                });
            } else {

                addOption(optionsArray, item.content.OfficeProvince, {
                    title: item.content.OfficeProvince,
                    group: t('FreeTextSearch_Provinces'),
                    type: 'province',
                    expression: item.content.OfficeProvince,
                    id: item.content.OfficeProvinceID
                });

                addOption(optionsArray, item.content.OfficeCity && normalizeString(item.content.OfficeCity).includes(normalizeString(inputValue)), {
                    title: `${item.content.OfficeCity ? item.content.OfficeCity.trim() : ""}, ${item.content.OfficeProvince}`,
                    group: t('FreeTextSearch_Cities'),
                    type: 'city',
                    expression: item.content.OfficeCity,
                    id: item.content.OfficeCityID
                });

                item.content.GeoDatas.map((geoData: any, index: number) => {
                    addOption(optionsArray, geoData.City && normalizeString(geoData.City).includes(normalizeString(inputValue)), {
                        title: `${geoData.City ? geoData.City.trim() : ""}, ${geoData.Province}`,
                        group: t('FreeTextSearch_Cities'),
                        type: 'city',
                        expression: geoData.City,
                        id: item.content.CityID
                    });

                    addOption(optionsArray, geoData.LocalZone && normalizeString(geoData.LocalZone.trim()).includes(normalizeString(inputValue.trim())), {
                        title: `${(geoData.LocalZone ? geoData.LocalZone.trim() : "")}, ${(geoData.City ? geoData.City.trim() : "")}, ${(geoData.Province)}`,
                        group: t('FreeTextSearch_LocalZones'),
                        type: 'localzone',
                        expression: geoData.LocalZone,
                        id: item.content.LocalZoneId
                    });
                });

                addOption(optionsArray, item.content.OfficeLocalZone && normalizeString(item.content.OfficeLocalZone).includes(normalizeString(inputValue)), {
                    title: `${item.content.OfficeLocalZone ? item.content.OfficeLocalZone.trim() : ""}, ${item.content.OfficeCity ? item.content.OfficeCity.trim() : ""}, ${item.content.OfficeProvince ? item.content.OfficeProvince.trim() : ""}`,
                    group: t('FreeTextSearch_LocalZones'),
                    type: 'localzone',
                    expression: item.content.OfficeLocalZone,
                    id: item.content.OfficeLocalZoneId
                });
            }

            return optionsArray;
        });

        const uniqueOptionsMap = new Map<string, Option>();
        transformedOptions.forEach(option => {

            if (!uniqueOptionsMap.has(option.id) && option.id !== null) {
                uniqueOptionsMap.set(option.id, option);
            }
        });
        const uniqueOptions = Array.from(uniqueOptionsMap.values());
     
        return uniqueOptions.sort((a, b) => a.title.localeCompare(b.title));
    } catch (error) {
        console.error('Failed to fetch autocomplete suggestions:', error);
        return [];
    }
};
const ProfileSearchFreeTextSearch: React.FC<Props> = ({ apiBaseUrl = '', onOptionSelect, onDetailsSelect, page = PAGE_TYPE.AGENT, clearSearch = false, countryId = 0 }) => {

    const { t } = useTranslation();
    const [options, setOptions] = useState<Option[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const emptyOption: Option = { title: '', group: '', type: '', expression: '', id: '' };
    const groupOrder = ['province', 'city', 'localzone', 'streetName'];
    const [value, setValue] = useState<any>(null);
    useEffect(() => {
        if (clearSearch) {
            setValue(null);
            setInputValue('');  // Reset the input text as well
            setOptions([]);
        }
    }, [clearSearch]);


    const handleClick = (option: Option) => (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        event.preventDefault();
        //dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, FreeText: option.expression } });
        let pageName = 'agent';
        if (page === PAGE_TYPE.OFFICE) {
            pageName = 'office';
        }
        const searchParams = new URLSearchParams(window.location.search);
        const locationList = ['Province', 'ProvinceNM', 'City', 'CityNM', 'Localzone', 'LocalzoneNM', 'StreetName'];
        searchParams.forEach((value, key) => {
            if (key !== option.type) {
                if (!locationList.includes(key)) {
                    searchParams.set(key, value);
                } else {
                    searchParams.delete(key);
                }
            }
        });
        let targetUrl = location.pathname + '?' + searchParams.toString();
        if (option.expression) {
            searchParams.set(option.type, option.expression);
            searchParams.set('searchType', pageName);
            targetUrl = location.pathname + '?' + searchParams.toString();
            console.log('targetUrl', targetUrl);
        } else if (option.title === '') {
            searchParams.forEach((value, key) => {
                if (locationList.includes(key)) {
                    searchParams.delete(key);
                }
            });
            targetUrl = location.pathname + '?' + searchParams.toString();
            console.log('targetUrl', targetUrl);
        }
        navigate(targetUrl + `&page=1`);
    };

    const debouncedFetchOptions = useCallback(debounce(async (input: string) => {
        if (input.length > 2) {
            const options = await fetchOptions(input, t, page, countryId);
            setOptions(options);
        }
    }, 290), [t]);

    useEffect(() => {
        if (inputValue.trim() === '') {
            setOptions([]);
            return;
        }
        if (inputValue.trim() === '' && inputValue.length > 2) {
            setOptions([]);
            return;
        }
        debouncedFetchOptions(inputValue);
    }, [inputValue, debouncedFetchOptions, apiBaseUrl]);

    const handleInputChange = useCallback((event: React.ChangeEvent<{}>, newValue: string) => {
        setInputValue(newValue);
        setOpen(newValue.trim().length > 0);
    }, []);

    const updateSearchParams = (searchParams: URLSearchParams, key: string, values: string[]) => {
        searchParams.delete(key);

        values.forEach(value => {
            if (value.trim() !== 'NaN') {
                searchParams.append(key, value);
            }
        });
    };

    const renderBoldText = (text: string, highlight: string) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

        return (
            <Typography component="span">
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <Typography component="span" sx={{ fontWeight: 'bold' }} key={index}>
                            {part}
                        </Typography>
                    ) : (
                        <Typography component="span" key={index}>
                            {part}
                        </Typography>
                    )
                )}
            </Typography>
        );
    };

    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }} useFlexGap>
            <Box sx={{ width: '100%' }}>
                <Autocomplete
                    fullWidth
                    sx={{
                        maxWidth: '100% !important',
                        width: '100% !important',
                        '& .MuiOutlinedInput-root': {
                            paddingRight: '14px !important',
                        },
                        '& .MuiAutocomplete-endAdornment': {
                            display: 'none', // Hide the dropdown arrow
                        },
                    }}
                    id="agent-search-autocomplete"
                    noOptionsText=""
                    value={value}
                    options={options.sort((a, b) => groupOrder.indexOf(a.type) - groupOrder.indexOf(b.type))}
                    //filterOptions={(x) => (options.length === 0 ? [] : x)}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => option.title}
                    inputValue={inputValue}
                    onChange={(event, newValue) => {
                        const ps = { City: [], CityNM: [], Province: [], ProvinceNM: [], LocalZone: [], LocalZoneNM: [] };
                        const searchParams = new URLSearchParams(location.search);
                        const locationList = ['Province', 'ProvinceNM', 'City', 'CityNM', 'LocalZone', 'LocalZoneNM'];
                        searchParams.forEach((value, key) => {
                            if (key !== newValue.type) {
                                if (locationList.includes(key)) {
                                    searchParams.set(key, value);
                                } else {
                                    searchParams.delete(key);
                                }
                            }
                        });

                        if (newValue !== null) {
                            if (newValue.type === 'city') {
                                ps.City = Array.isArray(ps.City) ? ps.City : []; // Ensure ps.City is an array
                                ps.CityNM = Array.isArray(ps.CityNM) ? ps.CityNM : []; // Ensure ps.CityNM is an array
                                if (!ps.City.includes(parseInt(newValue.id))) {
                                    ps.City.push(parseInt(newValue.id));
                                    ps.CityNM.push(newValue.id + "-" + newValue.title);
                                }
                            }
                            if (newValue.type === 'province') {
                                ps.Province = Array.isArray(ps.Province) ? ps.Province : []; // Ensure ps.Province is an array
                                ps.ProvinceNM = Array.isArray(ps.ProvinceNM) ? ps.ProvinceNM : []; // Ensure ps.ProvinceNM is an array
                                if (!ps.Province.includes(parseInt(newValue.id))) {
                                    ps.Province.push(parseInt(newValue.id));
                                    ps.ProvinceNM.push(newValue.id + "-" + newValue.title);
                                }
                            }

                            if (newValue.type === 'localzone') {
                                ps.LocalZone = Array.isArray(ps.LocalZone) ? ps.LocalZone : []; // Ensure ps.LocalZone is an array
                                ps.LocalZoneNM = Array.isArray(ps.LocalZoneNM) ? ps.LocalZoneNM : []; // Ensure ps.LocalZoneNM is an array
                                if (!ps.LocalZone.includes(parseInt(newValue.id))) {
                                    ps.LocalZone.push(parseInt(newValue.id));
                                    ps.LocalZoneNM.push(newValue.id + "-" + newValue.title);
                                }
                            }
                            updateSearchParams(searchParams, 'City', ps.City.map(city => city.toString()));
                            updateSearchParams(searchParams, 'CityNM', ps.CityNM);
                            updateSearchParams(searchParams, 'Province', ps.Province.map(province => province.toString()));
                            updateSearchParams(searchParams, 'ProvinceNM', ps.ProvinceNM);
                            updateSearchParams(searchParams, 'LocalZone', ps.LocalZone.map(localZone => localZone.toString()));
                            updateSearchParams(searchParams, 'LocalZoneNM', ps.LocalZoneNM);
                            navigate({ search: searchParams.toString() });

                        } else {
                            navigate(location.pathname);
                        }
                    }}

                    open={open && options.length > 0}
                    onClose={() => setOpen(false)}
                    onInputChange={handleInputChange}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {

                            const matchedOption = options.find(option => option.title === inputValue);
                            if (matchedOption) {
                                handleClick(matchedOption)(event as unknown as React.MouseEvent<HTMLDivElement>);
                            }
                            else {

                                //handleOptionSelect();
                            }
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { fontFamily: 'var(--font-family)' },
                            }}
                            label={t('Search_Location')}
                            aria-label={t('Search_Location')}
                            type="search"
                        />
                    )}
                    renderOption={(props, option, index) => {
                        const { key, ...rest } = props;
                        const uniqueKey = option.id ? `${option.id}-${index}` : `${option.title}-${index}`;
                        return (
                            <li key={uniqueKey} {...rest}>
                                {renderBoldText(option.title, inputValue)}
                            </li>
                        );
                    }}
                    renderGroup={(params) => (
                        <li key={params.key}>
                            <div style={{ color: 'var(--primary-color)', backgroundColor: 'var(--select-menu-active-color)', padding: '4px 10px' }}>{params.group}</div>
                            <div>{params.children}</div>
                        </li>
                    )}
                />
            </Box>
        </Stack>
    );
};

export default ProfileSearchFreeTextSearch;
