import React, { ChangeEvent, useEffect, useState } from 'react';
import { useMediaQuery, useTheme, SelectChangeEvent, Divider, InputBase, IconButton, Button, Hidden, Grid, Container, Card, CardContent, Typography, Pagination, Paper, Tabs, Tab, MenuItem, FormControl, InputLabel, Stack, TextField, CircularProgress, Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../components/utils/LoadingContext';
import { featchProvinceList, fetchLookupTranslations } from '../../services/TranslationService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { fetchDevelopmentProvinceList } from '../../services/DevelopmentService';
import { DevelopmentSearchResponse, LookupItem, Province, SiteSettings } from '../../types/TypesIndex';
import { DevelopmentSearchDevelopmentList } from '../../components/development/DevelopmentComponentsIndex';
import { Search as SearchIcon } from '@mui/icons-material';
import MetaTags from '../../components/utils/seo/MetaTags';
import { formatNumberWithRegionSetting } from '../../components/utils/helper/FormatNumberHelper';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));


const DevelopmentSearch: React.FC = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Parameters Declaration
    const { t, i18n } = useTranslation();
    //const { showLoading, hideLoading } = useLoading();

    // Hook Declaration 
    const [provinces, setProvinces] = useState<Province[]>([]); // Data Source: province list base on Site Region ID and Language Code
    const [lookupList, setLookupList] = useState<LookupItem[]>([]); // Data Source
    const [siteSettings, setSiteSettings] = useState<SiteSettings>(); // Data Source
    const [regionProvinceList, setregionProvinceList] = useState<Province[]>([]); // Data Source

    const [provinceId, setProvinceId] = useState<number>(-1); // Search Render: Selected Province Id
    const [debouncedProvinceId, setDebouncedProvinceId] = useState<number>(-1); // Search Parameters: selected Province Id
    const [developmentName, setDevelopmentName] = useState<string>(''); // Search Render: Typped In Development Name
    const [debouncedDevelopmentName, setDebouncedDevelopmentName] = useState<string>(''); // Search Parameters: Typped Development Name
    const [pageId, setPageId] = useState(1); // Search Parameters: selected page Id

    const [totalMatchCount, setTotalMatchCount] = useState(-1); // Search Status: Pagination - Result Count
    const [totalPageCount, setTotalPageCount] = useState(0); // Search Status: Pagination - Page Count 
    const [totalRecordsPerPage, settotalRecordsPerPage] = useState(24); // To Do: from site settings

    const [isFeatured, setIsFeatured] = useState(true); // Search Flag: search is featured development

   // const [isLoading, setIsLoading] = useState(true); // Page Render for skeleton 
    const [loading, setLoading] = React.useState(true); // To Do for Design,  there are two for loading skelton, to decide which one to use



    // UseEffect Data Loading 

    // useEffect(() => {
    //     // Simulate loading delay or perform data fetching
    //     const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
    //     return () => clearTimeout(timer);
    // }, []);

    const loadSiteDataSource = async () => {
        try {
            const lookupList: LookupItem[] = await fetchLookupTranslations();
            const siteSettings: SiteSettings = await fetchSiteSettings();
            setLookupList(lookupList);
            setSiteSettings(siteSettings);

            const regionProvinceList: Province[] = await featchProvinceList( i18n.language);
            setregionProvinceList(regionProvinceList);
            const developmentProvinceListFacets = await fetchDevelopmentProvinceList(window.regionid);
            if (regionProvinceList !== null && developmentProvinceListFacets !== null && typeof (developmentProvinceListFacets['@search.facets']?.['content/ProvinceID']) !== 'undefined') {
                let developmentProvinceList = developmentProvinceListFacets['@search.facets']?.['content/ProvinceID']?.map(p => ({ provinceId: p.value }));
                let provinces: Province[] = regionProvinceList.filter(rp => developmentProvinceList.some(dp => dp.provinceId.toString() === rp.ProvinceID.toString()))
                    .map(rp => ({ ProvinceID: rp.ProvinceID, ProvinceName: rp.ProvinceName }))
                    .sort((a, b) => a.ProvinceName.localeCompare(b.ProvinceName));
                setProvinces(provinces);

                initalSearchParaemters();
            }

        } catch (error) {
            console.error('Development Search : failed to load data source', error);
        }
 
}

    useEffect(() => {
       
        loadSiteDataSource();   
    }, [i18n.language]);

    // useEffect(() => {
    //     showLoading();
    //     // Simulate fetching data
    //     setTimeout(() => {
    //         hideLoading();
    //     }, 200);
    // }, []);


    // Event Handlers
    const handleProvinceIdChange = (event: SelectChangeEvent<number>) => {
        event.stopPropagation();
        let selectedProvinceId = event.target.value as number;
        setProvinceId(selectedProvinceId);
    };

    const handleDevelopmentNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.stopPropagation();
        setDevelopmentName(event.target.value);
    };

    const handleDevelopmentSearch = (): void => {
        handleIsFeaturedState();
        onPageIdChange(1);
        handleUpdateQueryString("developmentName", developmentName);
        handleUpdateQueryString("provinceId", provinceId > 0 ? provinceId.toString() : "");
        setDebouncedDevelopmentName(developmentName);   
        setDebouncedProvinceId(provinceId);    
    };    

    const handleIsFeaturedState = () : void => {
        if (developmentName === "" && provinceId === -1) {
            setIsFeatured(true); 
        } else {
            setIsFeatured(false);
        }
    }

    const handleTotalMatchCount = (matchCount: number) => {
        setTotalMatchCount(matchCount);
    }

    const handleTotalPageCount = (pageCount: number) => {
        setTotalPageCount(pageCount);
    };

    const onPageIdChange = (pageId: number) => {
        setPageId(pageId);
        handleUpdateQueryString("pageId", pageId.toString());
    };

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, pageId: number) => {
        onPageIdChange(pageId);

        // Determine scroll position based on device type
        const isMobile = window.matchMedia('(max-width: 600px)').matches;
        const scrollPosition = isMobile ? 600 : 0;

        // Scroll to the specified position
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    const handleUpdateQueryString = (key: string, value: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(key);
        if (value !== '') {
            searchParams.set(key, value);
        }
        const searchWithNameUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState(null, '', searchWithNameUrl);
    }

    // Inital Search Parameters from Query String
    const initalSearchParaemters = () => {
        const searchParams = new URLSearchParams(window.location.search);

        let provinceId = searchParams.get('provinceId');
        let developmentName = searchParams.get('developmentName');

        if ((developmentName === null|| typeof (developmentName) === 'undefined' || developmentName === "" )
            && (provinceId === null || typeof (provinceId) === 'undefined' || parseFloat(provinceId) <= 0) ){
            setIsFeatured(true);
        } else {
            setIsFeatured(false);
            onPageIdChange(1);
        }

        if (provinceId !== null && typeof (provinceId) !== 'undefined' && parseFloat(provinceId) > 0) {
            setProvinceId(parseFloat(provinceId));
            setDebouncedProvinceId(parseFloat(provinceId));    
        } 

        if (developmentName !== null && typeof (developmentName) !== 'undefined') {
            if (developmentName !== "") {
                setDevelopmentName(developmentName);
                setDebouncedDevelopmentName(developmentName);
                handleUpdateQueryString("developmentName", developmentName);
            } else {
                handleUpdateQueryString("developmentName", "");              
            }
        }
    }

    //Skeleton Section
    //skeleton Timer
    useEffect(() => {
        // Simulate a data fetch or initialization
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200); // Replace with actual loading logic

        return () => clearTimeout(timer);
    }, []);

    //Skeleton HTML render
    const DesktopSkeleton = (
        <div style={{ background: 'white' }}>

            <Container className="full-width-container text-align-left" sx={{
                paddingTop: {
                    xs: '2.5rem',
                    md: '3rem'
                },
                paddingBottom: {
                    xs: '2.5rem',
                    md: '3rem'
                },
            }}>

                <Stack direction="column" spacing={3}>
                    <Skeleton variant="rectangular" width={150} height={24} />
                </Stack>
            </Container>
        </div>
    );

    const MobileSkeleton = (
        <div style={{ background: 'white' }}>

            <Container sx={{
                paddingTop: {
                    xs: '2.5rem',
                    md: '3rem'
                },
                paddingBottom: {
                    xs: '2.5rem',
                    md: '3rem'
                },
            }}>

                <Stack direction="column" spacing={3}>
                    <Hidden mdDown>
                        <Skeleton variant="rectangular" width={150} height={24} />
                        <Card sx={{ padding: '1.5rem', borderRadius: '1rem', width: '100%' }}>

                            <Stack direction="column" spacing={2}>

                                <Skeleton variant="rectangular" width={150} height={24} />

                                <Paper className="light" elevation={0} component="form" sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', margin: 'auto', height: 'auto', minHeight: '1.4375em', padding: '.5rem', borderRadius: '4px', maxWidth: '554px', width: '100%' }}>

                                    <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }}>

                                        

                                        <Box sx={{ width: '100%' }}>
                                            <InputBase placeholder={t('Search_DevelopmentName')} />
                                        </Box>

                                        {/* Search Button */}
                                        {/* <IconButton role="button" aria-label="search">
                                            <SearchIcon />
                                        </IconButton> */}

                                    </Stack>

                                </Paper>

                                <FormControl sx={{ minWidth: 120 }}>

                                    <Select
                                        value={provinceId}
                                        onChange={handleProvinceIdChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        label={t('Search_Province')}
                                    >
                                        <MenuItem value={-1}>{t('Search_Province')}</MenuItem>
                                        {provinces && provinces.length > 0 && provinces.map((province) => (
                                            <MenuItem key={province.ProvinceID} value={province.ProvinceID}>{province.ProvinceName}</MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>


                                <Button
                                    role="button"
                                    aria-label={t('Search_Search')}
                                    className="primary-button"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '100%' }}
                                >
                                    {t('Search_CmdSearch')}
                                </Button>

                            </Stack>

                        </Card>
                    </Hidden>
                </Stack>
            </Container>
        </div>
    );

    // if (isLoading) {
    //     return (
    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    //             <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
    //         </div>
    //     );
    // }

    if (loading) {
        return (
            <>
                <Hidden lgDown>
                    {DesktopSkeleton}
                </Hidden>
                <Hidden lgUp>
                    {MobileSkeleton}
                </Hidden>
            </>
        );
    }




    return (
        <>
            <MetaTags metaTags={{
                title: t('Development_Developments'),
                metaTitle: t('Development_Developments'),
                metaDescription: t('Development_Developments'),
                metaKeywords: '',
                image: '',
                url: window.location.href,
                type: 'article',
                sitename: siteSettings?.RegionName, 
            }}
            />


            {/* Top Container */}
            <div style={{ background: 'white' }}>

                <Container className="full-width-container text-align-left" sx={{
                    paddingTop: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                    paddingBottom: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                }}>

                    <Stack direction="column" spacing={3}>

                        <Hidden mdDown>

                            <Typography className="h2" variant="h1">{t('Search_FindNewDevelopmentsInYourArea')}</Typography>

                        </Hidden>

                        {/* Mobile Search Layout */}
                        <Hidden mdUp>

                            <Card sx={{ padding: '1.5rem', borderRadius: '1rem', width: '100%' }}>

                                <Stack direction="column" spacing={2}>

                                    <Typography className="h6" variant="h6" textAlign="center">{t('Search_FindNewDevelopmentsInYourArea')}</Typography>

                                    <Paper className="light" elevation={0} component="form" sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', margin: 'auto', height: '56px', display: 'flex', alignItems: 'center', minHeight: '1.4375em', padding: '1rem', borderRadius: '4px', maxWidth: '1080px', width: '100%' }}>

                                        <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }}>

                                            <Box sx={{ width: '100%' }}>
                                                <InputBase
                                                    placeholder={t('Search_DevelopmentName')}
                                                    aria-label={t('Search_DevelopmentName')}
                                                    value={developmentName}
                                                    onChange={handleDevelopmentNameChange}                                             
                                                />
                                            </Box>

                                        </Stack>

                                    </Paper>

                                    <FormControl sx={{ minWidth: 120 }}>

                                        <Select
                                            value={provinceId}
                                            // label={t('Search_Province')}
                                            onChange={handleProvinceIdChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={-1}>{t('Search_Province')}</MenuItem>
                                            {provinces && provinces.length > 0 && provinces.map((province) => (
                                                <MenuItem key={province.ProvinceID} value={province.ProvinceID}>{province.ProvinceName}</MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>


                                    <Button
                                        role="button"
                                        aria-label={t('Search_Search')}
                                        className="primary-button searchbar-button"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{ width: '100%' }}
                                        onClick={handleDevelopmentSearch}
                                    >
                                        <Typography>{t('Search_CmdSearch')}</Typography>
                                    </Button>

                                </Stack>

                            </Card>

                        </Hidden>

                        {/* Desktop Search Layout */}
                        <Hidden mdDown>
                            <Stack direction="row" spacing={2} alignItems="center">

                                <Paper className="light" elevation={0} component="form" sx={{ margin: 'auto', padding: '.5rem 1rem', borderRadius: '28px', width: '30%' }}>

                                    <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%', minHeight: '40px' }}>

                                        <Box sx={{ width: '100%' }}>
                                            <InputBase sx={{width: '100%'}}
                                                placeholder={t('Search_DevelopmentName')}
                                                aria-label={t('Search_DevelopmentName')}
                                                value={developmentName}
                                                onChange={handleDevelopmentNameChange}
                                            />
                                        </Box>

                                        <Box>

                                            <FormControl fullWidth>
                                                <Select
                                                    labelId='development-province-dropdown'
                                                    label={t('Search_Province')}
                                                    inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    value={provinceId}
                                                    onChange={handleProvinceIdChange}
                                                    variant="standard"
                                                >
                                                    <MenuItem value={-1}>{t('Search_Province')}</MenuItem>
                                                    {provinces && provinces.length > 0 && provinces.map((province) => (
                                                        <MenuItem key={province.ProvinceID} value={province.ProvinceID}>{province.ProvinceName}</MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>

                                        </Box>

                                    </Stack>

                                </Paper>
                                
                                {/* New search button */}
                                <IconButton role="button" aria-label="search" onClick={handleDevelopmentSearch} className='searchbar-button' sx={{ width: '48px', height: '48px'}}>
                                    <SearchIcon />
                                </IconButton>

                            </Stack>

                        </Hidden>

                    </Stack>

                </Container>

            </div>

            {/* Middle Container */}

            <div className="light">

                <Container className="full-width-container text-align-left" sx={{
                    paddingTop: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                    paddingBottom: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                }}>

                    <Stack direction="column" spacing={3}>

                        <DevelopmentSearchDevelopmentList
                            debouncedProvinceId={debouncedProvinceId}
                            debouncedDevelopmentName={debouncedDevelopmentName}
                            pageId={pageId}
                            totalRecordsPerPage={totalRecordsPerPage}
                            onTotalMatchCount={handleTotalMatchCount}
                            onTotalPageCount={handleTotalPageCount}
                            siteSettings={siteSettings}
                            lookupList={lookupList}
                            regionProvinceList={regionProvinceList}
                            onPageIdChange={onPageIdChange}
                            isFeatured={isFeatured}
                        />

                        {/* Total Matches and Pagniation do not show on Featured Development */}
                        {!isFeatured && (
                            <>
                            <Stack
                                    direction={{ xs: 'column', md: 'row' }}
                                    sx={{ marginTop: '1.5rem' }}
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >

                                {/* Pagination (component)*/}
                                {totalMatchCount > totalRecordsPerPage && (
                                    <Pagination count={totalPageCount} shape="rounded" onChange={handlePaginationChange} />
                                )}
                                
                                {(totalMatchCount > 0) && (
                                    <Typography className="body1-medium" variant="body1" sx={{ fontFamily: 'var(--font-family) !important' }}>
                                        {t('ProfileSearch_TotalMatches')} {formatNumberWithRegionSetting(totalMatchCount, siteSettings)} 
                                        </Typography>
                                )}
                                </Stack>
                            </>
                        )}


                    </Stack>
                </Container>

            </div>

        </>
    );
};

export default DevelopmentSearch;
