import LookupItem from '../../../types/LookupItem';

function getLookupTranslationById(uid: string, lookupList: LookupItem[]): string {
  try {
    // Validate that lookupList is an array
    if (!Array.isArray(lookupList)) {
      console.error('getLookupTranslationById Error: lookupList is not an array', lookupList);
      return uid; // Fallback: Return the uid as the "translation"
    }

    const lookupItem = lookupList.find(l => l.ItemName === uid);

    // If no matching item found, return uid as a fallback
    return lookupItem ? lookupItem.Translation : uid;
  } catch (e) {
    console.error('Error in getLookupTranslationById:', e);
    return uid;
  }
}

function getLookupTranslationByIds(uids: string[], lookupList: LookupItem[]) {
  const lst = [];

  // Validate that lookupList is an array
  if (!Array.isArray(lookupList)) {
    console.error('getLookupTranslationByIds Error: lookupList is not an array', lookupList);
    // Return a list of items using the uid as both Value and DisplayValue
    return uids.map(uid => ({
      UID: uid,
      Value: uid,
      DisplayValue: uid
    }));
  }

  for (const UID of uids) {
    const value = getLookupTranslationById(UID.toString(), lookupList);
    lst.push({
      UID: UID,
      Value: value,
      DisplayValue: value,
    });
  }

  return lst;
}

function getListingSearchFilterLookupItemList(uidLstStr: string, lookupList: LookupItem[]) {
  const lst = [];
  const uids = uidLstStr.split(',');

  // Validate that lookupList is an array
  if (!Array.isArray(lookupList)) {
    console.error('getListingSearchFilterLookupItemList Error: lookupList is not an array', lookupList);
    return uids.map(uid => ({
      UID: uid,
      Value: uid,
      DisplayValue: uid
    }));
  }

  for (const UID of uids) {
    const value = getLookupTranslationById(UID.toString(), lookupList);
    lst.push({
      UID: UID,
      Value: value,
      DisplayValue: value,
    });
  }

  return lst;
}

function getListingSearchFilterMacroPropertyTypeList(uids: string[], lookupList: LookupItem[]) {
  let lst = [];

  // Validate that lookupList is an array
  if (!Array.isArray(lookupList)) {
    console.error('getListingSearchFilterMacroPropertyTypeList Error: lookupList is not an array', lookupList);
    // Return a list of items using the uid as both Value and DisplayValue
    lst = uids.map(uid => ({
      MacroPropertyTypeUID: uid,
      Value: uid,
      DisplayValue: uid,
    }));
  } else {
    for (const uid of uids) {
      const value = getLookupTranslationById(uid.toString(), lookupList);
      lst.push({
        MacroPropertyTypeUID: uid,
        Value: value,
        DisplayValue: value,
      });
    }
  }

  // Sort by DisplayValue if it’s an array of objects with a DisplayValue string.
  return lst.sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue));
}

export { 
  getLookupTranslationById, 
  getLookupTranslationByIds, 
  getListingSearchFilterLookupItemList, 
  getListingSearchFilterMacroPropertyTypeList 
};
