import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Hidden, Container, Paper, CircularProgress } from '@mui/material';
import { fetchLookupTranslations } from '../../services/TranslationService';
import { fetchAgentDataByAgentId } from '../../services/ProfileService';
import { fetchListingCountByAgentId } from '../../services/ListingService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { HITNAMES, TENANTS } from '../../constants/constants';
import { Agent, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { ProfileMetaTags, ProfileAgentCardMobile, ProfileAgentCard, ProfileAgentMain } from '../../components/profile/ProfileComponentsIndex';
import { hitService } from '../../services/HitService';
import HitType from '../../types/Hit';


// SEO Shortlink Pattern
interface AgentRouteParams extends Record<string, string | undefined> {
    languagetag: string;
    pagetype: string;
    countryorregion: string;
    cityorlozalzone: string;
    agentname: string;
    agentid: string;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

interface AgentInterface {
    agentId?: any;


}

const AgentProfile: React.FC<AgentInterface> = (agentInterface) => {

    // Parameters Declaration
    const params = useParams<AgentRouteParams>();
    const navigate = useNavigate(); // Redirects to OfficeProfile or ProfileSearch page when IsSalesAssociate is 0 or AgentName is empty or Agent is disabled/hidden
    const { i18n } = useTranslation();

    // Hook Declaration
    const [agent, setAgent] = useState<Agent>();
    const [listingCount, setListingCount] = useState(0);
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const hitProcessedRef = useRef(false);
    // UseEffect Data Loading
    useEffect(() => {

        const fetchAgentData = async () => {
            setIsLoading(true);
            const searchParams = new URLSearchParams(window.location?.search || '');
            let agentId = params.agentid ?? params.url;
            if (searchParams.has('AgentID')) {
               agentId = searchParams.get('AgentID');
            }
          
            if (agentInterface.agentId !== undefined) {
                agentId = agentInterface.agentId;
            }
            if (agentId !== undefined && agentId !== null) {

                var agentData = await fetchAgentDataByAgentId(agentId);
                if (agentData === null || agentData.AgentId <= 0 || agentData.OfficeId <= 0) {
                    let navigateUrl = "/ProfileSearch?searchType=agent&page=1";
                    if (siteSettings && siteSettings.CountryID > 0)
                        navigateUrl = navigateUrl + "&countryId=" + siteSettings.CountryID;
                    navigate(navigateUrl);
                    return null;
                }

                // To Do: Change IsSalesAssociate to Category to match REP needs
                if (agentData.Category !== 1 || agentData.AgentName === null || agentData.AgentName === "") {
                    navigate("/office/" + agentData?.OfficeId.toString());
                    return null;
                }

                setAgent(agentData);

                if (!hitProcessedRef.current) {
                    const hitData: HitType = {
                        TenantId: agentData.TenantId,
                        RegionId: agentData.RegionId,
                        LanguageCode: i18n.language,
                        OfficeId: agentData.OfficeId,
                        AgentId: agentData.AgentId,
                        SiteType: siteSettings?.SiteType ?? "",
                        HitType: HITNAMES.PUBAGENTFULL,
                    }
                    hitService(hitData);
                    hitProcessedRef.current = true;
                }


                if (agentData != null) {
                    var listingCountRes = await fetchListingCountByAgentId(agentData.AgentId);
                    setListingCount(listingCountRes['@odata.count']);
                }
                setIsLoading(false);
            } else {
                navigate("/ProfileSearch");
                return null;
            }
        };

        const loadSiteDataSource = async () => {
            try {
                await fetchLookupTranslations().then((lookupList) => {
                    if (lookupList == null) {
                        console.log("lookupList loads nothing");
                    } else {
                        setLookupList(lookupList);
                    }
                });

                await fetchSiteSettings().then((siteSettings) => {
                    if (siteSettings == null) {
                        console.log("siteSettings loads nothing");
                    } else {
                        setSiteSettings(siteSettings);
                    }
                });

            } catch (error) {
                console.error('Agent Profile: failed to load site data source', error);
            }
        };

        loadSiteDataSource();
        fetchAgentData();

    }, [i18n.language]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
            </div>
        );
    }

    return (

        <div>
            {/* To Do: SEO Meta Tags via Helmet */}
            <ProfileMetaTags profileMetaTags={{ agent: agent, lookupList: lookupList, siteSettings: siteSettings }} />


            {/* Top container */}
            <div>

                {/* Inner container */}
                <Container className="text-align-left light" sx={{
                    paddingTop: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                    paddingBottom: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                }}>

                    {/* Agent Card On Mobile */}
                    <Hidden mdUp>
                        <ProfileAgentCardMobile profileAgentCard={{ agent: agent, lookupList: lookupList, siteSettings: siteSettings, listingCount: listingCount }} />
                    </Hidden>

                    {/* Agent Card On Desktop */}
                    <Hidden mdDown>
                        <ProfileAgentCard profileAgentCard={{ agent: agent, lookupList: lookupList, siteSettings: siteSettings, listingCount: listingCount }} />
                    </Hidden>

                </Container>

            </div>

            {/* Middle container*/}
            <div style={{ backgroundColor: 'white' }}>
                <Container className="text-align-left" sx={{
                    paddingTop: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                    paddingBottom: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                }}>
                    <ProfileAgentMain profileAgentMain={{ agent: agent, lookupList: lookupList, siteSettings: siteSettings, listingCount: listingCount }} />
                </Container>
            </div>


            {/* Bottom container */}
            {/* To Do : Recently sold rented listed carousels component in Phase II
            {agent && <Carousels data={agent} />}
            */}

        </div>
    );
};
export default AgentProfile;

