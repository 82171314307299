import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack, Typography, Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { formatCurrency, formatNumberWithRegionSetting } from '../utils/helper/FormatNumberHelper';
import SiteSettings from '../../types/Settings/SiteSettings';

const NumberFormatting = (num: any): string => {
    if (num === null) {
        return ''
    }

    const parsedNum = parseFloat(num);
    return Number.isInteger(parsedNum) ? parsedNum.toString() : parsedNum.toFixed(1);
};

interface Props {
    listingDetail: ListingDetail;
    lookupList: LookupItem[];
    siteSettings: SiteSettings | undefined;
}

const ListingAttributes: React.FC<Props> = ({ listingDetail, lookupList, siteSettings }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const typographyClassName = isMobile ? "body1-medium" : "body1-medium";
    const typographyVariant = isMobile ? "body1" : "body1";

    const ignoredFields = [
        'NumberOfBedrooms',
        'TotalArea',
        'LotSize2',
        'ParkingSpaces',
        'LivingArea',
        'NumberOfBathrooms',
        'LotSize',
        'BuiltArea',
        'YearBuilt',
        'FloorLevelNumber',
        'MonthBuiltUID'
    ];

    if (
        listingDetail.NumberOfUnderGroundFloors === null &&
        listingDetail.ApartmentNumber === null &&
        listingDetail.NumberOfToiletRooms === null &&
        listingDetail.CubicVolume === null &&
        listingDetail.BuildingName === null &&
        listingDetail.BuildingManagementFormUID === null &&
        listingDetail.BuildingManagementStyleUID === null &&
        listingDetail.OrientationUID === null &&
        listingDetail.BuildingConstructionTypeUID === null &&
        listingDetail.TaxYear === null &&
        listingDetail.TaxAmount === null &&
        listingDetail.PrivatelyOwned === null &&
        listingDetail.CommPresentUse === null &&
        listingDetail.CommFutureUse === null &&
        listingDetail.CommZoning === null &&
        listingDetail.CommTypeOfGoods === null &&
        listingDetail.CommBuildingFrontage === null &&
        listingDetail.CommElevators === null &&
        listingDetail.CommClearanceHeight === null &&
        listingDetail.CommLand === null &&
        listingDetail.LeaseArea === null &&
        listingDetail.IncomePerYear === null &&
        listingDetail.CommResidualStock === null &&
        listingDetail.CommResidualStockValue === null &&
        listingDetail.NumberOfFloors === null &&
        listingDetail.FloorLevelNumber === null &&
        listingDetail.FloorNumberUID === null
    ) {
        return null;
    }

    return (
        <div>

            <Stack id="listing-attributes" direction="column" spacing={2} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                {/* Number of Number Of Floors */}
                {listingDetail.NumberOfFloors !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_NumberOfFloors")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {NumberFormatting(listingDetail.NumberOfFloors)}
                        </Typography>
                    </Stack>
                )}

                {/* Number of FloorNumber */}
                { (listingDetail.FloorLevelNumber !== null || (listingDetail.FloorNumberUID  !== null && listingDetail.FloorNumberUID > 0) )  && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">      
                            {t("ListingFull_FloorLevel")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.FloorLevelNumber !== null ? NumberFormatting(listingDetail.FloorLevelNumber) : ""}
                            {(listingDetail.FloorLevelNumber !== null && (listingDetail.FloorNumberUID  !== null && listingDetail.FloorNumberUID > 0)) ? "," : ""}
                            {(listingDetail.FloorNumberUID  !== null && listingDetail.FloorNumberUID > 0) ? getLookupTranslationById(listingDetail.FloorNumberUID.toString(), lookupList): ""}
                        </Typography>
                    </Stack>
                )}

                {/* Number of Underground Floors */}
                {listingDetail.NumberOfUnderGroundFloors !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("Listing_NumberOfUndergroundFloors")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {NumberFormatting(listingDetail.NumberOfUnderGroundFloors)}
                        </Typography>
                    </Stack>
                )}

                {/* Apartment/Unit/Room # */}
                {listingDetail.ApartmentNumber !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_AptUnitRoom")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.ApartmentNumber}
                        </Typography>
                    </Stack>
                )}

                {/* Toilet Rooms */}
                {listingDetail.NumberOfToiletRooms !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_TotalToilet")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.NumberOfToiletRooms}
                        </Typography>
                    </Stack>
                )}

                {/* Cubic Volume */}
                {/* To Do: Site Setting:  DefaultRegionalUnitOfMeasure */}
                {listingDetail.CubicVolume !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CuM")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {formatNumberWithRegionSetting(listingDetail.CubicVolume, siteSettings)}
                        </Typography>
                    </Stack>
                )}

                {/* Building Name */}
                {listingDetail.BuildingName !== null && (
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("Listing_BuildingName")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.BuildingName}
                        </Typography>
                    </Stack>
                )}

                {/* Building Management Form */}
                {listingDetail.BuildingManagementFormUID !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("Listing_BuildingManagementForm")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {getLookupTranslationById(listingDetail.BuildingManagementFormUID.toString(), lookupList)}
                        </Typography>
                    </Stack>
                )}

                {/* Building Management Style */}
                {listingDetail.BuildingManagementStyleUID !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("Listing_BuildingManagementStyle")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {getLookupTranslationById(listingDetail.BuildingManagementStyleUID.toString(), lookupList)}
                        </Typography>
                    </Stack>
                )}

                {/* Orientation */}
                {listingDetail.OrientationUID !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("Listing_Orientation")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {getLookupTranslationById(listingDetail.OrientationUID.toString(), lookupList)}
                        </Typography>
                    </Stack>
                )}

                {/* Building Construction Type */}
                {listingDetail.BuildingConstructionTypeUID !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("Listing_BuildingConstructionType")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {getLookupTranslationById(listingDetail.BuildingConstructionTypeUID.toString(), lookupList)}
                        </Typography>
                    </Stack>
                )}

                {/* Tax Year */}
                {listingDetail.TaxYear !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_TaxYear")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {NumberFormatting(listingDetail.TaxYear)}
                        </Typography>
                    </Stack>
                )}

                {/* Tax Amount */}
                {listingDetail.TaxAmount !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_TaxAmount")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {NumberFormatting(listingDetail.TaxAmount)}
                        </Typography>
                    </Stack>
                )}

                {/* Privately Owned */}
                {listingDetail.PrivatelyOwned !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_Owner")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {t("ListingFull_lblPrivate")}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Present Use */}
                {listingDetail.CommPresentUse !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommPresentUse")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommPresentUse}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Future Use */}
                {listingDetail.CommFutureUse !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommFutureUse")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommFutureUse}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Zoning */}
                {listingDetail.CommZoning !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommZoning")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommZoning}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Type Of Goods */}
                {listingDetail.CommTypeOfGoods !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommTypeOfGoods")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommTypeOfGoods}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Building Frontage */}
                {listingDetail.CommBuildingFrontage !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommBuildingFrontage")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommBuildingFrontage}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Elevator */}
                {listingDetail.CommElevators !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommElevators")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommElevators}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Clearance Height */}
                {listingDetail.CommClearanceHeight !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommClearanceHeight")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommClearanceHeight}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Land */}
                {listingDetail.CommLand !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommLand")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommLand}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Lease SqFt */}
                {/* To Do: a. Site Setting:  DefaultRegionalUnitOfMeasure b. Number Formatting base on Site Setting*/}
                {listingDetail.LeaseArea !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommLeaseSqM")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {formatNumberWithRegionSetting(listingDetail.LeaseArea, siteSettings)}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Income Per Year */}
                {listingDetail.IncomePerYear !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommIncomePerYear")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {formatCurrency(listingDetail.IncomePerYear, '', siteSettings)}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Residual Stock */}
                {listingDetail.CommResidualStock !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommResidualStock")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommResidualStock}
                        </Typography>
                    </Stack>
                )}

                {/* Commercial Residual Stock Value */}
                {listingDetail.CommResidualStockValue !== null && (
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                        <Typography className="secondary-text">
                            {t("ListingFull_CommResidualStockValue")}
                        </Typography>
                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {listingDetail.CommResidualStockValue}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </div>
    );
};

export default ListingAttributes;
