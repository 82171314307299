import React, { useEffect, useState } from 'react';
import { Box, Hidden, IconButton, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon } from '@mui/icons-material';
import FavoriteItem from '../../types/Model/FavoriteItem';
import { LookupItem, SiteSettings } from '../../types/TypesIndex';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { formatCurrency } from '../utils/helper/FormatNumberHelper';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { RECENTLY_VIEWED_ITEMS } from '../../constants/constants';

interface Props {
    limitList?: number;
    lookupList: LookupItem[];
}

const ListingRecentlyViewed: React.FC<Props> = ({ limitList = RECENTLY_VIEWED_ITEMS.MAX, lookupList }) => {
    //{/* Parameters Declaration */ }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    //{/* Hook Declaration */ }
    const [listingRecentlyViewed, setListingRecentlyViewed] = useState<FavoriteItem[]>([]);
    const { t, i18n } = useTranslation();
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();

    //{/* Event Handlers */ }
    const removeFromListingRecentlyViewed = (index: number) => {
        // Create a copy of the favorites array
        const updatedListingRecentlyViewed = [...listingRecentlyViewed];
        // Remove the item at the specified index
        updatedListingRecentlyViewed.splice(index, 1);
        // Update the favorites state
        setListingRecentlyViewed(updatedListingRecentlyViewed);
        // Update favorites in localStorage
        localStorage.setItem('listingRecentlyViewed', JSON.stringify(updatedListingRecentlyViewed));
    };



    useEffect(() => {

        const loadSiteSettings = async () => {
            await fetchSiteSettings().then((siteSettings) => {
                if (siteSettings == null) {
                    console.log("siteSettings loads nothing");
                } else {
                    setSiteSettings(siteSettings);
                }
            });
        };
        loadSiteSettings();

    }, []);


    useEffect(() => {
        const savedListingRecentlyViewedAll: FavoriteItem[] = JSON.parse(localStorage.getItem('listingRecentlyViewed') || '[]');
        const savedListingRecentlyViewed = savedListingRecentlyViewedAll.slice(0, limitList);
        setListingRecentlyViewed(savedListingRecentlyViewed);
    }, []);

    if (listingRecentlyViewed == null || listingRecentlyViewed.length == 0)
        return null;

    return (
        <div>

            {listingRecentlyViewed !== null && listingRecentlyViewed.length > 0 && (

                <Stack id="listingRecentlyViewed" direction="column" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                    <Typography className="h4" variant="h4">
                        {t('RecentlyViewed_Header')}
                    </Typography>

                    <Stack direction="column" spacing={3}>
                        {listingRecentlyViewed.map((item: FavoriteItem, index: number) => (

                            <Box key={index} position="relative">

                                <Stack direction="row" spacing={2} alignItems="center" useFlexGap flexWrap="wrap" sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                                    <Link underline="none" target="_blank" href={`${item.id}`} sx={{ textDecoration: 'none !important' }}>
                                        <Stack direction="row" spacing={2} alignItems="center" useFlexGap sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>

                                            <img style={{ width: isMobile ? '100%' : '35%', borderRadius: '1rem', minWidth: isMobile ? '100%' : '35%', maxWidth: isMobile ? '100%' : '35%' }} src={item.imagePath} alt={item.fullAddress} />

                                            <Stack direction="column" spacing={1}>

                                                <Typography className="body1-medium" variant="body1">
                                                    {item.price === 0 ? t("ListingFull_UponRequest") : <span dangerouslySetInnerHTML={{ __html: formatCurrency(item.price, item.listingCurrency, siteSettings) }} />}

                                                </Typography>

                                                {item.propertyType && (
                                                    <Typography className="body2 secondary-text" variant="body2">
                                                        {getLookupTranslationById(item.propertyType.toString(), lookupList)}
                                                    </Typography>
                                                )}

                                                <Typography className="body2 secondary-text" variant="body2">
                                                    {item.fullAddress}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Link>

                                    {/* <Hidden smDown>
                                        <IconButton aria-label="Close" role="button" onClick={() => removeFromListingRecentlyViewed(index)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Hidden>

                                    <Hidden smUp>
                                        <IconButton className="card-button-icons" aria-label="Close" role="button" onClick={() => removeFromListingRecentlyViewed(index)} sx={{ position: 'absolute', top: '16px', right: '16px' }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Hidden> */}
                                </Stack>
                            </Box>
                        ))}
                    </Stack>
                </Stack>
            )}

        </div>
    );
};

export default ListingRecentlyViewed;
