import React, { useState, useEffect, useCallback } from 'react';
import MetaTags from '../components/utils/seo/MetaTags';
import { useContent } from './ContentContext';
import { useTranslation } from 'react-i18next';
import LanguageMenu from '../components/utils/LanguageMenu';
import parse from 'html-react-parser';
import { useNavigate, useParams } from 'react-router-dom';
import OfficeProfile from './profile/OfficeProfile';
import Listing from '../pages/listing/ListingSearch';
import { SiteSettings } from '../types/TypesIndex';
import { fetchSiteSettings } from '../services/SiteSettingService';
import AgentProfile from './profile/AgentProfile';
import ListingDetail from './listing/ListingDetails';
import { KeyboardReturnOutlined } from '@mui/icons-material';
import { set } from 'lodash';
import TeamProfile from './profile/TeamProfile';

const isMLSID = (input: string): boolean => {
    const regex = /^\d{9}-\d{3}$|^\d{10}-\d{2}$|^\d{8}-\d{8}$|^\d{5}-\d{4}$|^\d{9}-\d{1}$|^\d{9}-\d{2}$/;
    return regex.test(input.trim());
};
interface ListingRouteParam extends Record<string, string | undefined> {

    content: string;
}

const CMSPage: React.FC = () => {
    const manageContent = useContent();
    const [htmlContent, setHtmlContent] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [SourceID, setSourceID] = useState<string>(' ');
    const [metaData, setMetaData] = useState({ title: '', description: '' });
    const [showOfficeProfile, setShowOfficeProfile] = useState(false);
    const [showAggentProfile, setshowAggentProfile] = useState(false);
    const [showListings, setshowListings] = useState(false);
    const [showProperty, setshowProperty] = useState(false);
    const [showTeam,setShowTeamProfile] = useState(false);
    const [showcms, setshowcms] = useState(false);
    const params = useParams<ListingRouteParam>();

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [parameter, setParameter] = useState<string>('');

    const toggleDirection = () => {
        // Your logic to toggle language direction (LTR/RTL)
    };

    const toggleLanguages = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const fetchCMSContent = async (language: string, path: string) => {
      
        try {
            const response = await fetch(`/cms/${language}/${path.replace('.aspx','')}?aposRefresh`);
            if (!response.ok) {
                navigate('/');
            }
            let html = await response.text();

            if (html.indexOf('ConfigRegion') > 0) {
                navigate('/');
            }
            // Remove <html>, <hea and <body> tags
            html = html.replace(/<\/?(html|head|body)[^>]*>/g, '');
            const div = document.createElement('div');
            div.innerHTML = html;
            const copyrightYearElement = div.querySelector('#copyrightYear');
            if (copyrightYearElement) {
                copyrightYearElement.textContent = new Date().getFullYear().toString();
            }
            setHtmlContent(div.innerHTML);
            setShowOfficeProfile(false);
        } catch (error) {
            
            setShowOfficeProfile(true);
            handleNotFound();
        } finally {
            
            manageContent.setFooter(true);
            manageContent.setHeader(true);
        }
    };

    const handleNotFound = async () => {
        if (window.tenantid !== undefined) {
            navigate('/');
            //await fetchShortLink(window.tenantid, window.macroregionid);
        } else {
            await fetchConfigRegion();
        }
    };

    const fetchShortLink = useCallback(async (tenantid: number, macroregionid: number) => {
        let _data = params.content;
        let _search = window.location.search;

        const urlParams = new URLSearchParams(_search);
        const t = urlParams.get('t');

        switch (t) {
            case "P":
                setshowProperty(true);
                break;
            case "L":
                setshowListings(true);
                break;
            case "O":
                setShowOfficeProfile(true);
                break;
            case "A":
                setshowAggentProfile(true);
                break;
            case "T":
                setShowTeamProfile(true);
                break;
            default:
                break;
        }
        let mlsidPattern = /^(\d+-\d+)$/;
        if (window.tenantid === 6)
            mlsidPattern = /^(\d{9}-\d{3}|\d{10}-\d{2}|\d{8}-\d{8}|\d{5}-\d{4}|\d{9}-\d{1}|\d{9}-\d{2}|\d{9}-\d{4}|\d{9}-\d{5}|\d{9}-\d{6}|\d+-\d+)(\/.*)?$/;
        if (mlsidPattern.test(_data)) {
            setshowProperty(true);
            setSourceID(_data);
            return;
        }
        if (!showAggentProfile && !showListings && !showOfficeProfile && !showProperty) {
         
            if (_data) {
                _data = _data.split('/')[0];
                _data = _data.split('?')[0];
            }
            let filter = `content/TenantId eq ${tenantid} and content/MacroRegionId eq ${macroregionid}`;
            //let _languagePatterj
            // if (window.location.pathname.indexOf('pesquisa') > 0 || window.location.pathname.indexOf('search') > 0) {
            filter += ` and (content/SourceUrl eq '${getSlackFromURL(window.location.pathname)}' or content/SourceUrl eq '${getSlackFromURL(window.location.pathname)}/')`;
            //}
            //else 
            // {
            //    filter += ` and (content/SourceUrl eq '${params.content}' or content/SourceUrl eq '${params.content}/')`;
            // }
            const datarequest = {
                count: false,
                skip: 0,
                top: 1,
                searchMode: "any",
                queryType: "simple",
                select: "*",
                filter: filter,
            };
            const body = JSON.stringify(datarequest);
            try {
                const response = await fetch('/ShortLink', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }, body: body
                });
                const data = await response.json();
                const siteSettings: SiteSettings = await fetchSiteSettings();
                if (data.value.length > 0 && data.value[0]?.content?.RedirectURL) {
                    if (getSlackFromURL(getPathFromUrl()) === getSlackFromURL(data.value[0].content.RedirectURL)) {
                        navigate(`'/' +${window.location.host}`, { replace: true });
                        return;
                    }
                    switch (data.value[0].content.SourceType) {
                        case "office":
                            setShowOfficeProfile(true);
                            setSourceID(data.value[0].content.SourceId);
                            break;
                        case "agent":
                            setshowAggentProfile(true);
                            setSourceID(data.value[0].content.SourceId);
                            break;
                        case "listing":
                            setshowProperty(true);
                            setSourceID(data.value[0].content.SourceId);
                            break;
                        case "team":
                            setShowTeamProfile(true);
                            setSourceID(data.value[0].content.SourceId);
                            break;
                        default:
                            navigate('/' + getSlackFromURL(data.value[0].content.RedirectURL));
                            break;
                    }
                    return;
                } if ( (window.location.pathname.toLocaleLowerCase().indexOf('customerfeedback.aspx') > 0)) { 
                    navigate ('/listings');
                    return;
                }
                 if ((window.location.pathname.toLocaleLowerCase().indexOf('unsubscribe') > 0)) {
                    navigate('/');
                    return;
                } else {
                    let siteLanguages = siteSettings?.RegionSupportedLanguages?.map(l => l.FullLanguageCode);
                    let language = i18n.language ?? window.defaultlanguage;
                    if (!language || (siteLanguages && !siteLanguages.includes(language))) {
                        language = window.defaultlanguage ?? "en-US";
                        i18n.language = language;
                    }
                    fetchCMSContent(language, params.content);
                }
            } catch (error) {
                console.error('Error fetching short link:', error);
            }
        }

    }, [params.content, i18n.language, navigate, showAggentProfile, showListings, showOfficeProfile, showProperty]);

    const fetchConfigRegion = async () => {
        if (window.tenantid !== undefined) {
            await fetchShortLink(window.tenantid, window.macroregionid);
            return;
        }
        try {
            const response = await fetch('/ConfigRegion');
            const data = await response.json();
            window.regionDomain = data.regiondomain;
            window.regionid = data.regionid;
            window.macroregionid = data.macroregionid;
            window.tenantid = data.tenantid;
            window.theme = data.theme;
            window.defaultlanguage = data.defaultlanguage;
            await fetchShortLink(data.tenantid, data.macroregionid);
        } catch (error) {
            console.error('Error fetching config region:', error);
        }
    };

    const getPathFromUrl = () => {
        let path = window.location.pathname;
        if (path.endsWith('/')) {
            path = path.slice(0, -1);
        }
        return path;
    };

    const getSlackFromURL = (path: string) => {
        if (path.startsWith('/')) {
            path = path.substring(1);
        }
        if (path.endsWith('/')) {
            path = path.slice(0, -1);
        }
        return path;
    }

    useEffect(() => {
        const path = getPathFromUrl();
        console.log('Path from URL:', path);
        // You can use the path variable as needed
    }, []);

    useEffect(() => {
        fetchConfigRegion();
    }, [i18n.language]);

    useEffect(() => {
        fetchConfigRegion();
    }, [params.content, i18n.language, navigate, showAggentProfile, showListings, showOfficeProfile, showProperty]);

    const options = {
        replace: (domNode: any) => {
            if (domNode.name === 'div' && domNode.attribs.class && domNode.attribs.class.includes('dropdown locale-select')) {
                return <LanguageMenu data={{ toggleDirection: toggleDirection, toggleLanguages: toggleLanguages }} />;
            }
        },
    };

    return (
        <>
            <MetaTags metaTags={{
                title: metaData.title,
                metaTitle: metaData.title,
                metaDescription: metaData.description,
                metaKeywords: '',
                image: '',
                url: window.location.href
            }}
            />
            {showAggentProfile && <AgentProfile agentId={SourceID} />}
            {showOfficeProfile && <OfficeProfile officeid={SourceID} />}
            {showListings && <Listing />}
            {showProperty && <ListingDetail PropertyId={SourceID} />}
            {showTeam && <TeamProfile teamId={SourceID}/>}

            <> {parse(htmlContent, options)} </>


        </>
    );
};

export default CMSPage;
