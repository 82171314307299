import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { BackendModule, ReadCallback } from 'i18next';

class CustomFilterBackend extends HttpBackend implements BackendModule {
  constructor(services: any, options: HttpBackendOptions = {}) {
    super(services, options);
  }

  read(language: string, namespace: string, callback: ReadCallback): void {
    // Skip backend call if language code has less than 3 characters
    if (language.length < 3) {
      callback(null, {});
      return;
    }

    super.read(language, namespace, callback);
  }
}

export default CustomFilterBackend;