import React, { useEffect, useState } from 'react';
import { fetchAgentListData } from '../../services/ProfileService';
import { SiteSettings, AgentSearchResponse } from '../../types/TypesIndex';
import ProfileSearchAgentCard from './ProfileSearchAgentCard';
import { escapeSpecialCharacters, normalizeString } from '../utils/helper/DataFormatHelper';
import { TENANTS } from '../../constants/constants';



interface ProfileSearchAgentListProps {
    searchType?: string;
    country?: string;
    countryId?: number;
    //region?: string;
    //province?: string;
    //city?: string;
    //localZone?: string;
    //streetName?: string;
    name?: string;
    spokenLanguageUID?: string
    page?: number;
    onTotalMatchCount: (matchCount: number) => void;
    totalRecordsPerPage?: number;
    onTotalPageCount: (total: number) => void;
    siteSettings?: SiteSettings;
    onPageChange?: (page: number) => void;
}

const ProfileSearchAgentList: React.FC<ProfileSearchAgentListProps> = ({
    searchType,
    country,
    countryId,
    //region,
    //province,
    //city,
    //localZone,
    //streetName,
    name,
    spokenLanguageUID,
    page = 1,
    totalRecordsPerPage = 10,
    onTotalMatchCount,
    onTotalPageCount,
    siteSettings,
    onPageChange
}) => {

    // Parameters Declaration
    const searchParams = new URLSearchParams(window.location.search);
    const province = searchParams.get('Province');
    const city = searchParams.get('City');
    const localzone = searchParams.get('LocalZone');
    const streetName = searchParams.get('StreetName');

    // Hook Declaration 
    const [agents, setAgents] = useState<AgentSearchResponse | undefined>();
    // const [totalPages, setTotalPages] = useState(0);

    // Use Effect Declaration 
    useEffect(() => {

        const getFilter = () => {

            let filter = `content/TenantId eq ${window.tenantid}`;

           
            if (countryId && countryId > 0 && window.tenantid === TENANTS.REMAX) {
                filter = filter + (` and content/MacroRegionId eq ${countryId}`);
            }else{
                filter = filter + (` and content/MacroRegionId eq ${window.regionid}`);
            }
            

            // Location Filter
            if (province) {
                filter = filter + (` and  content/OfficeProvinceID eq ${province}`);
            }
            if (city) {
                filter = filter + (` and content/OfficeCityID eq ${city}`);
            }
            if (localzone) {
                filter = filter + (` and content/OfficeLocalZoneId eq ${localzone}`);
            }
            if (streetName) {
                filter = filter + (` and search.ismatch('"${escapeSpecialCharacters(streetName)}*"', 'content/OfficeAddress')`);
            }

            // Agent Name
            if (name && name.indexOf(' ') > -1) {
                filter = filter + (` and (search.ismatch('"${normalizeString(name)}*"', 'content/AgentName') or
                                          search.ismatch('"${normalizeString(name)}*"', 'content/LastName') or 
                                          search.ismatch('"${normalizeString(name)}*"', 'content/FirstName'))`);
            }
     // Agent Name
     if (name && name.indexOf(' ') === -1) {
        filter = filter + (` and (search.ismatch('${normalizeString(name)}*', 'content/AgentName') or
                                  search.ismatch('${normalizeString(name)}*', 'content/LastName') or 
                                  search.ismatch('${normalizeString(name)}*', 'content/FirstName'))`);
    }
            // Spoken Language
            if (spokenLanguageUID && spokenLanguageUID !== '') {
                filter = filter + (` and search.ismatch('${spokenLanguageUID}', 'content/SpokenLanguages')`);
            }

            // Common Filter a. Category eq 1: only get the IsSalesAssociate == 1, b. ExcludeAgentTraining = 0, c. Disabled = 0, d. AgentName not null, e. Hidden = 0 , f. IsDisabledOffice = 0
            filter = filter + ` and content/Category eq 1 and content/ExcludeAgentTraining eq false and content/Disabled eq false and content/IsRegionalOffice eq false and content/AgentName ne null and content/Hidden eq false and content/IsDisabledOffice eq false`;

            return filter;
        }

        const loadAgentList = async () => {

            const searchRequestBody = {
                count: true,
                skip: (page - 1) * totalRecordsPerPage,
                top: totalRecordsPerPage,
                searchMode: 'any',
                queryType: 'simple',
                search: '*',
                filter: getFilter(),
                orderby: 'content/LastName asc, content/FirstName asc'
            };


            try {
                const result = await fetchAgentListData(searchRequestBody);

                const totalRecords = result['@odata.count'];
                const totalPages = Math.ceil(totalRecords / (totalRecordsPerPage || 10));

                setAgents(result); 
                onTotalMatchCount(totalRecords); 
                onTotalPageCount(totalPages); 

                if (page > totalPages && totalPages > 0) {
                    onPageChange?.(1); 
                }

            } catch (error) {
                console.log("Render Agent List Error", error);
            }
        };

        loadAgentList();
    }, [searchType, country, countryId, province, city, localzone, streetName, name, spokenLanguageUID, page, onTotalMatchCount, totalRecordsPerPage, onTotalPageCount]);

    return (
        <>
            {agents && typeof (agents?.value) !== 'undefined' && agents?.value.map((item) => (
                <ProfileSearchAgentCard key={item.content.AgentId} agent={item.content} siteSettings={siteSettings} />
            ))}
        </>
    );
};

export default ProfileSearchAgentList;
