import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Layout from './pages/Layout';
import Header from './pages/Header';
import Footer from './pages/Footer';
import { LanguageProvider } from './pages/LanguageContext';
import './style/fonts.scss';
import './i18n/i18n';
import { Helmet } from 'react-helmet';
import { LoadingProvider } from './components/utils/LoadingContext';
import { fetchSiteSettings } from './services/SiteSettingService';
import SiteSettings from './types/Settings/SiteSettings';
import AppRoute from './route/AppRoute';
import { SearchProvider } from './reducer/searchreducer';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from './i18n/i18n';
import { RegionProvider } from './pages/RegionContext';
import { ContentContextProvider } from './pages/ContentContext';
import { Store } from 'redux';

interface RegionContextProp {
  regionDomain: string;
  regionId: number;
  macroRegionId: number;
  tenantId: number;
}

const App: React.FC = () => {
  const [direction, setDirection] = useState('ltr');
  const [hideToolBar, setHideToolBar] = useState(false);
  const [siteSettings, setSiteSettings] = useState<SiteSettings>();

  const toggleDirection = (data: string) => setDirection(data);
  const toggleHideToolBar = (hide: boolean) => setHideToolBar(hide);

  const loadBundleMarkup = async () => {
    try {
      const response = await fetch('/fetch-bundle-markup');
      const data = await response.text();

      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'text/html');

      const cssLink = doc.querySelector('link[rel="stylesheet"]');
      const jsScript = doc.querySelector('script[src]');

      if (cssLink && jsScript) {
        const linkTag = document.createElement('link');
        linkTag.href = (cssLink as HTMLLinkElement).href;
        linkTag.rel = 'stylesheet';
        document.head.appendChild(linkTag);

        const scriptTag = document.createElement('script');
        scriptTag.src = (jsScript as HTMLScriptElement).src;
        document.head.appendChild(scriptTag);
      }
    } catch (error) {
      console.error('Error fetching bundle markup:', error);
    }
  };

  const toggleLanguages = (data: string) => {};

  useEffect(() => {
    const loadSiteSettings = async () => {
      import('./style/global.scss');
      if (window.theme !== undefined) {
        import(`./style/${window.theme}/default.scss`);
      } else {
        fetch('/ConfigRegion')
          .then(response => response.json())
          .then(data => {
            window.regionDomain = data.regiondomain;
            window.regionid = data.regionid;
            window.macroregionid = data.macroregionid;
            window.tenantid = data.tenantid;
            window.theme = data.theme;
            import(`./style/${window.theme}/default.scss`);
          });
      }
    };

    loadSiteSettings();
  }, []);

  useEffect(() => {
    const html = document.documentElement;
    const body = document.body;

    html.setAttribute('dir', direction);
    body.setAttribute('dir', direction);
  }, [direction]);

  return (
    <>
      <Helmet>
        {window.theme !== undefined && (
          <link rel="icon" href={`/images/${window.theme}/favicon.ico`} title={siteSettings?.RegionName} />
        )}
        <title>{siteSettings?.RegionName}</title>
      </Helmet>

      <LoadingProvider>
        <SearchProvider>
          <LanguageProvider>
            <ContentContextProvider>
              <Provider store={store}>
                <RegionProvider>
                  <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
                    <Layout startingTheme="light">
                      <div dir={direction}>
                        <Header
                          toggleDirection={toggleDirection}
                          toggleLanguages={toggleLanguages}
                          
                        />
                      <AppRoute
                        />
                        <Footer />
                      </div>
                    </Layout>
                  </Router>
                </RegionProvider>
              </Provider>
            </ContentContextProvider>
          </LanguageProvider>
        </SearchProvider>
      </LoadingProvider>
    </>
  );
};

export default App;
