// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import TagManager from 'react-gtm-module';



// Function to fetch configuration
const fetchConfig = async () => {
  try {
    const response = await fetch('/ConfigRegion');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error loading configuration:', error);
    return null;
  }
};

// Main function to initialize the app
const initializeApp = async () => {
  const config = await fetchConfig();
  if (config) {
    const gtmId = config.gtmid || '';
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }

    // Set global variables
    window.regionDomain = config.regiondomain;
    window.regionid = config.regionid;
    window.macroregionid = config.macroregionid;
    window.tenantid = config.tenantid;
    window.theme = config.theme;
    window.defaultlanguage = config.defaultlanguage;
  } else {
    console.error('Failed to load configuration. The app may not function as expected.');
  }

  // Now render the React app
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

// Call the initializeApp function
initializeApp();
