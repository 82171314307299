import React, { useState, useEffect } from 'react';
import { Link, useMediaQuery, useTheme } from '@mui/material';
import { Box, IconButton, Hidden, Stack, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../utils/helper/FormatNumberHelper';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import FavoriteItem from '../../types/Model/FavoriteItem';
import SiteSettings from '../../types/Settings/SiteSettings';
import { FAVOURITES, PAGE_TYPE, TRANSACTION_TYPES } from '../../constants/constants';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import LookupItem from '../../types/LookupItem';
import { fetchLookupTranslations } from '../../services/TranslationService';
interface FavoritesListProps {
    limitList?: number;
    pageType?: string
}

const ListingFavouritesList: React.FC<FavoritesListProps> = ({ limitList = FAVOURITES.LIMIT, pageType = PAGE_TYPE.LISTING_DETAIL }) => {
    {/* Parameters Declaration */ }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    {/* Hook Declaration */ }
    const [favorites, setFavorites] = useState<FavoriteItem[]>([]);
    const { t, i18n } = useTranslation();
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    {/* Event Handlers */ }
    const removeFromFavorites = (index: number) => {
        // Create a copy of the favorites array
        const updatedFavorites = [...favorites];
        // Remove the item at the specified index
        updatedFavorites.splice(index, 1);
        // Update the favorites state
        setFavorites(updatedFavorites);
        // Update favorites in localStorage
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    };


    {/* UseEffect Data Loading */ }
    useEffect(() => {

        const loadSiteSettings = async () => {
            await fetchSiteSettings().then((siteSettings) => {
                if (siteSettings == null) {
                    console.log("siteSettings loads nothing");
                } else {
                    setSiteSettings(siteSettings);
                }
            });
            const lookupList: LookupItem[] = await fetchLookupTranslations();
            setLookupList(lookupList);

            loadSiteSettings();
        }
    }, []);

    // Load favorites from localStorage on initial render
    useEffect(() => {
        const savedFavoritesAll: FavoriteItem[] = JSON.parse(localStorage.getItem('favorites') || '[]');
        const savedFavorites = savedFavoritesAll.slice(0, limitList);
        setFavorites(savedFavorites);
    }, []);

    if ((favorites == null || favorites.length == 0) && pageType === PAGE_TYPE.LISTING_DETAIL)
        return null;

    return (
        <div>

            {/* If No Favourites Hide The Section*/}
            {favorites !== null && favorites.length > 0 && (

                <Stack id="favourites" direction="column" spacing={3} sx={{ ...(pageType === PAGE_TYPE.LISTING_DETAIL && { borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }), }}>

                    {/* Favourites Header on Listing Details Page*/}
                    {pageType === PAGE_TYPE.LISTING_DETAIL && (
                        <Typography className="h4" variant="h4">
                            {t('Favourites_Header')}
                        </Typography>
                    )}

                    {/* Favourites List*/}
                    <Stack direction="column" spacing={3}>
                        {favorites.map((item: FavoriteItem, index: number) => (

                            <Box key={index} position="relative">

                                <Stack direction="row" spacing={2} alignItems="center" useFlexGap flexWrap="wrap" sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                                    <Link underline="none" target="_blank" href={`${item.listingShortlink ? item.listingShortlink : item.id}`} sx={{ textDecoration: 'none !important' }}>
                                        <Stack direction="row" spacing={2} alignItems="center" useFlexGap sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>

                                            {/* Image */}
                                            <img style={{ width: isMobile ? '100%' : '35%', borderRadius: '1rem', minWidth: isMobile ? '100%' : '35%', maxWidth: isMobile ? '100%' : '35%' }} src={item.imagePath} alt={item.fullAddress} />

                                            {/* Price, Property Type, Address */}
                                            <Stack direction="column" spacing={1}>

                                                {/* Price */}
                                                <Typography className="body1-medium" variant="body1">
                                                    {item.price === 0 ? t("ListingFull_UponRequest") : <span dangerouslySetInnerHTML={{ __html: formatCurrency(item.price, item.listingCurrency, siteSettings) }} />}
                                                    {' '}
                                                    {item.price !== 0 && item.rentalPriceGranularityUID !== null && item.transactionTypeUID == TRANSACTION_TYPES.FORRENT.toString()
                                                        ? (getLookupTranslationById(item.rentalPriceGranularityUID.toString(), lookupList)) : ''}
                                                </Typography>

                                                {/* Property Type */}
                                                {item.propertyType && (
                                                    <Typography className="body2 secondary-text" variant="body2">
                                                        {item.propertyType}
                                                    </Typography>
                                                )}

                                                {/* Address */}
                                                <Typography className="body2 secondary-text" variant="body2">
                                                    {item.fullAddress}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Link>
                                    {/* Close Button - Hidden on Mobile */}
                                    <Hidden smDown>
                                        <IconButton aria-label="Close" role="button" onClick={() => removeFromFavorites(index)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Hidden>

                                    {/* Close Button - Shown on Mobile */}
                                    <Hidden smUp>
                                        <IconButton className="card-button-icons" aria-label="Close" role="button" onClick={() => removeFromFavorites(index)} sx={{ position: 'absolute', top: '16px', right: '16px' }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Hidden>
                                </Stack>
                            </Box>
                        ))}
                    </Stack>
                </Stack>
            )}


            {(favorites === null || favorites.length === 0) && pageType === "listingList" && (
                <Stack direction="column" spacing={3}>
                    <Typography className="body1" variant="body1">
                        {t("Favourites_NoFavourites")}
                    </Typography>
                </Stack>
            )}
        </div>
    );
};

export default ListingFavouritesList;
