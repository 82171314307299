import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ListingDetailData from '../../types/Listing/ListingDetailData';
import { format } from 'date-fns';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import LookupItem from '@/types/LookupItem';

interface Props {
    property: ListingDetailData;
    LookupList: LookupItem[];
}

const ListingEnergyRating: React.FC<Props> = ({ property, LookupList }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const ListingDetail = property?.value[0]?.content;
    {/* To Do: Add Site Settings */ }
    const allEnergyValues = [ListingDetail.MainHeatSourceUID, ListingDetail.EnergyRatingUID, ListingDetail.EnergyInsulationLookupListUID]
    const notShowEnergyRatingComponent = allEnergyValues && allEnergyValues.every(item => item === null);

    if (notShowEnergyRatingComponent) {
        return null;
    }
    return (
        <>
            {!notShowEnergyRatingComponent && (
                <Stack id="energy-rating" direction="column" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                    {/* Energy Rating Info */}
                    {/* To Do: Site Setting*/}
                    {/* {ListingDetail.EnergyRatingUID !== null && (
                        <Stack direction="row" spacing={2} alignItems="center" useFlexGap flexWrap="wrap" justifyContent="space-between">
                            <Typography className="h4" variant="h4">
                                {t('ListingList_EnergyEfficiency')}
                            </Typography>
                            <Stack direction="row" spacing={2} alignItems="center" useFlexGap flexWrap="wrap"> */}
                    {/* Energy Rating Logo */}
                    {/* The Relative image path example is "/CommonImages/RegionImages/0/EnergyRating/EnergyRating_A_minus.gif" */}
                    {/* To Do: Image Full Path is confirming by Ops, should be env setting */}
                    {/* <img alt="" title="" style={{ maxWidth: '60px' }} src={"https://www.remax.com.br" + ListingDetail.EnergyRatingImage} /> */}
                    {/* Energy Recommended Logo */}
                    {/* To Do: Adjust the Logo path /common/images/EnergyRatingRecommended_{0}.gif", RemaxRequestContext.LanguageCode */}
                    {/* {ListingDetail.ShowEnergyRatingRecommend !== null && (
                                    <img alt="" title="" style={{ maxWidth: '60px' }} src="https://www.remax.com.br/common/images/EnergyRatingRecommended_PTB.gif" />
                                )}
                            </Stack>
                        </Stack>
                    )}

                    <Stack direction="column" spacing={2}> */}
                    {/* Energy Certificate */}
                    {/* {ListingDetail.EnergyCertificateTypeUID !== null && (
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                                <Typography className={`${isMobile ? "body1-medium" : "h5"} secondary-text`} variant={isMobile ? "body1" : "h5"}>
                                    {t('ListingList_Certificates')}
                                </Typography>
                                <Typography className={isMobile ? "body1-medium" : "h5"} variant={isMobile ? "body1" : "h5"}>
                                    {getLookupTranslationById(ListingDetail.EnergyCertificateTypeUID.toString(), LookupList)}
                                </Typography>
                            </Stack>
                        )} */}

                    {/* Energy Certificate Date */}
                    {/* {ListingDetail.EnergyCertificateDate !== null && (
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                                <Typography className={`${isMobile ? "body1-medium" : "h5"} secondary-text`} variant={isMobile ? "body1" : "h5"}>
                                    {t('ListingList_EnergyCertificateDate')}
                                </Typography>
                                <Typography className={isMobile ? "body1-medium" : "h5"} variant={isMobile ? "body1" : "h5"}>
                                    {format(new Date(ListingDetail.EnergyCertificateDate), 'dd/MM/yy')}
                                </Typography>
                            </Stack>
                        )} */}

                    {/* Energy Performance */}
                    {/* {ListingDetail.EnergyPerformance !== null && (
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                                <Typography className={`${isMobile ? "body1-medium" : "h5"} secondary-text`} variant={isMobile ? "body1" : "h5"}>
                                    {t('ListingList_EnergyPerformance')}
                                </Typography>
                                <Typography className={isMobile ? "body1-medium" : "h5"} variant={isMobile ? "body1" : "h5"}>
                                    {ListingDetail.EnergyPerformance}
                                </Typography>
                            </Stack>
                        )} */}

                    {/* Energy Electricity Consumption */}
                    {/* {ListingDetail.ElectricityConsumption !== null && (
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                                <Typography className={`${isMobile ? "body1-medium" : "h5"} secondary-text`} variant={isMobile ? "body1" : "h5"}>
                                    {t('ListingList_EnergyElectricityConsumption')}
                                </Typography>
                                <Typography className={isMobile ? "body1-medium" : "h5"} variant={isMobile ? "body1" : "h5"}>
                                    {ListingDetail.ElectricityConsumption}
                                </Typography>
                            </Stack>
                        )} */}
                    {/* Energy Rating Value */}
                    {/* {ListingDetail.EnergyRatingValue !== null && (
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                                <Typography className={`${isMobile ? "body1-medium" : "h5"} secondary-text`} variant={isMobile ? "body1" : "h5"}>
                                    {t('ListingList_EnergyRatingValue')}
                                </Typography>
                                <Typography className={isMobile ? "body1-medium" : "h5"} variant={isMobile ? "body1" : "h5"}>
                                    {ListingDetail.EnergyRatingValue}
                                </Typography>
                            </Stack>
                        )} */}
                    {/* Energy Rating Notes */}
                    {/* {ListingDetail.EnergyRatingNotes !== null && (
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                                <Typography className={`${isMobile ? "body1-medium" : "h5"} secondary-text`} variant={isMobile ? "body1" : "h5"}>
                                    {t('ListingList_EnergyRatingNotes')}
                                </Typography>
                                <Typography className={isMobile ? "body1-medium" : "h5"} variant={isMobile ? "body1" : "h5"}>
                                    {ListingDetail.EnergyRatingNotes}
                                </Typography>
                            </Stack>
                        )} */}

                    {/* Year Built */}
                    {/* {ListingDetail.MonthBuiltUID !== null && (
                            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">
                                <Typography className={`${isMobile ? "body1-medium" : "h5"} secondary-text`} variant={isMobile ? "body1" : "h5"}>
                                    {t('ListingFull_YearMonthBuilt')}
                                </Typography>
                                <Typography className={isMobile ? "body1-medium" : "h5"} variant={isMobile ? "body1" : "h5"}>
                                    {getLookupTranslationById(ListingDetail.MonthBuiltUID.toString(), LookupList)}
                                </Typography>
                            </Stack>
                        )} */}

                    {/* Energy Main Heat Source */}
                    {ListingDetail.MainHeatSourceUID !== null && (

                        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap">

                            <Typography className="secondary-text">
                                {t('ListingList_EnergyMainEnergySource')}
                            </Typography>

                            <Typography className="body1-medium" variant="body1">
                                {getLookupTranslationById(ListingDetail.MainHeatSourceUID.toString(), LookupList)}

                            </Typography>

                        </Stack>

                    )}

                {/* </Stack> */}

                    {/* To Do:  Energy Thermic Insulation Phase II*/}
                </Stack >

            )}

        </>
    );
};

export default ListingEnergyRating;
