function isNoIndexNoFollowUrl(): boolean {
    let url = window.location.hostname.split('.');

    if (url.includes('localhost') || url.includes('www2') ||
        url.includes('devbeta') || url.includes('dev') ||
        url.includes('testbeta') || url.includes('test') ||
        url.includes('stagebeta') || url.includes('stage') ||
        url.includes('prodbeta') || url.includes('prod'))
        return true;

    return false;
}

//  To Do: no index,  no follow

export { isNoIndexNoFollowUrl };