import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { useMediaQuery, CircularProgress, Hidden, Grid, Stack, Paper, Container, Box, Typography } from '@mui/material';
import ListingTitleAndDescription from '../../components/listing/ListingTitleAndDescription';
import ListingNavigationAndCta from '../../components/listing/ListingNavigationAndCta';
import ListingMobileImageSliderAndToolbar from '../../components/listing/ListingMobileImageSliderAndToolbar';
import ListingRelatedLinks from '../../components/listing/ListingRelatedLinks';
import ListingDesktopImageSlider from '../../components/listing/ListingDesktopImageSlider';
import ListingAttributes from '../../components/listing/ListingAttributes';
import ListingAttributesIcons from '../../components/listing/ListingAttributesIcons';
import ListingLegalRequirements from '../../components/listing/ListingLegalRequirements';
import ListingLandInfo from '../../components/listing/ListingLandInfo';
import ListingRooms from '../../components/listing/ListingRooms';
import ListingFeatures from '../../components/listing/ListingFeatures';
import ListingContactAgent from '../../components/listing/ListingContactAgent';
import ListingAdditionalCosts from '../../components/listing/ListingAdditionalCosts';
import ListingEnergyRating from '../../components/listing/ListingEnergyRating';
import ListingDisclaimer from '../../components/listing/ListingDisclaimer';
import ListingBreadcrumbs from '../../components/listing/ListingBreadcrumbs';
import ListingToolBar from '../../components/listing/ListingToolBar';
import { useLoading } from '../../components/utils/LoadingContext';
import ListingFavouritesList from '../../components/listing/ListingFavouritesList';
import { fetchAgentDataByAgentId } from '../../services/ProfileService';
import { fetchListingDataById, fetchListingDataByKey } from '../../services/ListingService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { fetchLookupTranslations, fetchMacroPropertyTypesTranslations } from '../../services/TranslationService';
import LookupItem from '../../types/LookupItem';
import SiteSettings from '../../types/Settings/SiteSettings';
import ListingDetailData from '../../types/Listing/ListingDetailData'
import Agent from '../../types/Profile/AgentFull';
import { DESCRIPTION, FAVOURITES, HITNAMES, LANGUAGE, LISTING_STATUS_TYPES, PAGE_TYPE, RECENTLY_VIEWED_ITEMS, TENANTS } from '../../constants/constants';
import ListingRecentlyViewed from '../../components/listing/ListingRecentlyViewed';
import { getListingDefaultImagePath, getListingImagePath } from '../../components/utils/helper/ImageHelper';
import ListingDescription from '@/types/Listing/ListingDescription';
import MetaTags from '../../components/utils/seo/MetaTags';
import ListingSimilarNearby from '../../components/listing/ListingSimilarNearby';
import { getLookupTranslationById } from '../../components/utils/helper/LookupHelper';
import { RegionProvider, useRegion } from '../../pages/RegionContext';
import ListingDevelopment from '../../components/listing/ListingDevelopment';
import Location from '../../components/utils/Location';
import SimilarNearbyCarousels from '../../components/listing/SimilarNearbyCarousels';
import ListingProductSchema from '../../components/listing/ListingProductSchema';
import MetaTag from '../../types/Seo/MetaTag';
import { hitService } from '../../services/HitService';
import HitType from '../../types/Hit';



interface ListingRouteParam extends Record<string, string | undefined> {
    languagetag: string;
    pagetype: string;
    propertytype: string;
    transactiontype: string;
    city: string;
    listingaddress: string;
    content: string;
}

interface ListingDetailProps {
    PropertyId?: string;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const ListingDetail: React.FC<ListingDetailProps> = (listingDetailProps) => {

    {/* Parameters Declaration */ }
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = useState(true);
    const params = useParams<ListingRouteParam>();

    const [soldListingCount, setSoldListingCount] = useState(null);
    const [listedListingCount, setListedListingCount] = useState(null);
    // State Declaration 

    const { showLoading, hideLoading } = useLoading();
    const { t, i18n } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [value, setValue] = React.useState(0);
    const [listingDetailData, setlistingDetailData] = useState<ListingDetailData | undefined>();
    const [agent, setAgent] = useState<Agent | undefined>(undefined);
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [macroPropertyTypes, setMacroPropertyTypes] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const hitProcessedRef = useRef(false);
    {/* Event Handlers */ }
    const scrollToElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    {/* UseEffect Data Loading */ }
    useEffect(() => {
        showLoading();
        // Simulate fetching data
        setTimeout(() => {
            hideLoading();
        }, 200);
    }, []);

    const hasFetchedData = useRef(false);



    const addListingRecentlyViewed = (
        id: string,
        price: number,
        fullAddress: string,
        imagePath: string,
        propertyType?: string,
        listingCurrency?: string
    ) => {
        const existingListingRecentlyViewed: any[] = JSON.parse(localStorage.getItem('listingRecentlyViewed') || '[]');
        const isCurrentlyListingRecentlyViewed = existingListingRecentlyViewed.some((item: any) => item.id === id);

        if (isCurrentlyListingRecentlyViewed) {
            const updatedListingRecentlyViewed = existingListingRecentlyViewed.filter((item: any) => item.id !== id);
            const listingRecentlyViewedItem = { id, price, fullAddress, imagePath, propertyType, listingCurrency };
            updatedListingRecentlyViewed.unshift(listingRecentlyViewedItem);
            localStorage.setItem('listingRecentlyViewed', JSON.stringify(updatedListingRecentlyViewed));
        } else {
            const listingRecentlyViewedItem = { id, price, fullAddress, imagePath, propertyType, listingCurrency };

            if (existingListingRecentlyViewed.length >= RECENTLY_VIEWED_ITEMS.MAX) {
                existingListingRecentlyViewed.pop();
            }

            const updatedListingRecentlyViewed = [listingRecentlyViewedItem, ...existingListingRecentlyViewed];
            localStorage.setItem('listingRecentlyViewed', JSON.stringify(updatedListingRecentlyViewed));
        }
    };

    const fetchData = useCallback(async () => {
        // setLoading(true);
        hasFetchedData.current = true;
        const queryParams = new URLSearchParams(window.location.search);
        const lkey =
        queryParams.get("lkey") ||
        queryParams.get("lKey") ||
        queryParams.get("LKey") ||
        queryParams.get("ListingKey");
        queryParams.get("LKEY");       
        const timeout = (ms: number) => new Promise<never>((_, reject) => setTimeout(() => reject(new Error("Request timed out")), ms));

        const SourceId = listingDetailProps.PropertyId ?? params.content;

        const response = lkey
            ? await fetchListingDataByKey(lkey)
            : await fetchListingDataById(SourceId);

            if (response.value.length === 0) {
                navigate("/listings");
                return;
              }
        

        if (response) {
            setlistingDetailData(response); // Add this line to set the state
            addListingRecentlyViewed(response.value[0].content.MLSID,
                response.value[0].content?.ListingPrice,
                response.value[0].content?.FullAddress,
                (response.value[0].content.ListingImages == null || response.value[0].content.ListingImages.length === 0) ?
                    getListingDefaultImagePath()
                    : getListingImagePath(
                        response.value[0].content.ListingImages[0].FileName
                        , response.value[0].content.RegionId
                        , response.value[0]?.content.ListingImages[0].HasLargeImage
                        , response.value[0]?.content.ListingImages[0].IsWatermarked
                    ),
                response.value[0].content?.PropertyTypeUID,
                response.value[0].content?.ListingCurrency
            );
        

    
        
        
            // Load Listing Agent or Selling Agent Data
            let agentId = response.value[0].content.AgentId.toString();
            let representingAgentID = response.value[0].content.RepresentingAgentID;
            if (representingAgentID !== null && agentId !== representingAgentID)
                agentId = representingAgentID;

            //let tenantID = response.value[0].content.TenantId;
            if (window.location.search.toLowerCase().includes('sagentid')) {
                const queryParams = new URLSearchParams(window.location.search);
                //SAgentID
                const encryptedAgentId = queryParams.get('SAgentID') ?? queryParams.get('sagentid');
                // const language = queryParams.get('Lang');

                if (encryptedAgentId) {
                    try {
                        const response_agent = await Promise.race([
                            fetch('/decrypt-link', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    key: response.value[0].content.ListingKey,
                                    agent: encryptedAgentId,
                                    language: i18n.language,
                                }),
                            }),
                            timeout(12000),
                        ]);
        
                        const _agentid = await response_agent.text();
                        if (encryptedAgentId !== _agentid) agentId = _agentid;
                    } catch (error) {
                        console.error("Error decrypting agent ID or timeout:", error);
                    }
                }
            }
            if (agentId) {
                const agent = await fetchAgentDataByAgentId(agentId, PAGE_TYPE.LISTING_DETAIL);
                if (agent != null) {
                    setAgent(agent);

                    if (!hitProcessedRef.current) {
                        const hitData: HitType = {
                            TenantId: response.value[0].content?.TenantId,
                            RegionId: response.value[0].content?.RegionId,
                            LanguageCode: i18n.language,
                            OfficeId: response.value[0].content?.OfficeId,
                            AgentId: response.value[0].content?.AgentId,
                            ListingId: response.value[0].content?.TenantId === TENANTS.REMAX ? response.value[0].content?.ListingId : response.value[0].content?.MLSID,
                            SiteType: siteSettings?.SiteType ?? "",
                            HitType: HITNAMES.PUBLISTINGFULL,
                        }
                        hitService(hitData);
                        hitProcessedRef.current = true;
                    }

                }

            }
        }
        
       
        setLoading(false);

    }, [params.content, window.location.search]);




    const loadSiteDataSource = useCallback(async () => {

        try {
            const lookupList: LookupItem[] = await fetchLookupTranslations();
            const macroPropertyTypes: LookupItem[] = await fetchMacroPropertyTypesTranslations();
            setLookupList(lookupList);
            setMacroPropertyTypes(macroPropertyTypes);

            await fetchSiteSettings().then((siteSettings) => {
                if (siteSettings == null) {
                    console.log("siteSettings loads nothing");
                } else {
                    setSiteSettings(siteSettings);
                }
            });

        } catch (error) {
            console.error('Failed to load site data source', error);
        }
    }, [i18n.language, window.tenantid]);



    useEffect(() => {

        const loadConfig = async () => {
            loadSiteDataSource();
            fetchData();
        };

        loadConfig();

    }, []);


    const imagepath = process.env.REACT_APP_USERIMAGES;

    const defaultLanguage = siteSettings?.RegionSupportedLanguages.find(
        lang => lang.IsDefault.trim() === DESCRIPTION.ISDEFAULT.toString()
    );
    const languageCode = defaultLanguage?.FullLanguageCode.trim() ?? LANGUAGE.DEFAULT;
    const filterByDescriptionTypeUID = (
        data: ListingDescription[],
        descriptionTypeUID: string,
    ): string => {
        const result = data.find(
            (item) =>
                item.DescriptionTypeUID === descriptionTypeUID &&
                item.LanguageCode === languageCode
        );
        return result ? result.Description : '';
    };


    const createTitle = (property: ListingDetailData, lookupList: LookupItem[]) => {
        let title = '';

        if (property.value[0].content.PropertyTypeUID !== null) {
            title += getLookupTranslationById(property.value[0].content.PropertyTypeUID.toString(), lookupList);
        }

        if (property.value[0].content.TransactionTypeUID !== null && property.value[0].content.PropertyCategoryUID !== "2612") {
            title += " - " + getLookupTranslationById(property.value[0].content.TransactionTypeUID.toString(), lookupList);
        }

        if (property.value[0].content.TitleAddress !== undefined && property.value[0].content.TitleAddress !== null) {
            title += " - " + property.value[0].content.TitleAddress;
        }

        title += " - " + property.value[0].content.MLSID;

        title += " , TENANTNAME - Public Listing";

        return title;
        //TODO remove harcode TENANTNAME
    };

    const ogTitleDescriptio = (property: ListingDetailData) => { return filterByDescriptionTypeUID(property?.value[0]?.content.ListingDescriptions, DESCRIPTION.TITLE.toString()); }
    const hideSimilarAndNearbyCarousel = soldListingCount === 0 && listedListingCount === 0;

    return (
        <>
            {loading ? (

                <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <CircularProgress sx={{ color: 'var(--primary-color)' }} />
                    </div>
                </>

            ) : (
                <>
                    {listingDetailData?.value.map((item) => (

                        <div key={item.content.MLSID} className="light">

                            {/* Meta Data Phase I */}
                            {listingDetailData?.value[0]?.content.ListingMetaTags && listingDetailData?.value[0]?.content.ListingMetaTags.length > 0
                                && listingDetailData?.value[0]?.content.ListingMetaTags.filter(m => m.LanguageCode == languageCode) !== null
                                && listingDetailData?.value[0]?.content.ListingMetaTags.filter(m => m.LanguageCode == languageCode).length > 0
                                && listingDetailData?.value[0]?.content.ListingMetaTags.filter(m => m.LanguageCode == languageCode)[0] && (
                                    <MetaTags metaTags={{
                                        title: createTitle(listingDetailData, lookupList),
                                        metaTitle: listingDetailData?.value[0]?.content.ListingMetaTags.filter(m => m.LanguageCode == languageCode)[0].MetaTitle,
                                        metaDescription: listingDetailData?.value[0]?.content.ListingMetaTags.filter(m => m.LanguageCode == languageCode)[0].MetaDescription,
                                        metaKeywords: listingDetailData?.value[0]?.content.MLSID, // To be Modify
                                        image: (listingDetailData?.value[0]?.content.ListingImages == null || listingDetailData?.value[0]?.content.ListingImages.length == 0) ?
                                            getListingDefaultImagePath()
                                            : getListingImagePath(listingDetailData?.value[0]?.content.ListingImages[0].FileName
                                                , listingDetailData?.value[0]?.content.RegionId
                                                , listingDetailData?.value[0]?.content.ListingImages[0].HasLargeImage
                                                , listingDetailData?.value[0]?.content.ListingImages[0].IsWatermarked
                                            ),
                                        url: window.location.href,
                                        type: 'article',
                                        sitename: siteSettings?.RegionName,
                                        ogArticlePublisher: listingDetailData?.value[0]?.content.ListingMetaTags.filter(m => m.LanguageCode == languageCode)[0].OgArticlePublisher,
                                    }}
                                    />
                                )
                            }


                            {/* Mobile Layout*/}
                            <Hidden mdUp>

                                <Container className="full-width-container text-align-left" sx={{ paddingLeft: '0 !important', paddingRight: '0 !important', paddingTop: '1rem' }}>

                                    <Stack className="full-width-container" direction="row" spacing={2} useFlexGap flexWrap="wrap" alignItems="center" pb={2} justifyContent="space-between">

                                        {/* Breadcrumbs (component) */}
                                        <ListingBreadcrumbs listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} macroPropertyTypes={macroPropertyTypes} />

                                        {/* Toolbar Favourite, Share, PDF Buttons (component) */}
                                        <ListingToolBar listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} siteSettings={siteSettings} />

                                    </Stack>

                                    {/* Image Slider */}
                                    <ListingMobileImageSliderAndToolbar property={listingDetailData} lookupitem={lookupList} />

                                </Container>

                                {/* Main Content Container */}
                                <Container className="full-width-container text-align-left" sx={{ paddingTop: '2.5rem', paddingBottom: '2.5rem' }}>

                                    <ListingProductSchema listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} macroPropertyTypes={macroPropertyTypes} siteSettings={siteSettings} />

                                    <Stack direction="column" spacing={5}>

                                        {/* Listing Title, Price and Description (component) */}
                                        <ListingTitleAndDescription ListingDetail={listingDetailData?.value[0]?.content} LookupList={lookupList} siteSettings={siteSettings} scrollToElement={scrollToElement} />

                                        {/* Listing Attributes with Icons (component) */}
                                        <ListingAttributesIcons listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} siteSettings={siteSettings} />

                                        {/* Listing Attributes (component) */}
                                        <ListingAttributes listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} siteSettings={siteSettings} />

                                        {/* Listing Land Info (component) */}
                                        <ListingLandInfo ListingDetail={listingDetailData?.value[0]?.content} LookupList={lookupList} />

                                        {/* Listing Legal Requirements (component) */}
                                        <ListingLegalRequirements ListingDetail={listingDetailData?.value[0]?.content} />

                                        {/* Additional Costs (component) */}
                                        <ListingAdditionalCosts ListingDetail={listingDetailData?.value[0]?.content} LookupList={lookupList} />

                                        {/* Rental Information (component) -- Phase 2 */}
                                        {/*<ListingRentalInformation property={listingDetailData} />*/}

                                        <ListingDevelopment developmentId={listingDetailData.value[0].content.DevelopmentID} tenantId={listingDetailData.value[0].content.TenantId} macroRegionId={listingDetailData.value[0].content.MacroRegionId} />

                                        {/* Features (component) */}
                                        <ListingFeatures property={listingDetailData} lookupList={lookupList} />

                                        {/* Rooms (component) */}
                                        <ListingRooms listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} siteSettings={siteSettings} />

                                        {/* Energy Rating (component) */}
                                        <ListingEnergyRating property={listingDetailData} LookupList={lookupList} />

                                        {/* Property Location (component) */}
                                        {/* <ListingLocation property={listingDetailData} scrollToElement={scrollToElement} /> */}
                                        <Location
                                            longitude={listingDetailData.value[0].content.Location.coordinates[0]}
                                            latitude={listingDetailData.value[0].content.Location.coordinates[1]}
                                            scrollToElement={scrollToElement}
                                            siteSettings={siteSettings}
                                            title={t('Listing_PropertyLocation')}
                                            showBorderBottom={true}
                                        />

                                        {/* Disclaimer (component) */}
                                        <ListingDisclaimer />

                                        {/* Agent Contact Info and Form (component) */}
                                        <ListingContactAgent agent={agent} property={listingDetailData} page={PAGE_TYPE.LISTING_DETAIL} />

                                        {/* Favourites (component) */}
                                        <ListingFavouritesList limitList={FAVOURITES.TOP} pageType={PAGE_TYPE.LISTING_DETAIL} />

                                        {/* Recently Viewed (component) */}
                                        <ListingRecentlyViewed lookupList={lookupList} />

                                        {/* Related Links (component) */}
                                        <ListingRelatedLinks listingDetail={listingDetailData?.value[0]?.content} macroPropertyTypes={macroPropertyTypes} lookupList={lookupList} siteSettings={siteSettings} />

                                    </Stack>

                                </Container>

                            </Hidden>

                            {/* Desktop Layout*/}
                            <Hidden mdDown>

                                <Container className="full-width-container text-align-left" sx={{ paddingTop: '3rem' }}>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 8 }}>

                                        {/* Left/Content Column */}
                                        <Grid item xs={12} md={6} className="text-align-left scrollbars-hidden" style={{ height: '100vh', overflowY: 'auto' }}>

                                            <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" alignItems="center" pb={5} justifyContent="space-between">

                                                {/* Breadcrumbs (component) */}
                                                <ListingBreadcrumbs listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} macroPropertyTypes={macroPropertyTypes} />

                                                {/* Toolbar Favourite, Share, PDF Buttons (component) */}
                                                <ListingToolBar listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} siteSettings={siteSettings} />

                                            </Stack>

                                            {/* Listing Title, Price and Description (component) */}
                                            <ListingProductSchema listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} macroPropertyTypes={macroPropertyTypes} siteSettings={siteSettings} />

                                            <ListingTitleAndDescription ListingDetail={listingDetailData?.value[0]?.content} LookupList={lookupList} siteSettings={siteSettings} scrollToElement={scrollToElement} />

                                            <Stack direction="column" spacing={5} pt={5} mb={6} sx={{ width: { md: '100%', lg: '100%', xl: '80%' } }}>

                                                {/* Listing Attributes with Icons (component) */}
                                                <ListingAttributesIcons listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} siteSettings={siteSettings} />

                                                {/* Listing Attributes (component) */}
                                                <ListingAttributes listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} siteSettings={siteSettings} />

                                                {/* Listing Land Info (component) */}
                                                <ListingLandInfo ListingDetail={listingDetailData?.value[0]?.content} LookupList={lookupList} />

                                                {/* Listing Legal Requirements (component) */}
                                                <ListingLegalRequirements ListingDetail={listingDetailData?.value[0]?.content} />

                                                {/* Additional Costs (component) */}
                                                <ListingAdditionalCosts ListingDetail={listingDetailData?.value[0]?.content} LookupList={lookupList} />

                                                {/* Rental Information (component) -- Phase 2 */}
                                                {/*<ListingRentalInformation property={listingDetailData} />*/}

                                                <ListingDevelopment developmentId={listingDetailData.value[0].content.DevelopmentID} tenantId={listingDetailData.value[0].content.TenantId} macroRegionId={listingDetailData.value[0].content.MacroRegionId} />

                                                {/* Features (component) */}
                                                <ListingFeatures property={listingDetailData} lookupList={lookupList} />

                                                {/* Rooms (component) */}
                                                <ListingRooms listingDetail={listingDetailData?.value[0]?.content} lookupList={lookupList} siteSettings={siteSettings} />

                                                {/* Energy Rating (component) */}
                                                <ListingEnergyRating property={listingDetailData} LookupList={lookupList} />

                                                {/* Property Location (component) */}
                                                {/* <ListingLocation property={listingDetailData} scrollToElement={scrollToElement} /> */}
                                                <Location
                                                    longitude={listingDetailData.value[0].content.Location.coordinates[0]}
                                                    latitude={listingDetailData.value[0].content.Location.coordinates[1]}
                                                    scrollToElement={scrollToElement}
                                                    siteSettings={siteSettings}
                                                    title={t('Listing_PropertyLocation')}
                                                    showBorderBottom={true}
                                                />

                                                {/* Disclaimer (component) */}
                                                <ListingDisclaimer />

                                                {/* Agent Contact Info and Form (component) */}
                                                <ListingContactAgent agent={agent} property={listingDetailData} page={PAGE_TYPE.LISTING_DETAIL} />

                                                {/* Favourites (component) */}
                                                <ListingFavouritesList limitList={FAVOURITES.TOP} pageType={"listingDetail"} />

                                                {/* Recently Viewed (component) */}
                                                <ListingRecentlyViewed lookupList={lookupList} />

                                                {/* Related Links (component) */}
                                                <ListingRelatedLinks listingDetail={listingDetailData.value[0].content} macroPropertyTypes={macroPropertyTypes} lookupList={lookupList} siteSettings={siteSettings} />

                                            </Stack>

                                        </Grid>

                                        {/* Right/Image Column */}
                                        <Grid item xs={12} md={6} className="scrollbars-hidden" style={{ height: '100vh', overflowY: 'auto' }}>

                                            {/* Desktop Image Slider (component) */}
                                            <ListingDesktopImageSlider property={listingDetailData} lookupitem={lookupList} />

                                        </Grid>

                                    </Grid>

                                </Container>

                            </Hidden>

                            {/* Listing Similar Nearby Carousels (component) */}
                            {!hideSimilarAndNearbyCarousel && (
                                <Box className="full-width-container text-align-left" sx={{ backgroundColor: 'white', marginTop: { xs: 0, sm: '40px' }, paddingTop: '40px', paddingBottom: '40px' }}>
                                    <SimilarNearbyCarousels listingDetailData={listingDetailData}
                                        soldListingCount={soldListingCount}
                                        listedListingCount={listedListingCount}
                                        setSoldListingCount={setSoldListingCount}
                                        setListedListingCount={setListedListingCount} />
                                </Box>
                            )}


                            {/* Listing Navigation and CTA (component) */}
                            <ListingNavigationAndCta agent={agent} property={listingDetailData.value[0].content} siteSettings={siteSettings} />

                        </div>

                    ))}
                </>
            )}
        </>
    );

};

export default ListingDetail;




