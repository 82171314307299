import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Autocomplete, Button, ButtonGroup, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, Box, Grid, ListSubheader, Popover } from '@mui/material';
import { Tune as TuneIcon, Search as SearchIcon, Close as CloseIcon, ArrowDropUpOutlined } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SelectChangeEvent } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import { useSearch } from '../../reducer/searchreducer';
import { RESET_SEARCH_STATE, SET_SEARCH_STATE } from '../../reducer/actionTypes';
import { LISTINGSEARCHFILTER, PROPERTY_TYPES, TENANTS, TRANSACTION_TYPES } from '../../constants/constants';
import { FacetItem } from '../../types/AdvancedSearch/Advanced';
import { SiteSettings, LookupItem, ListingSearchFilters, ListingSearchFilterLookupItem, ListingSearchFilterMacroPropertyType, ListingSearchFilterDevelopment, Country, Province } from '../../types/TypesIndex';
import { getListingSearchFilterLookupItemList, getListingSearchFilterMacroPropertyTypeList, getLookupTranslationById } from '../utils/helper/LookupHelper';
import { formatCurrency, formatNumberWithRegionSetting } from '../../components/utils/helper/FormatNumberHelper';
import { fetchListingDataFacets } from '../../services/ListingService';
import { fetchListingFilters, fetchSiteSettings } from '../../services/SiteSettingService';
import { featchCountryList, featchProvinceList, fetchLookupTranslations, fetchMacroPropertyTypesTranslations } from '../../services/TranslationService';
import { fetchDevelopmentProvinceList } from '../../services/DevelopmentService';
import { updateLocationUrl } from '../utils/helper/FunctionHelper';


const ListingSearchMoreOptions: React.FC = () => {

    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    // States Declaration 
    const { state, dispatch } = useSearch();
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [openFiltersModal, setOpenFiltersModal] = useState(false);
    const [resetFlag, setResetFlag] = useState<boolean>(true); // State to track reset

    // States for Search Paramters
    // const [comRes, setComRes] = useState<string>("0"); {/* Com Res: a. Query: 1: Com, 2 Res, 0: both; b. Hide or Show for Comm/Res Property Types, Bedrooms, Bath Rooms etc */ }
    // const [transactionType, setTransactionType] = useState<string>("261"); // Transaction Type: a, Query: 261: for sale, 260: for rent, b. Hide or Show for Price / Rental Price
    const [listingSearchFilters, setListingSearchFilters] = useState<ListingSearchFilters>();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [countryList, setCountryList] = useState<Country[]>([]); // GEO : Country list base on Site Region ID
    const [provinceList, setProvinceList] = useState<Province[]>([]); // GEO: Province list base on Site Region ID and Language Code
    const [provinces, setProvinces] = useState<FacetItem[]>([]); // ? Usage
    const [cities, setCities] = useState<FacetItem[]>([]);  // ? Usage
    const [selectedCities, setSelectedCities] = useState<string[]>([]); // ? Usage 
    const [bedroomsMenuList, setBedroomsMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [bathroomsMenuList, setBathroomsMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [parkingSpacesMenuList, setParkingSpacesMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    // const [bedroomSelectedValue, setBedroomSelectedValue] = useState<string | null>(null);
    // const [bathroomSelectedValue, setBathroomSelectedValue] = useState<string | null>(null);
    // const [parkingSelectedValue, setParkingSelectedValue] = useState<string | null>(null);
    const [mlsId, setMLSID] = useState<string>('');
    const [commMacroPropertyTypesMenuList, setCommMacroPropertyTypesMenuList] = useState<ListingSearchFilterMacroPropertyType[]>([]);
    const [resMacroPropertyTypesMenuList, setResMacroPropertyTypesMenuList] = useState<ListingSearchFilterMacroPropertyType[]>([]);
    const [landMacroPropertyTypesMenuList, setLandMacroPropertyTypesMenuList] = useState<ListingSearchFilterMacroPropertyType[]>([]);
    const [selectedMacroPropertyTypes, setSelectedMacroPropertyTypes] = useState<string[]>([]);
    const [selectedPropertyFeature, setSelectedPropertyFeature] = useState<string[]>([]);
    const [listingFeaturesMenuList, setlistingFeaturesMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [squareMetersMenuList, setSquareMetersMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [listingAddedMenuList, setListingAddedMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    // const [floorLevelMenuList, setFloorLevelMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    // const [marketStatusMenuList, setMarketStatusMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    // const [multiMedia, setmultiMedia] = useState<boolean>(false);
    const [developments, setDevelopments] = useState<ListingSearchFilterDevelopment[]>([]);
    const [developmentProvinceList, setDevelopmentProvinceList] = useState<Province[]>([]);
    const [showDevelopmentFilter, setShowDevelopmentFilter] = useState<boolean>(false);
    const [priceRangeTitleLabel, setPriceRangeTitleLabel] = useState(t('Search_PriceRange'));
    const [priceCurreny, setPriceCurrency] = useState<string>('');
    const [minPriceMenuList, setMinPriceMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [maxPriceMenuList, setMaxPriceMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [rentalMinPriceMenuList, setRentalMinPriceMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    const [rentalMaxPriceMenuList, setRentalMaxPriceMenuList] = useState<ListingSearchFilterLookupItem[]>([]);
    // const [isCheckedMultiMedia, setIsCheckedMultiMedia] = useState(false);

    // Event Handler 
    const handleFiltersModalOpen = () => {
        setOpenFiltersModal(true);
    };

    const handleFiltersModalClose = () => {
        setOpenFiltersModal(false);

    };
    const getOptionLabel = (option: FacetItem): string => {
        return option.value.toString();
    };

    const handleCityChange = (event: React.ChangeEvent<{}>, value: FacetItem[]) => {
        const selectedCityNames = value.map(city => city.value.toString());
        setSelectedCities(selectedCityNames);
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('city');
        selectedCityNames.forEach(city => searchParams.append('city', city));
        window.history.replaceState(null, '', `${window.location.pathname}?${searchParams.toString()}`);

        if (selectedCityNames.length > 0) {
            dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, City: selectedCityNames.map(c => parseInt(c)) } });
        }
    };

    const handleMacroPropertyTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSelectedMacroPropertyTypes(prevSelected => {
            let updatedSelected;
            if (prevSelected.includes(value)) {
                updatedSelected = prevSelected.filter(item => item !== value);
            } else {
                updatedSelected = [...prevSelected, value];
            }
            const selectedPropertyTypesString = updatedSelected.join(',');
            dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, MacroPropertyTypeUIDs: selectedPropertyTypesString } });
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.MACROPROPERTYTYPEUIDS, selectedPropertyTypesString);
            return updatedSelected;
        });
    };

    const handlePropertyFeatureChange = (event: SelectChangeEvent<string>) => {
        const { value } = event.target;
        setSelectedPropertyFeature(prevSelected => {
            let updatedSelected;
            if (prevSelected.includes(value)) {
                updatedSelected = prevSelected.filter(item => item !== value);
            } else {
                updatedSelected = [...prevSelected, value];
            }
            const selectedPropertyFeatureString = updatedSelected.join(',');

            dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, PropertyFeatures: selectedPropertyFeatureString } });
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PROPERTYFEATURES, selectedPropertyFeatureString);

            return updatedSelected;
        });
    }

    const handleBedroomButtonGroupClick = (value: string) => {
        // setBedroomSelectedValue(value);
        handleBedroomChange(value);
    };

    const handleBedroomChange = (value: string) => {
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, Bedrooms: value } });

        let val = (value && parseFloat(value) > 0) ? value : null;
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, Bedrooms: val } });
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.BEDROOMS, val);
    };

    const handleBathroomButtonGroupClick = (value: string) => {
        //setBathroomSelectedValue(value);
        handleBathroomChange(value); // Call your state update function here
    };

    const handleBathroomChange = (value: string) => {
        let val = (value && parseFloat(value) > 0) ? value : null;
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, Bathrooms: value } });
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.BATHROOMS, val);
    };

    const handleParkingButtonGroupClick = (value: string) => {
        // setParkingSelectedValue(value);
        handleParkingSpacesChange(value); // Call your state update function here
    };

    const handleParkingSpacesChange = (value: string) => {
        let val = (value && parseFloat(value) > 0) ? value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PARKINGSPACES, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, ParkingSpaces: val } });
    };

    const handleMinTotalAreaChange = (event: SelectChangeEvent<string>) => {
        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.MINTOTALAREA, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, MinTotalArea: val } });
    };

    const handleMaxTotalAreaChange = (event: SelectChangeEvent<string>) => {
        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.MAXTOTALAREA, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, MaxTotalArea: val } });
    };

    const handlePropertiesAddedChange = (event: SelectChangeEvent<string>) => {
        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PROPERTIESADDED, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, PropertiesAdded: val } });
    };

    const handleDevelopmentChange = (event: SelectChangeEvent<string>) => {
        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.DEVELOPMENTID, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, DevelopmentID: val } });
    };

    const handleMLSIDStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMLSID(event.target.value);
    };

    const handleMLSIDSearchSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        handleMLSIDInputChange(mlsId);
    };

    const handleMLSIDInputChange = (value: string) => {
        let val = (value && value.length > 0) ? value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.MLSID, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, MLSID: val } });
    };

    const handleMultiMediaCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setIsCheckedMultiMedia(event.target.checked);
        let val = event.target.checked ? "1" : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.HASMULTIMEDIA, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, HasMultiMedia: event.target.checked.toString() } });
    };


    const handleReset = () => {
        dispatch({ type: RESET_SEARCH_STATE });
        window.history.replaceState(null, '', `${window.location.pathname}`);
        setMLSID('');
        setResetFlag(prevFlag => !prevFlag);
    };


    {/* UseEffect Data Loading */ }
    useEffect(() => {
        if (openFiltersModal) {
            const searchParams = new URLSearchParams(location.search);
            const citiesFromUrl = searchParams.getAll('city');
            setSelectedCities(citiesFromUrl);
        }
    }, [openFiltersModal, location.search]);

    useEffect(() => {

        const loadListingSearchFiltersRenderList = async () => {
            try {

                // Load Data Source and Settings
                const listingfilter: ListingSearchFilters = await fetchListingFilters();
                const lookupList: LookupItem[] = await fetchLookupTranslations();
                const macroPropertyTypes: LookupItem[] = await fetchMacroPropertyTypesTranslations();
                const siteSettings: SiteSettings = await fetchSiteSettings();
                const regionCountryList: Country[] = await featchCountryList(i18n.language);
                const regionProvinceList: Province[] = await featchProvinceList(i18n.language);

                setListingSearchFilters(listingfilter);
                setLookupList(lookupList);
                setSiteSettings(siteSettings);
                setCountryList(regionCountryList);

                // Rooms 
                setBedroomsMenuList(getListingSearchFilterLookupItemList(listingfilter.BedRooms, lookupList));
                setBathroomsMenuList(getListingSearchFilterLookupItemList(listingfilter.Bathrooms, lookupList));
                setParkingSpacesMenuList(getListingSearchFilterLookupItemList(listingfilter.ParkingSpaces, lookupList));

                // Macro Property Types 
                const commMacroPropertyTypes = [...new Set(listingfilter.PropertyTypeList.filter(m => m.ComRes === PROPERTY_TYPES.COMMERCIAL.toString()).filter(m => m.MacroListingCount > 0).map(m => m.MacroID))];
                setCommMacroPropertyTypesMenuList(getListingSearchFilterMacroPropertyTypeList(commMacroPropertyTypes, macroPropertyTypes))
                const resMacroPropertyTypes = [...new Set(listingfilter.PropertyTypeList.filter(m => m.ComRes === PROPERTY_TYPES.RESIDENTIAL.toString()).filter(m => m.MacroListingCount > 0).map(m => m.MacroID))];
                setResMacroPropertyTypesMenuList(getListingSearchFilterMacroPropertyTypeList(resMacroPropertyTypes, macroPropertyTypes));
                const landMacroPropertyTypes = [...new Set(listingfilter.PropertyTypeList.filter(m => m.ComRes === PROPERTY_TYPES.LAND.toString()).filter(m => m.MacroListingCount > 0).map(m => m.MacroID))];
                setLandMacroPropertyTypesMenuList(getListingSearchFilterMacroPropertyTypeList(landMacroPropertyTypes, macroPropertyTypes));
                // Price 
                // To Do: a. Price Currency Change,  b. Transaction Type Changes 
                let curPriceCurreny = siteSettings.DefaultCurrency;
                let minPriceRangeLst = listingfilter?.PriceRangeList.filter(p => p.Code === curPriceCurreny).filter(p => p.Type === "MinPriceRange").map(p => p.UID).join(",");
                let maxPriceRangeLst = listingfilter?.PriceRangeList.filter(p => p.Code === curPriceCurreny).filter(p => p.Type === "MaxPriceRange").map(p => p.UID).join(",");
                let rentalMinPriceRangeLst = listingfilter?.PriceRangeList.filter(p => p.Code === curPriceCurreny).filter(p => p.Type === "RentalMinPriceRange").map(p => p.UID).join(",");
                let rentalMaxPriceRangeLst = listingfilter?.PriceRangeList.filter(p => p.Code === curPriceCurreny).filter(p => p.Type === "RentalMaxPriceRange").map(p => p.UID).join(",");
                setPriceCurrency(curPriceCurreny);

                let minPriceMenuListArray: ListingSearchFilterLookupItem[] = getListingSearchFilterLookupItemList(minPriceRangeLst, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue));
                if(minPriceMenuListArray.length === 1){
                    minPriceMenuListArray = [];
                }
                setMinPriceMenuList(minPriceMenuListArray);

                //setMaxPriceMenuList(getListingSearchFilterLookupItemList(maxPriceRangeLst, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue)));
                //Add + sign to the last item
                let formattedMaxPriceMenuList = getListingSearchFilterLookupItemList(maxPriceRangeLst, lookupList)
                    .sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue))
                    .map(p => ({
                        ...p, // spread the other properties
                        DisplayValue: formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings) // format the DisplayValue
                    }));
                if (formattedMaxPriceMenuList.length > 0) {
                    const lastItem = formattedMaxPriceMenuList[formattedMaxPriceMenuList.length - 1];
                    lastItem.DisplayValue = `${lastItem.DisplayValue} +`;
                }

                if(formattedMaxPriceMenuList.length === 1){
                    formattedMaxPriceMenuList = [];
                }
                setMaxPriceMenuList(formattedMaxPriceMenuList);

                setRentalMinPriceMenuList(getListingSearchFilterLookupItemList(rentalMinPriceRangeLst, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue)));

                //setRentalMaxPriceMenuList(getListingSearchFilterLookupItemList(rentalMaxPriceRangeLst, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue)));
                //Add + sign to the last item
                const formattedRentalMaxPriceMenuList = getListingSearchFilterLookupItemList(rentalMaxPriceRangeLst, lookupList)
                    .sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue))
                    .map(p => ({
                        ...p, // spread the other properties
                        DisplayValue: formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings) // format the DisplayValue
                    }));

                if (formattedRentalMaxPriceMenuList.length > 0) {
                    const lastItem = formattedRentalMaxPriceMenuList[formattedRentalMaxPriceMenuList.length - 1];
                    lastItem.DisplayValue = `${lastItem.DisplayValue} +`;
                }
                setRentalMaxPriceMenuList(formattedRentalMaxPriceMenuList);

                setSquareMetersMenuList(getListingSearchFilterLookupItemList(listingfilter.SquareMeters, lookupList));
                setListingAddedMenuList(getListingSearchFilterLookupItemList(listingfilter.ListingAdded, lookupList));
                //setFloorLevelMenuList(getListingSearchFilterLookupItemList(listingfilter.FloorLevel, lookupList));
                // setMarketStatusMenuList(getListingSearchFilterLookupItemList(listingfilter.MarketStatus, lookupList));
                // setmultiMedia(false);

                if (state.searchState && state.searchState.MLSID !== null && state.searchState.MLSID !== "") {
                    setMLSID(state.searchState.MLSID);
                } else {
                    setMLSID("");
                }

                // Listing Features */ }
                let listingFeaturesList = [];

                if (window.tenantid === TENANTS.REMAX) {
                    for (let f of listingfilter.ListingFeatureList) {
                        listingFeaturesList.push({
                            "UID": f.FeatureID,
                            "Value": t(f.FeatureName), //getLookupTranslationById(rUID.toString(), lookupList),
                            "DisplayValue": t(f.FeatureName), //getLookupTranslationById(rUID.toString(), lookupList),
                        });
                    }
                } else {
                    for (let f of listingfilter.ListingFeatureList) {
                        listingFeaturesList.push({
                            "UID": f.FeatureID,
                            "Value": getLookupTranslationById(f.FeatureID.toString(), lookupList),
                            "DisplayValue": getLookupTranslationById(f.FeatureID.toString(), lookupList)
                        });
                    }
                    // for (let f of listingfilter.ListingFeatureList) {
                    //     listingFeaturesList.push({
                    //         "UID": f.FeatureID,
                    //         "Value": t(f.FeatureName), //getLookupTranslationById(rUID.toString(), lookupList),
                    //         "DisplayValue": t(f.FeatureName), //getLookupTranslationById(rUID.toString(), lookupList),
                    //     });
                }
                setlistingFeaturesMenuList(listingFeaturesList.sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue)));

                if (state.searchState.PropertyFeatures !== null && typeof state.searchState.PropertyFeatures !== "undefined" && state.searchState.PropertyFeatures !== "") {
                    setSelectedPropertyFeature(state.searchState.PropertyFeatures.split(','));
                } else {
                    setMLSID("");
                }

                let showDevelopmentSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowDevelopmentsInSearch')?.HasAccess === "1";
                if (showDevelopmentSetting) {
                    setShowDevelopmentFilter(showDevelopmentSetting);

                    // 1: Get the development list which has public available listings
                    setDevelopments(listingfilter.DevelopmentList.sort((a, b) => a.ProvinceID - b.ProvinceID));

                    // 2: Generate the province list which has active development and deveopment has public available listings
                    if (Array.isArray(regionProvinceList) && listingfilter.DevelopmentList !== null) {
                        let developmentProvinceList = listingfilter.DevelopmentList?.map(d => ({ provinceId: d.ProvinceID }));
                        let dProvinces: Province[] = regionProvinceList.filter(rp => developmentProvinceList.some(dp => dp.provinceId.toString() === rp.ProvinceID.toString()))
                            .map(rp => ({ ProvinceID: rp.ProvinceID, ProvinceName: rp.ProvinceName }))
                            .sort((a, b) => a.ProvinceName.localeCompare(b.ProvinceName));
                        setDevelopmentProvinceList(dProvinces);
                    }
                }

            } catch (error) {
                console.error('Failure: loadListingSearchFiltersRenderList on ListingSearchMoreOptions', error);
            }
        };
        loadListingSearchFiltersRenderList();

    }, [resetFlag, t]);


    useEffect(() => {
        if (openFiltersModal) {
            const fetchDataMap = async () => {
                try {

                    let searchRequestBody = {
                        count: true,
                        skip: 0,
                        top: 0,
                        searchMode: 'any',
                        queryType: 'simple',
                        select: "content/TransactionTypeUID",
                        facets: [
                            "content/TransactionTypeUID,count:500,sort:count",
                            "content/Province,count:500,sort:count",
                            "content/City,count:500,sort:count",
                            "content/ListingClass,count:500,sort:count",
                            "content/PropertyTypeUID,count:500,sort:count"]
                    };
                    // showLoading();
                    const result = await fetchListingDataFacets(searchRequestBody);
                    setCities(result['@search.facets']['content/City']);
                    setProvinces(result['@search.facets']['content/City']); //TODO Change to Province
                    ///   setcity(result['@search.facets']['content/City']);
                    // setadvanced(result);

                    //   console.log(advanced);
                    //setListingsMap(result);

                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    // hideLoading();
                }
            };
            fetchDataMap();
        }

    }, [openFiltersModal]);



    // Update Text Fields Value by UseEffect
    useEffect(() => {
        updatePriceRangeTitleLabel(state.searchState.PriceMin, state.searchState.PriceMax);
    }, [i18n.language, state.searchState.PriceMin, state.searchState.PriceMax]);

    useEffect(() => {
        setSelectedMacroPropertyTypes(state.searchState.MacroPropertyTypeUIDs?.split(',') ?? []);
    }, [state.searchState.MacroPropertyTypeUIDs]);

    useEffect(() => {
        setMLSID((state.searchState.MLSID !== null && state.searchState.MLSID !== "") ? state.searchState.MLSID : "");
    }, [state.searchState.MLSID]);

    useEffect(() => {
        setSelectedPropertyFeature(state.searchState.PropertyFeatures?.split(',') ?? []);
    }, [state.searchState.PropertyFeatures]);

    // Price Section
    const openPriceRangePopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePriceRangePopover = () => {
        setAnchorEl(null);
    };

    const priceRangePopoverOpen = Boolean(anchorEl);
    const priceRangePopoverId = priceRangePopoverOpen ? 'price-range-popover' : undefined;

    const handleMinPriceTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updatePriceRangeTitleLabel(event.target.value, state.searchState.PriceMax);
        refreshMinPriceMenuList(event.target.value.toString());
        refreshMaxPriceMenuList(event.target.value.toString());

        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMIN, val);

        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, PriceMin: val } });
    };

    const handleMaxPriceTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        updatePriceRangeTitleLabel(state.searchState.PriceMin, event.target.value);
        refreshMinPriceMenuList(event.target.value.toString());
        refreshMaxPriceMenuList(event.target.value.toString());
        const searchParams = new URLSearchParams(window.location.search);


        let val = (event.target.value && parseFloat(event.target.value) > 0) ? event.target.value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMAX, val);
        dispatch({
            type: SET_SEARCH_STATE,
            payload: {
                ...state.searchState,
                PriceMax: val
            }
        });

    };

    const handleMinPriceMenuItemChange = (value: string) => {
        updatePriceRangeTitleLabel(value, state.searchState.PriceMax);
        setAnchorEl(null);
        refreshMaxPriceMenuList(value);

        let val = (value && parseFloat(value) > 0) ? value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMIN, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, PriceMin: value } });
    };

    const handleMaxPriceMenuItemChange = (value: string) => {
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, PriceMax: value } });
        updatePriceRangeTitleLabel(state.searchState.PriceMin, value);
        setAnchorEl(null);
        refreshMinPriceMenuList(value);

        let val = (value && parseFloat(value) > 0) ? value : null;
        updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMAX, val);
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, PriceMax: value } });
    };

    const refreshMinPriceMenuList = (value: string) => {

        let type = state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORRENT.toString() ? "RentalMinPriceRange" : "MinPriceRange";
        let minPriceUIDStr = listingSearchFilters?.PriceRangeList.filter(p => p.Code === priceCurreny).filter(p => p.Type === type).map(p => p.UID).join(",") ?? "";

        if (minPriceUIDStr === null || minPriceUIDStr === "")
            return;

        let minPriceList;
        if (value === "")
            minPriceList = getListingSearchFilterLookupItemList(minPriceUIDStr, lookupList).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue));
        else
            minPriceList = getListingSearchFilterLookupItemList(minPriceUIDStr, lookupList).filter(p => Number(p.Value) >= Number(value)).sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue));

        if (state.searchState.TransactionTypeUID !== TRANSACTION_TYPES.FORRENT.toString()) {
            setMinPriceMenuList(minPriceList);
        } else {
            setRentalMinPriceMenuList(minPriceList);
        }
    }

    const refreshMaxPriceMenuList = (value: string) => {
        let type = state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORRENT.toString() ? "RentalMaxPriceRange" : "MaxPriceRange";
        let maxPriceUIDStr = listingSearchFilters?.PriceRangeList.filter(p => p.Code === priceCurreny).filter(p => p.Type === type).map(p => p.UID).join(",") ?? "";
        if (maxPriceUIDStr === null || maxPriceUIDStr === "")
            return;

        let maxPriceList = getListingSearchFilterLookupItemList(maxPriceUIDStr, lookupList)
            .filter(p => Number(p.Value) >= (value === "" ? 0 : Number(value)))
            .sort((a, b) => Number(a.DisplayValue) - Number(b.DisplayValue));

        if (state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORRENT.toString()) {
            setRentalMaxPriceMenuList(maxPriceList);
        } else {
            setMaxPriceMenuList(maxPriceList);
        }
    };

    const updatePriceRangeTitleLabel = (minPrice?: string | null, maxPrice?: string | null) => {
        if ((minPrice === "" || Number(minPrice) <= 0) && (maxPrice === "" || Number(maxPrice) <= 0)) {
            setPriceRangeTitleLabel(t('Search_PriceRange'));
        }
        else {
            const minPriceTitle = (minPrice && Number(minPrice) >= 0) ? formatCurrency(parseFloat(minPrice), '', siteSettings) : t("Search_AnyPriceRange");
            const maxPriceTitle = (maxPrice && Number(maxPrice) >= 0) ? formatCurrency(parseFloat(maxPrice), '', siteSettings) : t("Search_AnyPriceRange");
            setPriceRangeTitleLabel(`${minPriceTitle} - ${maxPriceTitle}`);
        }
    };
    {/* End Price Range Related */ }

    // siteSetting Flag
    const showBathRoomsInSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'ShowBathRoomsInSearch')?.HasAccess === "1";
    const showBedroomsInSearchSetting = siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'HideNumOfBedroomsInSearch')?.HasAccess === "0";

    return (

        <>

            {/* More Options Button */}
            <Button
                type="button"
                role="button"
                aria-label={t('Search_MoreOptions')}
                onClick={handleFiltersModalOpen}
                variant="text"
                startIcon={<TuneIcon sx={{ color: 'var(--body-text-color) !important;' }} />}
                sx={{
                    color: 'var(--body-text-color) !important;',
                    whiteSpace: 'nowrap',
                    minWidth: 'auto !important',
                }}
            >
                {t('Search_MoreOptions')}
            </Button>

            {/* Advanced Search Modal */}
            <Dialog
                className="customBox"
                open={openFiltersModal}
                onClose={handleFiltersModalClose}
                aria-labelledby="modal-dialog-title"
                aria-describedby="modal-dialog-description"
                fullWidth
                maxWidth="sm"
            >

                {/* Modal Title */}
                <DialogTitle sx={{ m: 0, p: 3 }} id="filters-popup" component="div">
                    <Typography className="h5" variant="h5">
                        {t('Search_AdvancedSearch')}
                    </Typography>
                </DialogTitle>

                {/* Close Button */}
                <IconButton
                    aria-label="Close"
                    role="button"
                    onClick={handleFiltersModalClose}
                    sx={{
                        position: 'absolute',
                        right: 24,
                        top: 16,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {/* Modal Content */}
                <DialogContent dividers sx={{ padding: '1.5rem !important', borderBottom: 'none' }}>

                    <Stack direction="column" spacing={4}>

                        {/*    */}{/* Location */}
                        {/*    <Stack direction="column" spacing={2}>*/}

                        {/*        <Typography className="h6" variant="h6">*/}
                        {/*            {t('Search_Location')}*/}
                        {/*        </Typography>*/}

                        {/*        */}{/* To Do - Country Textfield */}
                        {/*        */}{/* onChange={handleCountryFilterChange}*/}
                        {/*        <Autocomplete*/}
                        {/*            fullWidth*/}
                        {/*            disablePortal*/}
                        {/*            id="country-dropdown"*/}
                        {/*            options={countries}*/}
                        {/*            sx={{ maxWidth: '100%' }}*/}
                        {/*            renderInput={(params) => <TextField*/}
                        {/*                {...params}*/}
                        {/*                label={t('Search_SelectCountry')}*/}
                        {/*                InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}*/}
                        {/*            />}*/}
                        {/*        />*/}

                        {/*        */}{/* To Do - Province Text field */}
                        {/*        <Autocomplete*/}
                        {/*            multiple*/}
                        {/*            limitTags={2}*/}
                        {/*            id="province-dropdown"*/}
                        {/*            options={provinces}*/}
                        {/*            disableCloseOnSelect*/}
                        {/*            getOptionLabel={(option) => getOptionLabel(option)}*/}
                        {/*            renderOption={(props, option, { selected }) => (*/}
                        {/*                <li {...props}>*/}
                        {/*                    <Checkbox*/}
                        {/*                        icon={icon}*/}
                        {/*                        checkedIcon={checkedIcon}*/}
                        {/*                        style={{ marginRight: 4 }}*/}
                        {/*                        checked={selected}*/}
                        {/*                    />*/}
                        {/*                    {option.value}*/}
                        {/*                </li>*/}
                        {/*            )}*/}
                        {/*            style={{ maxWidth: '100%' }}*/}
                        {/*            renderInput={(params) => (*/}
                        {/*                <TextField {...params} label={t('Search_SelectProvince')} placeholder={t('Search_SelectProvince')} InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }} />*/}
                        {/*            )}*/}
                        {/*        />*/}

                        {/*        */}{/* To Do - City Textfield */}
                        {/*        <Autocomplete*/}
                        {/*            multiple*/}
                        {/*            limitTags={2}*/}
                        {/*            id="city-dropdown"*/}
                        {/*            options={cities}*/}
                        {/*            disableCloseOnSelect*/}
                        {/*            getOptionLabel={(option) => getOptionLabel(option)}*/}
                        {/*            renderOption={(props, option, { selected }) => (*/}
                        {/*                <li {...props}>*/}
                        {/*                    <Checkbox*/}
                        {/*                        icon={icon}*/}
                        {/*                        checkedIcon={checkedIcon}*/}
                        {/*                        style={{ marginRight: 4 }}*/}
                        {/*                        checked={selected}*/}
                        {/*                    />*/}
                        {/*                    {option.value}*/}
                        {/*                </li>*/}
                        {/*            )}*/}
                        {/*            style={{ maxWidth: '100%' }}*/}
                        {/*            onChange={handleCityChange}*/}
                        {/*            value={cities.filter(city => selectedCities.includes(city.value.toString()))}*/}
                        {/*            renderInput={(params) => (*/}
                        {/*                <TextField {...params} label={'Search_SelectCityMunicipality'} placeholder={'Search_SelectCityMunicipality'} InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }} />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*</Stack>*/}


                        {/* Price Range */}

                        <Stack direction="column" spacing={2}>

                            <Typography className="h6" variant="h6">{t('Search_PriceRange')}</Typography>

                            <IconButton
                                disableTouchRipple
                                aria-label={t('Search_PriceRange')}
                                onClick={openPriceRangePopover}
                                className='listing-search-more-options-dropdown'
                                sx={{
                                    minHeight: '56px !important',
                                    justifyContent: 'space-between',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                    color: 'var(--body-text-color)',
                                    fontWeight: 400,
                                    paddingLeft: '14px !important',
                                    padding: '14px !important',
                                    whiteSpace: 'nowrap',
                                    fontFamily: 'var(--font-family)',
                                    p: 1,
                                    '&:hover': {
                                        backgroundColor: 'transparent !important',
                                        color: 'var(--body-text-color)',
                                        border: '1px solid black',
                                    },
                                    '&:focus': {
                                        backgroundColor: 'transparent !important',
                                        color: 'var(--body-text-color)',
                                        border: '2px solid var(--primary-color)',
                                    },
                                    '&:active': {
                                        backgroundColor: 'transparent !important',
                                        color: 'var(--body-text-color)',
                                        border: '2px solid var(--primary-color)',
                                    },
                                }}
                            >
                                {<div dangerouslySetInnerHTML={{ __html: priceRangeTitleLabel }} />}
                                {priceRangePopoverOpen ? <ArrowDropUpOutlined sx={{ color: 'rgba(0, 0, 0, 0.54) !important' }} />
                                    : <ArrowDropDownIcon sx={{ color: 'rgba(0, 0, 0, 0.54) !important' }} />}
                            </IconButton>

                            <Popover
                                id={priceRangePopoverId}
                                open={priceRangePopoverOpen}
                                anchorEl={anchorEl}
                                onClose={closePriceRangePopover}
                                sx={{ top: { lg: '-20px !important', xl: '-34px !important' } }}
                                PaperProps={{
                                    sx: {
                                        maxHeight: { lg: '65%', xl: '74%' },
                                        overflowY: 'auto',
                                        width: '535px'
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {state.searchState.TransactionTypeUID !== TRANSACTION_TYPES.FORRENT.toString() ? (
                                    <Box p={2} maxWidth="100%">
                                        <Grid container spacing={2}>
                                            <Grid container item xs={6} direction="column">
                                                <TextField
                                                    label={t('Search_MinPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMin) > 0 ? Number(state.searchState.PriceMin) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMinPriceTextFieldChange}
                                                />
                                                {minPriceMenuList != null && minPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMinPriceMenuItemChange(p.Value)}>
                                                        {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)}
                                                    </MenuItem>
                                                ))}
                                            </Grid>
                                            <Grid container item xs={6} direction="column">
                                                <TextField
                                                    label={t('Search_MaxPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMax) > 0 ? Number(state.searchState.PriceMax) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMaxPriceTextFieldChange}
                                                />
                                                {maxPriceMenuList != null && maxPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMaxPriceMenuItemChange(p.Value)}>
                                                        {p.DisplayValue}
                                                        {/* {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)} */}
                                                        {/*  */}
                                                    </MenuItem>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ) : (
                                    <Box p={2} width={300}>
                                        <Grid container spacing={2}>
                                            <Grid container item xs={6} direction="column">
                                                <TextField
                                                    label={t('Search_MinPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMin) > 0 ? Number(state.searchState.PriceMin) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMinPriceTextFieldChange}
                                                />
                                                {rentalMinPriceMenuList != null && rentalMinPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMinPriceMenuItemChange(p.Value)}>
                                                        {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)}
                                                    </MenuItem>
                                                ))}
                                            </Grid>
                                            <Grid container item xs={6} direction="column">
                                                <TextField
                                                    label={t('Search_MaxPrice')}
                                                    type="number"
                                                    value={Number(state.searchState.PriceMax) > 0 ? Number(state.searchState.PriceMax) : ''}
                                                    variant="outlined"
                                                    InputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                    sx={{ width: "100%" }}
                                                    onChange={handleMaxPriceTextFieldChange}
                                                />
                                                {rentalMaxPriceMenuList != null && rentalMaxPriceMenuList.map((p) => (
                                                    <MenuItem key={p.UID} onClick={() => handleMaxPriceMenuItemChange(p.Value)}>
                                                        {p.DisplayValue}
                                                        {/* {formatNumberWithRegionSetting(Number(p.DisplayValue), siteSettings)} */}
                                                    </MenuItem>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Popover>

                        </Stack>

                        {/* Macro Property Types */}
                        <Stack direction="column" spacing={2}>

                            <Typography className="h6" variant="h6">{t('Search_PropertyType')}</Typography>

                            <Grid container spacing={2} mt={0} sx={{ marginTop: '.5rem !important' }}>
                                {/* Residential */}
                                <Grid item xs={12} md={6} sx={{ paddingLeft: '0 !important', paddingTop: '0 !important' }}>
                                    <Stack direction="column">
                                        {resMacroPropertyTypesMenuList != null && (
                                            <>
                                                {/* //{(state.searchState.ListingClass === "2" || state.searchState.ListingClass === "") && ( */}
                                                <ListSubheader sx={{ top: 'unset !important', paddingLeft: '0 !important', paddingRight: '0 !important' }}>{t('Search_Residential')}</ListSubheader>
                                                {/* // )} */}
                                                {resMacroPropertyTypesMenuList.map((m) => (
                                                    <FormControlLabel
                                                        key={m.MacroPropertyTypeUID}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedMacroPropertyTypes.includes(m.MacroPropertyTypeUID) || state.searchState.PropertyTypeUID === m.MacroPropertyTypeUID}
                                                                onChange={handleMacroPropertyTypeChange}
                                                                value={m.MacroPropertyTypeUID}
                                                            />
                                                        }
                                                        label={
                                                            <Typography className="body1" variant="body1">
                                                                {m.DisplayValue}
                                                            </Typography>
                                                        }
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </Stack>
                                </Grid>

                                {/* Commercial */}
                                <Grid item xs={12} md={6} sx={{ paddingLeft: '0 !important', paddingTop: '0 !important' }}>
                                    <Stack direction="column">
                                        {commMacroPropertyTypesMenuList != null && (
                                            <>
                                                {/* {(state.searchState.ListingClass === "1" || state.searchState.ListingClass === "") && ( */}
                                                <ListSubheader sx={{ top: 'unset !important', paddingLeft: '0 !important', paddingRight: '0 !important' }}>{t('Search_Commercial')}</ListSubheader>
                                                {/* )} */}
                                                {commMacroPropertyTypesMenuList.map((m) => (
                                                    <FormControlLabel
                                                        key={m.MacroPropertyTypeUID}
                                                        control={
                                                            <Checkbox
                                                                color="default"
                                                                checked={selectedMacroPropertyTypes.includes(m.MacroPropertyTypeUID)}
                                                                onChange={handleMacroPropertyTypeChange}
                                                                value={m.MacroPropertyTypeUID}
                                                            />
                                                        }
                                                        label={
                                                            <Typography className="body1" variant="body1">
                                                                {m.DisplayValue}
                                                            </Typography>
                                                        }
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </Stack>
                                </Grid>
                                {/* Land */}
                                <Grid item xs={12} md={6} sx={{ paddingLeft: '0 !important', paddingTop: '0 !important' }}>
                                    <Stack direction="column">
                                        {landMacroPropertyTypesMenuList != null && (
                                            <>
                                                {/* //{(state.searchState.ListingClass === "2" || state.searchState.ListingClass === "") && ( */}
                                                {landMacroPropertyTypesMenuList.length > 0 && (
                                                    <ListSubheader sx={{ top: 'unset !important', paddingLeft: '0 !important', paddingRight: '0 !important' }}>{t('Search_Land')}</ListSubheader>
                                                )}
                                                {landMacroPropertyTypesMenuList.map((m) => (
                                                    <FormControlLabel
                                                        key={m.MacroPropertyTypeUID}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedMacroPropertyTypes.includes(m.MacroPropertyTypeUID) || state.searchState.PropertyTypeUID === m.MacroPropertyTypeUID}
                                                                onChange={handleMacroPropertyTypeChange}
                                                                value={m.MacroPropertyTypeUID}
                                                            />
                                                        }
                                                        label={
                                                            <Typography className="body1" variant="body1">
                                                                {m.DisplayValue}
                                                            </Typography>
                                                        }
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>

                        </Stack>

                        {/* Property Features */}
                        <Stack direction="column" spacing={2}>

                            <Typography className="h6" variant="h6">
                                {t('Search_PropertyFeatures')}
                            </Typography>

                            <Grid container spacing={2}>
                                {listingFeaturesMenuList != null && listingFeaturesMenuList.map((f, key) => (
                                    <Grid item xs={12} sm={6} key={key} sx={{ paddingLeft: '0 !important', paddingTop: '0 !important' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedPropertyFeature.includes(f.UID)}
                                                    onChange={handlePropertyFeatureChange}
                                                    value={f.UID}
                                                />
                                            }
                                            label={
                                                <Typography className="body1" variant="body1">
                                                    {f.DisplayValue}
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                        </Stack>

                        {/* Bedrooms */}
                        {(showBedroomsInSearchSetting && state.searchState.ListingClass.toString() !== PROPERTY_TYPES.COMMERCIAL.toString()) && (
                            <Stack direction="column" spacing={2}>

                                <Typography className="h6" variant="h6">{t('Search_MinBed')}</Typography>

                                <FormControl sx={{ minWidth: 120, background: 'white !important' }}>
                                    <ButtonGroup fullWidth variant="outlined" aria-label={t('Search_MinBed')}>
                                        {bedroomsMenuList != null && bedroomsMenuList.map((b) => (
                                            <Button
                                                key={b.UID}
                                                onClick={() => handleBedroomButtonGroupClick(b.Value)}
                                                sx={{
                                                    border: '1px solid var(--primary-color) !important',
                                                    color: 'var(--primary-color) !important',
                                                    padding: '.5rem !important',
                                                    backgroundColor: state.searchState.Bedrooms === b.Value ? 'var(--button-group-active-color)' : 'transparent',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--button-group-active-color)',
                                                    },
                                                }}
                                            >
                                                {b.DisplayValue}+ {/* Append '+' to DisplayValue */}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </FormControl>

                            </Stack>
                        )}

                        {/* Bathrooms */}
                        {(showBathRoomsInSearchSetting && state.searchState.ListingClass.toString() !== PROPERTY_TYPES.COMMERCIAL.toString()) && (
                            <Stack direction="column" spacing={2}>

                                <Typography className="h6" variant="h6">{t('Search_Bathrooms')}</Typography>

                                <FormControl sx={{ minWidth: 120, background: 'white !important' }}>
                                    <ButtonGroup fullWidth variant="outlined" aria-label={t('Search_Bathrooms')}>
                                        {bathroomsMenuList != null && bathroomsMenuList.map((b) => (
                                            <Button
                                                key={b.UID}
                                                onClick={() => handleBathroomButtonGroupClick(b.Value)}
                                                sx={{
                                                    border: '1px solid var(--primary-color) !important',
                                                    color: 'var(--primary-color) !important',
                                                    padding: '.5rem !important',
                                                    backgroundColor: state.searchState.Bathrooms === b.Value ? 'var(--button-group-active-color)' : 'transparent',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--button-group-active-color)',
                                                    },
                                                }}
                                            >
                                                {b.DisplayValue}+ {/* Append '+' to DisplayValue */}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </FormControl>

                            </Stack>
                        )}

                        {/* Parking */}
                        <Stack direction="column" spacing={2}>

                            <Typography className="h6" variant="h6">{t('Search_ParkingSpaces')}</Typography>

                            <FormControl sx={{ minWidth: 120, background: 'white !important' }}>
                                <ButtonGroup fullWidth variant="outlined" aria-label={t('Search_ParkingSpaces')}>
                                    {parkingSpacesMenuList != null && parkingSpacesMenuList.map((p) => (
                                        <Button
                                            key={p.UID}
                                            onClick={() => handleParkingButtonGroupClick(p.Value)}
                                            sx={{
                                                border: '1px solid var(--primary-color) !important',
                                                color: 'var(--primary-color) !important',
                                                padding: '.5rem !important',
                                                backgroundColor: state.searchState.ParkingSpaces === p.Value ? 'var(--button-group-active-color)' : 'transparent',
                                                '&:hover': {
                                                    backgroundColor: 'var(--button-group-active-color)',
                                                },
                                            }}
                                        >
                                            {p.DisplayValue}+ {/* Append '+' to DisplayValue */}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </FormControl>

                        </Stack>

                        {/* Total Area */}
                        <Stack direction="column" spacing={2}>

                            <Typography className="h6" variant="h6">{t('Search_MoreOptions')}</Typography>

                            <FormControl sx={{ minWidth: 120, background: 'white !important' }}>
                                <Select
                                    fullWidth
                                    inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                    labelId="total-area-selector-label"
                                    id="total-area-selector"
                                    input={<OutlinedInput />}
                                    value={state.searchState.MinTotalArea ?? '-1'}
                                    label={t('Search_MinSqM')}
                                    onChange={handleMinTotalAreaChange}>
                                    <MenuItem value="-1">{t('Search_MinSqM')}</MenuItem>
                                    {squareMetersMenuList != null && squareMetersMenuList.map((b) => (
                                        <MenuItem key={b.UID} value={b.Value}>{b.DisplayValue}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 120, background: 'white !important' }}>
                                <Select
                                    fullWidth
                                    inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                    labelId="total-area-selector-label"
                                    id="total-area-selector"
                                    input={<OutlinedInput />}
                                    value={state.searchState.MaxTotalArea ?? '-1'}
                                    label={t('Search_MaxSqM')}
                                    onChange={handleMaxTotalAreaChange}>
                                    <MenuItem value="-1">{t('Search_MaxSqM')}</MenuItem>
                                    {squareMetersMenuList != null && squareMetersMenuList.map((b) => (
                                        <MenuItem key={b.UID} value={b.Value}>{b.DisplayValue}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Added In The Last */}
                            <Stack direction="column" spacing={2}>

                                <FormControl sx={{ minWidth: 120, background: 'white !important' }}>
                                    <Select
                                        fullWidth
                                        inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                        labelId="added-in-the-last-selector-label"
                                        id="added-in-the-last-selector"
                                        input={<OutlinedInput />}
                                        value={state.searchState.PropertiesAdded ?? '-1'}
                                        label={t('Search_ShowPropertiesAdded')}
                                        onChange={handlePropertiesAddedChange}>
                                        <MenuItem value="-1">{t('Search_ShowPropertiesAdded')}</MenuItem>
                                        {listingAddedMenuList != null && listingAddedMenuList.map((b) => (
                                            <MenuItem key={b.UID} value={b.UID}>{b.DisplayValue}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Stack>

                            {/* Developments */}
                            {showDevelopmentFilter && (
                                <Stack direction="column" spacing={2}>

                                    <FormControl sx={{ minWidth: 120, background: 'white !important' }}>
                                        <Select
                                            label={t('Search_Development')}
                                            inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                            onChange={handleDevelopmentChange}
                                            value={state.searchState.DevelopmentID ?? '-1'}
                                            fullWidth
                                            labelId="added-in-the-last-selector-label"
                                            id="added-in-the-last-selector"
                                            input={<OutlinedInput />}
                                        >
                                            <MenuItem value="-1">{t('Search_Development')}</MenuItem>
                                            {developmentProvinceList !== null && developmentProvinceList.flatMap((p) => [
                                                <ListSubheader key={`parent-${p.ProvinceID}`} value="-2">{p.ProvinceName}</ListSubheader>,
                                                ...(developments ? developments
                                                    .filter(d => d.ProvinceID === p.ProvinceID)
                                                    .sort((a, b) => a.DevelopmentName.localeCompare(b.DevelopmentName))
                                                    .map((d) => (
                                                        <MenuItem key={d.DevelopmentID} value={d.DevelopmentID}>{d.DevelopmentName}</MenuItem>
                                                    )) : [])
                                            ])}
                                        </Select>
                                    </FormControl>

                                </Stack>
                            )}

                            {/* To Do - MLSID, the event trigger for valided mlsid */}
                            <Stack direction="column" spacing={2}>

                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="mls-id" style={{ fontFamily: 'var(--font-family)' }}>
                                        {t('Search_SearchByMLSID')}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="mls-id"
                                        value={mlsId}
                                        onChange={handleMLSIDStateChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label={t('Search_CmdSearch')}
                                                    onClick={handleMLSIDSearchSubmit}
                                                    onMouseDown={(event) => event.preventDefault()} // Prevents input focus behavior
                                                    edge="start"
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                        label={t('Search_SearchByMLSID')}
                                    />
                                </FormControl>

                            </Stack>


                            <FormControl fullWidth>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox checked={state.searchState.HasMultiMedia === "true"} onChange={handleMultiMediaCheckboxChange} />}
                                    labelPlacement="end"
                                    label={
                                        <Typography className="body1" variant="body1">
                                            {t("Search_Multimedia")}
                                        </Typography>
                                    }
                                />
                            </FormControl>

                        </Stack>

                    </Stack>

                </DialogContent>


                {/* Divider */}
                <Divider />

                {/* Reset and View Results Buttons */}
                <div style={{ padding: '1.5rem' }}>

                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" useFlexGap flexWrap="wrap">

                        {/* Reset Button */}
                        <Typography
                            className="secondary-medium"
                            variant="body1"
                            sx={{ color: 'var(--secondary-color) !important', textAlign: 'center', cursor: 'pointer' }}
                            role="button"
                            aria-label={t('Search_AppliedFilterClearAll')}
                            onClick={handleReset}>
                            {t('Search_Reset')}
                        </Typography>

                        {/* View Results Button */}
                        <Button className="primary-button" variant="contained" onClick={handleFiltersModalClose}>{t('Search_ViewResults')}</Button>

                    </Stack>

                </div>

            </Dialog>
        </>
    );
};

export default ListingSearchMoreOptions;


