import React, { useEffect, useState } from 'react';
import { Hidden, Stack, Skeleton } from '@mui/material';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { useTranslation } from 'react-i18next';
import { getListingDefaultImagePath, getListingImagePath } from '../utils/helper/ImageHelper';
import { DESCRIPTION, LANGUAGE } from '../../constants/constants';
import ListingDescription from '../../types/Listing/ListingDescription';
import { SiteSettings } from '../../types/TypesIndex';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';

interface Props {
    listingDetail: ListingDetail;
    macroPropertyTypes: LookupItem[];
    lookupList: LookupItem[];
    siteSettings: SiteSettings;
}

const ListingProductSchema: React.FC<Props> = ({ listingDetail, lookupList, macroPropertyTypes, siteSettings }) => {


    const [isLoading, setIsLoading] = useState(true);
    const { i18n } = useTranslation();
    const languageCode = i18n.language;

    
    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);


    const currentUrl = window.location.href; 
    const url = new URL(currentUrl);
    const hostUrl = `${url.protocol}//${url.host}/`; 

    const siteDefaultLanguage = siteSettings?.RegionSupportedLanguages.find(
        lang => lang.IsDefault.trim() === DESCRIPTION.ISDEFAULT.toString()
    );
    const siteDefaultLanguageCode = siteDefaultLanguage?.FullLanguageCode.trim() ?? LANGUAGE.DEFAULT;    

    // Listing Url
    let listingShortLink = hostUrl + listingDetail.MLSID;
    if (listingDetail.ShortLinks !== null
        && listingDetail.ShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && listingDetail.ShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        listingShortLink = hostUrl + listingDetail.ShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

    // Listing Image Data Handle
    let listingImageSrc =  hostUrl +  getListingDefaultImagePath();
    if (listingDetail.ListingImages.length > 0 ) {
        if (listingDetail.ListingImages.filter(i => i.Order == 1) !== null  && listingDetail.ListingImages.filter(i => i.Order == 1).length > 0) {
            let image =  listingDetail.ListingImages.filter(i => i.Order == 1)[0];
            listingImageSrc = getListingImagePath(image.FileName, listingDetail.RegionId, image.HasLargeImage, image.IsWatermarked)           
        } else {
            let image = listingDetail.ListingImages[0];
            listingImageSrc = getListingImagePath(image.FileName, listingDetail.RegionId, image.HasLargeImage, image.IsWatermarked)
        }
    }     

    let listingTitleAddress = listingDetail.TitleAddress;
    if (listingDetail.GeoDatas !== null
        && listingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode) !== null
        && listingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode).length > 0) {        
        listingTitleAddress = listingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode)[0].TitleAddress;
    }

    // Listing Description Data Handle   
    const filterDescriptionByUIDAndLanguage = (
        data: ListingDescription[],
        descriptionTypeUID: string
    ): string => {

        if (data == null || data.length <= 0)
            return "";

        let currentLanguageDescrption = data.filter(
            (item) =>
                item.DescriptionTypeUID == descriptionTypeUID &&
                item.LanguageCode === i18n.language
        );

        if (currentLanguageDescrption !== null && currentLanguageDescrption.length > 0)
            return currentLanguageDescrption[0].Description;

        let siteDefaultLanguageDescription = data.filter(
            (item) =>
                item.DescriptionTypeUID == descriptionTypeUID &&
                item.LanguageCode === siteDefaultLanguageCode
        );

        if (siteDefaultLanguageDescription !== null && siteDefaultLanguageDescription.length > 0)
            return siteDefaultLanguageDescription[0].Description;

        return "";
    };
    const listingDescriptionWithoutFormat = filterDescriptionByUIDAndLanguage(listingDetail.ListingDescriptions, DESCRIPTION.ID.toString());
   
    let listingH1Title = '';
    if (listingDetail.PropertyTypeUID !== null)
        listingH1Title += getLookupTranslationById(listingDetail.PropertyTypeUID.toString(), lookupList);
    if (listingDetail.TransactionTypeUID !== null && listingDetail.PropertyCategoryUID !== "2612")
        listingH1Title += " - " + getLookupTranslationById(listingDetail.TransactionTypeUID.toString(), lookupList);
    if (listingTitleAddress !== null)
        listingH1Title += " - " + listingTitleAddress;


    if (isLoading) {
        return (
            <Stack sx={{maxWidth: '100%'}}>
                <Skeleton variant="text" height={21} />
            </Stack>
        );
    }

    return (
        <Stack style={{ display: 'none' }} itemScope itemType="http://schema.org/Product">
            {/* Desktop */}
            <Hidden mdDown>
                <span style={{ display: 'none' }} itemProp="productID">{listingDetail.MLSID}</span>
                <span style={{ display: 'none' }} itemProp="brand">{window.theme}</span>
                <span style={{ display: 'none' }} itemProp="identifier">{listingDetail.MLSID}</span>
                <span style={{ display: 'none' }} itemProp="sku">{listingDetail.MLSID}</span>
                <span style={{ display: 'none' }} itemProp="url">{listingShortLink}</span>
                <img style={{ display: 'none' }} itemProp="image" src={listingImageSrc} />
                {listingDetail.YearBuilt && listingDetail.YearBuilt !== null && (
                    <span style={{ display: 'none' }} itemProp="productionDate">{listingDetail.YearBuilt}</span>
                )}
                {listingH1Title !== null && listingH1Title !== "" && (
                    <span style={{ display: 'none' }} itemProp="name">{listingH1Title}</span>
                )}                
                {listingDescriptionWithoutFormat && listingDescriptionWithoutFormat !== null && (
                    <span style={{ display: 'none' }} itemProp="description">{listingDescriptionWithoutFormat}</span>
                )}            
                <div itemScope itemProp="offers" itemType="http://schema.org/Offer">
                    <span style={{ display: 'none' }}  itemProp="price">{listingDetail.ListingPrice}</span> 
                    <span style={{ display: 'none' }}  itemProp="priceCurrency">{listingDetail.ListingCurrency}</span> 
                    <span style={{ display: 'none' }}  itemProp="url">{listingShortLink}</span>
                    <span style={{ display: 'none' }}  itemProp="availability">InStock</span>                
                </div>    
            </Hidden>

            {/* Mobile */}
            <Hidden mdUp>
                <span style={{ display: 'none' }} itemProp="productID">{listingDetail.MLSID}</span>
                <span style={{ display: 'none' }} itemProp="brand">{window.theme}</span>
                <span style={{ display: 'none' }} itemProp="identifier">{listingDetail.MLSID}</span>
                <span style={{ display: 'none' }} itemProp="sku">{listingDetail.MLSID}</span>
                <span style={{ display: 'none' }} itemProp="url">{listingShortLink}</span>
                <img style={{ display: 'none' }} itemProp="image" src={listingImageSrc} />
                {listingDetail.YearBuilt && listingDetail.YearBuilt !== null && (
                    <span style={{ display: 'none' }} itemProp="productionDate">{listingDetail.YearBuilt}</span>
                )}
                {listingH1Title !== null && listingH1Title !== "" && (
                    <span style={{ display: 'none' }} itemProp="name">{listingH1Title}</span>
                )}                
                {listingDescriptionWithoutFormat && listingDescriptionWithoutFormat !== null && (
                    <span style={{ display: 'none' }} itemProp="description">{listingDescriptionWithoutFormat}</span>
                )}
                <div itemScope itemProp="offers" itemType="http://schema.org/Offer">
                    <span style={{ display: 'none' }}  itemProp="price">{listingDetail.ListingPrice}</span> 
                    <span style={{ display: 'none' }}  itemProp="priceCurrency">{listingDetail.ListingCurrency}</span> 
                    <span style={{ display: 'none' }}  itemProp="url">{listingShortLink}</span>
                    <span style={{ display: 'none' }}  itemProp="availability">InStock</span>                
                </div>  
            </Hidden>
        </Stack>
    );
};

export default ListingProductSchema;
