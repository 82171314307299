import memoize from 'memoizee';
import { Agent, Office, AgentSearchResponse, OfficeSearchResponse } from '../types/TypesIndex';
import { PAGE_TYPE } from '../constants/constants';

const cacheDuration = 60 * 60 * 1000; // Cache for 1 hour
const memoizedFetch = memoize(async (url: string, options: RequestInit): Promise<any> => {
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
}, { promise: true, maxAge: cacheDuration });


// Get the agent content by Agent ID eg, Agent Profile and Lsiting Detail pages
const fetchAgentDataByAgentId = async (agentid: string, pageType?: string): Promise<Agent | null> => {
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_AGENT}`;
    let filter = ''
    if (window.tenantid !== undefined) {
        filter +=`content/TenantId eq ${window.tenantid} and `
    }        
    
    if (agentid !== undefined) {
        filter += `content/AgentId eq ${agentid}`
    }

    if (!pageType || pageType != PAGE_TYPE.LISTING_DETAIL) {
        filter += ` and content/IsRegionalOffice eq false and content/Disabled eq false and content/Hidden eq false` 
    } 
     
    const searchRequestBody = {
        count: true,
        skip: 0,
        top: 1,
        searchMode: 'any',
        queryType: 'simple',
        search: '*',
        filter: filter 
    };

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchRequestBody),
    });
    if (data.value.length === 0) { return null; }

    return data.value[0].content;
};

// Get the Agent List by Team Id eg, Team Profile Page
const featchAgentListByTeamId = async (teamid: string): Promise<AgentSearchResponse> => {
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_AGENT}`;
    let filter = ''

    if (window.tenantid !== undefined && teamid !== undefined)
        filter +=`content/TenantId eq ${window.tenantid} and content/TeamId eq ${teamid} and content/ExcludeAgentTraining eq false and content/Disabled eq false and content/AgentName ne null and content/Hidden eq false`
     
    const searchRequestBody = {
        count: true,
        skip: 0,
        top: 600,
        searchMode: 'any',
        queryType: 'simple',
        search: '*',
        facets: ["content/SpokenLanguages,count:500,sort:count"],
        // To Do: select: ["content/AgentId, content/AgentName, content/RegionId, content/AgentPhotoId, content/IsSalesAssociate, content/ExcludeAgentTraining, content/AgentLicenseNumber, content/AgentShortLinks"],
        filter: filter
    };

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(searchRequestBody),
    });

    return data;
};


// Get the Agent List by Office Id eg, Office Profile Page
const featchAgentListByOfficeId = async (officeId: string): Promise<AgentSearchResponse> => {
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_AGENT}`;
    let filter = ''

    if (window.tenantid !== undefined && officeId !== undefined)
        filter +=`content/TenantId eq ${window.tenantid} and content/OfficeId eq ${officeId} and content/ExcludeAgentTraining eq false and content/Disabled eq false and content/AgentName ne null and content/Hidden eq false`
     
    const searchRequestBody = {
        count: true,
        skip: 0,
        top: 600,
        searchMode: 'any',
        queryType: 'simple',
        search: '*',
        facets: ["content/OfficeCountry,count:500,sort:count", "content/SpokenLanguages,count:500,sort:count"],
        // To Do: select: ["content/AgentId, content/AgentName, content/RegionId, content/AgentPhotoId, content/IsSalesAssociate, content/ExcludeAgentTraining, content/AgentLicenseNumber, content/AgentShortLinks"],
        filter: filter
    };

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(searchRequestBody),
    });

    return data;
};




// Get the Office Content by Office Id eg, Office Profile Page
const fetchOfficeDataByOfficeId = async (officeId: string): Promise<Office | null> => {
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_OFFICE}`;
    let filter = ''

    if (window.tenantid !== undefined)
        filter +=`content/TenantId eq ${window.tenantid} and `

    if (officeId !== undefined)
        filter +=`content/OfficeId eq ${officeId} and `

    filter +=`content/IsRegionalOffice eq false and content/Disabled eq false`

    const searchRequestBody = {
        count: true,
        skip: 0,
        top: 1,
        searchMode: 'any',
        queryType: 'simple',
        search: '*',
        // filter: `content/OfficeId eq ${officeId}`
        filter:filter
    };

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchRequestBody),
    });

    if (data.value.length === 0) { return null; }
    return data.value[0].content;
};

// Get the Office List via Office Search
const fetchOfficeListData = async (body: any): Promise<OfficeSearchResponse> => {
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_OFFICE}`;

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Content-length': body.length,
        },
        body: JSON.stringify(body),
    });
    return data;
};


// Get the Agent List via Agent Search
const fetchAgentListData = async (body: any): Promise<AgentSearchResponse> => {
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_AGENT}`;

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Content-length': body.length,
        },
        body: JSON.stringify(body),
    });
    return data;
};


const fetchAgentSpokenLanguagesByRegionId = async (): Promise<AgentSearchResponse> => {
    const apiEndpoint = `${process.env.REACT_APP_API_SEARCH_AGENT}`;
    let filter = ''

    if (window.tenantid !== undefined && window.macroregionid !== undefined) {
        filter += `content/TenantId eq ${window.tenantid} and content/MacroRegionId eq ${window.macroregionid} and `
        filter += `content/Category eq 1 and content/ExcludeAgentTraining eq false and content/Disabled eq false and content/IsRegionalOffice eq false and content/AgentName ne null and content/Hidden eq false and content/IsDisabledOffice eq false`
    }

    const searchRequestBody = {
        count: true,
        skip: 0,
        top: 0,
        searchMode: 'any',
        queryType: 'simple',
        search: '*',
        facets: ["content/SpokenLanguages,count:500,sort:count"],
        filter: filter
    };

    const data = await memoizedFetch(apiEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(searchRequestBody),
    });

    return data;
};

export { 
    fetchOfficeDataByOfficeId
    , featchAgentListByOfficeId
    , featchAgentListByTeamId
    , fetchAgentDataByAgentId
    , fetchOfficeListData
    , fetchAgentListData
    , fetchAgentSpokenLanguagesByRegionId };
