import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import Listing from '../pages/listing/ListingSearch';
import ListingCardDetail from '../pages/listing/ListingDetails';
import ListingDetails from '../pages/listing/ListingDetails';
import ProfileSearch from '../pages/profilesearch/ProfileSearch';
import AgentProfile from '../pages/profile/AgentProfile';
import OfficeProfile from '../pages/profile/OfficeProfile';
import TeamProfile from '../pages/profile/TeamProfile';

import DevelopmentDetail from '../pages/development/DevelopmentDetail';
import DevelopmentSearch from '../pages/development/DevelopmentSearch';

import SellersPortal from '../pages/sellersportal/SellersPortal';

import Contact from '../pages/Contact';
import CMSPage from '../pages/CMSPage';
import Blog from '../pages/Blog';
import BlogPost from '../pages/BlogPost';
import HomeCMS from '../pages/HomeCMS';
import AreaGuideDetail from '../pages/areaguide/AreaGuideDetail';



const AppRoute: React.FC = () => {

  const location = useLocation();

  useEffect(() => {
    const hostname = window.location.hostname;
    const pagePath = location.pathname + location.search;

    // Initialize dataLayer if not already initialized
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'pageview',
      page: pagePath,
      hostname: hostname,
    });
  }, [location]);



  return (
    <Routes>
      <Route path="/Home" element={<Home value={0} />} />
      <Route path="/" element={<HomeCMS />} />
      <Route path="/:content" element={CMSPage ? <CMSPage /> : null} />
      <Route path="/:languagetag/listings/:propertytype/:transactiontype/:city/:listingaddress/:content" element={<ListingCardDetail />} />
      <Route path="/:content/pesquisa/:macroregion/:province/:localzone/:propertytype/:type" element={CMSPage ? <CMSPage /> : null} />

      <Route path="/:language/:content" element={CMSPage ? <CMSPage /> : null} />

      <Route path="/listings" element={<Listing  />} />
      <Route path="/PublicListingFull.aspx" element={<ListingCardDetail  />} />
      <Route path="/PubScripts/MapsProcessor.aspx" element={<ListingCardDetail  />} />
     
      <Route path="/publiclistinglist.aspx" element={<Listing  />} />
      <Route path="/ CustomerFeedback.aspx" element={<Listing  />} />
      <Route path="/:languagetag/:pagetype/:propertytype/:city/:content" element={<ListingCardDetail />} />
   

      <Route path="/:languagetag/:pagetype/:propertytype/:transactiontype/:listingaddress/:content" element={<ListingCardDetail />} />
      <Route path="/:languagetag/:pagetype/:propertytype/:transactiontype/:city/:listingaddress/:content" element={<ListingCardDetail />} />\
      <Route path="/:languagetag/listings/:propertytype/:transactiontype/:city/:listingaddress/:content" element={<ListingCardDetail />} />
      <Route path="/:languagetag/:pagetype/:propertytype/:transactiontype/:city/:content" element={<ListingCardDetail />} />

      <Route path="/:languagetag/:pagetype/:propertytype/:macropropertytype/:transactiontype/:city/:listingaddress/:content" element={<ListingCardDetail />} />
      <Route path="/:languagetag/:pagety/:city/:content" element={<ListingCardDetail />} />
 
      <Route path="/:languagetag/:pagetype/:propertytype/:propertytype2/:macropropertytype/:transactiontype/:city/:listingaddress/:content" element={<ListingCardDetail />} />

      <Route path="/ProfileSearch" element={<ProfileSearch />} />
      <Route path="/:languagetag/procurar-corretor/:provincename/:cityname" element={<ProfileSearch />} />
      
      <Route path="/office/:url" element={<OfficeProfile />} />
      <Route path="/office/:id" element={<OfficeProfile />} />
      <Route path="/office/:languagetag/:pagetype/:countryorregion/:cityorlozalzone/:officename/:officeid" element={<OfficeProfile />} />
      <Route path="/:languagetag/offices/:countryorregion/:cityorlozalzone/:officename/:officeid" element={<OfficeProfile />} />
      <Route path="/:languagetag/escritorios/:countryorregion/:cityorlozalzone/:officename/:officeid" element={<OfficeProfile />} />

      
      <Route path="/team/:url" element={<TeamProfile />} />
      <Route path="/team/:id" element={<TeamProfile />} />
      <Route path="/team/:languagetag/:pagetype/:countryorregion/:cityorlozalzone/:teamname/:teamid" element={<TeamProfile />} />

      <Route path="/agent/:url" element={<AgentProfile />} />
      <Route path="/agent/:id" element={<AgentProfile />} />
      <Route path="/AgentProfile.aspx" element={<AgentProfile />} />
      <Route path="/agent/:languagetag/:pagetype/:countryorregion/:cityorlozalzone/:agentname/:agentid" element={<AgentProfile />} />
      <Route path="/:languagetag/agents/:countryorregion/:cityorlozalzone/:agentname/:agentid" element={<AgentProfile />} />

      <Route path="/developments/:url/:developmentId" element={<DevelopmentDetail />} />
      <Route path="/developments/:developmentId" element={<DevelopmentDetail />} />
      <Route path="/developments" element={<DevelopmentSearch />} />

      <Route path="/Blog" element={<Blog />} />
      <Route path="/Blog/:id" element={<BlogPost />} />

     

      <Route path="/Contact" element={<Contact />} />
      <Route path="/v1/:content" element={<CMSPage />} />

      <Route path="/sellersportal" element={<SellersPortal />} />
  
    </Routes>
  );
};

export default AppRoute;
