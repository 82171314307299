import React, { useState, useEffect } from 'react';
import { Link, Stack, Card, CardHeader, CardContent, CardMedia, Typography, CardActions, Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import ContactLink from '../utils/ContactLink';
import HitType from '../../types/Hit'
import { hitService } from '../../services/HitService';
import SiteSettings from '../../types/Settings/SiteSettings';
import { useTranslation } from 'react-i18next';
import { PAGE_TYPE } from '../../constants/constants';


// To Do: the data logic needs to be updated, no site setting loaded separate

interface ProfileContactInfoProps {
  AgentPhone: string,
  DirectDialPhoneNumber: string,
  OfficePhone: string,
  Email: string,
  AgentId?: number,
  OfficeId?: number,
  TeamId?: number,
  RegionId?: number,
  TenantId?: number,
  siteSettings?: SiteSettings
}

const ProfileContactInfo: React.FC<{ contactInfo: ProfileContactInfoProps, page?: string }> = ({ contactInfo, page = "" }) => {

  // Hook Declaration
  const [showMorePhone, setShowMorePhone] = useState(false);
  const [showMoreDirectDial, setShowMoreDirectDial] = useState(false);
  const [showMoreOfficePhone, setShowMoreOfficePhone] = useState(false);
  const [showMoreEmail, setShowMoreEmail] = useState(false);
  const { t, i18n } = useTranslation();

  const triggerHitService = (hitName: string) => {
    const hitData = {
      TenantId: contactInfo.TenantId,
      RegionId: contactInfo.RegionId,
      LanguageCode: i18n.language,
      AgentId: contactInfo.AgentId,
      OfficeId: page === PAGE_TYPE.AGENTSEARCH ? 0 : contactInfo.OfficeId,
      SiteType: contactInfo.siteSettings?.SiteType ?? "",
      HitType: hitName,
    };

    hitService(hitData);
  };


  useEffect(() => {

    if (showMorePhone) {
      if (page === PAGE_TYPE.AGENT) {
        triggerHitService('AgentProfileViewAgentPhone');
      } else if (page === PAGE_TYPE.AGENTSEARCH) {
        triggerHitService('AgentSearchViewPhone');
      }
    }
  }, [showMorePhone]);

  useEffect(() => {
    if (showMoreDirectDial) {
      if (page === PAGE_TYPE.AGENT) {
        triggerHitService('AgentProfileViewDirectPhone');

      }
    }
  }, [showMoreDirectDial]);

  useEffect(() => {
    if (showMoreOfficePhone) {
      if (page === PAGE_TYPE.AGENT) {
        triggerHitService('AgentProfileViewOfficePhone');
      } else if (page === PAGE_TYPE.OFFICE) {
        triggerHitService('OfficeProfileViewOfficePhone');
      } else if (page === PAGE_TYPE.OFFICESEARCH) {
        triggerHitService('OfficeSearchViewPhone');
      }

    }
  }, [showMoreOfficePhone]);

  // useEffect(() => {
  //   if (showMoreEmail) {
  //     triggerHitService('AgentFull_Email');
  //   }
  // }, [showMoreEmail]);
  // To Do: Add sitesetting "ShowFullContactInfo" for Hide/Show the Show More Text
  return (
    <Stack spacing={1} >

      {/* Agent Phone */}
      {contactInfo.AgentPhone &&
        <Stack title={t('AgentFull_Phone')} id="agent-phone" direction="row" spacing={2} useFlexGap>
          <SmartphoneIcon sx={{ fontSize: 26, padding: '4px', background: 'var(--button-active-color)', borderRadius: '100px', color: 'var(--primary-color)' }} />
          <ContactLink type='tel' info={contactInfo.AgentPhone} showFull={showMorePhone} toggleShowFull={() => setShowMorePhone(!showMorePhone)} />
        </Stack>
      }

      {/* Agent Direct Dial PhoneNumber Only */}
      {contactInfo.DirectDialPhoneNumber &&
        <Stack title={t('AgentFull_DirectDialPhone')} id="agent-direct-dial" direction="row" spacing={2} useFlexGap>
          <PhoneIcon sx={{ fontSize: 26, padding: '4px', background: 'var(--button-active-color)', borderRadius: '100px', color: 'var(--primary-color)' }} />
          <ContactLink type='tel' info={contactInfo.DirectDialPhoneNumber} showFull={showMoreDirectDial} toggleShowFull={() => setShowMoreDirectDial(!showMoreDirectDial)} />
        </Stack>
      }

      {/* Office Phone */}
      {contactInfo.OfficePhone &&
        <Stack title={t('AgentFull_OfficePhone')} id="office-phone" direction="row" spacing={2} useFlexGap alignItems="center">
          <ApartmentIcon sx={{ fontSize: 26, padding: '4px', background: 'var(--button-active-color)', borderRadius: '100px', color: 'var(--primary-color)' }} />
          <ContactLink type='tel' info={contactInfo.OfficePhone} showFull={showMoreOfficePhone} toggleShowFull={() => setShowMoreOfficePhone(!showMoreOfficePhone)} />
        </Stack>
      }

      {/* Agent / Office Email */}
      {contactInfo.Email &&
        <Stack title={t('AgentFull_Email')} id="email" direction="row" spacing={2} useFlexGap>
          <EmailIcon sx={{ fontSize: 26, padding: '4px', background: 'var(--button-active-color)', borderRadius: '100px', color: 'var(--primary-color)' }} />
          <ContactLink type='mailto' info={contactInfo.Email} showFull={showMoreEmail} toggleShowFull={() => setShowMoreEmail(!showMoreEmail)} />
        </Stack>
      }
    </Stack>
  );
};

export default ProfileContactInfo;
