import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Stack, useMediaQuery, Box, Skeleton, Dialog, DialogContent, Toolbar, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import ListingBannersChipsSeeMorePhotos from '../../components/listing/ListingBannersChipsSeeMorePhotos';
import { Panorama as PanoramaIcon, Streetview as StreetviewIcon, ThreeSixty as ThreeSixtyIcon, Image as ImageIcon, YouTube as YouTubeIcon } from '@mui/icons-material';
import ListingDetailData from '@/types/Listing/ListingDetailData';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import ListingImages from '../../types/Listing/ListingImages';
import LookupItem from '../../types/LookupItem';
import { getListingDefaultImagePath, getListingImagePath } from '../utils/helper/ImageHelper';
import { getYouTubeEmbedUrl, isValidYouTubeUrl } from '../utils/helper/FunctionHelper';
import PanoramaPhotosphereOutlinedIcon from '@mui/icons-material/PanoramaPhotosphereOutlined';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import CloseIcon from '@mui/icons-material/Close';
import ListingStatusChips from '../utils/ListingStatusChips';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import PanoramaPhotosphereSelectIcon from '@mui/icons-material/PanoramaPhotosphereSelect';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface Props {
    property: ListingDetailData;
    lookupitem: LookupItem[];
}

const ListingDesktopImageSlider: React.FC<Props> = ({ property, lookupitem }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));

    const [isLoading, setIsLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [hasVideo, setHasVideo] = useState(false);
    const [show360Image, setShow360Image] = useState(false);
    const [showPanoImage, setShowPanoImage] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [error, setError] = useState<string | null>(null);

    const videoRef = useRef<HTMLDivElement | null>(null);

    const [isAtBottom, setIsAtBottom] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [lastScrollTop, setLastScrollTop] = useState(window.scrollY);
    const [opacity, setOpacity] = useState(1);
    const componentRef = useRef(null);

    const image360UID = "5103";
    const panoramaUID = "5104";
    const ListingDetail = property?.value[0]?.content;

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 200);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (ListingDetail?.VideoLinkURL && isValidYouTubeUrl(ListingDetail.VideoLinkURL)) {
            setHasVideo(true);
        }
    }, [ListingDetail]);

    const handleImageClick = (index: number) => {
        console.log("Clicked image index:", index);
        console.log("ListingDetail.ListingImages:", ListingDetail?.ListingImages);

        if (ListingDetail?.ListingImages && ListingDetail.ListingImages.length > 0) {
            const imagePath = getListingImagePath(
                ListingDetail.ListingImages[index].FileName,
                ListingDetail.RegionId,
                ListingDetail.ListingImages[index].HasLargeImage,
                ListingDetail.ListingImages[index].IsWatermarked
            );
            console.log("Image path for clicked image:", imagePath);
        }

        setSelectedImageIndex(index);
        setOpenDialog(true);
    };


    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handle360ImageClick = () => {
        setShow360Image(!show360Image);
        setError(null);
    };

    const handleShowPanoImageClick = () => {
        setShowPanoImage(!showPanoImage);
        setError(null);
    };

    const filterByDescriptionTypeUID = (data: ListingImages[], imageUD: string): ListingImages[] => {
        return data.filter((item) => item.ImageQualityTypeUID === imageUD);
    };

    const scrollToVideo = () => {
        videoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
            window.scrollBy({ top: -120, behavior: 'smooth' });
        }, 500);
    };

    const handleScroll = () => {
        if (componentRef.current) {
            const component = componentRef.current;
            const componentRect = component.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollY = window.scrollY;
            const scrollPosition = scrollY + viewportHeight;
    
            const bottomOffset = 700; // Define the offset before the end of the document
    
            // Detect if at the bottom of the component
            const atComponentBottom = componentRect.bottom <= viewportHeight;
    
            // Detect if near the bottom of the document (with offset)
            const nearDocumentBottom = scrollPosition >= documentHeight - bottomOffset;
    
            // Update state for both cases
            setIsAtBottom(atComponentBottom || nearDocumentBottom);
    
            const fadeOutStart = 10;
            const fadeOutEnd = component.scrollHeight - component.clientHeight; // Adjust for component scroll
            const scrollUpThreshold = 100; // Threshold for scrolling up by 100px
    
            // Determine scroll direction within the component
            const componentScrollTop = component.scrollTop; // Scroll position inside the component
    
            if (scrollY > lastScrollY && scrollY > fadeOutStart && scrollY < fadeOutEnd) {
                // Scrolling down in the window
                const opacityValue = 1 - (scrollY - fadeOutStart) / (fadeOutEnd - fadeOutStart);
                setOpacity(opacityValue);
            } else if (lastScrollY - scrollY > scrollUpThreshold || lastScrollTop - componentScrollTop > scrollUpThreshold) {
                // Scrolling up in the window or inside the component
                setOpacity(1);
    
                // Ensure the button is visible when scrolling up
                setIsAtBottom(false);
            }
    
            // Update last scroll positions
            setLastScrollY(scrollY);
            setLastScrollTop(componentScrollTop);
        }
    };
    
    useEffect(() => {
        const component = componentRef.current;
    
        // Attach scroll listeners to both window and the component
        window.addEventListener('scroll', handleScroll);
        if (component) {
            component.addEventListener('scroll', handleScroll);
        }
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (component) {
                component.removeEventListener('scroll', handleScroll);
            }
        };
    }, [lastScrollY, lastScrollTop]);
    
    
    
    

    


    const image360URL = filterByDescriptionTypeUID(ListingDetail.ListingImages, image360UID);
    const panoImageURL = filterByDescriptionTypeUID(ListingDetail.ListingImages, panoramaUID);
    const hasImage = hasVideo || (show360Image && image360URL.length > 0) || (showPanoImage && panoImageURL.length > 0) || (ListingDetail.ListingImages?.length > 0);

    if (isLoading) {
        return (
            <Stack spacing={3}>
                <Skeleton variant="rectangular" height={500} />
                <Skeleton variant="rectangular" height={500} />
                <Skeleton variant="rectangular" height={500} />
            </Stack>
        );
    }

    return (
        <>
            <Stack ref={componentRef} direction="column" spacing={3} style={{ marginBottom: '1.5rem', position: 'relative', overflow: 'hidden' }}>
                {hasVideo && (
                    <Box ref={videoRef} mt={2}>
                        <iframe
                            style={{ border: 'none', maxWidth: '100%', width: '100%', borderRadius: '1rem' }}
                            width="500"
                            height="450"
                            src={getYouTubeEmbedUrl(ListingDetail.VideoLinkURL)}
                            title={t("SocialMedia_YouTube")}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Box>
                )}

                {show360Image && image360URL.length > 0 && (
                    image360URL.sort((a,b) => a.Order - b.Order).map((item, currentIndex) => (
                        <ReactPhotoSphereViewer
                            key={currentIndex}
                            src={getListingImagePath(item.FileName, ListingDetail.RegionId, item.HasLargeImage, item.IsWatermarked)}
                            height="60vh"
                            width="100%"
                            mousewheel={false}
                        />
                    ))
                )}

                {showPanoImage && panoImageURL.length > 0 && (
                    panoImageURL.sort((a,b) => a.Order - b.Order).map((value, currentIndex1) => (
                        <ReactPhotoSphereViewer
                            key={currentIndex1}
                            src={getListingImagePath(panoImageURL[0].FileName, ListingDetail.RegionId, panoImageURL[0].HasLargeImage, panoImageURL[0].IsWatermarked)}
                            mousewheel={false}
                            moveSpeed={0}
                            height="35vh"
                            width="100%"
                            navbar={['fullscreen']}
                        />
                    ))
                )}

                {!hasImage ? (
                    <Stack direction="column" spacing={3} style={{ marginBottom: '1.5rem', position: 'relative' }}>
                        {/* <ListingBannersChipsSeeMorePhotos property={property} lookupitem={lookupitem} showViewGallery={false} /> */}
                        
                        <img
                            id="defaultImage"
                            style={{ maxWidth: '100%', width: '100%' }}
                            src={getListingDefaultImagePath()}
                            alt=""
                            loading="lazy"
                        />
                    </Stack>
                ) : (
                    ListingDetail.ListingImages?.sort((a,b) => a.Order - b.Order).map((image, index) => (
                        <div key={index} onClick={() => handleImageClick(index)}>
                            {index === 0 && (
                                <div key={index} style={{ position: 'absolute', top: 24, paddingLeft: '1.5rem', paddingRight: '1.5rem', width: '100%' }}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" flexDirection="row" mt={0}>
                                        {/* Global Listing Status Chips (component) */}
                                        <ListingStatusChips listingDetail={property?.value[0]?.content} lookupList={lookupitem}/>
                                        <Button type="button" role="button" aria-label={t('ListingFull_ViewPhotoGallery')} variant="outlined" className="brand-primary" sx={{ border: '1px solid var(--primary-color)', background: 'white !important' }} onClick={() => handleImageClick(1)}>{t('ListingFull_ViewPhotoGallery')}</Button>
                                    </Stack>
                                </div>
                            )}
                            <img
                                style={{ maxWidth: '100%', width: '100%', borderRadius: '1rem', cursor: 'pointer' }}
                                src={getListingImagePath(image.FileName, ListingDetail.RegionId, image.HasLargeImage, image.IsWatermarked)}
                                alt={image.Name || image.FileName}
                                loading="lazy"
                            />
                        </div>
                    ))
                )}

                
                <div style={{ position: 'relative' }}>
                <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen className="fullscreen-gallery slider-fullscreen-desktop" style={{zIndex: '9999999'}}>
                    <DialogContent sx={{ backgroundColor: 'black', color: 'white', alignContent: 'center', maxWidth: '100vw', maxHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'  }}>
                        {/* Toolbar with Pagination and Close Button */}
                        <Toolbar sx={{ paddingLeft: '0 !important', paddingRight: '0 !important', paddingBottom: '1.5rem', justifyContent: 'space-between', width: '100%' }}>
                            <Box className="custom-pagination"
                                sx={{
                                    padding: '8px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    borderRadius: '4px',
                                    color: 'white',
                                    display: 'inline-block',
                                    fontSize: '1rem',
                                }}
                            />
                            <IconButton onClick={handleCloseDialog}>
                                <CloseIcon sx={{ color: 'white !important' }} />
                            </IconButton>
                        </Toolbar>

                        {/* Swiper in Dialog */}
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            navigation
                            pagination={{
                                type: 'fraction',
                                el: '.custom-pagination',
                            }}
                            initialSlide={selectedImageIndex}  // Start from the clicked image
                            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {ListingDetail.ListingImages.map((image, index) => (
                                <SwiperSlide className="no-select" key={index} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={getListingImagePath(image.FileName, ListingDetail.RegionId, image.HasLargeImage, image.IsWatermarked)}
                                        alt={image.Name || image.FileName} style={{ objectFit: 'contain', width: '100%', aspectRatio: '3 / 2', maxHeight: '100%' }} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </DialogContent>
                </Dialog>
                </div>

                {/* Toolbar */}
                <div  style={{
                    position: isAtBottom ? 'absolute' : 'fixed',
                    display: isAtBottom ? 'none' : 'block',
                    top: isAtBottom ? 'auto' : '50%',
                    bottom: isAtBottom ? '20px' : 'auto',
                    right: '84px',
                    transform: isAtBottom ? 'none' : 'translateY(-50%)',
                    zIndex: 1,
                    transition: 'opacity 0.2s ease-in-out', // Faster fade-out transition
                }} className="Toolbar-ImageSlider">
                    <Stack direction="column" spacing={1}>
                        {hasVideo && (
                            <Tooltip title="Vídeo" placement="left">
                                <IconButton sx={{ background: 'white', color: 'var(--primary-color)' }} className='sticky-button' aria-label="Vídeo" onClick={scrollToVideo}>
                                    <VideocamIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {image360URL.length > 0 && (
                            <Tooltip title="360" placement="left">
                                <IconButton sx={{ background: 'white', color: 'var(--primary-color)' }} className='sticky-button' aria-label="360" onClick={handle360ImageClick}>
                                    <ThreeSixtyIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {panoImageURL.length > 0 && (
                            <Tooltip title="Panorama" placement="left">
                                <IconButton sx={{ background: 'white', color: 'var(--primary-color)' }} className='sticky-button' aria-label="Panorama" onClick={handleShowPanoImageClick}>
                                    <PanoramaPhotosphereSelectIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        
                        <IconButton sx={{ background: 'white', color: 'var(--primary-color)', display: 'none' }} aria-label={t("ListingFull_StreetView")}>
                            <StreetviewIcon />
                        </IconButton>
                    </Stack>
                </div>
            </Stack>
        </>
    );
};

export default ListingDesktopImageSlider;
