import { Payload } from '@/types/BlogPost';
import MetaTags from '../components/utils/seo/MetaTags';
import { Chip, CircularProgress, Stack, Container, Typography, Box } from '@mui/material';
import {
  Favorite as FavoriteIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface BlogPostParam extends Record<string, string | undefined> {
  id: string;

}
const BlogPost = () => {
  const params = useParams<BlogPostParam>();
  const [post, setPost] = useState<Payload>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    // Simulate loading delay or perform data fetching
    const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const route = `api/v1/blog/${params.id}?render-areas=true`;
    console.log(route);
    if (params.id) {
      fetch(`/cms/${route}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error fetching data');
          }
          return response.text();
        })
        .then(html => setPost(JSON.parse(html)))
        .catch(error => {
          console.error('Error fetching data:', error);
          navigate("/");
        });
    }

  }, [params.id]);

  if (!post) return <div>Loading...</div>;



  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
      </div>
    );
  }

  return (

    <>
      <MetaTags metaTags={{
        title: post.seoTitle ?? post.title,
        metaTitle: post.seoTitle ?? post.title,
        metaDescription: post.seoDescription ?? post.description,
        metaKeywords: '',
        image: '',
        url: window.location.href,
        type: 'article',
        sitename: '', 
        // image: post.image?._rendered, TODO: Add image to the post object
      }}
      />
      
      <Container maxWidth="xl" className="text-align-left" sx={{ paddingTop: { xs: '2.5rem', md: '3rem' }, paddingBottom: { xs: '2.5rem', md: '3rem' }, }}>
        
        <Stack direction="column" spacing={5} alignItems="center">
          
          {/* To Do - Update breadcrumbs */}
          <Stack direction="row" alignItems="center" justifyContent="flex-start" flexWrap="wrap" itemType="http://schema.org/BreadcrumbList" sx={{width: '100%'}}>
          
          <a style={{ fontSize: '0.875rem' }} href="/blog">
            <span itemProp="name">Blog</span>
            </a>
            
            <KeyboardArrowRightIcon className="flip-text" style={{ fontSize: 'Medium', color: 'rgba(0, 0, 0, 0.6)!important' }} />
            
            <a className="breadcrumbs-last-item" href="#" style={{ fontSize: '0.875rem' }}>
            {post.title && (
              <span itemProp="name">{post.title}</span>
            )}
              </a>
              
              </Stack>
              
              <Stack direction="column" spacing={3} textAlign="center" sx={{margin: 'auto', maxWidth: '680px'}}>
                
                {/* Category Chip */}
                {post.category && (
                  <Box>
                    <Chip id="post-category" label={post.category} variant="outlined" />
                    </Box>
                  )}
                  
                  {/* Blog Post Title */}
                  {post.title && (
                    <Typography id="post-title" className="h2" variant="h2" textAlign="center" sx={{fontSize: '3rem !important'}}>
                      {post.title}
                      </Typography>
                    )}
                    
                    {/* Blog Post Description */}
                    <Typography id="post-description" className="body1" variant="body1" textAlign="center">
                      {post.description}
                      </Typography>
                      
                      <Stack id="author-date" direction="row" spacing={1} textAlign="center" justifyContent="center" alignItems="center" useFlexGap>
                        
                        {/* Blog Author */}
                        <Typography className="body2 secondary-text" variant="body2">
                          {post.author}
                          </Typography>
                          
                          {/* Published Date */}
                          {post.publishDate && (
                            <>
                            <Typography className="body2 secondary-text" variant="body2">
                              /
                              </Typography>
                              <Typography className="body2 secondary-text" variant="body2">
                                {new Date(post.publishDate).toLocaleDateString()}
                                </Typography>
                                </>
                              )}
                              
                              </Stack>
                              
                              {/* To Do - Add post image */}

                              </Stack>

                              {/* To Do - Test how description is appearing */}
                              <div id="post-content" style={{maxWidth: '680px;'}} dangerouslySetInnerHTML={{ __html: post.post._rendered }} />
                                                           
                              </Stack>
                
                              </Container>

      
    </>
  );
};

export default BlogPost;
