import React, { useContext, ReactNode } from "react";
//import { ThemeContext, ThemeProvider } from "../contexts/ThemeContext";

interface LayoutProps {
  startingTheme: string; // Adjust the type based on your actual use case
  children: ReactNode;
}

function Layout({ startingTheme, children }: LayoutProps) {
  return (
  //  <ThemeProvider startingTheme={startingTheme}>
      <LayoutNoThemeProvider>{children}</LayoutNoThemeProvider>
   // </ThemeProvider>
  );
}

interface LayoutNoThemeProviderProps {
  children: ReactNode;
}

function LayoutNoThemeProvider({ children }: LayoutNoThemeProviderProps) {
  //const { theme } = useContext(ThemeContext);

  return (
    <div
      className="light" 
    >
      {children}
    </div>
  );
}

export default Layout;
