import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardMedia, CardContent, Button, Grid, Hidden, Link, Typography, Stack, Paper, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import { Development, Province } from '../../types/TypesIndex';
import { getListingDefaultImagePath, getListingImagePath } from '../utils/helper/ImageHelper';
import { PAGE_TYPE } from '../../constants/constants';

interface DevelopmentSearchDevelopmentCardProps {
    development: Development;
    regionProvinceList: Province[];
}

const DevelopmentSearchDevelopmentCard: React.FC<DevelopmentSearchDevelopmentCardProps> = ({ development , regionProvinceList}) => {

    // Parameters Declaration
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;

    const [loading, setLoading] = useState(true);


    let mainImageUrl = getListingDefaultImagePath(); 
    if (development.DevelopmentImages !== null && typeof(development.DevelopmentImages.filter(image => image.IsMain === "1")[0]) !== "undefined") {
        let mainImage = development.DevelopmentImages.filter(image => image.IsMain === "1")[0];
        mainImageUrl = getListingImagePath(mainImage.ImageFileName, development?.RegionId, mainImage.HasLargeImage, "0");
    }   
    
    let developmentShortLink = `/${PAGE_TYPE.DEVELOPMENT}/` + development.DevelopmentID;
    if (development.DevelopmentShortlink !== null) {
        developmentShortLink = development.DevelopmentShortlink;
    }

    useEffect(() => {
        // Simulate a data fetch or initialization
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200); // Replace with actual loading logic

        return () => clearTimeout(timer);
    }, []);

    return (
        <>

            <Card sx={{ background: 'transparent !important', boxShadow: 'none', borderRadius: '1rem' }}>

                <Link underline="none" target="_blank" href={`${developmentShortLink}`} sx={{textDecoration: 'none !important', '&:hover': {textDecoration: 'none !important'}}}>

                    <CardMedia
                        component="img"
                        image= {mainImageUrl}
                        alt={development.DevelopmentName}
                        title={development.DevelopmentName}
                    />

                    <CardContent>

                        <Stack direction="column" spacing={1}>
                            {/* To Do - Update the URL*/}
                            <Typography className="body1-medium text-align-left" variant="body1" sx={{textDecoration: 'none !important', '&:hover': {textDecoration: 'none !important'}}}>
                                {development.DevelopmentName}
                            </Typography>

                            
                        {/* Development Location: Province, City : NOTE: no city in GT Site*/}
                        {regionProvinceList && typeof (regionProvinceList.filter(rp => rp.ProvinceID == development.ProvinceID)[0]) !== "undefined" && (
                            <Typography className="body1 text-align-left" style={{color: "var(--secondary-grey-text-color) !important"}} variant="body1" sx={{textDecoration: 'none !important', '&:hover': {textDecoration: 'none !important'}}}>
                                    {regionProvinceList.filter(rp => rp.ProvinceID == development.ProvinceID)[0].ProvinceName}
                            </Typography>
                        )}                   

                        </Stack>

                    </CardContent>

                </Link>

            </Card>
        </>
    );
};

export default DevelopmentSearchDevelopmentCard;
