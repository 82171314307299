import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Grid, Link, Container, Stack, Paper, Hidden, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check as CheckIcon } from '@mui/icons-material';
import { getDesignationLogoImagePath, getProfileDefaultImagePath, getProfileImagePath } from '../utils/helper/ImageHelper';
import { Agent, Office, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import ProfileTeamNameAndLogo from './ProfileTeamNameAndLogo';
import { PAGE_TYPE } from '../../constants/constants';

interface ProfileOfficeInfoProps {
    office?: Office;
    agentList: Agent[],
    spokenLanguages: string[];
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
    officeListingCount: number;
    macroOfficeListingCount?: number;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const ProfileOfficeInfo: React.FC<{ profileOfficeInfo: ProfileOfficeInfoProps }> = ({ profileOfficeInfo }) => {
    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const validDesignations = profileOfficeInfo.office?.Designations.filter(d => d.Name && d.Name.trim() !== '' && d.Name.trim() !== "''");
    // Category is used to match both REP and GT: 0 for non agent, 1 for SalesAssociate, 2, 3, 4 etc will be used for REP Agent Type
    const agentList = profileOfficeInfo.agentList?.filter(a => a.Category === 1);
    const nonAgentList = profileOfficeInfo.agentList?.filter(a => a.Category === 0);
    const languageCode = i18n.language;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    const [lastBorder, setLastBorder] = useState({
        OfficeDisclaimer: true,
        NonAgentList: true,
        AgentList: true,
        Teams: true,

    });

    useEffect(() => {
        if (profileOfficeInfo.office?.OfficeDisclaimer) {
            setLastBorder(prevState => ({ ...prevState, OfficeDisclaimer: false }));
        } else if (nonAgentList && nonAgentList.length > 0) {
            setLastBorder(prevState => ({ ...prevState, NonAgentList: false }));
        } else if (agentList && agentList.length > 0) {
            setLastBorder(prevState => ({ ...prevState, AgentList: false }));
        } else if (profileOfficeInfo.office && profileOfficeInfo.office?.Teams.length > 0) {
            setLastBorder(prevState => ({ ...prevState, Teams: false }));
        }

    }, []);

    if (isLoading) {
        return (
            <Stack spacing={3}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={200} />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="rectangular" height={150} />
                <Skeleton variant="text" width="40%" />
            </Stack>
        );
    }

    return (
        <>

            {/* Slogan, Closer, and Description Section */}
            {profileOfficeInfo.office && (profileOfficeInfo.office.Slogan || profileOfficeInfo.office.OfficeDescription || profileOfficeInfo.office.Closer) && (
                <Stack id="slogan-description-closer" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                    {profileOfficeInfo.office && profileOfficeInfo.office.Slogan !== null && (
                        <Typography id="slogan" dangerouslySetInnerHTML={{ __html: profileOfficeInfo.office.Slogan }} className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}></Typography>
                    )}

                    {profileOfficeInfo.office && profileOfficeInfo.office.OfficeDescription !== null && (
                        <Typography id="description" dangerouslySetInnerHTML={{ __html: profileOfficeInfo.office.OfficeDescription }} className="body1" variant="body1"></Typography>
                    )}

                    {profileOfficeInfo.office && profileOfficeInfo.office.Closer !== null && (
                        <Typography id="closer" dangerouslySetInnerHTML={{ __html: profileOfficeInfo.office.Closer }} className="body1" variant="body1"></Typography>
                    )}
                </Stack>
            )}

            {/* Bullet Points */}
            {profileOfficeInfo.office
                && (profileOfficeInfo.office.BulletPoint1 !== null || profileOfficeInfo.office.BulletPoint2 !== null || profileOfficeInfo.office.BulletPoint3 !== null || profileOfficeInfo.office.BulletPoint4 !== null)
                && (
                    <Stack id="three-bullets" spacing={2} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important', paddingTop: 0 }}>

                        {profileOfficeInfo.office.BulletPoint1 && (
                            <Typography className="body1" variant="body1">
                                <CheckIcon className="bullet-point-checkmark" />
                                <span dangerouslySetInnerHTML={{ __html: profileOfficeInfo.office.BulletPoint1 }}></span>
                            </Typography>
                        )}

                        {profileOfficeInfo.office.BulletPoint2 && (
                            <Typography className="body1" variant="body1">
                                <CheckIcon className="bullet-point-checkmark" />
                                <span dangerouslySetInnerHTML={{ __html: profileOfficeInfo.office.BulletPoint2 }}></span>
                            </Typography>
                        )}

                        {profileOfficeInfo.office.BulletPoint3 && (
                            <Typography className="body1" variant="body1">
                                <CheckIcon className="bullet-point-checkmark" />
                                <span dangerouslySetInnerHTML={{ __html: profileOfficeInfo.office.BulletPoint3 }}></span>
                            </Typography>
                        )}

                        {profileOfficeInfo.office.BulletPoint4 && (
                            <Typography className="body1" variant="body1">
                                <CheckIcon className="bullet-point-checkmark" />
                                <span dangerouslySetInnerHTML={{ __html: profileOfficeInfo.office.BulletPoint4 }}></span>
                            </Typography>
                        )}
                    </Stack>
                )
            }

            {/* Spoken Languages */}
            {profileOfficeInfo.spokenLanguages && profileOfficeInfo.spokenLanguages !== null && profileOfficeInfo.spokenLanguages.length > 0 && (
                <Stack id="languages-spoken" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                    <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                        {t('OfficeFull_LanguageSpoken')}
                    </Typography>

                    <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">

                        {profileOfficeInfo.spokenLanguages.map((uid) => (
                            <Typography variant="body1" key={uid}>
                                {getLookupTranslationById(uid, profileOfficeInfo.lookupList)}
                            </Typography>
                        ))}

                    </Stack>

                </Stack>
            )}

            {/* Certifications/Designations/Achievements Section */}
            {validDesignations && validDesignations.length > 0 && (
                <Stack id="designations" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                    <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                        {t("ListingList_Certificates")}
                    </Typography>

                    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
                        {validDesignations && validDesignations.map((d) => (
                            <Link key={d.ID}
                                underline="hover"
                                target="_blank"
                                href={(d.URL === null || d.URL === '') ? "#" : d.URL}
                                sx={{ textDecoration: 'none' }}>
                                <img src={getDesignationLogoImagePath(d.Logo)} alt={d.Name} loading="lazy" />
                            </Link>
                        ))}
                    </Stack>

                </Stack>
            )}

            {/* Team List */}
            {profileOfficeInfo.office?.Teams && profileOfficeInfo.office?.Teams !== null && profileOfficeInfo.office?.Teams.length > 0 && (

                <>
                    <Stack direction="column" spacing={3} sx={{ borderBottom: lastBorder.Teams ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.Teams ? '1rem !important' : '0 !important' }}>

                        {/* Heading */}
                        <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                            {t('OfficeFull_Teams')}
                        </Typography>

                        <Grid container sx={{ maxWidth: '100%' }}>

                            {profileOfficeInfo.office?.Teams.sort((a, b) => a.TeamName.localeCompare(b.TeamName)).map((team, index) => (

                                <Grid key={team.TeamId} xs={6} sm={3}
                                    sx={{ textAlign: 'center', paddingLeft: '0.75rem', paddingRight: '0.75rem', marginBottom: '1.5rem' }}>


                                    {parseFloat(team.TeamId) > 0 && <ProfileTeamNameAndLogo
                                        teamId={parseFloat(team.TeamId)}
                                        teamName={team.TeamName}
                                        teamRegionId={profileOfficeInfo.office?.RegionId}
                                        teamPhotoOrLogo={team.TeamLogo}
                                        teamUrl={`/${PAGE_TYPE.TEAM}/` + team.TeamId}
                                        alignment="center"
                                    />}

                                </Grid>

                            ))}

                        </Grid>

                    </Stack>

                </>
            )}

            {/* Agent List */}
            {agentList !== null && agentList.length > 0 && (

                <>
                    <Stack direction="column" spacing={3} sx={{ borderBottom: lastBorder.AgentList ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.AgentList ? '1rem !important' : '0 !important' }}>

                        {/* Heading */}
                        <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                            {t('OfficeFull_Agents')}
                        </Typography>

                        <Grid container sx={{ maxWidth: '100%' }}>

                            {agentList.sort((a, b) => a.AgentName.localeCompare(b.AgentName)).map((agent, index) => (

                                <Grid key={agent.AgentId} xs={6} sm={3}
                                    sx={{ paddingLeft: '0.75rem', paddingRight: '0.75rem', marginBottom: '1.5rem' }}>

                                    <Link
                                        underline="hover"
                                        href={
                                            (agent.AgentShortLinks !== null
                                                && agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode) !== null
                                                && agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode).length > 0) ?
                                                window.location.origin + `/${PAGE_TYPE.AGENT}/` + agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink
                                                : `/${PAGE_TYPE.AGENT}/` + agent.AgentId
                                        }
                                        target="_blank"
                                        sx={{ textDecoration: 'none' }}>

                                        <Stack direction="column" alignItems="center" spacing={2}>

                                            {/* Agent Image */}
                                            <img
                                                style={{ width: '100%', height: 216, maxHeight: 216, objectFit: 'cover', borderRadius: '1rem' }}
                                                src={getProfileImagePath(agent.RegionId, agent.AgentPhotoId) || getProfileDefaultImagePath()}
                                                loading="lazy"
                                                alt={agent.AgentName}
                                                title={agent.AgentName}
                                            />


                                            <Stack direction="column" spacing={1} useFlexGap flexWrap="wrap" sx={{ maxWidth: '100%' }}>

                                                {/* Agent Name */}
                                                <Typography className="body1-medium" variant="body1" textAlign="center" sx={{ margin: 'auto', xs: { maxWidth: '147px' }, maxWidth: '162px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                    {agent.AgentName}
                                                </Typography>

                                                {/* License Number */}
                                                {agent.AgentLicenseNumber !== null && (
                                                    <Typography className="body2 secondary-text" variant="body2" align="center">
                                                        {t('OfficeFull_LicenseNumber')} {agent.AgentLicenseNumber}
                                                    </Typography>
                                                )}

                                            </Stack>

                                        </Stack>

                                    </Link>

                                </Grid>

                            ))}

                        </Grid>

                    </Stack>

                </>
            )}


            {/* Non Agent List */}
            {nonAgentList !== null && nonAgentList.length > 0 && (

                <>
                    <Stack direction="column" spacing={3} sx={{ borderBottom: lastBorder.NonAgentList ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.NonAgentList ? '1rem !important' : '0 !important' }}>

                        {/* Heading */}
                        <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                            {t('OfficeFull_OfficeAssistant')}
                        </Typography>

                        <Grid container sx={{ maxWidth: '100%' }}>

                            {nonAgentList.sort((a, b) => a.AgentName.localeCompare(b.AgentName)).map((agent, index) => (

                                <Grid key={agent.AgentId} xs={6} sm={4} className="padding-right-24" sx={{ marginBottom: '1.5rem' }}>

                                    <Stack direction="column">

                                        {/* Agent Name */}
                                        <Typography className="body1-medium text-align-left" variant="body1" sx={{ maxWidth: '100%', whiteSpace: 'wrap', }}>
                                            {agent.AgentName}
                                        </Typography>

                                    </Stack>

                                </Grid>

                            ))}

                        </Grid>

                    </Stack>

                </>

            )}

            {/* Social Media Feeds Section Phase II */}

            {/* Office Disclaimer Section */}

            {profileOfficeInfo.office?.OfficeDisclaimer && profileOfficeInfo.office?.OfficeDisclaimer !== null && (
                <Stack id="officedisclaimer" spacing={3} sx={{ borderBottom: lastBorder.OfficeDisclaimer ? 1 : 0, borderColor: 'divider', paddingBottom: lastBorder.OfficeDisclaimer ? '2.5rem !important' : '0 !important' }}>

                    <Typography className="h4" variant="h4" sx={{ marginBottom: '0 !important' }}>
                        {t("OfficeFull_OfficeDisclaimer")}
                    </Typography>

                    <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
                        <Typography variant="body1">
                            {profileOfficeInfo.office?.OfficeDisclaimer}
                        </Typography>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default ProfileOfficeInfo;