import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';

const ListingLandInfo: React.FC<{ ListingDetail: ListingDetail, LookupList: LookupItem[] }> = ({ ListingDetail, LookupList }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const typographyClassName = isMobile ? "body1-medium" : "body1-medium";
    const typographyVariant = isMobile ? "body1" : "body1";

    if (ListingDetail.LandTitleUID === null && ListingDetail.LandTitleDescription === null && ListingDetail.LandCategories === null
        && ListingDetail.BuildingCoverageRatio === null && ListingDetail.FloorAreaRatio === null && ListingDetail.DesignatedLandUse === null) {
        return null;
    }

    var LandCategories = (ListingDetail.LandCategories && ListingDetail.LandCategories !== '') ? ListingDetail.LandCategories.split(',').map(u => getLookupTranslationById(u.toString(), LookupList)).join(', ') : '';
    var DesignatedLandUse = (ListingDetail.DesignatedLandUse && ListingDetail.DesignatedLandUse !== '') ? ListingDetail.DesignatedLandUse.split(',').map(u => getLookupTranslationById(u.toString(), LookupList)).join(', ') : '';

    return (
        <div>
            <Stack id="land-info" direction="column" spacing={2} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                {/* Land Title */}
                {ListingDetail.LandTitleUID !== null && (

                    <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="flex-start" useFlexGap flexWrap="wrap">

                        <Typography className="secondary-text">
                            {t("Listing_LandTitle")}
                        </Typography>

                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {getLookupTranslationById(ListingDetail.LandTitleUID.toString(), LookupList)}
                        </Typography>

                    </Stack>

                )}

                {/* Land Title Description */}
                {ListingDetail.LandTitleDescription && ListingDetail.LandTitleDescription !== '' && (

                    <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="flex-start" useFlexGap flexWrap="wrap">

                        <Typography className="secondary-text">
                            {t("Listing_LandTitleDescription")}
                        </Typography>

                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {ListingDetail.LandTitleDescription}
                        </Typography>

                    </Stack>

                )}

                {/* LandCategory Loop */}
                {ListingDetail.LandCategories && ListingDetail.LandCategories !== '' && (

                    <Stack direction="column" spacing={1} justifyContent="space-between" alignItems="flex-start" useFlexGap flexWrap="wrap">

                        <Typography className="secondary-text">
                            {t("Listing_LandCategory")}
                        </Typography>

                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {LandCategories}
                        </Typography>

                    </Stack>

                )}

                {/* Building Coverage Ratio */}
                {ListingDetail.BuildingCoverageRatio !== null && (

                    <Stack direction="column" spacing={1} justifyContent="space-between" alignItems="flex-start" useFlexGap flexWrap="wrap">

                        <Typography className="secondary-text">
                            {t("Listing_BuildingCoverageRatio")}
                        </Typography>

                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {ListingDetail.BuildingCoverageRatio + "%"}
                        </Typography>

                    </Stack>

                )}

                {/* Floor Area Ratio */}
                {ListingDetail.FloorAreaRatio !== null && (

                    <Stack direction="column" spacing={1} justifyContent="space-between" alignItems="flex-start" useFlexGap flexWrap="wrap">

                        <Typography className="secondary-text">
                            {t("Listing_FloorAreaRatio")}
                        </Typography>

                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {ListingDetail.FloorAreaRatio + "%"}
                        </Typography>

                    </Stack>
                )}

                {/* Designated Land Use */}
                {ListingDetail.DesignatedLandUse && ListingDetail.DesignatedLandUse !== '' && (

                    <Stack direction="column" spacing={1} justifyContent="space-between" alignItems="flex-start" useFlexGap flexWrap="wrap">

                        <Typography className="secondary-text">
                            {t("Listing_DesignatedLandUse")}
                        </Typography>

                        <Typography className={typographyClassName} variant={typographyVariant}>
                            {DesignatedLandUse}
                        </Typography>

                    </Stack>

                )}

            </Stack>

        </div>
    );
};

export default ListingLandInfo;
