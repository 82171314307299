import React from 'react';
import { Grid, Stack } from '@mui/material';
import { ProfileContactCard, ProfileOfficeInfo } from '../../components/profile/ProfileComponentsIndex';
import { Agent, Office, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { PAGE_TYPE } from '../../constants/constants';

interface ProfileOfficeMainProps {
    office?: Office;
    agentList: Agent[],
    spokenLanguages: string[];
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
    officeListingCount: number;
    macroOfficeListingCount?: number;
}

const ProfileOfficeMain: React.FC<{ profileOfficeMain: ProfileOfficeMainProps }> = ({ profileOfficeMain }) => {

    return (
        <Grid container spacing={8}>

            {/* Left Content Column */}
            <Grid item xs={12} md={8}>

                <Stack direction="column" spacing={5} useFlexGap>

                    <ProfileOfficeInfo profileOfficeInfo={{
                        office: profileOfficeMain.office,
                        agentList: profileOfficeMain.agentList,
                        spokenLanguages: profileOfficeMain.spokenLanguages,
                        lookupList: profileOfficeMain.lookupList,
                        siteSettings: profileOfficeMain.siteSettings,
                        officeListingCount: profileOfficeMain.officeListingCount,
                        //macroOfficeListingCount?: number;
                    }} />

                </Stack>

            </Grid>

            {/* Right Contact Column */}
            {profileOfficeMain.office &&
                <ProfileContactCard
                    office={profileOfficeMain.office}
                    lookupList={profileOfficeMain.lookupList}
                    siteSettings={profileOfficeMain.siteSettings}
                    page={PAGE_TYPE.OFFICE} />}
        </Grid>
    );
};

export default ProfileOfficeMain;