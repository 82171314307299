import React from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { getListingImagePath } from '../utils/helper/ImageHelper';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import ListingRoomsType from '../../types/Listing/ListingRooms';
import SiteSettings from '../../types/Settings/SiteSettings';
import { REGION_PARAMETER, UNIT_OF_MEASURE } from '../../constants/constants';

interface Props {
    listingDetail: ListingDetail;
    lookupList: LookupItem[];
    siteSettings: SiteSettings | undefined;
}

const ListingRooms: React.FC<Props> = ({ listingDetail, lookupList, siteSettings }) => {
    const { t, i18n } = useTranslation();
    const baseImageUrl = process.env.REACT_APP_BASEIMAGEURL;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



    if (listingDetail.ListingRooms !== null && listingDetail.ListingRooms.length > 0) {
        const withImage = listingDetail.ListingRooms.filter(f => f.ImageFileName != "");
        const withoutImage = listingDetail.ListingRooms.filter(f => f.ImageFileName == "");
        listingDetail.ListingRooms = [...withImage, ...withoutImage]

    }

    //TODO Review on Phase 2 if the values need come from a translation
    const measure = siteSettings?.RegionParamters.find(param => param.ParameterName == REGION_PARAMETER.UNITOFMEASURE)?.ParameterValue.trim() === UNIT_OF_MEASURE.SQM
        ? 'm<sup>2</sup>'
        : 'ft<sup>2</sup>';


    return (
        <>

            {listingDetail.ListingRooms !== null && listingDetail.ListingRooms.length > 0 && (
                <Stack id="rooms" direction="column" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>
                    <Typography className="h4" variant="h4">
                        {t("ListingFull_Rooms")}
                    </Typography>

                    <Stack direction="column" spacing={3}>
                        {listingDetail.ListingRooms.map((Room, Index) => (
                            <div key={Index}>
                                <Stack direction="row" spacing={2} alignItems="center" useFlexGap flexWrap="wrap" sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>

                                    {/* Room Image */}
                                    {Room.ImageFileName !== null && Room.ImageFileName !== '' && (
                                        <img style={{ width: isMobile ? '100%' : '35%', borderRadius: '1rem', minWidth: isMobile ? '100%' : '35%', maxWidth: isMobile ? '100%' : '35%' }}
                                            src={getListingImagePath(Room.ImageFileName, listingDetail.RegionId, "0", "0")}
                                            alt={Room.ImageFileName}
                                            loading="lazy" />
                                    )}

                                    {!(Room.RoomTypeUID === null &&
                                        (Room.ShortDescription === null || Room.ShortDescription === '') &&
                                        (Room.RoomSize === null || Room.RoomSize === '') &&
                                        (Room.Dimensions === null || Room.Dimensions === '')) && (
                                            <Stack direction="column" spacing={1}>
                                                {/* Room Type */}
                                                {Room.RoomTypeUID !== null && (
                                                    <Typography className="body1-medium" variant="body1">
                                                        {getLookupTranslationById(Room.RoomTypeUID.toString(), lookupList)}
                                                    </Typography>
                                                )}

                                                {/* Room Description */}
                                                {Room.ShortDescription != null && Room.ShortDescription !== '' && (
                                                    <Typography className="body2 secondary-text" variant="body2">
                                                        {Room.ShortDescription}
                                                    </Typography>
                                                )}

                                                {/* Room Size */}
                                                {Room.RoomSize != null && Room.RoomSize !== '' && (
                                                    <Typography className="body2 secondary-text" variant="body2">
                                                        {t("ListingFull_RoomSize")}: {Room.RoomSize}  <span dangerouslySetInnerHTML={{ __html: measure }} />
                                                    </Typography>
                                                )}

                                                {/* Room Dimensions */}
                                                {Room.Dimensions != null && Room.Dimensions !== '' && (
                                                    <Typography className="body2 secondary-text" variant="body2">
                                                        {t("ListingFull_Dimensions")}: {Room.Dimensions}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        )}
                                </Stack>
                            </div>
                        ))}
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default ListingRooms;
