import React, { useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import {
    Chip,
    Stack,
    Box,
    Hidden,
    Container,
    Grid,
    Link,
    Paper,
    AppBar,
    FormControl,
    MenuItem,
    Typography,
    Button,
    IconButton,
    Tabs,
    Tab
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { LanguageMenu, MainMenu } from './../components/utils';
import ListingSearchFreeTextSearch from '../components/search/ListingSearchFreeTextSearch';
import { useSearch } from '../reducer/searchreducer';
import ListingSearchFilterDropdowns from '../components/search/ListingSearchFilterDropdowns';
import ListingSearchMoreOptions from '../components/search/ListingSearchMoreOptions';
import ListingSearchTabFilters from '../components/search/ListingSearchTabFilters';
import { PAGE_TYPE, PROPERTY_TYPES } from '../constants/constants';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import ListingSearchProps from '../types/Listing/ListingSearch';
import { RESET_SEARCH_STATE, SET_SEARCH_STATE } from '../reducer/actionTypes';
import ListingSearchPayload from '../types/Listing/ListingSearch';
import { getLookupTranslationById } from '../components/utils/helper/LookupHelper';
import LookupItem from '../types/LookupItem';
import { fetchLookupTranslations, fetchMacroPropertyTypesTranslations } from '../services/TranslationService';
import { useNavigate } from 'react-router-dom';
import { useContent } from './ContentContext';
import parse, { domToReact } from 'html-react-parser';
import ListingSearchFilterChip from '../components/search/ListingSearchFilterChip';
import { ListingSearchFilters } from '../types/TypesIndex';
import { Search as SearchIcon } from '@mui/icons-material';
import { handleClickSearch } from '../components/utils/helper/handleClickSearch';



interface HeaderProps {
    toggleDirection: (data: string) => void;
    toggleLanguages: (data: string) => void;
}


interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}

function HideOnScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const Header: React.FC<HeaderProps> = ({ toggleDirection, toggleLanguages }) => {

    {/* Parameters Declaration */ }
    const { t, i18n } = useTranslation();

    { /* States Declaration */ }
    const [header, setHeader] = useState<any | null>(null);

    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { state, dispatch } = useSearch();
    const [join, setJoin] = React.useState('');
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [clearSearch, setClearSearch] = useState(false);
    const [htmlContent, setHtmlContent] = useState<string>('');
    const navigate = useNavigate();
    const manageContent = useContent();
    const [value, setValue] = React.useState(0);
    const [logoSrc, setLogoSrc] = useState('');
    const [HideToolBar, setHideToolBar] = useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleChangeJoin = (event: SelectChangeEvent) => {
        setJoin(event.target.value);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const removeSearchCriteria = (key: string) => {
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, [key]: null } });
    };

    const handleClearAll = () => {
        dispatch({ type: RESET_SEARCH_STATE });
    };

    const createMarkup = (htmlContent: string) => ({ __html: htmlContent });
    //   let ProfileSearchUrl = "/ProfileSearch";

    {/* useScrollTrigger hook to determine scroll direction */ }
    const trigger = useScrollTrigger();
    const loadSiteDataSource = async () => {
        const lookupList: LookupItem[] = await fetchLookupTranslations();
        setLookupList(lookupList);
    };



    useEffect(() => {
        // Simulate loading delay or perform necessary data fetching
        const timer = setTimeout(() => setIsLoading(false), 100); // Adjust delay as needed
        const language = i18n.language ?? 'en-US';
        const path = `/cms/api/v1/@apostrophecms/global/header?aposLocale=${language}`;
        fetch(`${path}`)
            .then(response => {
                //  if (!response.ok) {
                //    throw new Error('Error fetching data');
                // }
                return response.text();
            })
            .then(html => {
                if (HideToolBar) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(htmlContent, 'text/html');
                    const logoImg = doc.querySelector('img[alt="Logo"]') as HTMLImageElement;

                    if (logoImg) {
                        setLogoSrc(logoImg.src);
                    }
                }
                setHtmlContent(html);
                loadSiteDataSource();
            })
            .catch(error => {
                //console.error('Error fetching data:', error);
            });
        return () => clearTimeout(timer);
    }, [i18n.language]);

    useEffect(() => {
        const routesToHideToolBar = [
          '/listings',
          '/publiclistinglist',
          '/publiclistinglist.aspx' // Added this route
        ];
        
        // Compare lowercase versions for consistent matching:
        const currentPath = window.location.pathname.toLowerCase();
    
        // If you want an exact match, use 'includes' only for simple checking.
        // If you need partial matches, consider other logic.
        if (routesToHideToolBar.some(route => route === currentPath)) {
          setHideToolBar(true);
        } else {
          setHideToolBar(false);
        }
      }, [window.location.pathname]);

    const options = {
        replace: (domNode: any) => {
            if (domNode.name === 'div' && domNode.attribs.class && domNode.attribs.class.includes('dropdown locale-select')) {
                return <LanguageMenu data={{ toggleDirection: toggleDirection, toggleLanguages: toggleLanguages }} />;
            }
        },
    };

    if (isLoading) {
        return null; // Or a loading spinner, if desired
    }

    return (
        <>

            {header?._children?.length && (
                <div dangerouslySetInnerHTML={createMarkup(header._children[0].main.items[0].content.items[0].content || '')} />
            )}

            <Slide appear={false} direction="down" in={!trigger}>
                <AppBar position="sticky" sx={{ backgroundColor: 'white', boxShadow: 'none' }}>
                    {HideToolBar ? (
                        <div className="header">
                            <Container className="header-inner-container">
                                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item className="nav-brand" textAlign={{ xs: 'center', md: 'left' }}>
                                        <Stack direction="row" spacing={2} justifyContent={{ xs: 'space-between', md: 'space-between' }} alignItems="center">
                                            {/* Logo */}
                                            <a title={`${window.theme} logo`} href="/">
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        width: 'var(--desktop-brand-logo-width)',
                                                        aspectRatio: '92 / 17',
                                                        height: 'auto'
                                                    }}
                                                    src={logoSrc || `/images/${window.theme}/logo.svg`}
                                                    alt="logo"
                                                    title="logo"
                                                    loading="lazy"
                                                />
                                            </a>
                                            <Hidden mdUp>
                                                <Stack direction="row" spacing={2} useFlexGap>
                                                    {/* Global Language Menu (component) */}
                                                    <LanguageMenu data={{ toggleDirection: toggleDirection, toggleLanguages: toggleLanguages }} />
                                                    {/* Global Main Menu (component) */}
                                                    <MainMenu />
                                                </Stack>
                                            </Hidden>
                                        </Stack>
                                    </Grid>
                                    {/* Tab Filters (component) */}
                                    <ListingSearchTabFilters page={PAGE_TYPE.LISTINGS} />
                                    {/* Language and Menu Buttons */}
                                    <Hidden mdDown>
                                        <Grid item className="nav-actions">
                                            {/* Global Language Menu (component) */}
                                            <LanguageMenu data={{ toggleDirection: toggleDirection, toggleLanguages: toggleLanguages }} />
                                            {/* Global Main Menu (component) */}
                                            <MainMenu />
                                        </Grid>
                                    </Hidden>
                                </Grid>
                                <Stack direction="row" mt={3} spacing={2} alignItems="center" justifyContent="space-between" useFlexGap sx={{
                                    flexWrap: 'wrap',
                                    [theme.breakpoints.up('sm')]: {
                                        flexWrap: 'nowrap',
                                    },
                                    justifyContent: 'center',
                                    [theme.breakpoints.up('md')]: {
                                        justifyContent: 'unset',
                                    },
                                }}>
                                    {/* Search Bar */}
                                    <Paper className="light" elevation={0} component="form" sx={{ padding: '.5rem 1rem', borderRadius: '28px', width: '100%' }}>
                                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" useFlexGap>
                                            {/* Search Button and Search Field */}
                                            <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }} useFlexGap>
                                                {/* Listing Search Free Text Search (component) */}
                                                <ListingSearchFreeTextSearch />
                                            </Stack>
                                            {/* Listing Search Dropdown Filters (component) - Hidden on Mobile */}
                                            <Hidden lgDown>
                                                <ListingSearchFilterDropdowns page={PAGE_TYPE.LISTINGS} />
                                            </Hidden>
                                        </Stack>
                                    </Paper>
                                    <Hidden smDown>
                                        <IconButton role="button" aria-label="search" className='searchbar-button' sx={{ width: '48px', height: '48px' }}
                                            onClick={handleClickSearch(null, true, state.searchState, dispatch, null, navigate)}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </Hidden>
                                    <Hidden smUp>
                                        <Button
                                            role="button"
                                            aria-label={t('Search_CmdSearch')}
                                            className="primary-button searchbar-button"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{ width: '100%', padding: '0.75rem 1.5rem !important', color: 'white !important' }}
                                        >
                                            <Typography>{t('Search_CmdSearch')}</Typography>
                                        </Button>
                                    </Hidden>
                                </Stack>
                                <Hidden smDown>
                                    <Stack direction="row" alignItems="center" spacing={3} sx={{ width: "100%" }} useFlexGap>
                                        {/* Listing More Options (component) */}
                                        <Box mt={2}>
                                            <ListingSearchMoreOptions />
                                        </Box>
                                        <ListingSearchFilterChip lookupList={lookupList} />
                                    </Stack>
                                </Hidden>
                                <Hidden smUp>
                                    <Stack direction="column" mt={2} spacing={1} sx={{ width: "100%" }} useFlexGap>
                                        {/* Listing More Options (component) */}
                                        <Stack direction="row" display="flex" alignItems="center" justifyContent="space-between">
                                            <ListingSearchMoreOptions />
                                            {/* <Button role="button" sx={{color: 'var(--secondary-color)'}}>Reset</Button> */}
                                        </Stack>
                                        <ListingSearchFilterChip lookupList={lookupList} />
                                    </Stack>
                                </Hidden>
                            </Container>
                        </div>
                    ) : (
                        <>{!manageContent.header && (
                            <div className="header">
                                <Container maxWidth="xl" className="header-inner-container">
                                    {parse(htmlContent, options)}
                                </Container>
                            </div>
                        )}
                        </>

                    )}
                </AppBar>
            </Slide >

        </>

    );
};

export default Header;