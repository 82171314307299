import React from 'react';
import { Chip, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListingDetail from '../../types/Listing/ListingDetail';
import LookupItem from '../../types/LookupItem';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { SiteSettings } from '../../types/TypesIndex';


const ListingStatusChips: React.FC<{ listingDetail: ListingDetail, lookupList: LookupItem[] , siteSettings?: SiteSettings}> = ({ listingDetail, lookupList, siteSettings }) => {
    
    const { t, i18n } = useTranslation();
    const listingStatusUIDList = [166, 167, 168, 169, 170, 171]; // 166	Prospective, 167 Rented, 168 Sale Agreed, 169	Sold, 170	Sold by Other Agent, 171 Sold by Owner
    const marketStatusUIDList = [3226, 1903, 3225, 1902, 3448,  3399 ]; //HighlightedProperties = 3448, BestDeal = 3226, Foreclosure = 3225,  ComingSoon = 1903,  NewtotheMarket = 1902,  inAuction = 3399
    const isCapitalize  = i18n.language === "he-IL";
    let showExclusiveBanner = true;
    if (siteSettings && siteSettings?.RegionalSettings.find(rs => rs.TargetName === 'iList_OpenListings')?.HasAccess === "0" ) {
        showExclusiveBanner = false;
    }       

    return (
        <div>
            {/* Banner */}
            <Stack direction="column" spacing={1}>
                {/* To Do: PropertyCategory in Phase II */}
                {showExclusiveBanner && listingDetail.ContractTypeUID !== null && listingDetail.ContractTypeUID.toString().trim() == "25"
                    && (listingDetail.ListingStatusUID !== null 
                        && listingDetail.ListingStatusUID.toString().trim() !== "169" 
                        && listingDetail.ListingStatusUID.toString().trim() !== "167") && (
                    <Chip sx={{ color: 'white', background: 'black !important', maxWidth: 'fit-content !important' }} className="market-status-chip" label={t('ListingFull_Exclusive')}  />
                )}

                { listingDetail.ListingStatusUID !== null && listingStatusUIDList.includes(parseFloat(listingDetail.ListingStatusUID)) && (
                    <Chip sx={{ maxWidth: 'fit-content !important' }} color="secondary" className="market-status-chip" 
                    label= {isCapitalize ? getLookupTranslationById(listingDetail.ListingStatusUID.toString(), lookupList).toUpperCase() : getLookupTranslationById(listingDetail.ListingStatusUID.toString(), lookupList)} />
                )}

                {/* To Check: ListingDetail.MarketStatusUID !== null && marketStatusUIDList.includes(parseFloat(ListingDetail.MarketStatusUID))*/}
                { (listingDetail.MarketStatusUID !== null && listingDetail.ListingStatusUID.toString().trim() !== "169")
                    && (listingDetail.ListingStatusUID !== null && listingDetail.ListingStatusUID.toString().trim() !== "167") && (
                    <Chip sx={{ color: 'white', background: 'var(--primary-color) !important', maxWidth: 'fit-content !important' }} color="primary" className="market-status-chip" label={getLookupTranslationById(listingDetail.MarketStatusUID.toString(), lookupList)} />
                )}
            </Stack>
        </div>
    );
};
export default ListingStatusChips;
