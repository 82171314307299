import React from 'react';
import { Agent, Team, Office, LookupItem, SiteSettings } from '../../types/TypesIndex';
import MetaTags from '../utils/seo/MetaTags';
import { useTranslation } from 'react-i18next';
import { PAGE_TYPE } from '../../constants/constants';
import { getProfileImagePath } from '../utils/helper/ImageHelper';

interface ProfileMetaTagsProps {
    agent?: Agent;
    team?: Team;
    office?: Office
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
}

const ProfileMetaTags: React.FC<{ profileMetaTags: ProfileMetaTagsProps }> = ({ profileMetaTags }) => {

    const { t, i18n } = useTranslation();
    
    let metaTag =  {
        title: '',
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
        image: '',
        url: window.location.href,
        type: 'article',
        sitename: profileMetaTags.siteSettings?.RegionName,
        ogArticlePublisher: '',      
    }
 
    
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const hostUrl = `${url.protocol}//${url.host}/`;
    const languageCode = i18n.language;

    if (profileMetaTags.agent) {
        if (profileMetaTags.agent.AgentMetaTags &&  profileMetaTags.agent.AgentMetaTags.length > 0
        && profileMetaTags.agent.AgentMetaTags.filter(m => m.LanguageCode == languageCode) !== null
        && profileMetaTags.agent.AgentMetaTags.filter(m => m.LanguageCode == languageCode).length > 0 
        && profileMetaTags.agent.AgentMetaTags.filter(m => m.LanguageCode == i18n.language)[0]) {
            let agentMeta = profileMetaTags.agent.AgentMetaTags.filter(m => m.LanguageCode == i18n.language)[0];
        
            let agentShortLink = hostUrl + `/${PAGE_TYPE.AGENT}/` + profileMetaTags.agent?.AgentId;
            if (profileMetaTags.agent && profileMetaTags.agent?.OfficeShortLinks !== null
                && profileMetaTags.agent?.AgentShortLinks.filter(s => s.LanguageCode === languageCode) !== null
                && profileMetaTags.agent?.AgentShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
                agentShortLink = hostUrl + profileMetaTags.agent?.AgentShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

            metaTag =  {
                title: profileMetaTags.agent.AgentName,
                metaTitle: agentMeta.MetaTitle,
                metaDescription: agentMeta.MetaDescription,
                metaKeywords: profileMetaTags.agent.AgentName,
                image: profileMetaTags.agent?.AgentPhotoId !== null ? getProfileImagePath(profileMetaTags.agent?.RegionId, profileMetaTags.agent?.AgentPhotoId) : "",
                url: agentShortLink,
                type: 'article',
                sitename: profileMetaTags.siteSettings?.RegionName,
                ogArticlePublisher: '',      
            }           
        } else {
            metaTag =  {
                title: profileMetaTags.agent.AgentName,
                metaTitle: profileMetaTags.agent.AgentName,
                metaDescription: profileMetaTags.agent.Closer,
                metaKeywords: '',
                image: '',
                url: window.location.href,
                type: 'article',
                sitename: profileMetaTags.siteSettings?.RegionName,
                ogArticlePublisher: '',      
            }
        }
    } else if (profileMetaTags.office) {
        if (profileMetaTags.office.OfficeMetaTags && profileMetaTags.office.OfficeMetaTags.length > 0
            && profileMetaTags.office.OfficeMetaTags.filter(m => m.LanguageCode == languageCode) !== null
            && profileMetaTags.office.OfficeMetaTags.filter(m => m.LanguageCode == languageCode).length > 0
            && profileMetaTags.office.OfficeMetaTags.filter(m => m.LanguageCode == i18n.language)[0] ) {
                let officeMeta = profileMetaTags.office.OfficeMetaTags.filter(m => m.LanguageCode == i18n.language)[0];
            
                let officeShortLink = hostUrl + `/${PAGE_TYPE.OFFICE}/` + profileMetaTags.office?.OfficeId;
    
                metaTag =  {
                    title: profileMetaTags.office.OfficeName,
                    metaTitle: officeMeta.MetaTitle,
                    metaDescription: officeMeta.MetaDescription,
                    metaKeywords: profileMetaTags.office.OfficeName,
                    image: profileMetaTags.office?.PhotoId !== null ? getProfileImagePath(profileMetaTags.office?.RegionId, profileMetaTags.office?.PhotoId) : "",
                    url: hostUrl + `/${PAGE_TYPE.OFFICE}/` + profileMetaTags.office?.OfficeId,
                    type: 'article',
                    sitename: profileMetaTags.siteSettings?.RegionName,
                    ogArticlePublisher: '',      
                }           
            } else {
                metaTag =  {
                    title: profileMetaTags.office.OfficeName,
                    metaTitle: profileMetaTags.office.OfficeName,
                    metaDescription: profileMetaTags.office.OfficeName,
                    metaKeywords: '',
                    image: '',
                    url: window.location.href,
                    type: 'article',
                    sitename: profileMetaTags.siteSettings?.RegionName,
                    ogArticlePublisher: '',      
                }
            }        

    } else if (profileMetaTags.team) {
        if (profileMetaTags.team.TeamMetaTags && profileMetaTags.team.TeamMetaTags.length > 0
            && profileMetaTags.team.TeamMetaTags.filter(m => m.LanguageCode == languageCode) !== null
            && profileMetaTags.team.TeamMetaTags.filter(m => m.LanguageCode == languageCode).length > 0
            && profileMetaTags.team.TeamMetaTags.filter(m => m.LanguageCode == i18n.language)[0] ) {
                let teamMeta = profileMetaTags.team.TeamMetaTags.filter(m => m.LanguageCode == i18n.language)[0];
            
                let teamShortLink = hostUrl + `/${PAGE_TYPE.TEAM}/` + profileMetaTags.team?.TeamId;
    
                metaTag =  {
                    title: profileMetaTags.team.TeamName,
                    metaTitle: teamMeta.MetaTitle,
                    metaDescription: teamMeta.MetaDescription,
                    metaKeywords: profileMetaTags.team.TeamName,
                    image: profileMetaTags.team?.TeamPhotoID !== null ? getProfileImagePath(profileMetaTags.team?.RegionId, profileMetaTags.team?.TeamPhotoID) : "",
                    url: teamShortLink,
                    type: 'article',
                    sitename: profileMetaTags.siteSettings?.RegionName,
                    ogArticlePublisher: '',      
                }           
            } else {
                metaTag =  {
                    title: profileMetaTags.team.TeamName,
                    metaTitle: profileMetaTags.team.TeamName,
                    metaDescription: profileMetaTags.team.TeamName,
                    metaKeywords: '',
                    image: '',
                    url: window.location.href,
                    type: 'article',
                    sitename: profileMetaTags.siteSettings?.RegionName,
                    ogArticlePublisher: '',      
                }
            }        
    }

    return (

        <MetaTags metaTags={{ 
            title: metaTag.title, 
            metaTitle: metaTag.metaTitle, 
            metaDescription: metaTag.metaDescription,
            metaKeywords: metaTag.metaKeywords,
            image: metaTag.image,
            url: metaTag.url,
            type: metaTag.type,
            sitename: metaTag.sitename, 
            ogArticlePublisher: metaTag.ogArticlePublisher,
        }} />

    );
};

export default ProfileMetaTags;
