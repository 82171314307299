import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';

interface RegionContextProp {
    regionDomain: string | null;
    regionId: number | null;
    macroRegionId: number | null;
    tenantId: number | null;
}

// Define the type for the context
interface RegionContextType {
    regionContext: RegionContextProp | null;
}

// Create the context with a default value
const RegionContext = createContext<RegionContextType>({ regionContext: null });

// Custom hook to use the RegionContext
export const useRegion = () => useContext(RegionContext);

// Create a provider component
export const RegionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [regionContext, setRegionContext] = useState<RegionContextProp | null>(null);

    useEffect(() => {
        const data: RegionContextProp = {
            regionDomain: window.regionDomain,
            regionId: window.regionid,
            macroRegionId: window.macroregionid,
            tenantId: window.tenantid,
        };
        setRegionContext(data);
    }, []);

    // Memoize the context value to avoid unnecessary recalculations
    const contextValue = useMemo(() => ({ regionContext }), [regionContext]);

    return (
        <RegionContext.Provider value={contextValue}>
            {children}
        </RegionContext.Provider>
    );
};
