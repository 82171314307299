import React, { useState, useEffect } from 'react';
import { Typography, Toolbar, Drawer, Divider, Container } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';

import { ContactForm } from '../utils';
import { t } from 'i18next';
import SiteSettings from '../../types/Settings/SiteSettings';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { MAP_PROVIDER } from '../../constants/constants';
import { Agent, Team,  Office} from '../../types/TypesIndex';

interface ContactMobileDrawerProps {
    agentdata?: Agent | undefined;
    team?: Team | undefined;
    office?: Office | undefined;
    open: boolean;
    hideOfficeUrl?: boolean;
    toggleDrawer: () => void;
    page?: string;

}

const ContactMobileDrawer: React.FC<{ data: ContactMobileDrawerProps }> = ({ data }) => {

    const isOfficeProfile = data.office !== undefined && data.agentdata === undefined && data.team === undefined;
    const isTeamProfile = data.team !== undefined && data.office === undefined && data.agentdata === undefined;
    const isAgentProfile = data.agentdata !== undefined && data.office === undefined && data.team === undefined;


    const [openDrawer, setOpenDrawer] = useState(false);
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();


    useEffect(() => {

        const loadSiteSettings = async () => {
            const _siteSettings = await fetchSiteSettings();
            setSiteSettings(_siteSettings);
        };

        loadSiteSettings();

    }, []);

    useEffect(() => { setOpenDrawer(data.open) }, [data.open])
    const defaultMap = siteSettings?.DefaultMapProvider ?? MAP_PROVIDER.LEAFLET


    return (
        <>
            {/* Agent Profile Mobile Contact Drawer */}
            {isAgentProfile && (
                <Drawer anchor="bottom" open={openDrawer} onClose={toggleDrawer} sx={{ '& .MuiDrawer-paper': { width: '100%', maxWidth: '100%', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' } }}>

                    {/* Title & Close Button */}
                    <Toolbar>
                        <Typography className="body1-medium" variant="body1" sx={{ flexGrow: 1 }}>
                            {t("ContactMe_SendMessage")}
                        </Typography>

                        <IconButton role="button" aria-label="Toggle Drawer" onClick={toggleDrawer} color="inherit" edge="end">
                            <CloseIcon />
                        </IconButton>

                    </Toolbar>

                    {/* Divider */}
                    <Divider></Divider>

                    {/* Modal Content */}
                    <Container sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>

                        {/* Global Contact Form Component */}
                        {data.agentdata && <ContactForm data={{
                            tenantId: data.agentdata?.TenantId,
                            regionId: data.agentdata?.RegionId,
                            officeId: data.agentdata?.OfficeId,
                            agentId: data.agentdata?.AgentId.toString(),
                            agentName: data.agentdata?.AgentName,
                            page: data.page
                        }} />}

                    </Container>

                </Drawer>

            )}

            {/* Team Profile Mobile Contact Drawer */}
            {isTeamProfile && (

                <Drawer anchor="bottom" open={openDrawer} onClose={toggleDrawer} sx={{ '& .MuiDrawer-paper': { width: '100%', maxWidth: '100%', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' } }}>

                    {/* Title & Close Button */}
                    <Toolbar>
                        <Typography className="body1-medium" variant="body1" sx={{ flexGrow: 1 }}>
                            {t("ContactMe_SendMessage")}
                        </Typography>

                        <IconButton role="button" aria-label="Toggle Drawer" onClick={toggleDrawer} color="inherit" edge="end">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>

                    {/* Divider */}
                    <Divider></Divider>

                    {/* Modal Content */}
                    <Container sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>

                        {/* Global Contact Form Component */}
                        {data.team && <ContactForm data={{
                            tenantId: data.team?.TenantId,
                            regionId: data.team?.RegionId,
                            officeId: data.team?.OfficeId,
                            agentId: (
                                (data.team?.OfficeLeadAdminAgentID && data.team?.OfficeLeadAdminAgentID > 0) ? data.team?.OfficeLeadAdminAgentID.toString() 
                                : ((data.team?.TeamLeadsAdmin && data.team?.TeamLeadsAdmin > 0) ? data.team?.TeamLeadsAdmin.toString() 
                                    : ((data.team?.TeamLeaderID && data.team?.TeamLeaderID > 0) ? data.team?.TeamLeaderID.toString() : ""))),
                            agentName: data.team?.TeamName, // To Do: bug fix
                            page: data.page,
                        }} />}

                    </Container>

                </Drawer>

            )}

            {/* Office Profile Mobile Contact Drawer */}
            {isOfficeProfile && (

                <Drawer anchor="bottom" open={openDrawer} onClose={toggleDrawer} sx={{ '& .MuiDrawer-paper': { width: '100%', maxWidth: '100%', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' } }}>

                    {/* Title & Close Button */}
                    <Toolbar>
                        <Typography className="body1-medium" variant="body1" sx={{ flexGrow: 1 }}>
                            {t("ContactMe_SendMessage")}
                        </Typography>

                        <IconButton role="button" aria-label="Toggle Drawer" onClick={toggleDrawer} color="inherit" edge="end">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>

                    {/* Divider */}
                    <Divider></Divider>

                    {/* Modal Content */}
                    <Container sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>

                        {/* Global Contact Form Component */}
                        {data.office && <ContactForm data={{
                            tenantId: data.office?.TenantId,
                            regionId: data.office?.RegionId,
                            officeId: data.office?.OfficeId,
                            agentName: data.office?.OfficeName, // To Do: bug fix
                            page: data.page,
                        }} />}

                    </Container>

                </Drawer>

            )}

        </>
    );
};

export default ContactMobileDrawer;
