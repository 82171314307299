import React, { useEffect, useState } from 'react';
import { Button, Typography, IconButton, Paper, FormControl, MenuItem, OutlinedInput, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Close as CloseIcon, Favorite as FavoriteIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ListingFavouritesList from '../../components/listing/ListingFavouritesList';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSearch } from '../../reducer/searchreducer';
import SiteSettings from '../../types/Settings/SiteSettings';
import { fetchListingFilters, fetchSiteSettings } from '../../services/SiteSettingService';
import { PROPERTY_TYPES, TRANSACTION_TYPES } from '../../constants/constants';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import LookupItem from '../../types/LookupItem';
import { ListingSearchFilters } from '../../types/TypesIndex';

interface DropdownComponentProps {
    setSortOption: (value: string) => void;
    sortOption: string;
    lookupList: LookupItem[];
}

const ListingSearchTopBar: React.FC<DropdownComponentProps> = ({ setSortOption, sortOption, lookupList }) => {
    const { t, i18n } = useTranslation();
    const isRtl = i18n.dir() === 'rtl';

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { state, dispatch } = useSearch(); // Use the search context
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [listingSearchFilters, setListingSearchFilters] = useState<ListingSearchFilters>();
    
    
    useEffect(() => {
            const loadSiteData = async () => {

                const listingfilter: ListingSearchFilters = await fetchListingFilters();
                const siteSettings: SiteSettings = await fetchSiteSettings();

                setSiteSettings(siteSettings);
                setListingSearchFilters(listingfilter);
            }
            loadSiteData();
        
    }, []);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleSortChange = (event: SelectChangeEvent) => { // Corrected the type declaration
        setSortOption(event.target.value as string);
    };

    return (
        <div>
            
                                                
            {/* ToDo Review translations */}
            
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" mb={3} useFlexGap flexWrap="wrap">
                {/* To Do - Add H1 Tag */}
                <Typography className="body1" variant="h1">
                    {siteSettings?.CountryName ?? ""}
                    {" "}
                    {state.searchState.ListingClass === PROPERTY_TYPES.COMMERCIAL.toString() ? t('Search_Commercial') :
                        state.searchState.ListingClass === PROPERTY_TYPES.RESIDENTIAL.toString() ? t('Search_Residential') :
                            state.searchState.ListingClass === PROPERTY_TYPES.NOTSELECTED.toString() ? "" :
                                ""}
                    {" "}
                    {t('SEO_RealEstate')}
                    {" "}
                    {t('ListingList_AllPropertyTypes')}
                    {" "}
                    {state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORRENT.toString() ? getLookupTranslationById(TRANSACTION_TYPES.FORRENT.toString(), lookupList) :
                        state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORSALE.toString() ? getLookupTranslationById(TRANSACTION_TYPES.FORSALE.toString(), lookupList) :
                            state.searchState.TransactionTypeUID === TRANSACTION_TYPES.NOTSELECTED.toString() ? "" :
                                ""}



                </Typography>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" useFlexGap flexWrap="wrap" sx={{ width: { xs: '100%', md: 'unset' }, }}>

                    {/* Favourites Button */}
                    <Button
                        type="button"
                        role="button"
                        aria-label={t('Favourites_Header')}
                        variant="text"
                        startIcon={<FavoriteIcon sx={{ color: 'var(--body-text-color) !important;', ...(isRtl && { transform: 'scaleX(-1)' }) }} />}
                        sx={{ color: 'var(--body-text-color) !important;' }}
                        onClick={openModal}>
                        {t('Favourites_Header')}
                    </Button>

                    {/* Favourites Popup */}
                    <Dialog
                        className="customBox"
                        open={modalOpen}
                        onClose={closeModal}
                        aria-labelledby="favourites-popup"
                        aria-describedby="favourites-popup-description"
                        fullWidth
                        maxWidth="sm">

                        {/* Title */}
                        <DialogTitle sx={{ m: 0, p: 3, position: 'relative' }} id="favourites-popup">
                            <Typography className="h5" variant="h5">
                                {t('Favourites_Header')}
                            </Typography>
                        </DialogTitle>

                        {/* Close Button */}
                        <IconButton aria-label="Close" role="button" onClick={closeModal} sx={{ position: 'absolute', right: 24, top: 16 }}>
                            <CloseIcon />
                        </IconButton>

                        {/* Divider */}
                        <DialogContent dividers sx={{ padding: '1.5rem !important' }}>

                            {/* Listing Favourites List (component) */}
                            <ListingFavouritesList pageType={"listingList"} />
                        </DialogContent>

                    </Dialog>

                    {/* Sort By Selector*/}
                    <FormControl size="small" sx={{ minWidth: 120, background: 'white !important' }}>
                        <Select
                            fullWidth
                            inputProps={{ style: { fontFamily: 'var(--font-family)' } }}
                            labelId="sort-selector-label"
                            id="sort-selector"
                            input={<OutlinedInput />}
                            value={sortOption}
                            label={t('Search_PriceIncreasing')}
                            onChange={handleSortChange}>
                            <MenuItem value='Search_PriceIncreasing'>{t('Search_PriceIncreasing')}</MenuItem>
                            <MenuItem value='Search_PriceDecreasing'>{t('Search_PriceDecreasing')}</MenuItem>
                            <MenuItem value='Search_MostRecent'>{t('Search_MostRecent')}</MenuItem>
                            <MenuItem value='Search_LivingAreaIncreasing'>{t('Search_LivingAreaIncreasing')}</MenuItem>
                            <MenuItem value='Search_LivingAreaDecreasing'>{t('Search_LivingAreaDecreasing')}</MenuItem>
                        </Select>
                    </FormControl>

                </Stack>

            </Stack>

        </div>
    );
};

export default ListingSearchTopBar;