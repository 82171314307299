import React, { useState } from 'react';
import {
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Tooltip,
    Snackbar,
    Stack,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { t } from 'i18next';

interface SharePopupProps {
    open: boolean;
    onClose: () => void;
}

const SharePopup: React.FC<SharePopupProps> = ({ open, onClose }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleShare = (type: string) => {
        if (type === 'email') {
            window.location.href = `mailto:?subject=Check this out!&body=Here's the link: ${window.location.href}`;
        } else if (type === 'facebook') {
            const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
            window.open(facebookUrl, '_blank');
        } else if (type === 'copy') {
            navigator.clipboard.writeText(window.location.href).then(() => {
                setSnackbarMessage(t('ListingList_CopiedToClipboard'));
                setSnackbarOpen(true);
            });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>

            <Dialog
                className="customBox"
                open={open}
                onClose={onClose}
                aria-labelledby={t('ShareAndCompareWidget_Share')}
                aria-describedby={t('ShareAndCompareWidget_Share')}
                fullWidth
                maxWidth="sm">

                {/* Modal Title */}
                <DialogTitle sx={{ m: 0, p: 3 }} component="div">
                    <Typography className="h5" variant="h5">
                        {t('ShareAndCompareWidget_Share')}
                    </Typography>
                </DialogTitle>

                {/* Close Button */}
                <IconButton
                    aria-label="Close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 24,
                        top: 16,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {/* Modal Content */}
                <DialogContent dividers sx={{paddingTop: '1.5rem', paddingBottom: '1.5rem'}}>

                    <Stack direction="row" spacing={2}>

                        {/* Email */}
                        <Tooltip title={t('AgentFull_Email')}>
                            <img style={{ cursor: 'pointer' }} onClick={() => handleShare('email')} src='/images/common/icon-email.svg' alt={t('AgentFull_Email')} height="48" width="48" loading='lazy' />
                        </Tooltip>

                        {/* Facebook */}
                        <Tooltip title="Facebook">
                            <img style={{ cursor: 'pointer' }} onClick={() => handleShare('facebook')} src='/images/common/icon-facebook.svg' alt='Facebook' height="48" width="48" loading='lazy' />
                        </Tooltip>

                        {/* Link to Copy */}
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={window.location.href}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <IconButton
                                        aria-label="copy link"
                                        onClick={() => handleShare('copy')}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                        
                    </Stack>

                </DialogContent>

            </Dialog>

            {/* Copied to Clipboard Snackbar */}
            <Snackbar
                sx={{fontFamily: 'var(--font-family) !important'}}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                message={t('ListingList_CopiedToClipboard')}
            />
            
        </div>
    );
};

export default SharePopup;
