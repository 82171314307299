import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { Language as LanguageIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import SiteSettings from '../../types/Settings/SiteSettings';
import RegionSupportedLanguage from '../../types/Settings/RegionSupportedLanguage';
import {  TENANTS } from '../../constants/constants';

interface LanguageMenuProps {
    toggleDirection: (data: string) => void;
    toggleLanguages: (data: string) => void;
}

const LanguageMenu: React.FC<{ data: LanguageMenuProps }> = ({ data }) => {

    const { toggleDirection, toggleLanguages } = data;

    const { t, i18n } = useTranslation();
    const [openLanguageMenu, setOpenLanguageMenu] = useState<null | HTMLElement>(null);
    const [localdirection, setlocaldirection] = useState<string>("");
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);


    useEffect(() => {

        const loadSiteDataSource = async () => {
            await fetchSiteSettings().then((siteSettings) => {
                if (siteSettings == null) {
                    console.log("siteSettings loads nothing");
                } else {
                    setSiteSettings(siteSettings);

                    // To Do: validate if i18n language is supported or not. 

                    document.documentElement.lang = i18n.language;
                    setCurrentLanguage(i18n.language);

                    let languageDirection = siteSettings?.RegionSupportedLanguages.find(lang => lang.FullLanguageCode === i18n.language)?.IsRightToLeft;
                    if (languageDirection === '1') {
                        toggleDirection("rtl");
                        setlocaldirection('rtl');
                    } else {
                        toggleDirection("ltr");
                        setlocaldirection('ltr');
                    }
                }
            })
        }

        loadSiteDataSource();

    }, [i18n.language]);


    const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenLanguageMenu(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setOpenLanguageMenu(null);
    };

    const handleLanguageChange = (language: string, IsRightToLeft?: string) => {
        i18n.changeLanguage(language);
        toggleLanguages(language);
        document.documentElement.lang = language;
        setCurrentLanguage(language);

        if (IsRightToLeft === '1') {
            toggleDirection("rtl");
            setlocaldirection('rtl');
        } else {
            toggleDirection("ltr");
            setlocaldirection('ltr');
        }

        setOpenLanguageMenu(null);
    };

    
    const sortByIsDefault = (a: RegionSupportedLanguage, b: RegionSupportedLanguage) => {
        const isDefaultA = parseInt(a.IsDefault.trim(), 10);
        const isDefaultB = parseInt(b.IsDefault.trim(), 10);
        return isDefaultB - isDefaultA;
    };

    return (
        <>
            <IconButton
                role="button"
                aria-label="Select Language"
                aria-controls="language-menu"
                aria-haspopup="menu"
                onClick={handleLanguageMenuOpen}
            >
                <LanguageIcon />
            </IconButton>

            <Menu
                id="language-menu"
                anchorEl={openLanguageMenu}
                open={Boolean(openLanguageMenu)}
                onClose={handleLanguageMenuClose}
            >
                {siteSettings?.RegionSupportedLanguages?.sort(sortByIsDefault).map((item, key) =>
                    <MenuItem
                        key={key}
                        onClick={() => handleLanguageChange(item.FullLanguageCode.trim(), item.IsRightToLeft)}
                        selected={item.FullLanguageCode.trim() === currentLanguage}
                    >
                        {item.LanguageName}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default LanguageMenu;
