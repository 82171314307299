import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.REACT_APP_API_MAPBOX_KEY;

interface MapProps {
  latitude: number;
  longitude: number;
  zoom?: number;
  height?: string;
  width?: string;
  zoomControl?: boolean;
  scrollWheelZoom?: boolean;
  hideLiveMapButton?: boolean;
}

const StaticMapBox = (MapProps: MapProps) => {
  const [showInteractiveMap, setShowInteractiveMap] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const zoom = MapProps.zoom || 12;
  const width = MapProps.width || '750';
  const height = MapProps.height || '500';

  // Static Map URL (basic pin with default style)
  // const staticMapUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s(${MapProps.longitude},${MapProps.latitude})/${MapProps.longitude},${MapProps.latitude},${zoom}/${width.replace(
  //   'px',
  //   ''
  // )}x${height.replace('px', '')}?access_token=${mapboxgl.accessToken}`;

  // Ref to store the previous static map URL
  const prevStaticMapUrlRef = useRef<string | null>(null);

  // useEffect(() => {
  //   // Check if the static map URL has changed
  //   if (prevStaticMapUrlRef.current !== staticMapUrl) {
  //     console.log('Static Map URL has changed. Calling Mapbox Static API:', staticMapUrl);
  //     prevStaticMapUrlRef.current = staticMapUrl; // Store the current URL for future comparisons
  //   } else {
  //     console.log('Static Map URL is the same, no need to reload');
  //   }
  // }, [staticMapUrl]);

  useEffect(() => {
    if (showInteractiveMap && mapContainerRef.current) {
      if (!mapRef.current) {
        mapRef.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [MapProps.longitude, MapProps.latitude],
          zoom,
        });

        const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = `url('/images/${window.theme !== undefined ? window.theme : ''}/pointer.svg')`;
      el.style.width = '60px';
      el.style.height = '60px';
      el.style.backgroundSize = 'contain';
      el.style.backgroundRepeat = 'no-repeat';


        new mapboxgl.Marker(el)
          .setLngLat([MapProps.longitude, MapProps.latitude])
          .addTo(mapRef.current);
      }
    }
  }, [showInteractiveMap, MapProps.latitude, MapProps.longitude]);


  const staticMapUrl = `/map?latitude=${MapProps.latitude}&longitude=${MapProps.longitude}&zoom=${zoom}&width=${width}&height=${height}&@2x`;
  //console.log('staticMapUrl', staticMapUrl);
  return (
    <div>
      {/* Static Map */}
      {!showInteractiveMap && (
        <img src={staticMapUrl} alt="Map" style={{borderRadius: '1rem', maxWidth: '100%'}}  />
        // <img
        //   src={staticMapUrl}
        //   alt="Static Map"
        //   style={{ width, height }}
        // />
      )}

      {/* Interactive Map */}
      <div
        ref={mapContainerRef}
        style={{
          width: width + 'px',
          height: height + 'px',
          display: showInteractiveMap ? 'block' : 'none',
        }}
      ></div>

      {!MapProps.hideLiveMapButton && !showInteractiveMap && (
        <button onClick={() => setShowInteractiveMap(true)}>Live Map</button>
      )}

    </div>
  );
};

export default StaticMapBox;
