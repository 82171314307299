import axios, { AxiosRequestConfig } from 'axios';
import memoize from 'memoizee';
import SiteSettings from '../types/Settings/SiteSettings';
import ListingSearchFilters from '../types/Listing/ListingSearchFilters';
import { Team } from '../types/TypesIndex';
import { TENANTS } from '../constants/constants';

const fetchOptions: AxiosRequestConfig = {
  headers: {
    'Accept-Encoding': 'gzip, deflate'
  }
};

const cacheDuration = 60 * 60 * 1000; // Cache for 1 hour

// Correctly defining the fetchWithCache function with memoize
const fetchWithCache = memoize(
  async function <T>(url: string): Promise<T> {
    const response = await axios.get<T>(url, fetchOptions);
    return response.data;
  },
  {
    promise: true,
    maxAge: cacheDuration,
  }
);

const fetchSiteSettings = async (): Promise<SiteSettings> => {
  let url = `/sitesettings/settings.json`;


  return fetchWithCache<SiteSettings>(url);
};

const fetchListingFilters = async (): Promise<ListingSearchFilters> => {
  let url = `/sitesettings/listingfilter.json`;
  return fetchWithCache<ListingSearchFilters>(url);
};


const fetchTeamByTeamId = async (teamId: number): Promise<Team> => {
  let url = `/locales/teams/${window.tenantid}/${window.macroregionid}/${teamId}.json`;
  
  return fetchWithCache<Team>(url);
};


export { fetchSiteSettings, fetchListingFilters, fetchTeamByTeamId };
